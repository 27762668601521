import { useEffect } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer as RC,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import styled, { useTheme } from 'styled-components';
import { LCProps, PayloadProps } from '.';
import { useMatchBreakpoints } from '../../../contexts';
import {
  getDateTicker,
  getLegendPayload,
  getTickFormat,
  getTimeframeType,
  getYAxis,
} from '../../../helper';
import { ResponsiveContainer } from '../../ResponsiveContainer';
import { Tooltip as CTooltip } from '../Tooltip';

const StyledResponsiveContainer = styled(ResponsiveContainer)<{
  mobile: boolean;
  payload: PayloadProps;
}>`
  .recharts-legend-item:not(:last-child) {
    margin-right: ${({ mobile }) => (mobile ? '10px' : '20px')} !important;

    &.legend-item-1 .recharts-legend-icon {
        fill: ${({ theme }) => theme.colors.graphDot};
        stroke: ${({ payload }) =>
          payload?.y[1] ? payload?.y[1]?.color : payload?.y[0]?.color};
      }
    }
  }
  .recharts-legend-item:last-child {
    margin-right: 0 !important;
  }
  .recharts-legend-item {
    .recharts-surface {
      width: ${({ mobile }) => (mobile ? '14px' : '36px')};
    }
  }
  .recharts-legend-item-text {
    color: ${({ theme }) => theme.colors.text} !important;
    font-weight: ${({ mobile }) => (mobile ? '400' : '500')};
    font-size: ${({ mobile }) => (mobile ? '12px' : '16px')};
    line-height: ${({ mobile }) => (mobile ? '12px' : '16px')};
    letter-spacing: ${({ mobile }) => (mobile ? '0' : '0.0025em')};
  }
  .recharts-active-dot:nth-last-of-type(3) {
    .recharts-dot {
      r: ${({ mobile }) => (mobile ? '2.5' : '')};
      stroke-width: ${({ mobile }) => (mobile ? '1' : '')};
      fill: ${({ theme }) => theme.colors.backgroundModalGraph};
      stroke: ${({ payload }) =>
        payload?.y[0].activeColor
          ? payload?.y[0].activeColor
          : payload?.y[0]?.color};
    }
  }
  .recharts-active-dot:last-of-type {
    .recharts-dot {
      r: ${({ mobile }) => (mobile ? '2.5' : '')};
      stroke-width: ${({ mobile }) => (mobile ? '1' : '')};
      fill: ${({ theme }) => theme.colors.backgroundModalGraph};
      stroke: ${({ payload }) =>
        payload?.y[1]
          ? payload?.y[1]?.activeColor
            ? payload?.y[1]?.activeColor
            : payload?.y[1]?.color
          : payload?.y[0]?.activeColor
          ? payload?.y[0]?.activeColor
          : payload?.y[0]?.color};
    }
  }
`;

export const LineChart: React.FC<LCProps> = ({
  id,
  type,
  data,
  legend = false,
  payload,
  referenceAmount = undefined,
  tooltipInfo,
  ...props
}) => {
  const theme = useTheme();
  const { isSm: mobile } = useMatchBreakpoints();
  const ran1 = Math.random() * 100;
  const ran2 = Math.random() * 100;
  const tickFont = {
    fontSize: type === 'mini' ? '12px' : '14px',
    fill: theme.colors.text,
  };

  const y0 =
    payload.y[1] && payload.y[1].shareYaxis
      ? getYAxis(
          data,
          type === 'mini' ? 3 : 8,
          payload.y[0].dataKey,
          payload.y[1].dataKey
        )
      : getYAxis(data, type === 'mini' ? 3 : 8, payload.y[0].dataKey);
  const y1 =
    payload.y[1] &&
    (payload.y[1].shareYaxis
      ? y0
      : getYAxis(data, type === 'mini' ? 3 : 8, payload.y[1].dataKey));

  useEffect(() => {
    const timeout = setTimeout(() => {
      const chart = document.getElementById(id);
      if (chart) {
        // Add rebalance dot on the legend when rendering the chart
        const legends = chart.getElementsByClassName('recharts-default-legend');
        for (let count = 0; count < legends.length; count++) {
          const x2 = mobile ? 34.28 : 54.86;
          const legendLines = legends[count].getElementsByTagName('line');
          for (let lineCount = 0; lineCount < legendLines.length; lineCount++) {
            legendLines[lineCount].setAttribute('x2', x2.toString());
          }
          if (legends[count].childElementCount === 3) {
            const cx = mobile ? 15.75 : 27.75;
            const cy = theme.colors.text === '#FFFFFF' ? 16.75 : 15.75;
            const r = mobile ? 5.72 : 8;
            const strokeWidth = mobile ? 2.5 : 3.32;
            const target =
              legends[count].getElementsByClassName('recharts-surface')[1];
            target.innerHTML =
              '<title></title><desc></desc>' +
              `<line stroke-width="4" fill="none" x1="0" y1="16" x2="${x2}" y2="16" class="recharts-legend-icon"></line>` +
              `<circle cx="${cx}" cy="${cy}" r="${r}" fill="${theme.colors.graphDot}" stroke="${theme.colors.graphRebalanceStroke}" stroke-width="${strokeWidth}"></circle>`;
          }
        }
        // If there is only 1 data with 2 lines on the chart, render the 2nd dot manually.
        if (data.length === 1 && payload.y[1] && payload.y[1].shareYaxis) {
          const current = chart.getElementsByClassName('recharts-area-dot')[0];
          let target = chart.getElementsByClassName('recharts-area-dots')[1];
          if (target.getElementsByClassName('recharts-area-dot').length === 0) {
            target.innerHTML = current.outerHTML;
            target = target.getElementsByClassName('recharts-area-dot')[0];
            target.setAttribute(
              'stroke',
              payload?.y[1]?.activeColor
                ? payload?.y[1]?.activeColor
                : payload?.y[1]?.color
            );
            const padding = 20;
            const cyStr = current.getAttribute('cy');
            let cy = parseFloat(cyStr ? cyStr : '0');
            cy =
              ((data[0][payload.y[1].dataKey] - y1[1]) /
                (data[0][payload.y[0].dataKey] - y1[1])) *
                (cy - padding) +
              padding;
            target.setAttribute('cy', cy.toString());
          }
        }
      }
    }, 1);
    return () => clearTimeout(timeout);
  });

  return (
    <StyledResponsiveContainer
      id={id}
      fixedheight={mobile ? (legend ? 181 : 160) : type === 'mini' ? 231 : 452}
      mobile={mobile}
      payload={payload}
      {...props}
    >
      <RC>
        <AreaChart
          data={data}
          margin={
            mobile
              ? { right: 0, left: 0, top: 20, bottom: 0 }
              : { right: 20, left: 20, top: 20, bottom: 0 }
          }
          style={
            !mobile && type === 'mini'
              ? { cursor: 'pointer', marginTop: '12px' }
              : mobile
              ? { cursor: 'default', marginTop: '8px' }
              : { cursor: 'default', marginTop: '16px' }
          }
        >
          <Tooltip
            content={
              <CTooltip
                mini={type === 'mini'}
                value={tooltipInfo.value}
                timeFrame={getTimeframeType(data)}
              />
            }
            cursor={{
              stroke: theme.colors.cartesianGrid,
              strokeWidth: '0.75',
              strokeLinecap: 'round',
              strokeDasharray: '5 5',
            }}
            position={mobile ? { x: 4, y: -40 } : undefined}
          />

          <CartesianGrid
            vertical={false}
            style={{
              opacity: '1',
              strokeWidth: '0.75',
              strokeLinecap: 'round',
              strokeDasharray: type === 'mini' ? '3 0' : '3 3',
              stroke: theme.colors.cartesianGrid,
            }}
          />

          {referenceAmount !== undefined && type === 'full' && (
            <ReferenceLine y={0} stroke={theme.colors.cartesianGrid} />
          )}

          {legend && (
            <Legend
              payload={getLegendPayload(payload)}
              wrapperStyle={{
                fontSize: mobile ? '12px' : '16px',
                bottom: mobile ? '0' : '-4px',
              }}
            />
          )}

          <XAxis
            dataKey={payload.x.dataKey}
            height={type === 'mini' ? 20 : 30}
            strokeWidth={2}
            tick={tickFont}
            tickLine={false}
            tickFormatter={(value: any, _: number) =>
              getTickFormat(
                value,
                getTimeframeType(data),
                type === 'mini' || mobile
              )
            }
            ticks={
              data.length === 1
                ? [data[0].timestamp_sec]
                : getDateTicker(data, getTimeframeType(data))
            }
            stroke={theme.colors.axis}
          />

          <YAxis
            dataKey={payload.y[0].dataKey}
            width={type === 'mini' ? 40 : 50}
            strokeWidth={2}
            tick={tickFont}
            tickLine={false}
            domain={[y0[0], y0[1]]}
            tickFormatter={payload.y[0].tickFormatter}
            ticks={y0[2]}
            stroke={theme.colors.axis}
            hide={payload.y[0].hide}
          />

          {payload.y[1] && (
            <YAxis
              dataKey={payload.y[1].dataKey}
              width={60}
              strokeWidth={2}
              tick={tickFont}
              tickLine={false}
              domain={[y1[0], y1[1]]}
              tickFormatter={payload.y[1].tickFormatter}
              ticks={y1[2]}
              stroke={theme.colors.axis}
              yAxisId="right"
              orientation="right"
              hide={payload.y[1].hide}
            />
          )}

          <Area
            type="monotone"
            dataKey={payload.y[0].dataKey}
            stroke={payload.y[0].color}
            fillOpacity={1}
            fill={`url(#percentage${ran1})`}
            strokeDasharray={payload.y[0].legendType === 'line' ? '3 0' : '6 2'}
            strokeWidth={type === 'mini' ? 1.5 : mobile ? 1 : 2}
          />
          <defs>
            <linearGradient
              id={'percentage' + ran1}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={payload.y[0].color}
                stopOpacity={payload.y[0].fill ? 0.4 : 0}
              />
              <stop
                offset="95%"
                stopColor={payload.y[0].color}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>

          {payload.y[1] && (
            <>
              <Area
                type="monotone"
                yAxisId="right"
                dataKey={payload.y[1].dataKey}
                stroke={payload.y[1].color}
                fillOpacity={1}
                fill={`url(#price${ran2})`}
                strokeDasharray={
                  payload.y[1].legendType === 'line' ? '3 0' : '6 2'
                }
                strokeWidth={type === 'mini' ? 1.5 : mobile ? 1 : 2}
                dot={(value) => {
                  if (value.payload.rebalanced) {
                    return (
                      <svg
                        x={value.cx - (type === 'mini' || mobile ? 2 : 4.5)}
                        y={value.cy - (type === 'mini' || mobile ? 2 : 4.5)}
                        width={type === 'mini' || mobile ? '10' : '12.5'}
                        height={type === 'mini' || mobile ? '10' : '12.5'}
                        viewBox={
                          type === 'mini' || mobile
                            ? '0 0 10 10'
                            : '0 0 12.5 12.5'
                        }
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx={type === 'mini' || mobile ? '2' : '4.75'}
                          cy={type === 'mini' || mobile ? '2' : '6.75'}
                          r={type === 'mini' || mobile ? '2.5' : '3.5'}
                          fill={theme.colors.graphDot}
                          stroke={theme.colors.graphRebalanceStroke}
                          strokeWidth={type === 'mini' || mobile ? '1' : '1.5'}
                        />
                      </svg>
                    );
                  }
                  return <></>;
                }}
              />
              <defs>
                <linearGradient id={'price' + ran2} x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={payload.y[1].color}
                    stopOpacity={payload.y[1].fill ? 0.4 : 0}
                  />
                  <stop
                    offset="95%"
                    stopColor={payload.y[1].color}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
            </>
          )}
        </AreaChart>
      </RC>
    </StyledResponsiveContainer>
  );
};
