import React from 'react';
import { useStore } from '../store';
import textContext from '../textContext';

export default function Credits() {
  const language = useStore((state) => state.appSlice.language);
  const text = textContext[language].credits;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="textClass container">
      <div>
        <h1>{text.title}</h1>
        <div className="flex">
          <p style={{ margin: 0, paddingRight: 4 }}>{text.text}</p>{' '}
          <a
            className="sub linkClass"
            target="_blank"
            rel="noreferrer"
            href={text.link[0]}
          >
            {text.link[1]}
          </a>
        </div>
      </div>
    </div>
  );
}
