import * as amplitude from '@amplitude/analytics-browser';
import {
  MetamaskIcon,
  OkxDarkIcon,
  OkxIcon,
  WalletConnectIcon,
} from '@aperture/assetkit';
import { logErr } from 'helpers/dev';
import { useCallback } from 'react';
import { useStore } from 'store';
import { useTheme } from 'styled-components';
import {
  Blockchain,
  chainList,
  isProdEnv,
} from '../../../../constants/crosschain';
import { ModalProps } from '../../../layout/ModalsContainer';
import { Modal } from '../../Modal/Modal';
import { WalletConnect } from '../../Wallet';
import { SelectNetwork } from '../../Wallet/SelectNetwork';
import './connect.scss';

amplitude.init(
  process.env.REACT_APP_AMPLITUDE_API_KEY!,
  process.env.REACT_APP_AMPLITUDE_USER_ID
);

export const Connect = ({ onClose }: ModalProps) => {
  const theme = useTheme();
  const { isWalletConnected, chainProvider, chain, setChain } = useStore(
    (state) => state.chainSlice
  );

  const amplitudeRecordWalletType = (walletType: string) => {
    let amplitudeEventName = '';
    switch (walletType) {
      case 'injected':
        amplitudeEventName = 'Metamask';
        break;
      case 'custom-okxwallet':
        amplitudeEventName = 'OKXWallet';
        break;
      case 'coinbasewallet':
        amplitudeEventName = 'CoinbaseWallet';
        break;
      case 'walletconnect':
        amplitudeEventName = 'WalletConnect';
        break;
    }
    amplitudeEventName !== '' && amplitude.track(amplitudeEventName);
  };

  const onChainSelect = useCallback(
    (chainId: Blockchain) => {
      setChain(chainId);
    },
    [setChain]
  );
  const onWalletSelect = async (walletType: any) => {
    if (chainProvider) {
      try {
        await chainProvider?.connectWallet(walletType);
        isProdEnv() && amplitudeRecordWalletType(walletType);
      } catch (err) {
        console.log(err);
        logErr(err);
      }
    }
  };

  return (
    <>
      {!isWalletConnected && (
        <Modal onClose={onClose}>
          <SelectNetwork
            chainList={chainList}
            selectedChain={chain}
            onSelect={(chain) => {
              onChainSelect(chain);
            }}
          />
          <WalletConnect
            walletList={[
              {
                walletType: 'injected',
                name: 'MetaMask',
                icon: MetamaskIcon,
              },
              {
                walletType: 'custom-okxwallet',
                name: 'OKX Wallet',
                icon: theme.colors.text === '#FFFFFF' ? OkxDarkIcon : OkxIcon,
              },

              {
                walletType: 'walletconnect',
                name: 'Wallet Connect',
                icon: WalletConnectIcon,
              },
            ]}
            onChainSelect={onChainSelect}
            onWalletSelect={onWalletSelect}
          />
        </Modal>
      )}
    </>
  );
};
