import React from 'react';
import styled from 'styled-components';
import { Headline5 } from '../../../../components/Typography';
import {
  APYGroup,
  CurrentValueAndProfits,
  StrategyType,
  TVLAndCapacity,
} from '../../StrategyCardContainer';
import { OverviewProps } from './types';

const StyledVault = styled.div`
  margin-bottom: 16px;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 12px;
  color: ${({ theme }) => theme.colors.text};
`;
const StyledLine = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;
const Container = styled.div`
  padding: 16px 0px 12px;
`;

const Overview: React.FC<OverviewProps> = ({
  vaultItem,
  strategyType,
  tvlCapacity,
  apy,
  position,
}) => {
  return (
    <Container>
      <StyledVault>{vaultItem}</StyledVault>
      <StyledContainer>
        <Headline5>Overview</Headline5>
        {position && position.active && (
          <>
            <CurrentValueAndProfits position={position} />
            <StyledLine />
          </>
        )}
        <StrategyType strategyType={strategyType} />
        <TVLAndCapacity tvlCapacity={tvlCapacity} />
        <APYGroup apy={apy} />
      </StyledContainer>
    </Container>
  );
};

export default Overview;
