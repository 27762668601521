import React from 'react';
import { ColoredValue } from '../../../components/ColoredValue';
import { Subtitle1 } from '../../../components/Typography';
import { StyledInfoDetail, StyledInfoTitle } from './SharedComponents';
import {
  CurrentValueAndProfitsProps,
} from './types';

export const CurrentValueAndProfits: React.FC<CurrentValueAndProfitsProps> = ({
  position,
}) => {
  return (
    <>
      <StyledInfoDetail>
        <StyledInfoTitle>Current Value</StyledInfoTitle>
        <Subtitle1>{position.currentValue}</Subtitle1>
      </StyledInfoDetail>
      <StyledInfoDetail>
        <StyledInfoTitle>Profits</StyledInfoTitle>
        <Subtitle1>
          <ColoredValue value={Number(position.profits[0])}>
            {position.profits[1]}
          </ColoredValue>
        </Subtitle1>
      </StyledInfoDetail>
    </>
  );
};
