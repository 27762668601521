import * as AWS from 'aws-sdk';
import RedshiftData from 'aws-sdk/clients/redshiftdata';
import { createContext } from 'react';

export const AWSDocumentClientContext = createContext({});
const keyConfiguration = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};
AWS.config.update(keyConfiguration);
const testRegions = ['us-west-2', 'eu-west-2', 'ap-southeast-1'];

const testConnections = () => {
  let promises = [];
  const connectionTestParams = {
    ExpressionAttributeValues: {
      ':id': { N: '0' },
    },
    KeyConditionExpression: 'id = :id',
    TableName: 'aperture_whitelist_address',
  };
  for (let count = 0; count < testRegions.length; count++) {
    const ddb = new AWS.DynamoDB({
      apiVersion: '2012-08-10',
      region: testRegions[count],
    });
    promises.push(ddb.query(connectionTestParams).promise());
  }
  return promises;
};

export const dynamoClient = Promise.race(testConnections()).then((response) => {
  const region = response.$response.request.httpRequest.region;
  AWS.config.update({ region });
  return new AWS.DynamoDB.DocumentClient();
});

export const redshiftClient = new RedshiftData({ region: 'us-west-2' });
