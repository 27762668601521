import axios, { AxiosInstance } from 'axios';

export const homoraApi: AxiosInstance = axios.create({
  baseURL: 'https://api.homora.alphaventuredao.io/v2/43114/',
});

export const homoraEndPoints = {
  apys: 'apys',
};

export interface HomoraApyResponse {
  [key: string]: {
    totalAPY: string;
    tradingFeeAPY: string;
    farmingAPY: string;
  };
}

export const ironBankApi: AxiosInstance = axios.create({
  baseURL: 'https://api.ib.xyz/api/v1/',
});

export const ironBankEndPoints = {
  rates: 'rates?comptroller=avalanche',
};

export interface ironBankRate {
  apr: string;
  apy: string;
  tokenSymbol: string;
}

export interface ironBankApyResponse {
  block_number: Number;
  borrowRates: ironBankRate[];
  lendRates: ironBankRate[];
}

export interface ProtocolDataResponses {
  apys: HomoraApyResponse;
  rates: ironBankApyResponse;
}
