import {
  calculate_timeframe_apy,
  PortfolioCard,
  UnderlyingPool,
  VaultItem,
} from '@aperture/uikit';
import {
  useFetchRebalance,
  useFetchReturn,
  useFetchUserGraphInfo,
} from 'features/graph/useFetchGraphData';
import {
  getMaximumDrawdown,
  getPerformanceData,
  getStrategyRebalance,
} from 'helpers/graph';
import React, { Fragment, useEffect, useMemo } from 'react';
import {
  StrategyTitleTextMap,
  StrategyType,
  StrategyTypeTextMap,
} from '../../constants/crosschain';
import { Strategy, StrategyInfoMap } from '../../constants/strategy';
import { useFetchStrategy } from '../../features/strategies/hooks';
import {
  calculateThreshold,
  isTVLGreaterOrEqualToCapacity,
  numberParseLarge,
  profitsDataParser,
  rebalanceDataParser,
  timestampToUTCString,
} from '../../helpers/utilities';
import { useStore } from '../../store';
import { useOpenModal } from '../../store/base/application/hooks';
import { ModalType } from '../../store/base/AppSlice';
import { PositionInfo } from '../../types';
import { Button } from '../v1';

interface ITableAssetsProps {
  isExtra?: boolean;
  info?: Object;
  isOpen: React.Dispatch<React.SetStateAction<boolean>>;
  strategy: Strategy;
  setStrategyInfoMap: React.Dispatch<React.SetStateAction<StrategyInfoMap>>;
  positionInfo: PositionInfo;
  title: string;
  strategyOrder: number;
}

export default function TablePositions(props: ITableAssetsProps) {
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const { strategy, positionInfo, title, strategyOrder, setStrategyInfoMap } =
    props;
  const { isLoading: strategyIsLoading, data } = useFetchStrategy(strategy);
  const { data: returns } = useFetchReturn(strategy.strategyId);
  const { data: rebalance } = useFetchRebalance();
  const { data: performance } = useFetchUserGraphInfo(
    positionInfo?.positionId ?? ''
  );
  const performanceList = getPerformanceData(
    performance,
    positionInfo?.userEquity,
    strategy?.tokens[0].decimals
  );
  const tvlGteCapacity = useMemo(
    () =>
      data
        ? isTVLGreaterOrEqualToCapacity(data.TVL, data.capacity, data.leverage)
        : false,
    [data]
  );
  const inception_Date = useMemo(
    () =>
      returns && returns.inception.length > 0
        ? timestampToUTCString(
            returns.inception[0]!.timestamp_sec,
            'DayMonthYear'
          )
        : '--',
    [returns]
  );
  const return_Changes = useMemo(
    () =>
      returns && returns.inception.length > 0
        ? returns.inception[returns.inception.length - 1].percentage
        : Infinity,
    [returns]
  );
  const threshold = useMemo(
    () =>
      calculateThreshold(data?.priceMovementThreshold ?? 0, strategy.leverage),
    [data, strategy]
  );
  const maximum_Drawdown = useMemo(
    () => getMaximumDrawdown(returns?.inception ?? []),
    [returns]
  );
  const number_Rebalance = useMemo(
    () =>
      rebalanceDataParser(
        getStrategyRebalance(rebalance ?? [], strategy.strategyId),
        returns && returns.inception.length > 0
          ? returns.inception[0]!.timestamp_sec
          : Infinity
      ),
    [returns, rebalance, strategy]
  );

  const apy_7days = useMemo(
    () => (returns?.week ? calculate_timeframe_apy(returns?.week) : undefined),
    [returns?.week]
  );
  const apy_season = useMemo(
    () =>
      returns?.season ? calculate_timeframe_apy(returns?.season) : undefined,
    [returns?.season]
  );

  const profits_Text = useMemo(() => {
    const text_value =
      positionInfo && positionInfo.profits
        ? profitsDataParser(
            positionInfo.equity,
            positionInfo.profits,
            strategy.tokens[0].ticker
          )
        : '--';
    return positionInfo && positionInfo.profits
      ? [positionInfo.profits.profits, text_value]
      : ['', text_value];
  }, [positionInfo, strategy.tokens]);

  useEffect(() => {
    if (data?.strategyId) {
      setStrategyInfoMap((prev: StrategyInfoMap) => {
        return {
          ...prev,
          [data.strategyId]: {
            ...data,
          },
        };
      });
    }
  }, [data, setStrategyInfoMap]);

  const vaultItem = () => {
    return (
      <VaultItem
        tokens={strategy.tokens}
        protocols={strategy.protocols}
        showNewTag={strategy.strategyType === StrategyType.PSEUDO_1X_LONG}
        leverage={strategy.leverage}
      />
    );
  };

  const modalProps = {
    strategyInfo: data,
    positionInfo,
    vaultItem: vaultItem(),
    title: StrategyTitleTextMap[strategy.strategyType],
  };
  const openInvestModal = useOpenModal(ModalType.INVEST, {
    invest: true,
    ...modalProps,
  });

  const openWithdrawModal = useOpenModal(ModalType.INVEST, {
    invest: false,
    ...modalProps,
  });
  const openPDNDetailsModal = useOpenModal(ModalType.PDN_DETAILS, {
    strategyInfo: data ?? null,
    positionInfo: positionInfo ?? null,
    vaultTitle: title,
  });
  const handleInvest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    openInvestModal();
  };
  const handleWithdraw = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    openWithdrawModal();
  };

  return (
    <Fragment>
      <PortfolioCard
        ratio="260px 12fr 24fr 16.5fr 16.5fr 18fr"
        order={strategyOrder}
        vaultItem={vaultItem()}
        buttonGroup={
          <>
            {!props.isExtra && (
              <Button
                variant="outline"
                size="small"
                text="Deposit"
                disabled={tvlGteCapacity}
                onClick={(event) => handleInvest(event)}
                style={{
                  padding: '1px 9px',
                  width: '96px',
                }}
              />
            )}
            <Button
              variant="info"
              size="small"
              text="Withdraw"
              disabled={Number(positionInfo.equity) > 0 ? false : true}
              onClick={(event) => handleWithdraw(event)}
              style={{ padding: `1px 9px`, width: '96px' }}
            />
          </>
        }
        charts={[
          {
            chartsType: 'performance',
            chartsData: performanceList,
            chartsId: positionInfo?.positionId ?? '',
          },
          {
            chartsType: 'return',
            chartsData: returns,
            chartsId: strategy.strategyId,
          },
        ]}
        tokens={[strategy.tokens[0].ticker, strategy.tokens[1].ticker]}
        openStrategyDetails={() => openPDNDetailsModal()}
        strategy={{
          leverage: strategy.leverage,
          inceptionDate: inception_Date,
          returnChanges: return_Changes,
          threshold: threshold,
          sharpRatio: 5.28, //missing
          maxDrawdown: maximum_Drawdown,
          rebalances: number_Rebalance,
          underlyingPool: (
            <UnderlyingPool
              icon={strategy.protocols[0].icon}
              text={`${strategy.protocols[0].name} ${strategy.tokens[0].ticker}-${strategy.tokens[1].ticker}`}
            />
          ),
          strategyType:
            StrategyTypeTextMap[strategy.strategyType] === 'bull'
              ? 'bull'
              : 'crab',
          tvlCapacity:
            strategyIsLoading || !data || !data.TVL || !data.capacity
              ? undefined
              : {
                  content: `$${numberParseLarge(
                    data?.TVL ?? 0,
                    2
                  )} / ${numberParseLarge(data?.capacity ?? 0, 2)}`,
                  percentage: Number(data?.TVL) / Number(data?.capacity),
                },
          apy: {
            apy: data?.apy === undefined ? undefined : Number(data?.apy),
            apy_7days: apy_7days,
            apy_season: apy_season,
          },
          position: {
            active: positionInfo ? true : false,
            currentValue: positionInfo
              ? `${numberParseLarge(
                  positionInfo?.userEquity ?? 0,
                  positionInfo?.userEquity ?? 0 > 0.1 ? 2 : 4
                )} ${strategy.tokens[0].ticker}`
              : '--',
            profits: profits_Text,
          },
        }}
        MobileButtonGroup={
          <>
            <Button
              variant="outline"
              size="small"
              text={'Deposit'}
              onClick={(event) => handleInvest(event)}
              disabled={tvlGteCapacity || !isWalletConnected}
              style={{ width: '40%', padding: '3px' }}
            />
            <Button
              variant="info"
              size="small"
              text={'Withdraw'}
              onClick={(event) => handleWithdraw(event)}
              disabled={
                !(
                  isWalletConnected &&
                  positionInfo &&
                  positionInfo.equity &&
                  Number(positionInfo.equity) > 0
                )
              }
              style={{ width: '40%', padding: '3px' }}
            />
          </>
        }
        isLoading={!data || !returns || !performance}
      />
    </Fragment>
  );
}
