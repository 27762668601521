import React from 'react';
import styled from 'styled-components';
import { Headline5 } from '../../../../components/Typography';
import { useMatchBreakpoints } from '../../../../contexts';
import { HowItWorksProps } from './types';

const StyledVault = styled.div`
  margin-bottom: 16px;
`;
const StyledContainer = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ sm }) => (sm ? '12px' : '16px')};
  font-weight: 400;
  font-size: ${({ sm }) => (sm ? '12px' : '14px')};
  line-height: ${({ sm }) => (sm ? '18px' : '21px')};
  color: ${({ theme }) => theme.colors.text};
`;
const StyledHeader = styled.span<{ sm: boolean }>`
  color: ${({ theme, sm }) =>
    sm ? theme.colors.textCardDetail : theme.colors.text};
`;
const StyledStepContainer = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: ${({ sm }) => (sm ? 'column' : 'row')};
  padding: 0;
  gap: ${({ sm }) => (sm ? '12px' : '36px')};
`;
const StyledStepDetail = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ sm }) => (sm ? '8px' : '13px')};
`;
const Container = styled.div<{ sm: boolean }>`
  padding: ${({ sm }) => (sm ? '16px 0px' : '16px 32px')};
`;
const HowItWorks: React.FC<HowItWorksProps> = ({
  vaultItem,
  tokenA,
  tokenB,
  leverage,
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <Container sm={isSm}>
      {isSm && <StyledVault>{vaultItem}</StyledVault>}
      <StyledContainer sm={isSm}>
        <StyledHeader sm={isSm}>
          Below is an example of how your earn yield through this leveraged
          farming vault:
        </StyledHeader>
        <StyledStepContainer sm={isSm}>
          <StyledStepDetail sm={isSm}>
            <Headline5>Step 1 - Your move</Headline5>
            <span>
              Deposit <b>200 {tokenA}</b> (yield earning token) into the
              selected vault:{' '}
              <b>
                Earn {tokenA}/{tokenA}-{tokenB}({leverage}
                X) Vault
              </b>
              .
            </span>
          </StyledStepDetail>
          <StyledStepDetail sm={isSm}>
            <Headline5>Step 2</Headline5>
            <span>
              Aperture will borrow twice as much for you. That’s{' '}
              <b>$100 {tokenA}</b> and <b>$300 {tokenB}</b> borrowed. We will
              also conduct certain swaps to ensure delta neutrality on your{' '}
              {tokenB} tokens.
            </span>
          </StyledStepDetail>
          <StyledStepDetail sm={isSm}>
            <Headline5>Step 3</Headline5>
            <span>
              You are now farming with 3 times the money, and earning 3X the
              yields on designated DEX pool. Your LP position is now{' '}
              <b>
                $300 {tokenB} — $300 {tokenA}
              </b>
              .
            </span>
          </StyledStepDetail>
          <StyledStepDetail sm={isSm}>
            <Headline5>Step 4</Headline5>
            <span>
              Aperture will <b>automatically rebalance</b> for you as the price
              of {tokenB} moves across the rebalance threshold and{' '}
              <b>compound</b> your earnings periodically over time.
            </span>
          </StyledStepDetail>
        </StyledStepContainer>
      </StyledContainer>
    </Container>
  );
};

export default HowItWorks;
