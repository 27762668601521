import { SvgProps } from '..';

export const CrossIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M8.09836 6.9997L13.7715 1.32653C14.0754 1.0227 14.0754 0.529276 13.7715 0.227874C13.4677 -0.0735275 12.9743 -0.0759581 12.6729 0.227874L6.9997 5.90104L1.32653 0.227874C1.0227 -0.0759581 0.529276 -0.0759581 0.227874 0.227874C-0.0735275 0.531707 -0.0759581 1.02513 0.227874 1.32653L5.90104 6.9997L0.227874 12.6729C-0.0759581 12.9767 -0.0759581 13.4701 0.227874 13.7715C0.378575 13.9222 0.57789 14 0.777204 14C0.976518 14 1.17583 13.9246 1.32653 13.7715L6.9997 8.09836L12.6729 13.7715C12.8236 13.9222 13.0229 14 13.2222 14C13.4215 14 13.6208 13.9246 13.7715 13.7715C14.0754 13.4677 14.0754 12.9743 13.7715 12.6729L8.09836 6.9997Z"
        fill="black"
      />
    </svg>
  );
};
