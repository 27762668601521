import React from 'react';
import styled from 'styled-components';
import { Label } from '../../../components/Label';
import { Headline5 } from '../../../components/Typography';
import { useMatchBreakpoints } from '../../../contexts';
import { VaultItemProps } from './types';

const VaultTitle = styled(Headline5)<{ sm: boolean; biggerSize: boolean }>`
  margin-bottom: 8px;
  font-weight: ${({ sm }) => (sm ? 600 : 500)};
  font-size: ${({ biggerSize }) => (biggerSize ? '18px' : '16px')};
  line-height: ${({ biggerSize }) => (biggerSize ? '18px' : '16px')};
  height: ${({ biggerSize }) => (biggerSize ? '18px' : '16px')};
  letter-spacing: ${({ biggerSize }) => (biggerSize ? '0' : '0.0025em')};
`;
const VaultItemContainer = styled.div`
  display: flex;
  text-transform: initial;
  font-size: 14px;
  font-weight: 500;
`;
const TokenContainer = styled.div<{ sm: boolean }>`
  display: flex;
  margin-top: 0;
  margin-left: 0rem;
  margin-right: ${({ sm }) => (sm ? '4px' : '12px')};
`;
const TokenInfo = styled.div<{ biggerSize: boolean }>`
  display: flex;
  > svg:first-child {
    width: ${({ biggerSize }) => (biggerSize ? '40px' : '36px')};
    height: ${({ biggerSize }) => (biggerSize ? '40px' : '36px')};
  }
  > svg:not(:first-child) {
    margin-left: -7px;
    margin-top: 20px;
    z-index: 1;
    width: ${({ biggerSize }) => (biggerSize ? '18px' : '16px')};
    height: ${({ biggerSize }) => (biggerSize ? '18px' : '16px')};
  }
`;
const VaultInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const VaultInfo = styled.div`
  display: flex;
`;
const VaultLeverage = styled.div<{ sm: boolean; biggerSize: boolean }>`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ sm }) => (sm ? 400 : 500)};
  font-size: ${({ sm, biggerSize }) => (sm || biggerSize ? '14px' : '12px')};
  line-height: ${({ sm, biggerSize }) => (sm || biggerSize ? '14px' : '12px')};
  height: ${({ sm, biggerSize }) => (sm || biggerSize ? '14px' : '12px')};
`;
const LabelWrap = styled.div<{ sm: boolean }>`
  margin-left: ${({ sm }) => (sm ? '6px' : '4px')};
  margin-top: -8px;
`;
const ProtocolContainer = styled.div`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.subText};

  > svg {
    width: 16px;
    height: 16px;
    padding-right: 4px;
    filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.199301));
  }
`;
const ProtocolWrap = styled.p<{ sm: boolean; biggerSize: boolean }>`
  margin: 0;
  font-weight: 500;
  font-size: ${({ sm, biggerSize }) => (sm || biggerSize ? '14px' : '12px')};
  line-height: ${({ sm, biggerSize }) => (sm || biggerSize ? '14px' : '12px')};
  height: ${({ sm, biggerSize }) => (sm || biggerSize ? '14px' : '12px')};
  color: ${({ theme }) => theme.colors.subText};
`;

const VaultItem: React.FC<VaultItemProps> = ({
  tokens,
  protocols,
  showNewTag,
  leverage,
  hideTitle = false,
  biggerSize = false,
}) => {
  const { isSm } = useMatchBreakpoints();
  const SourceIcon = tokens[0].icon;
  const TargetIcon = tokens[1].icon;
  const ProtocolIcon = protocols[0].icon;
  return (
    <>
      {!hideTitle && (
        <VaultTitle sm={isSm} biggerSize={biggerSize}>
          Earn {tokens[0].ticker}
        </VaultTitle>
      )}
      <VaultItemContainer>
        <TokenContainer sm={isSm}>
          <TokenInfo biggerSize={biggerSize}>
            <SourceIcon />
            <TargetIcon />
          </TokenInfo>
        </TokenContainer>
        <VaultInfoContainer>
          <VaultInfo>
            <VaultLeverage sm={isSm} biggerSize={biggerSize}>
              {' '}
              {tokens[0].ticker}-{tokens[1].ticker}({leverage}X){' '}
            </VaultLeverage>
            {showNewTag && (
              <LabelWrap sm={isSm}>
                <Label> New! </Label>
              </LabelWrap>
            )}
          </VaultInfo>
          {protocols && (
            <ProtocolContainer>
              <ProtocolIcon />
              <ProtocolWrap sm={isSm} biggerSize={biggerSize}>
                {protocols[0].name}
              </ProtocolWrap>
            </ProtocolContainer>
          )}
        </VaultInfoContainer>
      </VaultItemContainer>
    </>
  );
};

export default VaultItem;
