import {
  useCloseModal,
  useCurrentOpenModal,
} from '../../store/base/application/hooks';
import { ModalType } from '../../store/base/AppSlice';
import {
  PdnStrategyDetails,
  PdnStrategyDetailsProps,
} from '../v1/Components/Strategy/Dropdown/PdnStrategyDetails';
import Asset from '../v1/Modals/AssetModal';
import { AssetProps } from '../v1/Modals/AssetModal/index';
import {
  ConfirmationModal,
  ConfirmationProps,
  RejectedModal,
  SubmittedModal,
} from '../v1/Modals/TransactionModals';
import { WrongNetworkModal } from '../v1/Modals/WrongNetworkModal';
import { Connect } from '../v1/TopBar/Connect';
export type ModalProps = {
  onClose: () => void;
};
export const ModalsContainer = () => {
  const currentOpenModal = useCurrentOpenModal();
  const closeModal = useCloseModal();
  return (
    <div className="modals-container">
      {currentOpenModal?.modal === ModalType.CONFIRMATION && (
        <ConfirmationModal
          onClose={closeModal}
          {...(currentOpenModal.modalProps as ConfirmationProps)}
        />
      )}
      {currentOpenModal?.modal === ModalType.TXN_REJECTED && (
        <RejectedModal onClose={closeModal} />
      )}
      {currentOpenModal?.modal === ModalType.TXN_SUBMITTED && (
        <SubmittedModal onClose={closeModal} />
      )}
      {currentOpenModal?.modal === ModalType.INVEST && (
        <Asset
          onClose={closeModal}
          {...(currentOpenModal.modalProps as AssetProps)}
        />
      )}
      {currentOpenModal?.modal === ModalType.NETWORK_SELECTOR && (
        <Connect onClose={closeModal} />
      )}
      {currentOpenModal?.modal === ModalType.WRONG_NETWORK && (
        <WrongNetworkModal onClose={closeModal} />
      )}
      {currentOpenModal?.modal === ModalType.PDN_DETAILS && (
        <PdnStrategyDetails
          onClose={closeModal}
          {...(currentOpenModal.modalProps as PdnStrategyDetailsProps)}
        />
      )}
    </div>
  );
};
