import { CrossIcon } from '@aperture/assetkit';
import {
  Backtest,
  BacktestPayloadProps,
  numberParse,
  OverallInfo,
  Performance,
  PerformancePayloadProps,
  Return,
  ReturnPayloadProps,
  UnderlyingPool,
  VaultItem,
} from '@aperture/uikit';
import { Modal } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  StrategyTitleTextMap,
  StrategyType,
  StrategyTypeTextMap,
} from '../../../../../../constants/crosschain';
import { StrategyInfo } from '../../../../../../constants/strategy';
import {
  useFetchBacktestAnalysis,
  useFetchRebalance,
  useFetchReturn,
  useFetchUserGraphInfo,
} from '../../../../../../features/graph/useFetchGraphData';
import {
  calculate_timeframe_apy,
  getMaximumDrawdown,
  getPerformanceData,
  getStrategyRebalance,
  TimeframeType,
} from '../../../../../../helpers/graph';
import {
  calculateThreshold,
  isTVLGreaterOrEqualToCapacity,
  numberParseLarge,
  profitsDataParser,
  rebalanceDataParser,
  timestampToUTCString,
} from '../../../../../../helpers/utilities';
import { useStore } from '../../../../../../store';
import { useOpenModal } from '../../../../../../store/base/application/hooks';
import { ModalType } from '../../../../../../store/base/AppSlice';
import { PositionInfo } from '../../../../../../types';
import { ModalProps } from '../../../../../layout/ModalsContainer';
import { Button } from '../../../Button';
import './index.scss';
export interface PdnStrategyDetailsProps {
  strategyInfo: StrategyInfo;
  positionInfo: PositionInfo | null;
  vaultTitle: string;
  scrollToReturn?: boolean;
  scrollToAnalysis?: boolean;
}
const StyledCrossIcon = styled(CrossIcon)`
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

export const PdnStrategyDetails = ({
  strategyInfo,
  vaultTitle,
  scrollToReturn,
  scrollToAnalysis,
  positionInfo,
  onClose,
}: PdnStrategyDetailsProps & ModalProps) => {
  const returnRef = useRef(null);
  const analysisRef = useRef(null);
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );

  const { data: backtestAnalysisData } = useFetchBacktestAnalysis();
  const { data: rebalance } = useFetchRebalance();
  const { data: returns } = useFetchReturn(strategyInfo.strategyId);
  const { data: performance } = useFetchUserGraphInfo(
    positionInfo?.positionId ?? ''
  );
  const performanceList = getPerformanceData(
    performance,
    positionInfo?.userEquity,
    strategyInfo?.tokens[0].decimals
  );

  const vaultItem = (
    <VaultItem
      tokens={strategyInfo.tokens}
      protocols={strategyInfo.protocols}
      showNewTag={strategyInfo.strategyType === StrategyType.PSEUDO_1X_LONG}
      leverage={strategyInfo.leverage}
      biggerSize={true}
    />
  );

  const tvlGteCapacity = useMemo(
    () =>
      isTVLGreaterOrEqualToCapacity(
        strategyInfo.TVL,
        strategyInfo.capacity,
        strategyInfo.leverage
      ),
    [strategyInfo.TVL, strategyInfo.capacity, strategyInfo.leverage]
  );

  const threshold = useMemo(
    () =>
      calculateThreshold(
        strategyInfo.priceMovementThreshold,
        strategyInfo.leverage
      ),
    [strategyInfo]
  );
  const inception_Date = useMemo(
    () =>
      returns && returns.inception.length > 0
        ? timestampToUTCString(
            returns.inception[0]!.timestamp_sec,
            'DayMonthYear'
          )
        : '--',
    [returns]
  );
  const return_Changes = useMemo(
    () =>
      returns && returns.inception.length > 0
        ? returns.inception[returns.inception.length - 1].percentage
        : Infinity,
    [returns]
  );
  const maximum_Drawdown = useMemo(
    () => getMaximumDrawdown(returns?.inception ?? []),
    [returns]
  );
  const number_Rebalance = useMemo(
    () =>
      rebalanceDataParser(
        getStrategyRebalance(rebalance ?? [], strategyInfo.strategyId),
        returns && returns.inception.length > 0
          ? returns.inception[0]!.timestamp_sec
          : Infinity
      ),
    [returns, rebalance, strategyInfo]
  );
  const apy_7days = useMemo(
    () =>
      returns?.week
        ? calculate_timeframe_apy(returns?.week, TimeframeType.WEEK)
        : undefined,
    [returns?.week]
  );

  const apy_season = useMemo(
    () =>
      returns?.season
        ? calculate_timeframe_apy(returns?.season, TimeframeType.SEASON)
        : undefined,
    [returns?.season]
  );

  const profits_Text = useMemo(() => {
    const text_value =
      positionInfo && positionInfo.profits
        ? profitsDataParser(
            positionInfo.equity,
            positionInfo.profits,
            strategyInfo.tokens[0].ticker
          )
        : '--';
    return positionInfo && positionInfo.profits
      ? [positionInfo.profits.profits, text_value]
      : ['', text_value];
  }, [positionInfo, strategyInfo.tokens]);

  useEffect(() => {
    setTimeout(() => {
      if (scrollToReturn && returnRef && returnRef.current) {
        returnRef!.current!.scrollIntoView({ behavior: 'smooth' });
      }
      if (scrollToAnalysis && analysisRef && analysisRef.current) {
        analysisRef!.current!.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [returnRef, analysisRef]);

  const openWalletConnectModal = useOpenModal(ModalType.NETWORK_SELECTOR);
  const handleInvest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isWalletConnected) {
      openInvestModal();
    } else {
      openWalletConnectModal();
    }
  };

  const handleWithdraw = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    openWithdrawModal();
  };

  const modalProps = {
    strategyInfo,
    positionInfo,
    vaultItem,
    title: StrategyTitleTextMap[strategyInfo.strategyType],
  };

  const openInvestModal = useOpenModal(ModalType.INVEST, {
    invest: true,
    ...modalProps,
  });

  const openWithdrawModal = useOpenModal(ModalType.INVEST, {
    invest: false,
    ...modalProps,
  });

  const theme = useTheme();
  const returnPayload: ReturnPayloadProps = {
    tokens: [strategyInfo.tokens[0].ticker, strategyInfo.tokens[1].ticker],
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'percentage',
        legendText: 'Return',
        legendType: 'line',
        color: theme.colors.graphLine1,
        activeColor: theme.colors.graphActiveDot1,
        tickFormatter: (value: any, index: number) =>
          numberParse(value, 'percentage', undefined, 3),
      },
      {
        dataKey: 'price',
        legendText: `${strategyInfo.tokens[1].ticker} Price`,
        legendType: 'dash',
        color: theme.colors.graphLine2,
        activeColor: theme.colors.graphActiveDot2,
        tickFormatter: (value: any, index: number) => value,
      },
      {
        dataKey: 'price',
        legendText: 'Rebalanced Point',
        legendType: 'dot',
        color: theme.colors.graphDot,
      },
    ],
  };
  const backtestPayload: BacktestPayloadProps = {
    tokens: [strategyInfo.tokens[0].ticker, strategyInfo.tokens[1].ticker],
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'percentage',
        legendText: 'Return',
        legendType: 'line',
        color: theme.colors.graphLine2,
        tickFormatter: (value: any, index: number) =>
          numberParse(value, 'percentage'),
      },
    ],
  };

  const performancePayload: PerformancePayloadProps = {
    tokens: [strategyInfo.tokens[0].ticker, strategyInfo.tokens[1].ticker],
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'position_value',
        legendText: 'Position Value',
        legendType: 'line',
        color: theme.colors.graphLine1,
      },
      {
        dataKey: 'net_deposit',
        legendText: 'Net Deposits',
        legendType: 'dash',
        color: theme.colors.graphLine2,
        hide: true,
        shareYaxis: true,
      },
    ],
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={'modal-content modal-pdn-strategy-details'}>
        <div className="cross" onClick={() => onClose()}>
          <StyledCrossIcon />
        </div>
        <div className="pdn-strategy-details">
          <div className="pdn-strategy-details-title">
            <div className="headline-2">
              {`${StrategyTitleTextMap[strategyInfo.strategyType]}(${
                strategyInfo.leverage
              }X)`}
            </div>
            <div className="details-row">
              <div>{vaultItem}</div>
              <div className="action-button-group">
                <Button
                  variant="contained"
                  size="medium"
                  text={'Deposit'}
                  onClick={(event) => handleInvest(event)}
                  disabled={tvlGteCapacity}
                ></Button>
                <Button
                  className="action-withdraw"
                  variant="info"
                  size="medium"
                  text={'Withdraw'}
                  disabled={
                    !(
                      isWalletConnected &&
                      positionInfo &&
                      Number(positionInfo.equity) > 0
                    )
                  }
                  onClick={(event) => handleWithdraw(event)}
                ></Button>
              </div>
            </div>
          </div>
          <div className="pdn-strategy-details-content">
            <OverallInfo
              strategy={{
                leverage: strategyInfo.leverage,
                inceptionDate: inception_Date,
                returnChanges: return_Changes,
                threshold: threshold,
                sharpRatio: 5.28, //missing
                maxDrawdown: maximum_Drawdown,
                rebalances: number_Rebalance,
                underlyingPool: (
                  <UnderlyingPool
                    showIcon={false}
                    icon={strategyInfo.protocols[0].icon}
                    text={`${strategyInfo.protocols[0].name} ${strategyInfo.tokens[0].ticker}-${strategyInfo.tokens[1].ticker}`}
                  />
                ),
                strategyType:
                  StrategyTypeTextMap[strategyInfo.strategyType] === 'bull'
                    ? 'bull'
                    : 'crab',
                tvlCapacity: {
                  content: `$${numberParseLarge(
                    strategyInfo?.TVL ?? 0,
                    2
                  )} / ${numberParseLarge(strategyInfo?.capacity ?? 0, 2)}`,
                  percentage:
                    Number(strategyInfo?.TVL) / Number(strategyInfo?.capacity),
                },
                apy: {
                  apy: Number(strategyInfo?.apy ?? Infinity),
                  apy_7days: apy_7days ?? Infinity,
                  apy_season: apy_season ?? Infinity,
                },
                position: {
                  active: positionInfo ? true : false,
                  currentValue: positionInfo
                    ? `${numberParseLarge(
                        positionInfo?.userEquity ?? 0,
                        positionInfo?.userEquity ?? 0 > 0.1 ? 2 : 4
                      )} ${strategyInfo.tokens[0].ticker}`
                    : '--',
                  profits: profits_Text,
                },
              }}
            />
            {positionInfo && Number(positionInfo.equity) > 0 && (
              <Performance
                id={`performance-charts-${positionInfo?.positionId ?? ''}`}
                data={performanceList}
                payload={performancePayload}
              />
            )}

            <div ref={returnRef}>
              <Return
                id={`return-charts-${strategyInfo.strategyId}`}
                data={returns}
                payload={returnPayload}
              />
            </div>

            <div ref={analysisRef}>
              <Backtest
                id={`backtest-charts-${strategyInfo.strategyId}`}
                data={
                  backtestAnalysisData &&
                  backtestAnalysisData[strategyInfo.strategyId]
                }
                payload={backtestPayload}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
