import { IStore, useStore } from '../../index';

export function useChainDetails(): any {
  const {
    chainProvider,
    transactionProvider,
    chain,
    chainId,
    chainInfo,
    chainType,
  } = useStore((state: IStore) => state.chainSlice);
  return {
    chainProvider,
    transactionProvider,
    chain,
    chainId,
    chainInfo,
    chainType,
  };
}

export function useChainType(): any {
  return useStore((state: IStore) => state.chainSlice.chainType);
}

export function useChainProvider(): any {
  return useStore((state: IStore) => state.chainSlice.chainProvider);
}
