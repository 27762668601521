import Drawer from '@mui/material/Drawer';
import React from 'react';
import './navbar.scss';

import { Logo } from './../Components/Logo';
import { Footer } from './Footer';
import { NavLink } from './NavLink';
import styled from 'styled-components';
import { EllipsisIcon, CrossIcon } from '@aperture/assetkit';

interface NavbarProps {
  mobile: boolean;
  active?: boolean;
}

const Dot = styled(EllipsisIcon)`
  path {
    fill: ${({ theme }) => theme.colors.buttonModalLeft};
  }
`;
const StyledCrossIcon = styled(CrossIcon)`
  path {
    fill: white;
  }
`;

export const Navbar = ({
  mobile = false,
  active = false,
  ...props
}: NavbarProps) => {
  const [nav, setNav] = React.useState(false);

  const NavbarContent = () => (
    <>
      {mobile && (
        <button
          className="btn-outline-white btn cross"
          onClick={() => setNav(false)}
          style={{
            padding: '8px 10px',
            height: '40px',
            position: 'absolute',
            right: '6px',
            top: '12px',
            width: '40px',
          }}
        >
          <StyledCrossIcon />
        </button>
      )}
      <div className="nav-container">
        <div className="logoClass">
          <Logo colored text mode="dark" direction="horizontal" />
          <span className="private-beta">Public Beta</span>
        </div>

        <NavLink active={active} mobile={mobile} />
      </div>
      <Footer mobile={mobile} {...props} />
    </>
  );

  const mobileNav = (
    <div className="mobile-nav">
      <button
        className="btn-outline-white btn dot"
        onClick={() => setNav(true)}
        style={{
          padding: '8px 10px',
          marginRight: '0px',
          height: '36px',
          width: '36px',
        }}
      >
        <Dot />
      </button>
      <Drawer
        className="mobile-menu-bar"
        anchor="right"
        open={nav}
        onClose={() => setNav(false)}
      >
        <NavbarContent />
      </Drawer>
    </div>
  );

  return (
    <>
      {mobile ? (
        mobileNav
      ) : (
        <nav>
          <NavbarContent />
        </nav>
      )}
    </>
  );
};
