import React from "react";
import styled from "styled-components";
import { ColoredValueProp } from './types'

const StyledValue = styled.span<{ value: number | undefined, color: string | undefined }>`
  color: ${({ theme, value, color }) => value === null || value === undefined || value === 0 ? (color ? color : theme.colors.text) : (value > 0 ? theme.colors.positive : theme.colors.negative)};
`;

export const ColoredValue: React.FC<React.PropsWithChildren<ColoredValueProp>> = ({ value = 0, color, children }) => {
  return (<StyledValue value={value} color={color}>{children}</StyledValue>)
};
