import React from 'react';
import './connectDialogCard.scss';
import { MetamaskIcon } from '@aperture/assetkit';
import { Button } from '../../Components/Button';

interface ConnectDialogCardProps {
  text: string;
  Icon: any;
  onClickReturn: any;
  onSelect?: (onClickReturn: any) => void;
  selected: boolean;
  disabled: boolean;
}

export const ConnectDialogCard = ({
  text = 'Metamask',
  Icon = MetamaskIcon,
  onClickReturn = null,
  onSelect,
  selected,
  disabled,
  ...props
}: ConnectDialogCardProps) => {
  return (
    <Button
      variant={'outline'}
      light={true}
      className={`connectCard ${selected ? 'connectCard-selected' : ''}`}
      disabled={disabled}
      onClick={() => onSelect && !disabled && onSelect(onClickReturn)}
    >
      <div className="chain-info">
        <Icon width="25" />
        <span className="button-2">{text}</span>
        {disabled && (
          <div className="comingSoon">
            <div>Coming soon</div>
          </div>
        )}
      </div>
    </Button>
  );
};
