export interface EvmChainInfo {
  name: string;
  chainId: number;
  shortName: string;
  networkId: number;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpc: string[];
  faucets: string[];
  infoURL: string;
  network: string;
}

export type TEvmChainMap = {
  [chainId: number]: EvmChainInfo;
};

export const ChainExplorerRegex = /(^.+\/)\/?(\$\{TYPE\})(.+)?(\$\{ADDRESS\})/;
export const EvmChainMap: TEvmChainMap = {
  '1': {
    name: 'Ethereum Mainnet',
    chainId: 1,
    shortName: 'eth',
    networkId: 1,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpc: [
      'https://mainnet.infura.io/v3/${INFURA_API_KEY}',
      'wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}',
      'https://api.mycryptoapi.com/eth',
      'https://cloudflare-eth.com',
    ],
    faucets: [],
    infoURL: 'https://etherscan.io/${TYPE}/${ADDRESS}',
    network: 'mainnet',
  },
  '2': {
    name: 'Expanse Network',
    chainId: 2,
    shortName: 'exp',
    networkId: 1,
    nativeCurrency: {
      name: 'Expanse Network Ether',
      symbol: 'EXP',
      decimals: 18,
    },
    rpc: ['https://node.expanse.tech'],
    faucets: [],
    infoURL: 'https://expanse.tech',
    network: 'expanse',
  },
  '3': {
    name: 'Ropsten',
    chainId: 3,
    shortName: 'rop',
    networkId: 3,
    nativeCurrency: {
      name: 'Ropsten Ether',
      symbol: 'ROP',
      decimals: 18,
    },
    rpc: [
      'https://ropsten.infura.io/v3/${INFURA_API_KEY}',
      'wss://ropsten.infura.io/ws/v3/${INFURA_API_KEY}',
    ],
    faucets: [
      'http://fauceth.komputing.org?chain=3&address=${ADDRESS}',
      'https://faucet.ropsten.be?${ADDRESS}',
    ],
    infoURL: 'https://ropsten.etherscan.io/${TYPE}/${ADDRESS}',
    network: 'ropsten',
  },
  '4': {
    name: 'Rinkeby',
    chainId: 4,
    shortName: 'rin',
    networkId: 4,
    nativeCurrency: {
      name: 'Rinkeby Ether',
      symbol: 'RIN',
      decimals: 18,
    },
    rpc: [
      'https://rinkeby.infura.io/v3/${INFURA_API_KEY}',
      'wss://rinkeby.infura.io/ws/v3/${INFURA_API_KEY}',
    ],
    faucets: [
      'http://fauceth.komputing.org?chain=4&address=${ADDRESS}',
      'https://faucet.rinkeby.io',
    ],
    infoURL: 'https://rinkeby.etherscan.io/${TYPE}/${ADDRESS}',
    network: 'rinkeby',
  },
  '5': {
    name: 'Görli',
    chainId: 5,
    shortName: 'gor',
    networkId: 5,
    nativeCurrency: {
      name: 'Görli Ether',
      symbol: 'GOR',
      decimals: 18,
    },
    rpc: [
      'https://goerli.infura.io/v3/${INFURA_API_KEY}',
      'wss://goerli.infura.io/v3/${INFURA_API_KEY}',
      'https://rpc.goerli.mudit.blog/',
    ],
    faucets: [
      'http://fauceth.komputing.org?chain=5&address=${ADDRESS}',
      'https://goerli-faucet.slock.it?address=${ADDRESS}',
      'https://faucet.goerli.mudit.blog',
    ],
    infoURL: 'https://goeri.etherscan.io/${TYPE}/${ADDRESS}',
    network: 'goerli',
  },
  '6': {
    name: 'Ethereum Classic Testnet Kotti',
    chainId: 6,
    shortName: 'kot',
    networkId: 6,
    nativeCurrency: {
      name: 'Kotti Ether',
      symbol: 'KOT',
      decimals: 18,
    },
    rpc: ['https://www.ethercluster.com/kotti'],
    faucets: [],
    infoURL: 'https://explorer.jade.builders/?network=kotti',
    network: 'kotti',
  },
  '8': {
    name: 'Ubiq',
    chainId: 8,
    shortName: 'ubq',
    networkId: 8,
    nativeCurrency: {
      name: 'Ubiq Ether',
      symbol: 'UBQ',
      decimals: 18,
    },
    rpc: ['https://rpc.octano.dev', 'https://pyrus2.ubiqscan.io'],
    faucets: [],
    infoURL: 'https://ubiqsmart.com',
    network: 'ubiq',
  },
  '9': {
    name: 'Ubiq Network Testnet',
    chainId: 9,
    shortName: 'tubq',
    networkId: 2,
    nativeCurrency: {
      name: 'Ubiq Testnet Ether',
      symbol: 'TUBQ',
      decimals: 18,
    },
    rpc: [],
    faucets: [],
    infoURL: 'https://ethersocial.org',
    network: 'ubiq-testnet',
  },
  '10': {
    name: 'Optimism',
    chainId: 10,
    shortName: 'oeth',
    networkId: 10,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpc: ['https://mainnet.optimism.io/'],
    faucets: [],
    infoURL: 'https://optimism.io',
    network: 'optimism',
  },
  '11': {
    name: 'Metadium Mainnet',
    chainId: 11,
    shortName: 'meta',
    networkId: 11,
    nativeCurrency: {
      name: 'Metadium Mainnet Ether',
      symbol: 'META',
      decimals: 18,
    },
    rpc: ['https://api.metadium.com/prod'],
    faucets: [],
    infoURL: 'https://metadium.com',
    network: 'metadium',
  },
  '12': {
    name: 'Metadium Testnet',
    chainId: 12,
    shortName: 'kal',
    networkId: 12,
    nativeCurrency: {
      name: 'Metadium Testnet Ether',
      symbol: 'KAL',
      decimals: 18,
    },
    rpc: ['https://api.metadium.com/dev'],
    faucets: [],
    infoURL: 'https://metadium.com',
    network: 'metadium-testnet',
  },
  '18': {
    name: 'ThunderCore Testnet',
    chainId: 18,
    shortName: 'TST',
    networkId: 18,
    nativeCurrency: {
      name: 'ThunderCore Testnet Ether',
      symbol: 'TST',
      decimals: 18,
    },
    rpc: ['https://testnet-rpc.thundercore.com'],
    faucets: ['https://faucet-testnet.thundercore.com'],
    infoURL: 'https://thundercore.com',
    network: 'thundercore-testnet',
  },
  '22': {
    name: 'ELA-DID-Sidechain Mainnet',
    chainId: 22,
    shortName: 'eladid',
    networkId: 22,
    nativeCurrency: {
      name: 'Elastos',
      symbol: 'ELA',
      decimals: 18,
    },
    rpc: [],
    faucets: [],
    infoURL: 'https://www.elastos.org/',
    network: 'lukso-l14-testnet',
  },
  '23': {
    name: 'ELA-DID-Sidechain Testnet',
    chainId: 23,
    shortName: 'eladidt',
    networkId: 23,
    nativeCurrency: {
      name: 'Elastos',
      symbol: 'tELA',
      decimals: 18,
    },
    rpc: [],
    faucets: [],
    infoURL: 'https://elaeth.io/',
    network: 'lukso-l15-testnet',
  },
  '25': {
    name: 'Cronos Mainnet Beta',
    chainId: 25,
    shortName: 'cro',
    networkId: 25,
    nativeCurrency: {
      name: 'Cronos',
      symbol: 'CRO',
      decimals: 18,
    },
    rpc: ['https://evm.cronos.org'],
    faucets: [],
    infoURL: 'https://cronos.org/',
    network: 'cronos',
  },
  '30': {
    name: 'RSK Mainnet',
    chainId: 30,
    shortName: 'rsk',
    networkId: 30,
    nativeCurrency: {
      name: 'RSK Mainnet Ether',
      symbol: 'RBTC',
      decimals: 18,
    },
    rpc: ['https://public-node.rsk.co', 'https://mycrypto.rsk.co'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://rsk.co',
    network: 'rsk',
  },
  '31': {
    name: 'RSK Testnet',
    chainId: 31,
    shortName: 'trsk',
    networkId: 31,
    nativeCurrency: {
      name: 'RSK Testnet Ether',
      symbol: 'tRBTC',
      decimals: 18,
    },
    rpc: [
      'https://public-node.testnet.rsk.co',
      'https://mycrypto.testnet.rsk.co',
    ],
    faucets: ['https://faucet.testnet.rsk.co'],
    infoURL: 'https://rsk.co',
    network: 'rsk-testnet',
  },
  '42': {
    name: 'Kovan',
    chainId: 42,
    shortName: 'kov',
    networkId: 42,
    nativeCurrency: {
      name: 'Kovan Ether',
      symbol: 'KOV',
      decimals: 18,
    },
    rpc: [
      'https://kovan.poa.network',
      'http://kovan.poa.network:8545',
      'https://kovan.infura.io/v3/${INFURA_API_KEY}',
      'wss://kovan.infura.io/ws/v3/${INFURA_API_KEY}',
      'ws://kovan.poa.network:8546',
    ],
    faucets: [
      'http://fauceth.komputing.org?chain=42&address=${ADDRESS}',
      'https://faucet.kovan.network',
      'https://gitter.im/kovan-testnet/faucet',
    ],
    infoURL: 'https://kovan-testnet.github.io/website',
    network: 'kovan',
  },
  '56': {
    name: 'Binance Smart Chain Mainnet',
    chainId: 56,
    shortName: 'bnb',
    networkId: 56,
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpc: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://www.binance.org',
    network: 'binance',
  },
  '60': {
    name: 'GoChain',
    chainId: 60,
    shortName: 'go',
    networkId: 60,
    nativeCurrency: {
      name: 'GoChain Ether',
      symbol: 'GO',
      decimals: 18,
    },
    rpc: ['https://rpc.gochain.io'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://gochain.io',
    network: 'gochain',
  },
  '61': {
    name: 'Ethereum Classic Mainnet',
    chainId: 61,
    shortName: 'etc',
    networkId: 1,
    nativeCurrency: {
      name: 'Ethereum Classic Ether',
      symbol: 'ETC',
      decimals: 18,
    },
    rpc: ['https://www.ethercluster.com/etc'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/?'],
    infoURL: 'https://ethereumclassic.org',
    network: 'etc',
  },
  '62': {
    name: 'Ethereum Classic Testnet Morden',
    chainId: 62,
    shortName: 'tetc',
    networkId: 2,
    nativeCurrency: {
      name: 'Ethereum Classic Testnet Ether',
      symbol: 'TETC',
      decimals: 18,
    },
    rpc: [],
    faucets: [],
    infoURL: 'https://ethereumclassic.org',
    network: 'etc-morden',
  },
  '63': {
    name: 'Ethereum Classic Testnet Mordor',
    chainId: 63,
    shortName: 'metc',
    networkId: 7,
    nativeCurrency: {
      name: 'Mordor Classic Testnet Ether',
      symbol: 'METC',
      decimals: 18,
    },
    rpc: ['https://www.ethercluster.com/mordor'],
    faucets: [],
    infoURL: 'https://github.com/eth-classic/mordor/',
    network: 'etc-testnet',
  },
  '64': {
    name: 'Ellaism',
    chainId: 64,
    shortName: 'ella',
    networkId: 64,
    nativeCurrency: {
      name: 'Ellaism Ether',
      symbol: 'ELLA',
      decimals: 18,
    },
    rpc: ['https://jsonrpc.ellaism.org'],
    faucets: [],
    infoURL: 'https://ellaism.org',
    network: 'ellaism',
  },
  '69': {
    name: 'Optimism Kovan',
    chainId: 69,
    shortName: 'okov',
    networkId: 69,
    nativeCurrency: {
      name: 'Kovan Ether',
      symbol: 'KOR',
      decimals: 18,
    },
    rpc: ['https://kovan.optimism.io/'],
    faucets: ['http://fauceth.komputing.org?chain=69&address=${ADDRESS}'],
    infoURL: 'https://optimism.io',
    network: 'optimism-kovan',
  },
  '76': {
    name: 'Mix',
    chainId: 76,
    shortName: 'mix',
    networkId: 76,
    nativeCurrency: {
      name: 'Mix Ether',
      symbol: 'MIX',
      decimals: 18,
    },
    rpc: ['https://rpc2.mix-blockchain.org:8647'],
    faucets: [],
    infoURL: 'https://mix-blockchain.org',
    network: 'mix',
  },
  '77': {
    name: 'POA Network Sokol',
    chainId: 77,
    shortName: 'spoa',
    networkId: 77,
    nativeCurrency: {
      name: 'POA Sokol Ether',
      symbol: 'SPOA',
      decimals: 18,
    },
    rpc: [
      'https://sokol.poa.network',
      'wss://sokol.poa.network/wss',
      'ws://sokol.poa.network:8546',
    ],
    faucets: ['https://faucet.poa.network'],
    infoURL: 'https://poa.network',
    network: 'poa-sokol',
  },
  '88': {
    name: 'TomoChain',
    chainId: 88,
    shortName: 'tomo',
    networkId: 88,
    nativeCurrency: {
      name: 'TomoChain',
      symbol: 'TOMO',
      decimals: 18,
    },
    rpc: ['https://rpc.tomochain.com'],
    faucets: [],
    infoURL: 'https://tomochain.com',
    network: 'tomochain',
  },
  '97': {
    name: 'Binance Smart Chain Testnet',
    chainId: 97,
    shortName: 'bnbt',
    networkId: 97,
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'tBNB',
      decimals: 18,
    },
    rpc: [
      'https://data-seed-prebsc-1-s1.binance.org:8545',
      'https://data-seed-prebsc-2-s1.binance.org:8545',
      'https://data-seed-prebsc-1-s2.binance.org:8545',
      'https://data-seed-prebsc-2-s2.binance.org:8545',
      'https://data-seed-prebsc-1-s3.binance.org:8545',
      'https://data-seed-prebsc-2-s3.binance.org:8545',
    ],
    faucets: ['https://testnet.binance.org/faucet-smart'],
    infoURL: 'https://testnet.binance.org/',
    network: 'binance-testnet',
  },
  '99': {
    name: 'POA Network Core',
    chainId: 99,
    shortName: 'poa',
    networkId: 99,
    nativeCurrency: {
      name: 'POA Network Core Ether',
      symbol: 'POA',
      decimals: 18,
    },
    rpc: [
      'https://core.poanetwork.dev',
      'http://core.poanetwork.dev:8545',
      'https://core.poa.network',
      'ws://core.poanetwork.dev:8546',
    ],
    faucets: [],
    infoURL: 'https://poa.network',
    network: 'poa-core',
  },
  '100': {
    name: 'Gnosis Chain (formerly xDai)',
    chainId: 100,
    shortName: 'gno',
    networkId: 100,
    nativeCurrency: {
      name: 'xDAI',
      symbol: 'xDAI',
      decimals: 18,
    },
    rpc: [
      'https://rpc.gnosischain.com',
      'https://xdai.poanetwork.dev',
      'wss://rpc.gnosischain.com/wss',
      'wss://xdai.poanetwork.dev/wss',
      'http://xdai.poanetwork.dev',
      'https://dai.poa.network',
      'ws://xdai.poanetwork.dev:8546',
    ],
    faucets: [
      'https://faucet.gimlu.com/gnosis',
      'https://stakely.io/faucet/gnosis-chain-xdai',
      'https://faucet.prussia.dev/xdai',
    ],
    infoURL: 'https://www.xdaichain.com/',
    network: 'xdai',
  },
  '101': {
    name: 'EtherInc',
    chainId: 101,
    shortName: 'eti',
    networkId: 1,
    nativeCurrency: {
      name: 'EtherInc Ether',
      symbol: 'ETI',
      decimals: 18,
    },
    rpc: ['https://api.einc.io/jsonrpc/mainnet'],
    faucets: [],
    infoURL: 'https://einc.io',
    network: 'etherinc',
  },
  '108': {
    name: 'ThunderCore Mainnet',
    chainId: 108,
    shortName: 'TT',
    networkId: 108,
    nativeCurrency: {
      name: 'ThunderCore Mainnet Ether',
      symbol: 'TT',
      decimals: 18,
    },
    rpc: ['https://mainnet-rpc.thundercore.com'],
    faucets: ['https://faucet.thundercore.com'],
    infoURL: 'https://thundercore.com',
    network: 'thundercore',
  },
  '137': {
    name: 'Polygon Mainnet',
    chainId: 137,
    shortName: 'MATIC',
    networkId: 137,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpc: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com',
    ],
    faucets: [],
    infoURL: 'https://polygon.technology/',
    network: 'matic',
  },
  '162': {
    name: 'Lightstreams Testnet',
    chainId: 162,
    shortName: 'tpht',
    networkId: 162,
    nativeCurrency: {
      name: 'Lightstreams PHT',
      symbol: 'PHT',
      decimals: 18,
    },
    rpc: ['https://node.sirius.lightstreams.io'],
    faucets: ['https://discuss.lightstreams.network/t/request-test-tokens'],
    infoURL: 'https://explorer.sirius.lightstreams.io',
    network: 'sirius',
  },
  '163': {
    name: 'Lightstreams Mainnet',
    chainId: 163,
    shortName: 'pht',
    networkId: 163,
    nativeCurrency: {
      name: 'Lightstreams PHT',
      symbol: 'PHT',
      decimals: 18,
    },
    rpc: ['https://node.mainnet.lightstreams.io'],
    faucets: [],
    infoURL: 'https://explorer.lightstreams.io',
    network: 'lightstreams',
  },
  '211': {
    name: 'Freight Trust Network',
    chainId: 211,
    shortName: 'EDI',
    networkId: 0,
    nativeCurrency: {
      name: 'Freight Trust Native',
      symbol: '0xF',
      decimals: 18,
    },
    rpc: [
      'http://13.57.207.168:3435',
      'https://app.freighttrust.net/ftn/${API_KEY}',
    ],
    faucets: ['http://faucet.freight.sh'],
    infoURL: 'https://freighttrust.com',
    network: 'freight',
  },
  '250': {
    name: 'Fantom Opera',
    chainId: 250,
    shortName: 'ftm',
    networkId: 250,
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpc: ['https://rpc.ftm.tools'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://fantom.foundation',
    network: 'fantom',
  },
  '269': {
    name: 'High Performance Blockchain',
    chainId: 269,
    shortName: 'hpb',
    networkId: 269,
    nativeCurrency: {
      name: 'High Performance Blockchain Ether',
      symbol: 'HPB',
      decimals: 18,
    },
    rpc: ['https://hpbnode.com', 'wss://ws.hpbnode.com'],
    faucets: ['https://myhpbwallet.com/'],
    infoURL: 'https://hpb.io',
    network: 'hpb',
  },
  '338': {
    name: 'Cronos Testnet',
    chainId: 338,
    shortName: 'tcro',
    networkId: 338,
    nativeCurrency: {
      name: 'Crypto.org Test Coin',
      symbol: 'TCRO',
      decimals: 18,
    },
    rpc: [
      'https://cronos-testnet-3.crypto.org:8545',
      'wss://cronos-testnet-3.crypto.org:8546',
    ],
    faucets: ['https://cronos.crypto.org/faucet'],
    infoURL: 'https://cronos.crypto.org',
    network: 'cronos-testnet',
  },
  '385': {
    name: 'Lisinski',
    chainId: 385,
    shortName: 'lisinski',
    networkId: 385,
    nativeCurrency: {
      name: 'Lisinski Ether',
      symbol: 'LISINS',
      decimals: 18,
    },
    rpc: ['https://rpc-bitfalls1.lisinski.online'],
    faucets: ['https://pipa.lisinski.online'],
    infoURL: 'https://lisinski.online',
    network: 'lisinski',
  },
  '820': {
    name: 'Callisto Mainnet',
    chainId: 820,
    shortName: 'clo',
    networkId: 1,
    nativeCurrency: {
      name: 'Callisto Mainnet Ether',
      symbol: 'CLO',
      decimals: 18,
    },
    rpc: ['https://clo-geth.0xinfra.com'],
    faucets: [],
    infoURL: 'https://callisto.network',
    network: 'callisto',
  },
  '821': {
    name: 'Callisto Testnet',
    chainId: 821,
    shortName: 'tclo',
    networkId: 2,
    nativeCurrency: {
      name: 'Callisto Testnet Ether',
      symbol: 'TCLO',
      decimals: 18,
    },
    rpc: [],
    faucets: [],
    infoURL: 'https://callisto.network',
    network: 'callisto-testnet',
  },
  '1007': {
    name: 'Newton Testnet',
    chainId: 1007,
    shortName: 'tnew',
    networkId: 1007,
    nativeCurrency: {
      name: 'Newton',
      symbol: 'NEW',
      decimals: 18,
    },
    rpc: ['https://rpc1.newchain.newtonproject.org'],
    faucets: [],
    infoURL: 'https://www.newtonproject.org/',
    network: 'newchain-testnet',
  },
  '1012': {
    name: 'Newton',
    chainId: 1012,
    shortName: 'new',
    networkId: 1012,
    nativeCurrency: {
      name: 'Newton',
      symbol: 'NEW',
      decimals: 18,
    },
    rpc: ['https://global.rpc.mainnet.newtonproject.org'],
    faucets: [],
    infoURL: 'https://www.newtonproject.org/',
    network: 'newchain-mainnet',
  },
  '1284': {
    name: 'Moonbeam',
    chainId: 1284,
    shortName: 'mbeam',
    networkId: 1284,
    nativeCurrency: {
      name: 'Glimmer',
      symbol: 'GLMR',
      decimals: 18,
    },
    rpc: ['https://rpc.api.moonbeam.network', 'wss://wss.api.moonbeam.network'],
    faucets: [],
    infoURL: 'https://moonbeam.network/networks/moonbeam/',
    network: 'moonbeam',
  },
  '1285': {
    name: 'Moonriver',
    chainId: 1285,
    shortName: 'mriver',
    networkId: 1285,
    nativeCurrency: {
      name: 'Moonriver',
      symbol: 'MOVR',
      decimals: 18,
    },
    rpc: [
      'https://rpc.api.moonriver.moonbeam.network',
      'wss://wss.api.moonriver.moonbeam.network',
    ],
    faucets: [],
    infoURL: 'https://moonbeam.network/networks/moonriver/',
    network: 'moonriver',
  },
  '42161': {
    name: 'Arbitrum One',
    chainId: 42161,
    shortName: 'arb1',
    networkId: 42161,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpc: [
      'https://arbitrum-mainnet.infura.io/v3/${INFURA_API_KEY}',
      'https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}',
      'https://arb1.arbitrum.io/rpc',
      'wss://arb1.arbitrum.io/ws',
    ],
    faucets: [],
    infoURL: 'https://arbitrum.io',
    network: 'arbitrum',
  },
  '42220': {
    name: 'Celo Mainnet',
    chainId: 42220,
    shortName: 'CELO',
    networkId: 42220,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
    rpc: ['https://forno.celo.org', 'wss://forno.celo.org/ws'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://docs.celo.org/',
    network: 'celo',
  },
  '43113': {
    name: 'Avalanche Fuji Testnet',
    chainId: 43113,
    shortName: 'Fuji',
    networkId: 1,
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpc: ['https://api.avax-test.network/ext/bc/C/rpc'],
    faucets: ['https://faucet.avax-test.network/'],
    infoURL: 'https://cchain.explorer.avax-test.network/${TYPE}/${ADDRESS}',
    network: 'avalanche-fuji-testnet',
  },
  '31337': {
    name: 'Avalanche Clone',
    chainId: 31337,
    shortName: 'Avalanche',
    networkId: 31337,
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'GO',
      decimals: 18,
    },
    rpc: [
      // "https://dev.hyperfocal.tech",
      'http://dev.hyperfocal.tech:8989',
    ],
    faucets: ['https://faucet.avax-test.network/'],
    infoURL: 'https://cchain.explorer.avax-test.network/${TYPE}/${ADDRESS}',
    network: 'avalanche-fuji-testnet',
  },
  '43114': {
    name: 'Avalanche C-Chain',
    chainId: 43114,
    shortName: 'Avalanche',
    networkId: 43114,
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpc: [
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_RPC_ENDPOINT_AVAX_DEV
        ? process.env.REACT_APP_RPC_ENDPOINT_AVAX_DEV
        : 'https://api.avax.network/ext/bc/C/rpc',
    ],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://snowtrace.io/${TYPE}/${ADDRESS}',
    network: 'avalanche-fuji-mainnet',
  },
  '44787': {
    name: 'Celo Alfajores Testnet',
    chainId: 44787,
    shortName: 'ALFA',
    networkId: 44787,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
    rpc: [
      'https://alfajores-forno.celo-testnet.org',
      'wss://alfajores-forno.celo-testnet.org/ws',
    ],
    faucets: [
      'https://celo.org/developers/faucet',
      'https://cauldron.pretoriaresearchlab.io/alfajores-faucet',
    ],
    infoURL: 'https://docs.celo.org/',
    network: 'celo-alfajores',
  },
  '62320': {
    name: 'Celo Baklava Testnet',
    chainId: 62320,
    shortName: 'BKLV',
    networkId: 62320,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
    rpc: ['https://baklava-forno.celo-testnet.org'],
    faucets: [
      'https://docs.google.com/forms/d/e/1FAIpQLSdfr1BwUTYepVmmvfVUDRCwALejZ-TUva2YujNpvrEmPAX2pg/viewform',
      'https://cauldron.pretoriaresearchlab.io/baklava-faucet',
    ],
    infoURL: 'https://docs.celo.org/',
    network: 'celo-baklava',
  },
  '80001': {
    name: 'Mumbai',
    chainId: 80001,
    shortName: 'maticmum',
    networkId: 80001,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpc: [
      'https://matic-mumbai.chainstacklabs.com',
      'https://rpc-mumbai.maticvigil.com',
      'https://matic-testnet-archive-rpc.bwarelabs.com',
    ],
    faucets: ['https://faucet.polygon.technology/'],
    infoURL: 'https://polygon.technology/',
    network: 'mumbai',
  },
  '246529': {
    name: 'ARTIS sigma1',
    chainId: 246529,
    shortName: 'ats',
    networkId: 246529,
    nativeCurrency: {
      name: 'ARTIS sigma1 Ether',
      symbol: 'ATS',
      decimals: 18,
    },
    rpc: ['https://rpc.sigma1.artis.network'],
    faucets: [],
    infoURL: 'https://artis.eco',
    network: 'artis-s1',
  },
  '246785': {
    name: 'ARTIS Testnet tau1',
    chainId: 246785,
    shortName: 'atstau',
    networkId: 246785,
    nativeCurrency: {
      name: 'ARTIS tau1 Ether',
      symbol: 'tATS',
      decimals: 18,
    },
    rpc: ['https://rpc.tau1.artis.network'],
    faucets: [],
    infoURL: 'https://artis.network',
    network: 'artis-t1',
  },
  '421611': {
    name: 'Arbitrum Rinkeby',
    chainId: 421611,
    shortName: 'arb-rinkeby',
    networkId: 421611,
    nativeCurrency: {
      name: 'Arbitrum Rinkeby Ether',
      symbol: 'ARETH',
      decimals: 18,
    },
    rpc: ['https://rinkeby.arbitrum.io/rpc', 'wss://rinkeby.arbitrum.io/ws'],
    faucets: ['http://fauceth.komputing.org?chain=421611&address=${ADDRESS}'],
    infoURL: 'https://arbitrum.io',
    network: 'arbitrum-rinkeby',
  },
  '1313161554': {
    name: 'Aurora Mainnet',
    chainId: 1313161554,
    shortName: 'aurora',
    networkId: 1313161554,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpc: ['https://mainnet.aurora.dev'],
    faucets: [],
    infoURL: 'https://aurora.dev',
    network: 'aurora',
  },
  '1666600000': {
    name: 'Harmony Mainnet Shard 0',
    chainId: 1666600000,
    shortName: 'hmy-s0',
    networkId: 1666600000,
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    rpc: ['https://api.harmony.one', 'https://api.s0.t.hmny.io'],
    faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
    infoURL: 'https://www.harmony.one/',
    network: 'harmony-shard1',
  },
};
