import { timestampToUTCString } from './utilities';

const ERC20TokenMap: { [token: string]: number } = {
  'DAI.e': 0,
  'USDC.e': 1,
  'USDT.e': 2,
  'WBTC.e': 0, // TODO: Update with correct `vault_id`
  'WETH.e': 0, // TODO: Update with correct `vault_id`
};

const tickerMap: { [token: string]: string } = {
  wavax: 'AVAX',
};

export enum TimeframeType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  SEASON = 'season',
  INCEPTION = 'inception',
}

const TimeframeDays = {
  [TimeframeType.DAY]: 1,
  [TimeframeType.WEEK]: 7,
  [TimeframeType.MONTH]: 30,
  [TimeframeType.SEASON]: 90,
  [TimeframeType.INCEPTION]: 365,
};

const TimeframeText = {
  [TimeframeType.DAY]: 'Past 1 Day',
  [TimeframeType.WEEK]: 'Past 1 Week',
  [TimeframeType.MONTH]: 'Past 1 Month',
  [TimeframeType.SEASON]: 'Past 3 Months',
  [TimeframeType.INCEPTION]: 'All Time',
};

export const getTimeIntervalText = (timeRage: TimeframeType, data: any[]) => {
  return timeRage === TimeframeType.INCEPTION
    ? data && data.length > 0
      ? `since ${timestampToUTCString(data[0].timestamp_sec, 'MonthDayYear')}`
      : TimeframeText[timeRage]
    : TimeframeText[timeRage];
};

export const TimeframeInput = [
  { text: '1D', value: TimeframeType.DAY },
  { text: '1W', value: TimeframeType.WEEK },
  { text: '1M', value: TimeframeType.MONTH },
  { text: '3M', value: TimeframeType.SEASON },
  { text: 'ALL', value: TimeframeType.INCEPTION },
];

const mergeByTime = (a1: any[], a2: any[]) =>
  a1.map((itm) => ({
    ...a2.find((item) => item.timestamp_sec === itm.timestamp_sec && item),
    ...itm,
  }));

export const find_x_days_ago = (data: any[], days: number) => {
  if (data.length > 0) {
    const earlist = data[data.length - 1].timestamp_sec - 86400 * days;
    const index = data.findIndex((item) => item.timestamp_sec >= earlist);
    return data.slice(index, data.length);
  }
  return data;
};

export const calculate_backtest_analysis_apy = (data: any[]) => {
  if (data.length > 0) {
    const days_count =
      (data[data.length - 1].timestamp_sec - data[0].timestamp_sec) / 86400;
    const period = 365 / days_count;
    return Math.pow(data[data.length - 1].percentage + 1, period) - 1;
  }
  return 0;
};

export const calculate_return = (data: any[]) => {
  if (data && data.length > 0) {
    return (
      (data[data.length - 1].share_price - data[0].share_price) /
      data[0].share_price
    );
  }
  return null;
};

export const calculate_timeframe_apy = (
  data: any[],
  timeframe: TimeframeType
) => {
  const returnData = calculate_return(data);
  if (returnData !== null) {
    const days =
      (data[data.length - 1].timestamp_sec - data[0].timestamp_sec) / 86400;
    return Math.exp(Math.log(returnData + 1) / (days / 365)) - 1;
  }
  return null;
};

/* token A stable coin, token B unit coin */

export const getReturnData = (return_raw: any[], rebalance: any[]) => {
  const return_data = combineRebalance(
    return_raw.map((token: any) => ({
      ...{ timestamp_sec: token.timestamp },
      ...token,
    })),
    rebalance
  );

  const getReturn = (data: any[]) => {
    const initial_price = data[0].share_price_stable;
    return data.map((itm: any) => ({
      ...{
        percentage: (itm.share_price_stable - initial_price) / initial_price,
        share_price: itm.share_price_stable,
        price: itm.asset_price_stable,
      },
      ...itm,
    }));
  };

  return {
    day: getReturn(find_x_days_ago(return_data, 1)),
    week: getReturn(find_x_days_ago(return_data, 7)),
    month: getReturn(find_x_days_ago(return_data, 30)),
    season: getReturn(find_x_days_ago(return_data, 90)),
    inception: getReturn(return_data),
  };
};

export const getPerformanceData = (
  performanceRaw: any,
  userEquity: any,
  decimal?: number
) => {
  // when user first time deposit/open a position. Database doesn't have user's data, we hard code one data point to show user's deposit.
  if (!performanceRaw || performanceRaw.length <= 0) {
    performanceRaw = [
      {
        timestamp: Math.floor(Date.now() / 1000),
        equity: Number(userEquity ?? 0) * Math.pow(10, decimal ?? 1),
        net_deposit: Number(userEquity ?? 0) * Math.pow(10, decimal ?? 1),
      },
    ];
  }
  const performance = performanceRaw.map((itm: any) => ({
    ...{ timestamp_sec: itm.timestamp },
    ...itm,
  }));

  const updatePerformanceData = (list: any[], decimal: number) => {
    return list.map((itm: any) => ({
      timestamp_sec: itm.timestamp,
      percentage:
        itm.net_deposit === 0
          ? 0
          : (itm.equity - itm.net_deposit) / itm.net_deposit,
      position_value: itm.equity / decimal,
      net_deposit: itm.net_deposit / decimal,
      p_and_l: (itm.equity - itm.net_deposit) / decimal,
    }));
  };
  return {
    //get data from different timeframes
    day: updatePerformanceData(
      find_x_days_ago(performance, 1),
      Math.pow(10, decimal ?? 1)
    ),
    week: updatePerformanceData(
      find_x_days_ago(performance, 7),
      Math.pow(10, decimal ?? 1)
    ),
    month: updatePerformanceData(
      find_x_days_ago(performance, 30),
      Math.pow(10, decimal ?? 1)
    ),
    season: updatePerformanceData(
      find_x_days_ago(performance, 90),
      Math.pow(10, decimal ?? 1)
    ),
    inception: updatePerformanceData(performance, Math.pow(10, decimal ?? 1)),
  };
};

function isNotEmpty(obj: Object) {
  if (obj) {
    return Object.keys(obj).length !== 0;
  } else return false;
}

/* timeGap unit second, 1800 = 30mins */
export const bucketTimestamp = (data: any[], timeGap: number) => {
  if (data && data.length > 1) {
    const list: any[] = [];
    let time_counter = Math.floor(data[0].timestamp_sec / timeGap) * timeGap;
    data.forEach((itm: any, index: number) => {
      if (data[index].timestamp_sec > time_counter + timeGap) {
        time_counter =
          Math.floor(data[index].timestamp_sec / timeGap) * timeGap;
      }
      if (index + 1 === data.length) {
        itm.timestamp_sec = time_counter;
        list.push(itm);
      } else if (
        data[index].timestamp_sec >= time_counter &&
        data[index].timestamp_sec < time_counter + timeGap &&
        data[index + 1].timestamp_sec >= time_counter + timeGap
      ) {
        itm.timestamp_sec = time_counter;
        list.push(itm);
      }
    });
    return list;
  }
  return data;
};

export const getStrategyRebalance = (data: any[], strategy_id: string) => {
  if (data && data.length > 0) {
    return data.filter((item) => item.strategy_id === strategy_id).length;
  }
  return Infinity;
};

/* the definition of Maximum Drawdown: https://www.investopedia.com/terms/m/maximum-drawdown-mdd.asp */
export const getMaximumDrawdown = (data: any[]) => {
  if (data && data.length > 0) {
    const eachPoint: number[] = [];
    let high_price = 0;
    data.forEach((item) => {
      if (high_price === 0) high_price = item.share_price;
      if (item.share_price > high_price) high_price = item.share_price;
      eachPoint.push(item.share_price / high_price - 1);
    });
    return Math.min(...eachPoint);
  }
  return Infinity; // means the data is invalid
};

export const combineRebalance = (data: any[], rebalance: any[]) => {
  if (data && data.length > 0) {
    let left = 0,
      right = data.length - 1;
    rebalance.every((item: any) => {
      const position = searchFirstGreaterElement(
        data,
        item.timestamp,
        left,
        right
      );
      if (position === -1) return false;

      left = position;
      if (
        position > 0 &&
        data[position].timestamp_sec - item.timestamp >
          item.timestamp - data[position - 1].timestamp_sec
      ) {
        data[position - 1] = { ...data[position - 1], rebalanced: true };
      } else {
        data[position] = { ...data[position], rebalanced: true };
      }

      return true;
    });
  }
  return data;
};

function searchFirstGreaterElement(
  data: any[],
  target: number,
  left: number,
  right: number
) {
  let low = left,
    high = right;
  while (low <= high) {
    let mid = low + ((high - low) >> 1);
    if (data[mid].timestamp_sec >= target) {
      if (mid === 0 || data[mid - 1].timestamp_sec < target) {
        return mid;
      }
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }
  return -1;
}
