import {
  AvaxIcon,
  DaiIcon,
  SvgProps,
  UsdcEIcon,
  UsdcIcon,
  Usdt2Icon,
  UsdtEIcon,
  WbtcEIcon,
  WethEIcon,
} from '@aperture/assetkit';
import { ethers } from 'ethers';
import { FunctionComponent } from 'react';

export interface SerializedTokenInfo extends TokenInfo {
  balance: ethers.BigNumber;
  displayBalance: string;
  allowance: ethers.BigNumber;
}
export interface TokenInfo {
  readonly name: string;
  readonly ticker: string;
  readonly replacedTicker?: string;
  readonly icon: FunctionComponent<React.PropsWithChildren<SvgProps>>;
  readonly tokenContractAddress: string;
  readonly decimals: number;
  readonly native: boolean;
}

export interface Token {
  [ticker: string]: TokenInfo;
}

export interface ITokenMap {
  [tokenContractAddress: string]: TokenInfo;
}

export const ERC20TokenMap: Token = {
  usdc: {
    name: 'USD Coin',
    ticker: 'USDC',
    icon: UsdcIcon,
    native: false,
    tokenContractAddress: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    decimals: 6,
  },
  usdc_e: {
    name: 'USD Coin',
    ticker: 'USDC.e',
    icon: UsdcEIcon,
    native: false,
    tokenContractAddress: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    decimals: 6,
  },
  usdt: {
    name: 'Tether',
    ticker: 'USDT',
    icon: Usdt2Icon,
    native: false,
    tokenContractAddress: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    decimals: 6,
  },
  usdt_e: {
    name: 'Tether',
    ticker: 'USDT.e',
    icon: UsdtEIcon,
    native: false,
    tokenContractAddress: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    decimals: 6,
  },
  // busd: {
  //   name: 'Binance USD',
  //   ticker: 'busd',
  //   icon: BusdIcon,
  //   tokenContractAddress: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  // },
  // tusd: {
  //   name: 'TrueUSD',
  //   ticker: 'tusd',
  //   icon: TusdIcon,
  //   tokenContractAddress: '0x0000000000085d4780B73119b644AE5ecd22b376',
  // },
  // usdp: {
  //   name: 'Pax Dollar',
  //   ticker: 'usdp',
  //   icon: Usdp1Icon,
  //   tokenContractAddress: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
  // },
  dai_e: {
    name: 'Dai Stablecoin',
    ticker: 'DAI.e',
    icon: DaiIcon,
    native: false,
    tokenContractAddress: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    decimals: 18,
  },
  // btc: {
  //   name: 'Bitcoin',
  //   ticker: 'BTC',
  //   icon: BtcIcon, // token address different in each network still thinking
  //   native: false,
  //   tokenContractAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
  //   decimals: 8,
  // },
  wbtc_e: {
    name: 'Wrapped BTC',
    ticker: 'WBTC.e',
    icon: WbtcEIcon,
    native: false,
    tokenContractAddress: '0x50b7545627a5162f82a992c33b87adc75187b218',
    decimals: 8,
  },
  // eth: {
  //   name: 'Ethereum',
  //   ticker: 'ETH',
  //   icon: EthIcon,
  //   native: false,
  //   tokenContractAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   decimals: 18,
  // },
  weth_e: {
    name: 'Wrapped Ether',
    ticker: 'WETH.e',
    icon: WethEIcon,
    native: false,
    tokenContractAddress: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    decimals: 18,
  },
  avax: {
    name: 'AVAX',
    ticker: 'AVAX',
    replacedTicker: 'WAVAX',
    icon: AvaxIcon,
    native: true,
    tokenContractAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', // use wavax address to get certain dataInfo
    decimals: 18,
  },
  // wavax: {
  //   name: 'Wrapped AVAX',
  //   ticker: 'WAVAX',
  //   icon: AvaxIcon,
  //   native: false,
  //   tokenContractAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  //   decimals: 18,
  // },
};
