import { NetworkType } from '../../constants/base/network';
import { nanoid } from 'nanoid';

import {
  DEFAULT_DEADLINE_FROM_NOW,
  DEFAULT_TXN_DISMISS_MS,
} from '../../constants/misc';
import { AppSubscriptionKeys } from '../../constants/store';
import { SliceCreator } from '..';

export enum PopupType {
  INFO = 'info',
  TXN = 'txn',
  ERROR = 'error',
}
export type TxPopupContent = {
  [key in PopupType]?: any;
};

export enum ModalType {
  INVEST,
  WITHDRAWL,
  STRATEGY_OPTIMIZATION,
  PDN_DETAILS,
  NETWORK_SELECTOR,
  CONFIRMATION,
  TXN_SUBMITTED,
  TXN_REJECTED,
  WRONG_NETWORK,
}

type Popup = {
  key: string;
  show: boolean;
  content: TxPopupContent;
  removeAfterMs: number | null;
};

type Modal = {
  modal: ModalType;
  modalProps?: object;
};
type PopupList = Array<Popup>;
type WalletList = Array<string>;

export interface IAppSlice {
  deadline: number;
  slippageTolerance: { [account: string]: number | 'auto' };
  networkType: NetworkType;
  // width: number;
  loading: boolean;
  language: string;
  disclaimerAccepted: boolean;
  walletModalIsOpen: boolean;
  openModal: Modal | null;
  popupList: PopupList;
  doneInit: boolean;
  privateBeta: boolean;
  walletChecked: WalletList;
  version: number;

  initSubscription: () => {
    [unSubKey: string]: () => void;
  };
  setNetworkType: (networkType: NetworkType) => void;
  // setWidth: (width: number) => void;
  setDisclaimerAccepted: () => void;
  setErrMsg: (errMsg: string | null) => void;
  setLoading: (loading: boolean) => void;
  langSelect: (lang: string) => void;
  setWalletModalIsOpen: (walletModalIsOpen: boolean) => void;
  setOpenModal: (modal: ModalType | null, modalProps?: object) => void;
  addPopup: (
    content: TxPopupContent,
    key: string | null,
    removeAfterMs: number
  ) => void;
  removePopup: (key: string) => void;
  updateSlippageTolerance: (slippageTolerance: number | 'auto') => void;
  updateDeadline: (deadline: number) => void;
  setDoneInit: () => void;
  setPrivateBeta: (isPrivate: boolean) => void;
  setWalletChecked: (wallet: string) => void;
  upgradeStore: () => void;
}

export const appSlice: SliceCreator<IAppSlice> = (
  set,
  get,
  api,
  setSelf,
  getSelf
) => {
  return {
    networkType: NetworkType.MAINNET,
    // width: window.innerWidth,
    language: 'english',
    disclaimerAccepted: false,
    loading: false,
    walletModalIsOpen: false,
    openModal: null,
    popupList: [],
    slippageTolerance: {},
    deadline: DEFAULT_DEADLINE_FROM_NOW,
    doneInit: false,
    privateBeta: true,
    walletChecked: [],
    version: 1,

    setDoneInit() {
      if (getSelf().doneInit === true) return;
      set((state: any) => {
        state.appSlice.doneInit = true;
      });
    },

    setNetworkType: (networkType: NetworkType) => {
      set((state: any) => {
        state.appSlice.networkType = networkType;
      });
      /**
       * As we set the network type (MAINNET/TESTNET), we need to initialize the configurations
       * for strategy blockchain networks (i.e. where our trading strategies are located).
       */
    },
    initSubscription: () => {
      return {
        [AppSubscriptionKeys.NETWORK]: api.subscribe(
          (state: any) => state,
          (appSlice: any, prevAppSlice: any) => {
            const { networkType } = appSlice;
            if (networkType !== prevAppSlice.networkType) {
            }
          }
        ),
      };
    },
    setDisclaimerAccepted: () => {
      set((state: any) => {
        state.appSlice.disclaimerAccepted = true;
      });
    },
    langSelect: (lang: string) => {
      // Update state of language in application and local storage
      set((state: any) => {
        state.appSlice.language = lang;
      });
    },
    setErrMsg: (errMsg: string | null) => {
      set((state: any) => {
        state.appSlice.errMsg = errMsg;
      });
    },
    setLoading: (loading: boolean) => {
      set((state: any) => {
        state.appSlice.loading = loading;
      });
    },
    setWalletModalIsOpen: (walletModalIsOpen: boolean) => {
      set((state: any) => {
        state.appSlice.walletModalIsOpen = walletModalIsOpen;
      });
    },
    setOpenModal(modal: ModalType | null, modalProps?: object) {
      set((state: any) => {
        state.appSlice.openModal = { modal, modalProps };
      });
    },
    addPopup(
      content: TxPopupContent,
      key: string | null,
      removeAfterMs: number = DEFAULT_TXN_DISMISS_MS
    ) {
      set((state: any) => {
        state.appSlice.popupList = (
          key
            ? state.appSlice.popupList.filter(
                (popup: Popup) => popup.key !== key
              )
            : state.appSlice.popupList
        ).concat([
          {
            key: key || nanoid(),
            show: true,
            content,
            removeAfterMs,
          },
        ]);
      });
    },
    removePopup(key: string) {
      set((state: any) => {
        state.appSlice.popupList.forEach((p: Popup) => {
          if (p.key === key) {
            p.show = false;
          }
        });
      });
    },
    updateSlippageTolerance(slippageTolerance: number | 'auto') {
      const address = get().chainSlice.chainProvider?.walletAddress;
      if (!address) return;
      set((state: any) => {
        state.appSlice.slippageTolerance[address] = slippageTolerance;
      });
    },
    updateDeadline(deadline: number) {
      set((state: any) => {
        state.appSlice.deadline = deadline;
      });
    },
    setPrivateBeta: (isPrivate: boolean) => {
      set((state: any) => {
        state.appSlice.privateBeta = isPrivate;
      });
    },
    setWalletChecked: (wallet: string) => {
      set((state: any) => {
        if (!state.appSlice.walletChecked.includes(wallet.toLowerCase()))
          state.appSlice.walletChecked.push(wallet);
      });
    },
    // Clear persisted store to support user based txn/slippage.
    upgradeStore: () => {
      const { slippageTolerance } = getSelf();
      // console.log(typeof slippageTolerance);
      if (typeof slippageTolerance !== 'object') {
        set((state: any) => {
          state.appSlice.slippageTolerance = {};
          state.transactionManagerSlice.pendingTransactions = {};
          state.transactionManagerSlice.completedTransactions = {};
          state.transactionManagerSlice.failedTransactions = {};
        });
        window.location.reload();
      }
    },
  };
};
