import {
  forwardRef,
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';
import { Blockchain, chainList } from '../../../../constants/crosschain';
import {
  ExplorerDataType,
  getExplorerLink,
} from '../../../../helpers/evm/getExplorerLink';
import { transactionMessageParser } from '../../../../helpers/utilities';
import { useChainDetails } from '../../../../store/base/chain/hooks';
import { ITransaction } from '../../../../store/transaction/TransactionManagerSlice';
import { Button } from '../../Components/Button';
import { CopyButton } from '../../Components/Button/CopyButton';
import { ViewOnExplorer } from '../../Components/Button/ViewOnExplorer';
import { Dropdown } from '../../Components/Dropdown';
import { NumberCounter } from '../../Components/NumberCounter';
import { MobilePopOver } from '../../Modal/MobilePopOver';
import { NetworkMap } from '../SwitchNetwork';
import './authUser.scss';
import { useStore } from '../../../../store';
import { IEvmProvider } from '../../../../store/provider/Evm/EvmProvider';
import styled from 'styled-components';
import { CompletedTxnIcon, FailedTxnIcon, LinkIcon, PendingTxnIcon, SvgProps } from '@aperture/assetkit';

interface AuthUserProps {
  mobile: boolean;
  balance: number;
  address: string;
  explorerURL: string;
  disconnectFunc: () => void;
  transactionsHistory: ITransaction[];
  walletIcon: FunctionComponent<React.PropsWithChildren<SvgProps>>;
  chain: Blockchain;
  clearAllTransactions: () => void;
}

const StyledPendingTxnIcon = styled(PendingTxnIcon)`
  linearGradient {
    stop:first-child {
      stop-color: ${({ theme }) => theme.colors.pendingTxnColor1};
    }
    stop:nth-child(2) {
      stop-color: ${({ theme }) => theme.colors.pendingTxnColor2};
    }
    stop:nth-child(3) {
      stop-color: ${({ theme }) => theme.colors.pendingTxnColor3};
    }
  }
`;

const StyledCompletedTxnIcon = styled(CompletedTxnIcon)`
  circle { fill: ${({ theme }) => (theme.colors.completedTxnColor1)}; }
  path { fill: ${({ theme }) => (theme.colors.completedTxnColor2)}; }
`;

const StyledLinkIcon = styled(LinkIcon)`
  path { stroke: ${({ theme }) => (theme.colors.text)}; }
`;

export const AuthUser = forwardRef<AuthUserProps, PropsWithChildren<any>>(
  (
    {
      mobile,
      balance,
      address,
      explorerURL,
      disconnectFunc,
      transactionsHistory,
      walletIcon,
      chain,
      clearAllTransactions,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const { chainId } = useChainDetails();
    const childRef = useRef(null);

    const slideAddress = (address: string, long: boolean) => {
      return long
        ? address.slice(0, 11) + '...' + address.slice(-11)
        : address.slice(0, 6) + '...' + address.slice(-4);
    };
    const providerController = useStore(
      (state) =>
        (state.chainSlice.chainProvider as IEvmProvider)?._web3Modal
          ?.providerController
    );

    const walletName = useMemo(
      () => providerController?.getName(),
      [providerController]
    );
    const txnHistoryEntry = (txn: ITransaction) => {
      return (
        <li key={txn.timestamp} className="txnHistory-entry">
          {txn.status === 'OPENED' ? (
            <StyledPendingTxnIcon />
          ) : txn.status === 'CLOSED' ? (
            <StyledCompletedTxnIcon />
          ) : txn.status === 'ERROR' ? (
            <FailedTxnIcon />
          ) : (
            <></>
          )}
          <span>{transactionMessageParser(txn)}</span>
          {txn.hash !== undefined && chainId && (
            <a
              href={getExplorerLink(
                chainId,
                txn.hash,
                ExplorerDataType.TRANSACTION
              )}
              target="_blank"
              rel="noreferrer"
            >
              {<StyledLinkIcon />}
            </a>
          )}
        </li>
      );
    };

    const Content = () => (
      <div className={['authUser-content', mobile ? '' : 'desktop'].join(' ')}>
        <div className="authUser-content-top">
          <div className="headline-5">{`Connected With ${walletName}`}</div>
          <div className="flex-vertical">
            <div style={{ marginRight: '8px' }}>{walletIcon}</div>
            <div className="headline-5">{slideAddress(address, true)}</div>
          </div >
          <div className="flex-vertical">
            <ViewOnExplorer
              mobile={mobile}
              address={getExplorerLink(
                chainId,
                address,
                ExplorerDataType.ADDRESS
              )}
            />
            <CopyButton mobile={mobile} copyContent={address} />
          </div>

          <Button
            className={mobile ? 'button-3' : 'button-5'}
            variant="info"
            size="xsmall"
            style={{ width: '100%', padding: mobile ? '8px 6px' : '4px 20px' }}
            onClick={disconnectFunc}
            text="Disconnect"
          />
        </div >
        <div className="authUser-content-bottom">
          <div className="authUser-content-bottom-title">
            <span className={mobile ? 'subtitle-1' : 'subtitle-2'}>
              Recent Activities
            </span>
            {transactionsHistory && transactionsHistory.length > 0 && (
              <button
                className="button-4"
                onClick={() => clearAllTransactions()}
              >
                Clear all
              </button>
            )}
          </div>
          <div className="authUser-content-bottom-body">
            {transactionsHistory && transactionsHistory.length > 0 ? (
              <ul className="body-2">
                {transactionsHistory.map((txn: ITransaction) =>
                  txnHistoryEntry(txn)
                )}
              </ul>
            ) : (
              <p className={mobile ? 'body-1' : 'subtitle-2'}>
                Your recent transactions will appear here...
              </p>
            )}
          </div>
        </div>
      </div >
    );

    const AuthBtn = (props: any) => (
      <div
        onClick={props.onClick}
        className="authUser-trigger"
        style={{ marginRight: mobile ? '8px' : '0px' }}
      >
        <span className="authUser-address-block">
          {walletIcon}
          <div
            className={mobile ? 'button-5' : 'headline-5'}
            style={{ letterSpacing: '0.0025em' }}
          >
            {slideAddress(address, false)}
          </div>
        </span >
        {!mobile && (
          <div className="headline-5 top" style={{ letterSpacing: '0.0025em' }}>
            <NumberCounter
              value={balance}
              formatNumberFunction={(value: number) =>
                Math.floor(value * 100) / 100 +
                ' ' +
                chainList[NetworkMap[chain]].nativeDenom
              }
            />
          </div>
        )}
      </div >
    );
    return (
      <>
        {mobile ? (
          <>
            <AuthBtn onClick={() => setOpen(true)} />
            <MobilePopOver
              open={open}
              onClose={() => setOpen(false)}
              cross={true}
              sx={{ padding: 0 }}
              className="connected-pop-over"
            >
              <Content />
            </MobilePopOver>
          </>
        ) : (
          <Dropdown
            ref={childRef}
            direction={{
              anchorOrigin_vertical: 'bottom',
              anchorOrigin_horizontal: 'right',
              transformOrigin_vertical: 'top',
              transformOrigin_horizontal: 'right',
            }}
            trigger={<AuthBtn />}
            style={{ padding: 0 }}
          >
            <Content />
          </Dropdown>
        )}
      </>
    );
  }
);
