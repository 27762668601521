import React from 'react';
import { PortfolioCardMobile, PortfolioCardWeb } from '.';
import { useMatchBreakpoints } from '../../../contexts';
import { StrategyCardContainer } from '../StrategyCardContainer';
import { StrategyDropdown } from '../StrategyDropdown';
import { PortfolioCardProps } from './types';

export const PortfolioCard: React.FC<PortfolioCardProps> = ({
  order,
  ratio,
  vaultItem,
  strategy,
  buttonGroup,
  MobileButtonGroup,
  isLoading,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StrategyCardContainer
      order={order}
      title={
        <>
          {isSm ? (
            <PortfolioCardMobile
              {...{
                vaultItem,
                strategyType: strategy.strategyType,
                tvlCapacity: strategy.tvlCapacity,
                position: strategy.position!,
                MobileButtonGroup,
              }}
            />
          ) : (
            <PortfolioCardWeb
              {...{
                ratio,
                vaultItem,
                strategyType: strategy.strategyType,
                tvlCapacity: strategy.tvlCapacity,
                position: strategy.position!,
                buttonGroup,
              }}
            />
          )}
        </>
      }
      details={
        <StrategyDropdown
          {...{ vaultItem, strategy, MobileButtonGroup, ...props }}
        />
      }
      isLoading={isLoading}
    />
  );
};
