import styled, { useTheme } from 'styled-components';
import { useMatchBreakpoints } from '../../..';
import {
  getDate,
  getDayInString,
  getDayInToday,
  getHours,
  getPrefix,
  numberParse,
  TimeframeType,
} from '../../../helper';
import { ColoredValue } from '../../ColoredValue';
import { TooltipProps } from './types';

const Dot = styled.div<{ color: string; rebalanced: boolean }>`
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  background: ${({ color, rebalanced }) =>
    rebalanced ? color : 'transparent'};
  border: 2px solid
    ${({ theme, color, rebalanced }) =>
      rebalanced ? theme.colors.graphRebalanceStroke : color};
  border-radius: 50%;
  display: inline-block;
  margin-right: -4px;
`;

const DotSM = styled.div<{ color: string; rebalanced: boolean }>`
  width: 4px;
  height: 4px;
  background: ${({ color, rebalanced }) =>
    rebalanced ? color : 'transparent'};
  border: 2px solid
    ${({ theme, color, rebalanced }) =>
      rebalanced ? theme.colors.graphRebalanceStroke : color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0px;
  margin-top: -1px;
`;

const Container = styled.div<{ mini?: boolean; mobile: boolean }>`
  ${({ mobile, mini, theme }) =>
    mobile
      ? `
    z-index: 99999;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: calc(100vw - 84px);
    text-align: center;
    padding: 10px 0px 6px;
    background: ${theme.colors.backgroundGraph};
    color: ${theme.colors.text};
  `
      : `
    display: flex;
    flex-direction: column;
    padding: ${mini ? '4px 8px' : '12px'};
    border-radius: ${mini ? '2px' : '10px'};
    gap: ${mini ? '4px' : '10px'};
    background: ${theme.colors.backgroundGraphTooltip};
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  `}
`;

const TextWhite = styled.span<{ sm?: boolean; mobile?: boolean }>`
  color: ${({ theme, mobile }) =>
    mobile ? theme.colors.text : theme.colors.white};
  font-weight: 400;
  font-size: ${({ sm, mobile }) => (mobile ? '11px' : sm ? '10px' : '14px')};
  display: flex;
  align-items: center;
  gap: ${({ sm }) => (sm ? '4px' : '12px')};
  line-height: 14px;
  ${({ mobile }) => (mobile ? `width: fit-content;` : ``)}
`;

const TextRight = styled.div<{ sm?: boolean; mobile?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ sm }) => (sm ? '12px' : '14px')};
  margin-left: auto;
  order: 2;
`;

const Font11 = styled.div`
  font-size: 11px;
`;

const Flex = styled.div<{ mini: boolean; mobile: boolean }>`
  ${({ mobile, mini }) =>
    mobile
      ? `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
  `
      : `
      display: flex;
      flex-direction: column;
      gap: ${mini ? '4px' : '12px'};
      `}
`;

export const Tooltip = ({
  active,
  payload,
  value,
  mini = false,
  timeFrame = TimeframeType.DAY,
  ...props
}: TooltipProps) => {
  const { isSm: mobile } = useMatchBreakpoints();
  const theme = useTheme();
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Container mini={mini} mobile={mobile} {...props}>
        {!mobile ? (
          <TextWhite sm={mini}>
            {timeFrame === TimeframeType.DAY &&
              getDayInToday(data.timestamp_sec) + ', '}
            {timeFrame === TimeframeType.WEEK &&
              getDayInString(data.timestamp_sec) + ', '}
            {!(timeFrame === TimeframeType.DAY && mini) &&
              getDate(data.timestamp_sec)}
            {!mini ? (
              <TextRight sm={mini}> {getHours(data.timestamp_sec)} </TextRight>
            ) : (
              <span> {getHours(data.timestamp_sec)}</span>
            )}
          </TextWhite>
        ) : (
          <Font11>{getDate(data.timestamp_sec)}</Font11>
        )}

        <Flex mini={mini} mobile={mobile}>
          {value.map((item, index) => {
            const percentage = item.suffix === '%';
            const value = data[item.value];
            const changingRate = item.changingRate
              ? data[item.changingRate]
              : undefined;
            if (item.type === 'rebalanced' && data.rebalanced) {
              const activeDots = document.getElementsByClassName(
                'recharts-dot'
              ) as HTMLCollectionOf<HTMLElement>;
              if (activeDots[1]) {
                if (activeDots[1].parentElement) {
                  const cx = activeDots[1].getAttribute('cx');
                  const cy = activeDots[1].getAttribute('cy');
                  const r = mobile ? '2.5' : '3.5';
                  activeDots[1].setAttribute(
                    'style',
                    `r: ${mobile ? '3.5' : '4.75'}; fill: ${item.color};`
                  );
                  activeDots[1].setAttribute(
                    'stroke-width',
                    mobile ? '1' : '1.5'
                  );
                  activeDots[1].parentElement.innerHTML =
                    activeDots[1].outerHTML +
                    `<circle cx="${cx}" cy="${cy}" r="${r}" fill="transparent" stroke-width="1" stroke="${theme.colors.graphRebalanceStroke}"></circle>`;
                }
              }
            }
            return (
              (item.type != 'rebalanced' ||
                (item.type === 'rebalanced' && data.rebalanced && !mobile)) && (
                <TextWhite sm={mini} mobile={mobile} key={index}>
                  {mini ? (
                    <DotSM
                      color={item.color}
                      rebalanced={item.type === 'rebalanced'}
                    />
                  ) : (
                    <Dot
                      color={item.color}
                      rebalanced={item.type === 'rebalanced'}
                    />
                  )}
                  {item.text}
                  {item.type !== 'rebalanced' && ':'}
                  {item.type !== 'rebalanced' && (
                    <TextRight sm={mini} mobile={mobile}>
                      <ColoredValue
                        value={item.colorChanging ? data.percentage : 0}
                        color={mobile ? undefined : 'white'}
                      >
                        {getPrefix(value, percentage || item.addSignForValue)}
                        {item.prefix}
                        {percentage
                          ? numberParse(Math.abs(value), 'percentage', false, 3)
                          : `${numberParse(
                              Math.abs(value),
                              'large',
                              false,
                              4,
                              undefined,
                              item.suffix
                            )}`}
                        {changingRate !== undefined &&
                          ` (${numberParse(
                            Math.abs(changingRate),
                            'percentage',
                            false,
                            3,
                            getPrefix(changingRate)
                          )})`}
                      </ColoredValue>
                    </TextRight>
                  )}
                </TextWhite>
              )
            );
          })}
        </Flex>
      </Container>
    );
  }
  return null;
};
