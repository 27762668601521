import { WalletIcon, WrongNetwork2Icon } from '@aperture/assetkit';
import { forwardRef, PropsWithChildren, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Blockchain, BlockchainType } from '../../../constants/crosschain';
import { IStore, useStore } from '../../../store';
import { useOpenModal } from '../../../store/base/application/hooks';
import { ModalType } from '../../../store/base/AppSlice';
import { Button } from '../Components/Button';
import { Logo } from './../Components/Logo';
import { Navbar } from './../Navbar';
import { AuthUser } from './AuthUser';
import { Avatar } from './Avatar';
import { SwitchNetwork } from './SwitchNetwork';
import './topbar.scss';

interface TopBarProps {
  mobile: boolean;
  walletConnected: boolean;
  onSelect: (chainId: any) => void;
  balance: number;
  address: string;
  explorerURL: string;
  disconnectFunc: () => void;
  transactionsHistory: any;
  walletIcon: string;
  onChainSelect: (chainId: any) => void;
  onWalletSelect: (walletType: any) => void;
  chain: Blockchain | null;
  chainType: BlockchainType;
  clearAllTransactions: () => void;
}

interface WrongNetworkButtonProps {
  mobile: boolean;
}
const WrongNetworkButton = styled(Button)<WrongNetworkButtonProps>`
  background: ${({ theme }) => theme.colors.warningError} !important;
  /* font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; */
  /* identical to box height */
  height: ${({ mobile }) => (mobile ? '37.5px' : '40px')};

  /* display: flex;
  align-items: center;
  text-align: center; */
  :after {
    color: ${({ theme }) => theme.colors.black} !important;
    padding: ${({ mobile }) => (mobile ? '0px' : '4px')};
  }
  margin-right: ${({ mobile }) => (mobile ? '8px' : '0px')};
  :hover {
    /* background: ${({ theme }) => theme.colors.warningError} !important; */
    box-sizing: border-box;
    outline: 1px solid map.get($text-line, 'light-grey-blue');
  }
  > div {
    display: flex;

    > svg {
      > * {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }
`;

const StyledWalletIcon = styled(WalletIcon)`
  path {
    fill: ${({ theme }) => theme.colors.textAlt};
  }
  rect {
    fill: ${({ theme }) => theme.colors.textAlt};
  }
`;

export const TopBar = forwardRef<TopBarProps, PropsWithChildren<any>>(
  (
    {
      mobile,
      walletConnected,
      onSelect,
      balance,
      address,
      explorerURL,
      disconnectFunc,
      transactionsHistory,
      walletIcon,
      onChainSelect,
      onWalletSelect,
      children,
      chain,
      chainType,
      clearAllTransactions,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const isWrongNetwork = useStore(
      (state: IStore) => state.chainSlice.isWrongNetwork
    );
    const openWrongNetworkModal = useOpenModal(ModalType.WRONG_NETWORK);
    useEffect(() => {
      if (isWrongNetwork) {
        openWrongNetworkModal();
      }
    }, [isWrongNetwork, openWrongNetworkModal]);
    const openWalletConnectModal = useOpenModal(ModalType.NETWORK_SELECTOR);

    return (
      <div>
        <div className={'topbar'}>
          {mobile && (
            <Logo
              colored
              direction="horizontal"
              mode={theme.colors.text === '#FFFFFF' ? 'dark' : 'light'}
              text
              style={{ width: '105.24px', height: '36px' }}
            />
          )}
          <div className="topbar-right">
            <SwitchNetwork
              mobile={mobile}
              onSelect={onSelect}
              chain={chain}
              style={{ marginRight: '10px' }}
            />
            {isWrongNetwork ? (
              <WrongNetworkButton
                className={mobile ? 'button-3' : 'button-5'}
                variant="contained"
                size="xsmall"
                style={{
                  width: '100%',
                  padding: mobile ? '8px 6px' : '4px 20px',
                }}
                onClick={() => openWrongNetworkModal()}
                text={mobile ? 'Network' : 'Wrong Network'}
                mobile={mobile}
              >
                <div>
                  <WrongNetwork2Icon />
                </div>
              </WrongNetworkButton>
            ) : !walletConnected ? (
              <div>
                <Button
                  className="connect-wallet"
                  variant="contained"
                  size="small"
                  disabled={walletConnected}
                  onClick={() => openWalletConnectModal()}
                >
                  {' '}
                  <>
                    <StyledWalletIcon width="17.5" />
                    <span
                      className="button-3"
                      style={{ marginLeft: mobile ? 4 : 10 }}
                    >
                      {' '}
                      {mobile ? 'connect' : 'connect wallet'}
                    </span>
                  </>
                </Button>
              </div>
            ) : (
              <>
                <AuthUser
                  mobile={mobile}
                  balance={balance}
                  address={address}
                  explorerURL={explorerURL}
                  disconnectFunc={disconnectFunc}
                  transactionsHistory={transactionsHistory}
                  walletIcon={walletIcon}
                  chain={chain}
                  clearAllTransactions={clearAllTransactions}
                />
                {!mobile && <Avatar />}
              </>
            )}
            {mobile && <Navbar active mobile={mobile} {...props} />}
          </div>
        </div>
        <div
          id="main-container"
          style={{
            height: mobile ? 'calc(100vh - 151px)' : 'calc(100vh - 92px)',
            overflowY: 'auto',
            width: mobile ? '100vw' : 'calc(100vw - 260px)',
            overflowX: 'hidden',
            marginLeft: mobile ? '0px' : '260px',
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);
