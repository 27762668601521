import * as amplitude from '@amplitude/analytics-browser';
import {
  MetamaskIcon,
  OkxDarkIcon,
  OkxIcon,
  WalletConnectIcon,
} from '@aperture/assetkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';
import { Blockchain, isProdEnv } from '../../constants/crosschain';
import { keys } from '../../features/keyNames';
import { logErr } from '../../helpers/dev';
import { useStore } from '../../store';
import { IEvmProvider } from '../../store/provider/Evm/EvmProvider';
import { NavContainer } from '../v1';

const defaultWalletState = {
  walletConnected: false,
  balance: 0,
  address: '',
  explorerURL: 'https://snowtrace.io/',
  disconnectFunc: () => {},
  transactionsHistory: [],
  walletIcon: <MetamaskIcon />,
};

amplitude.init(
  process.env.REACT_APP_AMPLITUDE_API_KEY!,
  process.env.REACT_APP_AMPLITUDE_USER_ID
);

export default function NavbarContainer(props) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const chainProvider = useStore((state) => state.chainSlice.chainProvider);
  const setChain = useStore((state) => state.chainSlice.setChain);
  const chain = useStore((state) => state.chainSlice.chain);
  const walletAddress = useStore(
    (state) => state.chainSlice.chainProvider?.walletAddress
  );
  const chainId = useStore((state) => state.chainSlice.chainId);
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const isWrongNetwork = useStore((state) => state.chainSlice.isWrongNetwork);

  const pendingTransactions = useStore(
    (state) => state.transactionManagerSlice.pendingTransactions
  );
  const completedTransactions = useStore(
    (state) => state.transactionManagerSlice.completedTransactions
  );

  const failedTransactions = useStore(
    (state) => state.transactionManagerSlice.failedTransactions
  );

  const clearAllTransactions = useStore(
    (state) => state.transactionManagerSlice.removeAllTransactions
  );

  const providerController = useStore(
    (state) =>
      (state.chainSlice?.chainProvider as IEvmProvider)?._web3Modal
        ?.providerController
  );

  const [walletData, setWalletData] = useState(defaultWalletState);
  const getWalletIcon = useCallback(() => {
    if (providerController && providerController.cachedProvider) {
      switch (providerController.getName()) {
        case 'MetaMask':
          return <MetamaskIcon />;
        case 'OKX Wallet':
          return theme.colors.text === '#FFFFFF' ? (
            <OkxDarkIcon />
          ) : (
            <OkxIcon />
          );
        case 'WalletConnect':
          return <WalletConnectIcon />;
        default:
          return <MetamaskIcon />;
      }
    }
  }, [theme.colors.text, providerController]);

  const transactionsHistory = useMemo(() => {
    if (!walletAddress || !chainId) return [];
    const extractTransactions = (transactions) =>
      transactions?.[walletAddress]?.[chainId]
        ? Object.values(transactions[walletAddress][chainId])
        : [];
    const txns = [
      ...extractTransactions(pendingTransactions).filter(
        (tx) => tx.status !== 'INIT'
      ),
      ...extractTransactions(completedTransactions),
      ...extractTransactions(failedTransactions),
    ];
    txns.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    return txns;
  }, [
    pendingTransactions,
    completedTransactions,
    failedTransactions,
    walletAddress,
    chainId,
  ]);
  useEffect(() => {
    if (chainProvider) {
      setWalletData({
        walletConnected: isWalletConnected,
        balance: chainProvider.balance,
        address: chainProvider.walletAddress,
        chain,
        walletAddress,
        explorerURL: `https://snowtrace.io/address/${chainProvider.walletAddress}`,
        disconnectFunc: async () => {
          await chainProvider.deleteWallet();
          queryClient.removeQueries(keys.positionInfo);
          queryClient.removeQueries(keys.positions);
        },
        clearAllTransactions: () =>
          clearAllTransactions(walletAddress, chainId),
        transactionsHistory,
        walletIcon: getWalletIcon(),
      });
    } else {
      setWalletData(defaultWalletState);
    }
  }, [
    chain,
    chainId,
    chainProvider,
    walletAddress,
    clearAllTransactions,
    isWalletConnected,
    queryClient,
    transactionsHistory,
    getWalletIcon,
  ]);

  const connectWallet = async (walletType) => {
    if (chainProvider) {
      try {
        await chainProvider?.connectWallet(walletType);
        isProdEnv() && amplitudeRecordWalletType(walletType);
      } catch (err) {
        console.log(err);
        logErr(err);
      }
    }
  };

  const amplitudeRecordWalletType = (walletType: string) => {
    let amplitudeEventName = '';
    switch (walletType) {
      case 'injected':
        amplitudeEventName = 'Metamask';
        break;
      case 'custom-okxwallet':
        amplitudeEventName = 'OKXWallet';
        break;
      case 'coinbasewallet':
        amplitudeEventName = 'CoinbaseWallet';
        break;
      case 'walletconnect':
        amplitudeEventName = 'WalletConnect';
        break;
    }
    amplitudeEventName != '' && amplitude.track(amplitudeEventName);
  };

  const switchChain = useCallback(
    (chainId) => {
      setChain(chainId);
    },
    [setChain]
  );

  // Set default network.
  useEffect(() => {
    if (!isWalletConnected) switchChain(Blockchain.AVALANCHE);
  }, [switchChain, isWalletConnected, isWrongNetwork]);

  return (
    <NavContainer
      walletConnected={walletData.walletConnected}
      balance={walletData.balance}
      address={walletData.address}
      explorerURL={walletData.explorerURL}
      disconnectFunc={walletData.disconnectFunc}
      transactionsHistory={walletData.transactionsHistory.slice(0, 5)}
      walletIcon={walletData.walletIcon}
      onChainSelect={switchChain}
      onWalletSelect={connectWallet}
      chain={walletData.chain}
      walletAddress={walletData.walletAddress}
      clearAllTransactions={walletData.clearAllTransactions}
      {...props}
    >
      {props.children}
    </NavContainer>
  );
}
