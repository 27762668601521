import { SvgProps } from '../../Svg/types';

export const CaretIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.967734 4.81106L5.6129 0.152074C5.6682 0.096774 5.7281 0.0576958 5.79262 0.0348386C5.85714 0.0116128 5.92626 6.33612e-08 5.99999 6.2482e-08C6.07373 6.16027e-08 6.14285 0.0116128 6.20737 0.0348386C6.27188 0.0576958 6.33179 0.096774 6.38709 0.152074L11.0461 4.81106C11.1751 4.94009 11.2396 5.10138 11.2396 5.29493C11.2396 5.48848 11.1705 5.65438 11.0323 5.79263C10.894 5.93088 10.7327 6 10.5484 6C10.364 6 10.2028 5.93088 10.0645 5.79263L5.99999 1.72811L1.93548 5.79263C1.80644 5.92166 1.64755 5.98618 1.45879 5.98618C1.26967 5.98618 1.10598 5.91705 0.967734 5.7788C0.829486 5.64055 0.760361 5.47926 0.760361 5.29493C0.760361 5.1106 0.829486 4.94931 0.967734 4.81106Z" />
    </svg>
  );
};
