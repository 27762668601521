import { CrossIcon } from '@aperture/assetkit';
import { Modal as MuiModal } from '@mui/material';
import {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useRef,
} from 'react';
import { ModalType } from 'store/base/AppSlice';
import styled from 'styled-components';
import { useModalIsOpen } from '../../../../store/base/application/hooks';
import './modal.scss';

interface ModalProps {
  onClose: () => void;
  modalClassName?: string;
}

const StyledCrossIcon = styled(CrossIcon)`
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

export const Modal = forwardRef<ModalProps, PropsWithChildren<any>>(
  ({ onClose, modalClassName, children }, ref) => {
    const domRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => domRef.current!);
    const isOpen = useModalIsOpen(ModalType.NETWORK_SELECTOR);

    const Cross = () => (
      <div className="cross" onClick={() => onClose()}>
        <StyledCrossIcon />
      </div>
    );
    return (
      <>
        <MuiModal
          open={isOpen}
          onClose={() => onClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className={'modal-content'.concat(` ${modalClassName as string}`)}
          >
            <Cross />
            {children}
          </div>
        </MuiModal>
      </>
    );
  }
);
