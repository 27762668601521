import { SvgProps } from '..';

export const QuestionMarkIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 0.5C5.14933 0.5 0 5.64933 0 12C0 18.3507 5.14933 23.5 11.5 23.5C17.8507 23.5 23 18.3507 23 12C23 5.64933 17.8507 0.5 11.5 0.5ZM11.5 21.5491C6.22746 21.5491 1.95089 17.2725 1.95089 12C1.95089 6.72746 6.22746 2.45089 11.5 2.45089C16.7725 2.45089 21.0491 6.72746 21.0491 12C21.0491 17.2725 16.7725 21.5491 11.5 21.5491Z"
        fill="white"
      />
      <path
        d="M14.3645 6.98616C13.5944 6.31105 12.5778 5.94141 11.4997 5.94141C10.4216 5.94141 9.40508 6.31362 8.63499 6.98616C7.8341 7.68694 7.39258 8.62902 7.39258 9.63784V9.83292C7.39258 9.94587 7.48499 10.0383 7.59794 10.0383H8.83008C8.94302 10.0383 9.03544 9.94587 9.03544 9.83292V9.63784C9.03544 8.5058 10.1418 7.58426 11.4997 7.58426C12.8576 7.58426 13.964 8.5058 13.964 9.63784C13.964 10.4362 13.3993 11.1677 12.5239 11.504C11.9797 11.7119 11.5177 12.0765 11.1866 12.5539C10.8503 13.0416 10.6757 13.6269 10.6757 14.2199V14.7718C10.6757 14.8847 10.7681 14.9771 10.8811 14.9771H12.1132C12.2262 14.9771 12.3186 14.8847 12.3186 14.7718V14.1891C12.3199 13.9399 12.3963 13.6969 12.5378 13.4917C12.6793 13.2866 12.8793 13.1288 13.1118 13.0391C14.6263 12.4564 15.6043 11.1215 15.6043 9.63784C15.6069 8.62902 15.1653 7.68694 14.3645 6.98616ZM10.4729 17.6468C10.4729 17.9191 10.5811 18.1803 10.7737 18.3728C10.9662 18.5654 11.2274 18.6735 11.4997 18.6735C11.772 18.6735 12.0332 18.5654 12.2258 18.3728C12.4183 18.1803 12.5265 17.9191 12.5265 17.6468C12.5265 17.3744 12.4183 17.1133 12.2258 16.9207C12.0332 16.7282 11.772 16.62 11.4997 16.62C11.2274 16.62 10.9662 16.7282 10.7737 16.9207C10.5811 17.1133 10.4729 17.3744 10.4729 17.6468Z"
        fill="white"
      />
    </svg>
  );
};
