import { animated, SpringValue } from '@react-spring/web';
import { forwardRef, useCallback, useEffect } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components/macro';

import { useRemovePopup } from '../../../store/base/application/hooks';
import { PopupType, TxPopupContent } from '../../../store/base/AppSlice';
import TransactionPopup from './TransactionPopup';

const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 12px;
  width: 14px;
  height: 14px;
  :hover {
    cursor: pointer;
  }
`;
const Popup = styled(animated.div)`
  /* top: -817px; */
  width: 330px;
  height: fit-content !important;
  background: ${({ theme }) => theme.colors.backgroundPopup};
  display: inline-block;
  padding: 12px 16px;
  /* background-color: ${({ theme }) => theme.bg0}; */
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  /* ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 290px;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  `} */
`;
const AnimatedFader = styled(animated.div)`
  position: absolute;
  bottom: ${(props) => (props.top ? '10px' : '0')};
  left: 0px;
  width: auto;
  background-image: linear-gradient(73.11deg, #a88ef5 9.59%, #77dff2 90.41%);
  height: 5px;
`;

// const AnimatedFader = animated(Fader);

const PopupItem = forwardRef<
  HTMLDivElement,
  {
    removeAfterMs: number | null;
    content: TxPopupContent;
    popKey: string;
    life?: SpringValue<string>;
    style?: any;
  }
>(({ removeAfterMs, content, popKey, life, style }, ref) => {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(
    () => removePopup(popKey),
    [popKey, removePopup]
  );
  useEffect(() => {
    if (removeAfterMs === null) return undefined;

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, removeAfterMs + 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [removeAfterMs, removeThisPopup]);

  let popupContent;
  if (PopupType.TXN in content) {
    const {
      [PopupType.TXN]: { title, hash },
    } = content;
    popupContent = <TransactionPopup title={title} hash={hash} />;
  } else if (PopupType.ERROR in content) {
    const {
      [PopupType.ERROR]: { title, msg },
    } = content;
    popupContent = <TransactionPopup title={title} error={msg} />;
  } else if (PopupType.INFO in content) {
    const {
      [PopupType.INFO]: { data },
    } = content;
    popupContent = data;
  }
  //  else if ('failedSwitchNetwork' in content) {
  //   popupContent = (
  //     <FailedNetworkSwitchPopup chainId={content.failedSwitchNetwork} />
  //   );
  // }
  // console.log(life);

  return (
    <Popup style={style}>
      <div ref={ref}>
        <StyledClose
          //  color={theme.text2}
          onClick={removeThisPopup}
        />
        {popupContent}
        {removeAfterMs !== null ? (
          <AnimatedFader style={{ right: life }} />
        ) : null}
      </div>
    </Popup>
  );
});

export default PopupItem;
