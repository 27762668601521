import { SvgProps } from '..';

export const CheckMarkIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 22.5C12.8791 22.5018 14.245 22.231 15.5192 21.7032C16.7933 21.1754 17.9506 20.401 18.9245 19.4245C19.901 18.4506 20.6754 17.2933 21.2032 16.0192C21.731 14.745 22.0018 13.3791 22 12C22.0017 10.6209 21.731 9.25498 21.2032 7.98083C20.6754 6.70668 19.901 5.54939 18.9245 4.57546C17.9506 3.59897 16.7933 2.82458 15.5192 2.29679C14.245 1.769 12.8791 1.49822 11.5 1.50001C10.1209 1.49825 8.75498 1.76905 7.48083 2.29683C6.20668 2.82462 5.04939 3.59899 4.07546 4.57546C3.09899 5.54939 2.32462 6.70668 1.79683 7.98083C1.26905 9.25498 0.998251 10.6209 1.00001 12C0.998222 13.3791 1.269 14.745 1.79679 16.0192C2.32458 17.2933 3.09897 18.4506 4.07546 19.4245C5.04939 20.401 6.20668 21.1754 7.48083 21.7032C8.75498 22.231 10.1209 22.5017 11.5 22.5V22.5Z"
        stroke="#3A9A77"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M7.2998 12.0001L10.4498 15.1501L16.7498 8.8501"
        stroke="#3A9A77"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
