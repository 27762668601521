import { useStore } from '../../../../store';
import './index.scss';
interface ConnectProps {}

export const Avatar = ({ ...props }: ConnectProps) => {
  const walletAddress = useStore(
    (state) => state.chainSlice.chainProvider?.walletAddress
  );
  const src = 'https://robohash.org/' + walletAddress + '.png';
  return (
    <div {...props} className="avatar-img">
      <img src={src} alt="avatar" />
    </div>
  );
};
