import { BigNumber, ethers, utils } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  EvmStrategyContractAbi,
  HOMORA_BN_VAL,
} from '../../../constants/crosschain';
import { strategiesMap } from '../../../constants/strategy/strategies';
import { ERC20TokenMap } from '../../../constants/tokenConfig';
import { calculateProfits, ethersBNToBN } from '../../../helpers/utilities';
import { IStore, useStore } from '../../../store';
import { dynamoClient } from '../../../store/provider/AWSProvider';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';
import {
  IPNLDetails,
  IPNLInfos,
  UnpopulatedPositionInfo,
} from '../../../types';
import { positionConfig } from './config';

export const getPosition = async (
  provider: ethers.providers.Provider | ethers.Signer | undefined,
  strategyAbi: ethers.ContractInterface,
  positionInfo: UnpopulatedPositionInfo
) => {
  // const posMap: { [strategyAddress: string]: PositionInfo } = {};
  if (!provider || !strategyAbi || !positionInfo) return null;

  const getUserProfits = async (
    strategyChainId: number,
    positionId: string,
    stableTokenDecimals: number
  ) => {
    const partition_key = `${strategyChainId}-${positionId}`;
    const param_position_pnl = {
      TableName: 'position_pnl',
      KeyConditionExpression: 'chain_position = :a',
      ExpressionAttributeValues: {
        ':a': `${partition_key}`,
      },
      ScanIndexForward: false,
      Limit: 1,
    };

    let pNLInfos;

    try {
      pNLInfos = await dynamoClient.then((client) =>
        client
          .query(param_position_pnl)
          .promise()
          .then(async (res) => {
            if (res?.Items && res?.Items?.length) {
              const item = res.Items[0];
              return {
                timestamp: item.timestamp,
                net_deposit: formatUnits(
                  item.net_deposit.toString(),
                  stableTokenDecimals
                ),
                equity: formatUnits(
                  item.equity.toString(),
                  stableTokenDecimals
                ),
                net_deposit_24h: formatUnits(
                  item.net_deposit_24h.toString(),
                  stableTokenDecimals
                ),
                equity_24h_ago: formatUnits(
                  item.equity_24h_ago.toString(),
                  stableTokenDecimals
                ),
              } as IPNLInfos;
            } else return undefined;
          })
      );
    } catch (e: any) {
      console.log('Failed to get profits data error.', e);
    }

    if (pNLInfos) {
      return calculateProfits(pNLInfos) as IPNLDetails;
    }

    return undefined;
  };

  const getUserEquityUsd = async (position: {
    positionId: string;
    strategyChainId: number;
    strategyId: string;
  }) => {
    const strategyManager = new ethers.Contract(
      strategiesMap[position.strategyId].strategyAddress,
      strategyAbi,
      provider
    );

    const stableTokenAddr =
      strategiesMap[position.strategyId].tokens[0].tokenContractAddress;
    const stableTokenDecimals =
      strategiesMap[position.strategyId].tokens[0].decimals;
    const decimalPower = BigNumber.from(10).pow(
      BigNumber.from(stableTokenDecimals)
    );
    const [
      vaultState,
      userShares,
      usdPriceETH, // tokenAinTokenB
      totalEquityEth,
      pendingRewardStable,
      usdcPxRaw,
      tokenAPxRaw,
      profits,
    ] = await Promise.all([
      strategyManager.vaultState(),
      strategyManager.getShareAmount([
        position.positionId,
        position.strategyChainId,
      ]),
      strategyManager.getTokenETHValue(stableTokenAddr, decimalPower),
      strategyManager.getEquityETHValue(),
      strategyManager.pendingRewardStable(),
      strategyManager.getTokenETHValue(
        ERC20TokenMap.usdc.tokenContractAddress,
        HOMORA_BN_VAL
      ),
      strategyManager.getTokenETHValue(stableTokenAddr, HOMORA_BN_VAL),
      getUserProfits(
        position.strategyChainId,
        position.positionId,
        stableTokenDecimals
      ),
    ]);
    const totalShares = vaultState[0];
    const avaxPrice = ethersBNToBN(parseUnits('1', 18))
      .div(ethersBNToBN(usdcPxRaw).times(1e6).div(ethersBNToBN(HOMORA_BN_VAL)))
      .toString();
    const tokenAPxUSDC = utils.formatUnits(
      tokenAPxRaw
        .mul(utils.parseUnits('1', stableTokenDecimals))
        .div(usdcPxRaw),
      6
    );
    const pendingRewardETH = pendingRewardStable
      .mul(tokenAPxRaw)
      .div(HOMORA_BN_VAL);
    const userEquityEth: BigNumber = totalShares.isZero()
      ? BigNumber.from(0)
      : totalEquityEth.add(pendingRewardETH).mul(userShares).div(totalShares);

    const totalEquityInUnit = totalEquityEth
      .mul(HOMORA_BN_VAL)
      .div(tokenAPxRaw);
    const userEquityInUnit = formatUnits(
      userEquityEth.mul(HOMORA_BN_VAL).div(tokenAPxRaw),
      stableTokenDecimals
    );

    return [
      avaxPrice,
      userEquityEth,
      totalEquityInUnit,
      userEquityInUnit,
      totalShares,
      userShares,
      totalEquityEth,
      stableTokenDecimals,
      usdPriceETH,
      tokenAPxUSDC,
      profits,
    ];
  };

  try {
    const [
      avaxPrice,
      userEquityEth,
      totalEquityInUnit,
      userEquityInUnit,
      totalShares,
      userShares,
      totalEquityEth,
      stableTokenDecimals,
      usdPriceETH,
      tokenAPxUSDC,
      profits,
    ] = await getUserEquityUsd(positionInfo);
    return {
      ...positionInfo,
      totalEquity: totalEquityInUnit,
      userEquity: userEquityInUnit,
      totalEquityUsd: (
        Number(formatUnits(totalEquityEth, 18)) * Number(avaxPrice)
      ).toString(),
      equity: (
        Number(formatUnits(userEquityEth, 18)) * Number(avaxPrice)
      ).toString(), // 18 is for avax
      totalShares,
      userShares,
      totalEquityEth,
      usdPriceETH,
      decimals: stableTokenDecimals,
      tokenAPxUSDC,
      profits,
    };
  } catch (e: any) {
    console.log(e.stack);
  }
};
export function useFetchEvmPosition(
  positionInfo: UnpopulatedPositionInfo | null,
  options?: any
) {
  const { enabled: extraEnable, ...ops } = options ?? { enabled: true };
  const evmProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  );
  const { chainId } = evmProvider?._evmChainInfo ?? { chainId: null };
  const multicallProvider = evmProvider?._multicallProvider;
  const strategyAbi = useMemo(
    () => (chainId ? EvmStrategyContractAbi[chainId] : null),
    [chainId]
  );
  const shouldFetch: boolean = positionInfo?.strategyId !== null;
  const { queryKey, queryFn } = useMemo(
    () =>
      // @ts-ignore
      positionConfig(strategyAbi, multicallProvider, positionInfo),
    [positionInfo, multicallProvider, strategyAbi]
  );
  return useQuery(queryKey, queryFn, {
    // refetchOnMount: false,
    enabled: shouldFetch && extraEnable,
    ...ops,
  });
}
