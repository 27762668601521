/**
 * Returns a function that returns a random strategy.
 * @returns {Function} A function that returns a random strategy.
 */

import { ERC20TokenMap } from '../../../constants/tokenConfig';
import { Strategy, StrategyInfo } from '../../../constants/strategy';
import {
  Blockchain,
  BlockchainToEvmIdMap,
  BlockchainType,
  BlockchainTypeMap,
  EvmStrategyContractAbi,
  EvmVaultLibContractAbi,
  EvmVaultLibContractAddress,
  HOMORA_BN_VAL,
  WormholeChainIdToBlockchainMap,
} from '../../../constants/crosschain';
import { BN, ethersBNToBN } from '../../../helpers/utilities';
import { BigNumber, ethers, utils } from 'ethers';
import { avax_HOMORA } from '../../../constants/avax-homora';
import { providers } from '@0xsequence/multicall';

const _getEvmStrategyInfo = async (
  strategy: Strategy,
  multicallProvider: providers.MulticallProvider
) => {
  const {
    strategyAddress,
    oracleAddress,
    strategyChainId,
    homoraPosId,
    leverage,
    tokens,
  } = strategy;
  const tokenA = tokens[0]; //deposit token
  const tokenB = tokens[1];
  const chain: Blockchain = WormholeChainIdToBlockchainMap[strategyChainId];
  const evmChainId = BlockchainToEvmIdMap[chain as keyof object];
  const strategyManager = new ethers.Contract(
    strategyAddress,
    EvmStrategyContractAbi[evmChainId],
    multicallProvider
  );
  const vaultLib = new ethers.Contract(
    EvmVaultLibContractAddress[evmChainId],
    EvmVaultLibContractAbi[evmChainId],
    multicallProvider
  );
  const [
    feeConfig,
    vaultLimits,
    leverageRaw,
    totalEquityETHValueRaw,
    tokenAinEthRaw,
    deltaThreshold,
    usdcPxRaw,
    tokenAPxRaw,
    tokenBPxRaw,
    debts,
  ] = await Promise.all([
    strategyManager.feeConfig(),
    strategyManager.vaultLimits(),
    strategyManager.leverageLevel(),
    strategyManager.getEquityETHValue(),
    strategyManager.getTokenETHValue(
      tokenA.tokenContractAddress,
      BigNumber.from(10).pow(tokenA.decimals)
    ),
    strategyManager.deltaThreshold(),
    strategyManager.getTokenETHValue(
      ERC20TokenMap.usdc.tokenContractAddress,
      HOMORA_BN_VAL
    ),
    strategyManager.getTokenETHValue(
      tokenA.tokenContractAddress,
      HOMORA_BN_VAL
    ),
    strategyManager.getTokenETHValue(
      tokenB.tokenContractAddress,
      HOMORA_BN_VAL
    ),
    vaultLib.getDebtAmounts(
      avax_HOMORA.HOMORA_BANK_ADDRESS,
      homoraPosId,
      tokenA.tokenContractAddress,
      tokenB.tokenContractAddress
    ),
  ]);
  const [withdrawFee, ,] = feeConfig;
  const tokenAinETH = utils.formatEther(tokenAinEthRaw);
  // The oracle address `contractInfo[2]` and the `homoraPosId` are fixed for the same strategy.
  // Store them locally so that the queries to the `vaultLib` contract can be parallelized with the ones to `strategyManager`.
  const tokenATotokenB = utils.formatUnits(
    tokenAPxRaw.mul(utils.parseUnits('1', tokenA.decimals)).div(tokenBPxRaw),
    tokenB.decimals
  );

  const usdcInEth = ethersBNToBN(usdcPxRaw)
    .times(1e6)
    .div(ethersBNToBN(HOMORA_BN_VAL));
  // const leverage = leverageRaw.div(1e4).toString();
  const [capacityInUnits, maxDeposit, maxWithdraw] = vaultLimits.map(
    (x: BigNumber) => utils.formatUnits(x, tokenA.decimals)
  );

  const capacityETH = BN(capacityInUnits).times(tokenAinETH);
  const capacity = BN(capacityETH)
    .times(1e18)
    .times(leverage)
    .div(usdcInEth)
    .toString();

  const totalEquityETHValue = utils.formatUnits(totalEquityETHValueRaw, 18); //18
  const TVL = ethersBNToBN(totalEquityETHValueRaw)
    .times(leverage)
    .div(usdcInEth)
    .toString();
  const TVLInUnits = BN(totalEquityETHValue).times(leverage).toString();

  // const capacityInUnits = BN(totalEquityETHValue)
  const strategyInfo: StrategyInfo = {
    ...strategy,
    withdrawFee,
    tokenInAvax: tokenAinETH,
    oracleAddress,
    leverage,
    TVL,
    TVLInUnits,
    capacity,
    capacityInUnits,
    maxDeposit,
    maxWithdraw,
    apy: null,
    priceMovementThreshold: BN(deltaThreshold.toString())
      .times(4)
      .div(100)
      .toString(),
    totalEquityETHValue,
    debts,
    // Changed the logic and hardcoded `18` here because `tokenB` is not always ETH
    tokenATotokenB,
  };
  return strategyInfo;
};
export const getStrategyInfo = async (
  strategy: Strategy,
  multicallProvider: providers.MulticallProvider
) => {
  const { strategyChainId } = strategy;
  const chain: Blockchain = WormholeChainIdToBlockchainMap[strategyChainId];
  const chainType: BlockchainType = BlockchainTypeMap[chain];
  if (chainType === BlockchainType.EVM) {
    return await _getEvmStrategyInfo(strategy, multicallProvider);
  }
};
