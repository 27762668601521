import { WalletIcon } from '@aperture/assetkit';
import { useMatchBreakpoints } from '@aperture/uikit';
import { isProdEnv } from 'constants/crosschain';
import { useEffect } from 'react';
import { redshiftClient } from 'store/provider/AWSProvider';
import styled from 'styled-components';
import { PositionInfoMap } from '../../constants/position';
import {
  useFetchEvmExtraPositions,
  useFetchEvmPositionInfo,
  useFetchEvmPositions,
} from '../../features/positions/hooks';
import { BN } from '../../helpers/utilities';
import { useStore } from '../../store';
import { PositionInfo } from '../../types';
import TableLoader from '../Investment/TableLoader';
import { Box } from '../v1';
import PositionList from './PositionList';
import './styles/Position.scss';

// TODO: add storybook
const LoadingContainer = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* text-align: center; */
  /* padding: 8% 24px; */
  /* grid-gap: 32px; */
  /* gap: 32px; */
  color: #cad2d8;
  background: rgba(13, 23, 24, 0.55);
  border-radius: 0px 0px 8px 8px;
`;
const StyledWalletIcon = styled(WalletIcon)`
  path {
    fill: ${({ theme }) => theme.colors.linkClear};
  }
  rect {
    fill: ${({ theme }) => theme.colors.linkClear};
  }
`;

export default function PositionContainer() {
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const walletAddress = useStore(
    (state) => state.chainSlice.chainProvider?.walletAddress
  );
  const isWrongNetwork = useStore((state) => state.chainSlice.isWrongNetwork);
  const loaders = new Array(6).fill('');
  const { isSm } = useMatchBreakpoints();

  // Move `useFetchEvmPositionInfo` out of `useFetchEvmPositions` and `useFetchEvmExtraPositions` to avoid redundant queries
  const { data: positionInfos } = useFetchEvmPositionInfo();
  const positionResults = useFetchEvmPositions(positionInfos);
  const extraPositionResults = useFetchEvmExtraPositions(positionInfos);

  const positionsIsLoading =
    positionResults.some((r) => r.status === 'loading') ||
    extraPositionResults.some((r) => r.status === 'loading');

  const positions: PositionInfoMap = (() => {
    return (
      (positionResults.map((r) => r.data ?? null) as PositionInfo[]).filter(
        (x) => x !== null && BN(x.equity).gt(0)
      ) as PositionInfo[]
    ).reduce((acc: PositionInfoMap, cur: PositionInfo): PositionInfoMap => {
      let { strategyId } = cur;
      return { ...acc, [strategyId]: cur };
    }, {}) as PositionInfoMap;
  })();

  const extraPositions: PositionInfo[] = (() => {
    return (
      extraPositionResults.map((r) => r.data ?? null) as PositionInfo[]
    ).filter((x) => x !== null && BN(x.equity).gt(0));
  })();

  useEffect(() => {
    // TODO remove !
    if (
      isProdEnv() &&
      isWalletConnected &&
      walletAddress &&
      !isWrongNetwork &&
      !positionsIsLoading &&
      Object.keys(positions).length
    ) {
      const chainPositions = Object.values(positions).map(
        (position) => position.strategyChainId + '-' + position.positionId
      );
      const timestamp = Math.floor(Date.now() / 1000);
      redshiftClient.executeStatement(
        {
          WorkgroupName: 'aperture-workgroup',
          Database: 'dev',
          Sql: `INSERT INTO login_time VALUES ('${walletAddress}',${timestamp},'${chainPositions}')`,
        },
        function (err) {
          if (err) console.log(err, err.stack);
        }
      );
    }
  }, [positions]);

  if (!isWalletConnected || isWrongNetwork) {
    return (
      <Box className="reminder-container">
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <StyledWalletIcon width="22.5" height="22.5" />
          <span
            className={isSm ? 'headline-5' : 'headline-4 medium'}
            style={{
              paddingLeft: '10px',
            }}
          >
            {isWrongNetwork
              ? 'Please switch to the right network to view your portfolio.'
              : 'Connect Wallet to View Your Portfolio!'}
          </span>
        </div>
      </Box>
    );
  }

  const IsPositionNotEmpty =
    (positions &&
      Object.keys(positions).length !== 0 &&
      Object.keys(positions).find(
        (key) => Number(positions[key].equity) !== 0
      )) ||
    (extraPositions?.length > 0 &&
      extraPositions.find((p) => Number(p.equity) !== 0));

  return (
    <>
      {positionsIsLoading || !positions || !extraPositions ? (
        <LoadingContainer>
          {loaders.map((d, i) => (
            <TableLoader key={i} />
          ))}
        </LoadingContainer>
      ) : IsPositionNotEmpty ? (
        <PositionList
          extraPositions={extraPositions ?? null}
          positions={positions}
          strategyType={'PDN'}
        />
      ) : (
        <Box className="reminder-container">
          <div style={{ textAlign: 'center' }}>
            <span className={isSm ? 'headline-5' : 'headline-4 medium'}>
              {"You haven't opened a position yet!"}
            </span>
          </div>
        </Box>
      )}
    </>
  );
}
