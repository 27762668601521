import { Grid } from '@mui/material';
import styled from 'styled-components/macro';
import {
  ExplorerDataType,
  getExplorerLink,
} from '../../../helpers/evm/getExplorerLink';
import {
  getVaultName,
  transactionMessageParser,
  transactionTypeParser,
} from '../../../helpers/utilities';
import { useStore } from '../../../store';
import { useTransaction } from '../../../store/provider/transaction/Evm/hooks';
import { useMemo } from 'react';
import { CheckIcon, PendingIcon, RejectIcon } from '@aperture/assetkit';

const GridNoFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ExternalLink = styled.a`
  color: #b7b7b7;
`;

export default function TransactionPopup({
  hash,
  error,
  title,
}: {
  title?: string;
  hash?: string;
  error?: any;
}) {
  const tx = useTransaction(hash);

  const pendingTransactions = useStore(
    (state) => state.transactionManagerSlice.pendingTransactions
  );
  const completedTransactions = useStore(
    (state) => state.transactionManagerSlice.completedTransactions
  );
  const failedTransactions = useStore(
    (state) => state.transactionManagerSlice.failedTransactions
  );
  const walletAddress = useStore(
    (state) => state.chainSlice.chainProvider?.walletAddress
  );

  const chainId = useStore((state) => state.chainSlice.chainId);


  const success = tx
    ? Boolean(
      tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined'
    )
    : false;

  const { popMessage, popTitle } = useMemo(() => {
    if (tx && hash && chainId && walletAddress) {
      const ptx =
        tx.receipt?.status === 1
          ? completedTransactions[walletAddress]?.[chainId]?.[hash]
          : tx.receipt?.status === 0
            ? failedTransactions[walletAddress]?.[chainId]?.[hash]
            : pendingTransactions[walletAddress]?.[chainId]?.[hash];
      if (ptx) {
        return {
          popTitle: getVaultName(ptx.transactionPayload.strategyInfo.tokens),
          popMessage: transactionMessageParser(ptx),
        };
      }
    }
    return {
      popTitle: '',
      popMessage: '',
    };
  }, [chainId, completedTransactions, failedTransactions, hash, pendingTransactions, tx, walletAddress]);

  return (
    <GridNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success
          ? tx?.receipt?.status === 1
            ? <CheckIcon />
            : <PendingIcon />
          : <RejectIcon />}
      </div>
      <Grid container direction={'column'} item gap="8px">
        {/* TODO: Implement dynamic title. */}
        <span className="subtitle-2 medium popup-title">
          {title
            ? title
            : popTitle !== ''
              ? popTitle
              : `Leveraged Farming (Pseudo Delta Neutral)`}
        </span>
        {tx && tx.info && (
          <span className="popup-text">
            {popMessage === ''
              ? `${transactionTypeParser(tx.info.type)} ${tx.receipt?.status === 1
                ? 'has been completed.'
                : tx.receipt?.status === 0
                  ? 'has failed.'
                  : 'is pending.'
              } `
              : popMessage}
          </span>
        )}
        {!tx && error && (
          <span className="popup-text" style={{ wordBreak: 'break-all' }}>
            {error}
          </span>
        )}

        {chainId && hash && (
          <ExternalLink
            className="button-4 medium"
            href={getExplorerLink(
              Number(chainId),
              hash,
              ExplorerDataType.TRANSACTION
            )}
            target="_blank"
          >
            View on Explorer
          </ExternalLink>
        )}
      </Grid>
    </GridNoFlex>
  );
}
