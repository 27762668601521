import { Popover } from '@mui/material';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Subtitle2, Title3 } from '../../../components/Typography';
import { useMatchBreakpoints } from '../../../contexts';
import { KeyboardArrowDownIcon } from '../../Icon/KeyboardArrowDownIcon';
import { SwitchNetworkProps } from './types';

const StyledNetworkButton = styled.div<{ sm: boolean; open: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ sm }) => (sm ? '8px' : '10px')};
  padding: ${({ sm }) => (sm ? '7px' : '7px 15px')};
  background-color: ${({ theme }) => theme.colors.backgroundMobileNavButton};
  ${({ open, theme }) =>
    open
      ? `border: 1px solid ${theme.colors.border}`
      : `border: 1px solid transparent`};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  cursor: pointer;
  width: max-content;
  box-sizing: border-box;
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.border};
  }
  > svg:first-child {
    width: ${({ sm }) => (sm ? '16px' : '20px')};
    height: ${({ sm }) => (sm ? '16px' : '20px')};
  }
`;
const StyledContainer = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px`};
  width: max-content;
`;
const StyledTitle = styled(Subtitle2)`
  font-weight: 500 !important;
`;
const StyledNetworkItem = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  box-sizing: border-box;
  min-width: ${({ sm }) => (sm ? '190px' : '167px')};
  height: ${({ sm }) => (sm ? '37px' : '40px')};
  :hover {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.backgroundNavActive};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;
const StyledStatus = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.online};
`;
const StyledSwitchNetworkDropdown = styled(Popover)<{ sm: boolean }>`
  .MuiPaper-root {
    left: 0px !important;
    background-color: ${({ theme }) => theme.colors.backgroundDropdown};
    padding: 12px;
    margin-top: ${({ sm }) => (sm ? '4px' : '8px')};
    border: 1px solid ${({ theme }) => theme.colors.border};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
`;

export const SwitchNetwork: React.FC<SwitchNetworkProps> = ({
  listItem,
  defaultSelect = 0,
  onSelect,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState(listItem[defaultSelect].name);
  const SelectedIcon =
    listItem[listItem.findIndex((x) => x.name === selected)].icon;
  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledNetworkButton
        sm={isSm}
        open={open}
        onClick={handleClick}
        ref={buttonRef}
        {...props}
      >
        <SelectedIcon />
        {!isSm && (
          <Title3>
            {listItem[listItem.findIndex((x) => x.name === selected)].name}
          </Title3>
        )}
        <KeyboardArrowDownIcon />
      </StyledNetworkButton>
      <StyledSwitchNetworkDropdown
        sm={isSm}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledContainer gap={8}>
          <StyledTitle>Switch Network</StyledTitle>
          <StyledContainer gap={4}>
            {listItem.map((item, index) => {
              return (
                <StyledNetworkItem
                  sm={isSm}
                  key={`${index}-${item.name}`}
                  onClick={() => {
                    setSelected(item.name);
                    onSelect(item.name);
                    handleClose();
                  }}
                >
                  <item.icon />
                  <Title3>{item.name}</Title3>
                  {item.name === selected && <StyledStatus />}
                </StyledNetworkItem>
              );
            })}
          </StyledContainer>
        </StyledContainer>
      </StyledSwitchNetworkDropdown>
    </>
  );
};
