import styled from 'styled-components/macro';

import {
  useActivePopups,
  useRemovePopup,
} from '../../../store/base/application/hooks';
// import { useURLWarningVisible } from '../../../state/user/hooks';
import PopupItem from './PopupItem';
import { Grid } from '@mui/material';
import { useTransition } from '@react-spring/web';
import { useMemo } from 'react';

const MobilePopupWrapper = styled.div<{ height: string | number }>`
  position: relative;
  max-width: 100%;
  height: ${({ height }) => height};
  margin: ${({ height }) => (height ? '0 auto;' : 0)};
  margin-bottom: ${({ height }) => (height ? '20px' : 0)};

  display: none;
`;

const MobilePopupInner = styled.div`
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FixedPopupColumn = styled(Grid)`
  position: fixed;
  top: 84px;
  right: 83px;
  max-width: fit-content;
  width: 100%;
  z-index: 10000;
  color: white;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1199px) {
    top: 68px;
    right: 16px;
  }
`;

export default function Popups() {
  // get all popups
  const activePopups = useActivePopups();
  const removePopup = useRemovePopup();
  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);
  const transitionProps = useMemo(
    () => ({
      from: { opacity: 0, height: 0, life: '100%' },
      keys: (item) => item.key,
      enter: (item) => async (next, cancel) => {
        cancelMap.set(item, cancel);
        await next({ opacity: 1, height: refMap.get(item).offsetHeight });
        await next({ life: '0%' });
      },
      leave: [{ opacity: 0 }, { height: 0 }],
      onRest: (result, ctrl, item) => {
        removePopup(item.key);
      },
      config: (item, index, state) => (key) =>
        state === 'enter' && key === 'life'
          ? { duration: item.removeAfterMs ?? undefined }
          : { tension: 125, friction: 20, precision: 0.1 },
    }),
    []
  );
  const popupTransition = useTransition(activePopups, transitionProps);
  // const mobilePopupTransition = useTransition(
  //   activePopups.slice(0).reverse(),
  //   transitionProps
  // );

  return (
    <>
      <FixedPopupColumn gap="20px">
        {popupTransition(({ life, ...style }, item) => (
          <PopupItem
            style={style}
            key={item.key}
            content={item.content}
            popKey={item.key}
            removeAfterMs={item.removeAfterMs}
            life={life}
            ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
          />
        ))}
      </FixedPopupColumn>
      {/* <MobilePopupWrapper height={activePopups?.length > 0 ? 'fit-content' : 0}>
        <MobilePopupInner>
          {mobilePopupTransition(({ life, ...style }, item) => (
            <PopupItem
              style={style}
              key={item.key}
              content={item.content}
              popKey={item.key}
              removeAfterMs={item.removeAfterMs}
              life={life}
              ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
            />
          ))}
        </MobilePopupInner>
      </MobilePopupWrapper> */}
    </>
  );
}
