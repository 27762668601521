import {
  BWLightLogo,
  BWDarkLogo,
  ColorLogo,
  BWLightHLogo,
  BWLightVLogo,
  ColorLightHLogo,
  ColorLightVLogo,
  BWDarkHLogo,
  BWDarkVLogo,
  ColorDarkHLogo,
  ColorDarkVLogo
} from '@aperture/assetkit';
import {
  forwardRef,
  ImgHTMLAttributes,
  useRef,
  useImperativeHandle,
} from 'react';
import { Link } from 'react-router-dom';

interface LogoProps {
  colored?: boolean;
  mode?: 'dark' | 'light';
  text?: boolean;
  direction?: 'vertical' | 'horizontal';
}

interface Props extends ImgHTMLAttributes<HTMLImageElement>, LogoProps { }

export const Logo = forwardRef<HTMLImageElement, Props>(
  ({ colored, mode, text, direction, ...props }, ref) => {
    const domRef = useRef<HTMLImageElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    const getLogo = () => {
      if (!text && colored) {
        return <ColorLogo />;
      }
      if (!text && !colored && mode === 'light') {
        return <BWDarkLogo />;
      }
      if (!text && !colored && mode === 'dark') {
        return <BWLightLogo />;
      }

      if (text && !colored && mode === 'light' && direction === 'vertical') {
        return <BWDarkVLogo />;
      }
      if (text && !colored && mode === 'light' && direction === 'horizontal') {
        return <BWDarkHLogo />;
      }
      if (text && !colored && mode === 'dark' && direction === 'vertical') {
        return <BWLightVLogo />;
      }
      if (text && !colored && mode === 'dark' && direction === 'horizontal') {
        return <BWLightHLogo />;
      }

      if (text && colored && mode === 'light' && direction === 'vertical') {
        return <ColorDarkVLogo />;
      }
      if (text && colored && mode === 'light' && direction === 'horizontal') {
        return <ColorDarkHLogo />;
      }
      if (text && colored && mode === 'dark' && direction === 'vertical') {
        return <ColorLightVLogo />;
      }
      if (text && colored && mode === 'dark' && direction === 'horizontal') {
        return <ColorLightHLogo />;
      } else {
        return <ColorLogo />;
      }
    };

    return (
      <Link to="/" style={{ display: 'flex' }}>
        {getLogo()}
      </Link>
    );
  }
);
