type VarsMap = {
  [key: string]: string;
};

const textContextUtils = {
  stringReplacer: (vars: VarsMap, baseString: string) => {
    Object.keys(vars).forEach((k) => {
      const reg = new RegExp(`%{${k}}`, 'gm');
      baseString = baseString.replaceAll(reg, vars[k]);
    });
    return baseString;
  },
};

export default textContextUtils;
