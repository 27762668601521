import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnect from '@walletconnect/web3-provider';
import Web3Modal, { ProviderNotFoundError, UserRejectedError } from '@aperture/web3modal';
import { BigNumber, ethers, utils } from 'ethers';
import { WritableDraft } from 'immer/dist/internal';

import { providers } from '@0xsequence/multicall';
import { TransactionRequest } from '@ethersproject/abstract-provider/src.ts';
import { IStore, SetStore } from '../..';
import { NetworkType } from '../../../constants/base/network';
import {
  AvailableBlockchains,
  Blockchain,
  BlockchainExplorerType,
  BlockchainToEvmIdMap,
  DefaultEvmChain,
  EvmIdToBlockchainMap,
  EvmManagerAddress,
  EvmManagerContractAbi,
  EvmStrategyContractAbi,
  EvmVaultLibContractAbi,
  EvmVaultLibContractAddress,
  IErrorInfo,
  InvestType,
  InvestTypeTextMap,
  WormholeChainIdToBlockchainMap,
} from '../../../constants/crosschain';
import {
  EvmChainInfo,
  EvmChainMap,
} from '../../../constants/crosschain/evmChainidMap';
import { EvmProviderSubscriptionKeys } from '../../../constants/store';
import {
  approveERC20,
  checkApprovalERC20,
  decreasePosition as evmDecreasePosition,
  getETHPxRaw,
  getPositions as evmGetPositions,
  increasePosition as evmIncreasePosition,
  openPosition as evmOpenPosition,
  simulateThenExecute,
} from '../../../helpers/crosschain';
import {
  BN,
  errorABIParser,
  ethersErrorParser,
} from '../../../helpers/utilities';
import IChainProvider, { InvestParams } from '../IChainProvider';
import { TransactionDetails } from '../transaction/Evm/types';
import _ from 'lodash';

import { OkxIcon } from '@aperture/assetkit';

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionDetails;
  };
}

export const transactions: TransactionState = {};

export interface IEvmProvider extends IChainProvider {
  _onConnect: (
    id?: string,
    switchNetworkCheck?: boolean,
    ...params: any[]
  ) => void;
  _web3Modal: Web3Modal | null;
  _setWeb3Modal: (web3Modal: Web3Modal | null) => void;
  _web3Provider: ethers.providers.Web3Provider | null;
  _multicallProvider: providers.MulticallProvider;
  _setMulticallProvider: (chainId: number) => providers.MulticallProvider;
  _evmManager: ethers.Contract | null;
  _evmChainInfo: EvmChainInfo;
  _switchNetwork: (
    provider?: ethers.providers.Web3Provider | null,
    chainId?: number
  ) => Promise<void>;
  _subscribeProvider: (
    provider: ethers.providers.Web3Provider
  ) => Promise<void>;

  _setEvmManager: (evmManager: ethers.Contract) => void;
  _vaultLib: ethers.Contract | null;
  _setVaultLib: (vaultLib: ethers.Contract) => void;
}

export const EvmProvider = (
  set: SetStore<IStore>,
  get: () => IStore,
  api: any,
  setSelf: (fn: (state: WritableDraft<IEvmProvider>) => void) => void,
  getSelf: () => IEvmProvider
) => {
  const { chain } = get().chainSlice;
  function getNetworkType(chainId: number) {
    return EvmChainMap[chainId].name.includes('Mainnet')
      ? NetworkType.MAINNET
      : NetworkType.TESTNET;
  }
  const getChainInfo = (chainId: number): EvmChainInfo => {
    const chainData = EvmChainMap[chainId];
    if (!chainData) {
      throw new Error('ChainId missing or not supported');
    }
    const API_KEY = process.env.REACT_APP_INFURA_ID;
    let rpc = chainData.rpc;
    if (API_KEY) {
      // TODO: Resolve the warnings
      rpc = rpc.map((x) => {
        if (x.includes('infura.io') && x.includes('${INFURA_API_KEY}')) {
          return x.replace('${INFURA_API_KEY}', API_KEY);
        }
        return x;
      });
    }
    return chainData;
  };
  const initWeb3 = (
    provider:
      | ethers.providers.ExternalProvider
      | ethers.providers.JsonRpcFetchFunc
  ) => {
    return new ethers.providers.Web3Provider(provider);
  };
  const evmChainInfo = getChainInfo(
    chain === null ? 1 : BlockchainToEvmIdMap[chain as keyof object]
  );
  const getProviderOptions = () => {
    // const infuraId = process.env.REACT_APP_INFURA_ID;
    return {
      walletconnect: {
        package: WalletConnect,
        options: {
          // rpc: getChainInfo(43114).rpc[0],
          rpc: {
            43114: 'https://api.avax.network/ext/bc/C/rpc',
            // 1: 'https://api.avax.network/ext/bc/C/rpc'
          },
          // update this
          // infuraId,
        },
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: 'Aperture Finance',
          // infuraId,
          rpc: { 43114: 'https://api.avax.network/ext/bc/C/rpc' },
        },
      },
      'custom-okxwallet': {
        display: null,
        package: true,
        name: 'OKX Wallet',
        connector: async () => {
          let provider = null;
          if (typeof window.okxwallet !== 'undefined') {
            provider = window.okxwallet;
            try {
              await provider.request({ method: 'eth_requestAccounts' });
            } catch (error) {
              throw new UserRejectedError('User Rejected');
            }
          } else {
            throw new ProviderNotFoundError('No OKX Chain Wallet found');
          }
          return provider;
        },
      },
    };
  };

  const _state = {
    _web3Modal: null,
    _web3Provider: null,
    _evmManager: null,
    _evmChainInfo: evmChainInfo,
    _vaultLib: null,
  };

  const state = {
    walletAddress: null,
    balance: null,
  };

  const _actions = {
    _setWeb3Modal: (web3Modal: Web3Modal) => {
      set((state: any) => {
        state.chainSlice.chainProvider._web3Modal = web3Modal;
      });
    },
    _subscribeProvider: async (provider: ethers.providers.Web3Provider) => {
      const { _onConnect, _web3Provider, deleteWallet, walletAddress } =
        getSelf();
      const { provider: ethereum } = provider;
      provider.removeAllListeners();
      if (_web3Provider) {
        _web3Provider.removeAllListeners();
        _web3Provider.provider.removeAllListeners();
      }
      ethereum
        .removeAllListeners()
        .once('disconnect', () => {
          deleteWallet();
        })
        .once('accountsChanged', (accounts: Array<string>) => {
          const { walletAddress } = getSelf();
          if (accounts.length === 0) {
            deleteWallet();
          }
          if (walletAddress && accounts[0] !== walletAddress) {
            _onConnect(undefined, false, accounts);
          }

          console.log('accountsChanged', accounts);
        })
        .once('chainChanged', (chain: any) => {
          console.log('chainChanged', chain);
          _onConnect(undefined, false, chain);
        });
    },
    _switchNetwork: async (
      web3?: ethers.providers.Web3Provider | null,
      chainId?: number
    ) => {
      !chainId && (chainId = BlockchainToEvmIdMap[DefaultEvmChain]);
      !web3 && (web3 = getSelf()._web3Provider);
      const provider = web3?.provider;
      if (!provider || !provider.request) return;
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: ethers.utils.hexlify(chainId),
            },
          ],
        });
      } catch (err: any) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: EvmChainMap[chainId].name,
                chainId: ethers.utils.hexlify(chainId),
                nativeCurrency: EvmChainMap[chainId].nativeCurrency,
                rpcUrls: EvmChainMap[chainId].rpc,
              },
            ],
          });
        } else {
          console.log('Switch network error.');
          throw err;
        }
        // provider.removeAllListeners()
      }
    },

    // _checkNetwork: (provider: any, chainId: number) => {
    //   return (provider.networkVersion && provider.networkVersion !== chainId + '') {
    //     set((state: WritableDraft<IStore>) => {
    //       state.chainSlice.isWrongNetwork = true;
    //       // state.chainSlice.isWalletConnected = false;
    //     });
    //   }
    // },

    _setMulticallProvider: (chainId: number) => {
      /*
       * An ethers Provider will execute frequent getNetwork calls to ensure the network calls and network being
       * communicated with are consistent.
       *
       * In the case of a client like MetaMask, this is desired as the network may be changed by the user at any time,
       * in such cases the cost of checking the chainId is local and therefore cheap.
       *
       * However, there are also many times where it is known the network cannot change, such as when connecting to an
       * INFURA endpoint, in which case, the StaticJsonRpcProvider can be used which will indefinitely cache the chain
       * ID, which can reduce network traffic and reduce round-trip queries for the chain ID.
       *
       * This Provider should only be used when it is known the network cannot change.
       * https://docs.ethers.io/v5/api/providers/jsonrpc-provider/#StaticJsonRpcProvider
       */
      const multicallProvider = new providers.MulticallProvider(
        new ethers.providers.StaticJsonRpcProvider(
          EvmChainMap[chainId].rpc[0],
          {
            name: EvmChainMap[chainId].name,
            chainId,
          }
        ),
        // TODO: Store the `multicall` config in a map
        {
          batchSize: 100,
          timeWindow: 0,
          contract: '0xf69751e4378c5e53f56ca60c98d0b87db5b5190a',
          verbose: process.env.NODE_ENV === 'development', // for debugging
        }
      );
      set((state: any) => {
        state.chainSlice.chainProvider._multicallProvider = multicallProvider;
      });
      return multicallProvider;
    },

    _setVaultLib: (vaultLib: ethers.Contract) => {
      set((state: any) => {
        state.chainSlice.chainProvider._vaultLib = vaultLib;
      });
    },

    _onConnect: async (
      id?: string,
      switchNetworkCheck = false,
      ...params: any[]
    ) => {
      const {
        _subscribeProvider,
        _web3Modal,
        _evmChainInfo,
        updateBalance,
        _switchNetwork,
        deleteWallet,
      } = getSelf();
      let providerChainId: string | number | null = null;
      if (typeof params[0] !== 'object') {
        providerChainId = Number(params[0]);
      }

      const { chainId } = _evmChainInfo;

      try {
        const web3: ethers.providers.Web3Provider = initWeb3(
          id ? await _web3Modal?.connectTo(id!) : await _web3Modal?.connect()
        );
        !providerChainId &&
          (providerChainId = web3 ? (await web3.getNetwork()).chainId : null);
        if (!providerChainId) {
          return deleteWallet();
        }
        const isWrongChain = providerChainId !== chainId;

        if (isWrongChain && switchNetworkCheck) {
          await _subscribeProvider(web3);
          set((state: any) => {
            const chainProvider = state.chainSlice.chainProvider;
            chainProvider._web3Provider = web3;
          });
          return await _switchNetwork(web3);
        }

        const address = await web3.getSigner().getAddress();

        /**
         *
         * TODO: Determine what token balance to show on wallet connection UI.
         * Right new we are showing the native token of supported connected chain.
         * Use balance contract to get balance instead if we decide to show non-native token balance in the future.
         */
        // const balance = isWrongChain
        //   ? 0
        //   : ethers.utils.formatEther(await web3.getSigner().getBalance());

        await _subscribeProvider(web3);
        set((state: any) => {
          const chainProvider = state.chainSlice.chainProvider;
          chainProvider._web3Provider = web3;
          chainProvider.walletAddress = address;
          // chainProvider.balance = balance;
          if (isWrongChain) {
            state.chainSlice.isWrongNetwork = true;
            state.chainSlice.isWalletConnected = false;
          } else {
            state.chainSlice.isWalletConnected = true;
            state.chainSlice.isWrongNetwork = false;
          }
        });
        if (!isWrongChain) {
          setTimeout(updateBalance, 100);
        }
      } catch (err: any) {
        throw new Error(`EVM connecting to wallet failed: ${err.message}`);
      }
    },

    updateBalance: async () => {
      const _web3Provider = getSelf()._web3Provider;

      try {
        if (_web3Provider) {
          const balance = ethers.utils.formatEther(
            await _web3Provider!.getSigner().getBalance()
          );
          set((state: any) => {
            const chainProvider = state.chainSlice.chainProvider;
            chainProvider.balance = balance;
          });
        }
      } catch (err: any) {
        throw new Error(`failed to updated balance: ${err.message}`);
      }
    },

    _setEvmManager: (evmManager: ethers.Contract) => {
      set((state: any) => {
        state.chainSlice.chainProvider._evmManager = evmManager;
      });
    },
  };

  const actions = {
    on: (event: string, callback: any) => {
      const _web3Provider = getSelf()?._web3Provider;
      if (!_web3Provider) {
        return;
      }
      _web3Provider.on(event, callback);
    },
    removeListener: (event: string, callback: any) => {
      const web3Provider = getSelf()?._web3Provider;
      if (!web3Provider) return;
      web3Provider.removeListener(event, callback);
    },
    getBlockNumber: async (): Promise<number> => {
      const { _multicallProvider } = getSelf();
      if (!_multicallProvider) {
        throw new Error('MulticallProvider not initialized');
      }
      // `getBlockNumber` doesn't have caching. Use `_getFastBlockNumber` instead.
      return _multicallProvider._getFastBlockNumber();
    },
    getTransactionReceipt(
      txHash: string
    ): Promise<ethers.providers.TransactionReceipt> {
      const _web3Provider = getSelf()._web3Provider;
      if (!_web3Provider) {
        throw new Error('Web3 provider not initialized');
      }
      return _web3Provider.getTransactionReceipt(txHash);
    },
    getRevertReason: async (txHash: string) => {
      const _web3Provider = getSelf()._web3Provider;
      if (!_web3Provider) {
        throw new Error('Web3 provider not initialized');
      }
      const resp = await _web3Provider.getTransaction(txHash);
      let tx: TransactionRequest = {
        to: resp.to,
        from: resp.from,
        nonce: resp.nonce,
        gasLimit: resp.gasLimit,
        data: resp.data,
        value: resp.value,
        chainId: resp.chainId,
      };
      try {
        let res = await _web3Provider.call(tx, resp.blockNumber);
        if (res) {
          return errorABIParser(res, true);
        }
      } catch (err) {
        return err.reason;
      }
    },
    connectWallet: (id: string) => {
      console.log(getChainInfo(43114).rpc);

      return new Promise(async (resolve, reject) => {
        const { _onConnect, walletAddress } = getSelf();
        try {
          await _onConnect(id, true);
          resolve(walletAddress);
        } catch (err) {
          reject(err);
        }
      });
    },
    deleteWallet: async () => {
      const removeChain = get().chainSlice.removeChain;
      const _web3Modal = getSelf()._web3Modal;
      const provider = getSelf()._web3Provider;
      if (provider) {
        provider.removeAllListeners();
        provider.provider?.removeAllListeners();
      }
      await _web3Modal!.clearCachedProvider();

      localStorage.removeItem('walletconnect');
      removeChain();
    },
    // TODO: Use BN or string instead of number.
    approve: async (tokenAddr: string, amount: number) => {
      const { _evmManager, _web3Provider } = getSelf();
      if (_evmManager && _web3Provider) {
        try {
          return await (
            await approveERC20(
              tokenAddr,
              amount,
              _web3Provider.getSigner(),
              _evmManager
            )
          ).wait();
        } catch (err: any) {
          throw new Error(
            `Approval failed: ${err.message}`,
            ethersErrorParser(err)
          );
        }
      }
    },
    checkApproval: async (tokenAddr: string) => {
      const { _evmManager, _web3Provider } = getSelf();
      if (_evmManager && _web3Provider) {
        try {
          return await checkApprovalERC20(
            tokenAddr,
            _web3Provider.getSigner(),
            _evmManager
          );
        } catch (err: any) {
          throw new Error(
            `Check approval failed: ${err.message}`,
            ethersErrorParser(err)
          );
        }
      }
    },
    invest: async ({
      strategyInfo,
      amount,
      token,
      slippage,
      type,
      positionInfo,
      estimateGas,
    }: // Only needed for delta neutral strategy.
      InvestParams): Promise<
        string | ethers.providers.TransactionReceipt | null
      > => {
      const {
        _evmManager,
        _web3Provider,
        _multicallProvider,
        _vaultLib,
        walletAddress,
      } = getSelf();
      const chain: Blockchain =
        WormholeChainIdToBlockchainMap[strategyInfo.strategyChainId];
      const evmChainId = BlockchainToEvmIdMap[chain as keyof object];
      const vaultManager = new ethers.Contract(
        strategyInfo.strategyAddress,
        EvmStrategyContractAbi[evmChainId],
        _multicallProvider
      );

      if (
        _evmManager &&
        _web3Provider &&
        _vaultLib &&
        token &&
        token.decimals &&
        token.native !== undefined
      ) {
        // Same-chain case.
        // if (providerChain === strategyChain) {
        let params: any[] | null = null;
        let txnFn: Function | null = null;

        if (type === InvestType.OPEN) {
          params = [
            _evmManager,
            vaultManager,
            BigNumber.from(
              BN(amount).times(BN(10).pow(token.decimals)).toString()
            ),
            strategyInfo.strategyChainId,
            strategyInfo.strategyId,
            token.tokenContractAddress,
            await getETHPxRaw(
              _vaultLib,
              strategyInfo.oracleAddress,
              token.tokenContractAddress
            ),
            slippage,
            token.native,
          ];
          txnFn = evmOpenPosition;
        } else if (type === InvestType.DECREASE) {
          params = [
            _evmManager,
            strategyInfo.strategyChainId,
            walletAddress!,
            positionInfo!.userShares,
            positionInfo!.totalShares,
            positionInfo!.totalEquity,
            Number(amount),
            slippage,
            positionInfo!.positionId,
            strategyInfo.tokens[0].decimals,
            strategyInfo.withdrawFee,
          ];
          txnFn = evmDecreasePosition;
        } else if (type === InvestType.INCREASE) {
          params = [
            _evmManager,
            vaultManager,
            BigNumber.from(
              BN(amount).times(BN(10).pow(token.decimals)).toString()
            ),
            positionInfo!.positionId,
            token.tokenContractAddress,
            await getETHPxRaw(
              _vaultLib,
              strategyInfo.oracleAddress,
              token.tokenContractAddress
            ),
            slippage,
            token.native,
          ];
          txnFn = evmIncreasePosition;
        } else {
          throw new Error('Invalid invest type');
        }

        if (estimateGas) {
          try {
            const gasPrice: BigNumber = await _web3Provider.getGasPrice();
            const functionGasFees = await txnFn(...params, true);
            const finalGasPrice = gasPrice.mul(functionGasFees);
            return utils.formatEther(finalGasPrice + '');
          } catch (err: any) {
            throw errorABIParser(err);
          }
        } else {
          try {
            // Simulate the transaction first, only execute if it succeeds.
            const pos: ethers.providers.TransactionReceipt =
              await simulateThenExecute(txnFn, params);
            return pos;
          } catch (err: any) {
            const e: IErrorInfo = {
              title: `Failed to ${InvestTypeTextMap[type]} position`,
              reason: `${errorABIParser(err)}`,
            };
            throw e;
          }
        }
      }
      return null;

      // if (strategy === StrategyType.DELTA_NEUTRAL) {
      //   await _investDelta(amount, MAssetAddr!, col!);
      // } else if (strategy === StrategyType.STABLE_YIELD) {
      //   await _investStable(amount, type);
      // }
      // @TODO: Implement cross-chain position opening.
    },
    getPositions: async () => {
      const { _multicallProvider, _evmManager, walletAddress } = getSelf();
      if (_multicallProvider && _evmManager && walletAddress) {
        // Use `MulticallProvider` here because it's read-only.
        try {
          const pos = await evmGetPositions(
            _evmManager.connect(_multicallProvider),
            walletAddress
          );
          return pos?.map((x) => {
            const { positionId, strategyChainId } = x;
            return { positionId, chainId: strategyChainId };
          });
        } catch (err) {
          throw new Error('Failed to get evm positions.');
        }
      }
    },
    getPositionDetails: async (
      positionId: string,
      strategyChainId: number,
      strategyAddress: string
    ) => {
      const chainId = get().chainSlice.chainId;
      const { _evmManager, walletAddress, _multicallProvider } = getSelf();

      if (_evmManager && walletAddress && chainId && _multicallProvider) {
        try {
          const strategyManager = new ethers.Contract(
            strategyAddress,
            EvmStrategyContractAbi[chainId],
            _multicallProvider
          );

          return await strategyManager.positions(strategyChainId, positionId);
        } catch (err) {
          throw new Error('Failed to get evm position details.');
        }
      }
    },

    getBlockchainExplorerUrl: (type: BlockchainExplorerType, address: string) =>
      null,

    initSubscribe: () => ({
      [EvmProviderSubscriptionKeys.EVM_CHAIN_INFO]: api.subscribe(
        (state: any) =>
          (state.chainSlice.chainProvider as IEvmProvider)._evmChainInfo,
        (_evmChainInfo: EvmChainInfo) => {
          const { chainId, network } = _evmChainInfo;
          const { chainSlice } = get();
          const { setChainId } = chainSlice;
          const { _setMulticallProvider, _setVaultLib, _setWeb3Modal } =
            chainSlice.chainProvider as IEvmProvider;
          if (chainId) {
            setChainId(chainId + '');
            const multicallProvider = _setMulticallProvider(chainId);
            const vaultLib = new ethers.Contract(
              EvmVaultLibContractAddress[chainId],
              EvmVaultLibContractAbi[chainId],
              multicallProvider
            );
            _setVaultLib(vaultLib);
          }
          if (network) {
            const web3Modal = new Web3Modal({
              network,
              cacheProvider: true,
              providerOptions: getProviderOptions(),
              renderModal: false,
            });
            _setWeb3Modal(web3Modal);
          }
        },
        { fireImmediately: true }
      ),
      [EvmProviderSubscriptionKeys.WEB3_MODAL]: api.subscribe(
        (state: any) =>
          (state.chainSlice.chainProvider as IEvmProvider)._web3Modal,
        (_web3Modal: any) => {
          if (_web3Modal?.cachedProvider) {
            const { _onConnect } = get().chainSlice
              .chainProvider as IEvmProvider;
            _onConnect();
          }
        },
        { fireImmediately: true }
      ),
      [EvmProviderSubscriptionKeys.WEB3_PROVIDER]: api.subscribe(
        (state: any) =>
          (state.chainSlice.chainProvider as IEvmProvider)._web3Provider,
        async (_web3Provider: any) => {
          if (_web3Provider) {
            const { _evmChainInfo, walletAddress } = getSelf();
            if (!(_evmChainInfo && walletAddress)) return;
            const { chainId } = _evmChainInfo;
            const EvmManagerContract: ethers.Contract = new ethers.Contract(
              EvmManagerAddress[chainId],
              EvmManagerContractAbi[chainId],
              _web3Provider.getSigner()
            );
            const _setEvmManager = getSelf()._setEvmManager;
            _setEvmManager(EvmManagerContract);

            const setNetworkType = get().appSlice.setNetworkType;

            if (chainId) {
              // Check if we support the chain.
              if (
                EvmIdToBlockchainMap[chainId] &&
                !AvailableBlockchains[EvmIdToBlockchainMap[chainId]]
              ) {
                throw new Error('This evm chain id is not supported.');
              }
              setNetworkType(getNetworkType(chainId));
            }
          }
        }
      ),
    }),
  };

  const slice: IEvmProvider = {
    ..._state,
    ...state,
    ..._actions,
    ...actions,
  };

  return slice;
};
