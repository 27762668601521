import { SvgProps } from '..';

export const CopiedIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 12.625C4.99158 12.625 3.54494 12.0258 2.47833 10.9592C1.41172 9.89256 0.8125 8.44592 0.8125 6.9375C0.8125 5.42908 1.41172 3.98244 2.47833 2.91583C3.54494 1.84922 4.99158 1.25 6.5 1.25C8.00842 1.25 9.45506 1.84922 10.5217 2.91583C11.5883 3.98244 12.1875 5.42908 12.1875 6.9375C12.1875 8.44592 11.5883 9.89256 10.5217 10.9592C9.45506 12.0258 8.00842 12.625 6.5 12.625ZM6.5 13.4375C8.22391 13.4375 9.87721 12.7527 11.0962 11.5337C12.3152 10.3147 13 8.66141 13 6.9375C13 5.21359 12.3152 3.56029 11.0962 2.34131C9.87721 1.12232 8.22391 0.4375 6.5 0.4375C4.77609 0.4375 3.12279 1.12232 1.90381 2.34131C0.68482 3.56029 0 5.21359 0 6.9375C0 8.66141 0.68482 10.3147 1.90381 11.5337C3.12279 12.7527 4.77609 13.4375 6.5 13.4375Z"
        fill="#7E51FD"
      />
      <path
        d="M8.91303 4.47561C8.90725 4.48122 8.90182 4.48719 8.89678 4.49348L6.07497 8.0888L4.37441 6.38742C4.25889 6.27978 4.1061 6.22118 3.94823 6.22397C3.79036 6.22675 3.63974 6.29071 3.52809 6.40235C3.41644 6.514 3.35249 6.66463 3.3497 6.8225C3.34692 6.98037 3.40552 7.13315 3.51316 7.24867L5.66303 9.39936C5.72095 9.45717 5.78992 9.50272 5.86582 9.5333C5.94172 9.56388 6.02301 9.57886 6.10483 9.57734C6.18665 9.57583 6.26732 9.55785 6.34204 9.52448C6.41676 9.49111 6.48399 9.44303 6.53972 9.38311L9.78322 5.32873C9.89366 5.21282 9.95405 5.05809 9.95132 4.898C9.94859 4.73792 9.88297 4.58534 9.76864 4.47325C9.65431 4.36116 9.50046 4.29857 9.34035 4.29901C9.18024 4.29946 9.02674 4.36289 8.91303 4.47561Z"
        fill="#7E51FD"
      />
    </svg>
  );
};
