import styled, { useTheme } from 'styled-components';
import { BacktestProps } from '.';
import { Box, GraphBox } from '../../components/Box';
import { LineChart } from '../../components/Chart/LineGraph';
import { YAxisTitle } from '../../components/Chart/YAxisTitle';
import { ColoredValue } from '../../components/ColoredValue';
import { Body1, Headline4, Headline5 } from '../../components/Typography';
import { useMatchBreakpoints } from '../../contexts';
import {
  calculate_timeframe_apy,
  numberParse,
  TimeframeType,
  timestampToUTCString,
} from '../../helper';
import { ExpandedIcon } from '../Icon/ExpandedIcon';

const StyledH5 = styled(Headline5)`
  margin-top: 16px;
  margin-right: 8px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.tableHeaderText};
`;
const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;
const StyledBox = styled(Box)`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
  margin-bottom: 16px;
`;
const StyledColoredValue = styled(ColoredValue)`
  margin-left: 8px;
`;
const MarginRight = styled.span`
  padding-left: 32px;
`;
const MiniTitle = styled.div`
  color: ${({ theme }) => theme.colors.tableHeaderText};
  display: block;
  text-align: center;
  width: 100%;
  height: 24px;
`;
const StyledBody = styled(Body1)`
  margin-bottom: 8px;
  display: block;
  color: ${({ theme }) => theme.colors.tableHeaderText};
`;

export const Backtest: React.FC<BacktestProps> = ({
  id,
  type = 'default',
  data = [],
  payload,
  openStrategyDetails,
  ...props
}) => {
  const theme = useTheme();
  const { isSm: mobile } = useMatchBreakpoints();

  const tooltipValue = [
    {
      color: theme.colors.graphLine2,
      text: 'Return',
      value: 'percentage',
      suffix: '%',
      colorChanging: true,
    },
  ];
  const Content = (
    <GraphBox mini={type === 'mini'} {...props}>
      {!mobile && type === 'mini' && (
        <ExpandedIcon
          className="chart-expanded-icon"
          onClick={openStrategyDetails}
        />
      )}
      {type !== 'mini' && !mobile && (
        <TitleWrapper>
          <Headline4>Backtest Analysis</Headline4>
          <br />
          <StyledH5>Backtest Return:</StyledH5>
          <StyledColoredValue value={data[data.length - 1]?.percentage}>
            {numberParse(data[data.length - 1].percentage, 'percentage')}
          </StyledColoredValue>

          <MarginRight>
            <StyledH5>Annualized Return:</StyledH5>
            <StyledColoredValue value={calculate_timeframe_apy(data)}>
              {numberParse(calculate_timeframe_apy(data) ?? 0, 'percentage')}
            </StyledColoredValue>
          </MarginRight>
        </TitleWrapper>
      )}

      {type !== 'mini' && !mobile && (
        <YAxisTitle
          left={`Backtest Result (${payload.tokens[0]}-${payload.tokens[1]} 3X)`}
          right={`${timestampToUTCString(
            data[0]?.timestamp_sec ?? 0,
            'MonthYear'
          )} - ${timestampToUTCString(
            data[data.length - 1]?.timestamp_sec ?? 0,
            'MonthYear'
          )}`}
        />
      )}

      {(type === 'mini' || mobile) && (
        <MiniTitle>
          {timestampToUTCString(data[0]?.timestamp_sec ?? 0, 'MonthYear')} -{' '}
          {timestampToUTCString(
            data[data.length - 1]?.timestamp_sec ?? 0,
            'MonthYear'
          )}
        </MiniTitle>
      )}
      <LineChart
        id={id}
        data={data}
        referenceAmount={0}
        type={type === 'mini' || mobile ? 'mini' : 'full'}
        legend={false}
        payload={{ ...{ timeframe: TimeframeType.SEASON }, ...payload }}
        tooltipInfo={{
          day: false,
          hours: true,
          value: tooltipValue,
        }}
        onClick={openStrategyDetails}
      />
    </GraphBox>
  );

  if (mobile) {
    return (
      <>
        <StyledBox>
          <div>
            <StyledBody>Backtest Return:</StyledBody>
            <StyledColoredValue value={data[data.length - 1]?.percentage}>
              {numberParse(data[data.length - 1].percentage, 'percentage')}
            </StyledColoredValue>
          </div>
          <div>
            <StyledBody>Annualized Return:</StyledBody>
            <StyledColoredValue value={calculate_timeframe_apy(data)}>
              {numberParse(calculate_timeframe_apy(data) ?? 0, 'percentage')}
            </StyledColoredValue>
          </div>
        </StyledBox>
        {Content}
      </>
    );
  } else {
    return <>{Content}</>;
  }
};
