export const avax_HOMORA = {
  AVAX_CHAIN_ID: 6,
  HOMORA_BANK_ADDRESS: '0x376d16C7dE138B01455a51dA79AD65806E9cd694',
  TJ_SPELLV3_WAVAX_USDC_ADDRESS: '0x28F1BdBc52Ad1aAab71660f4B33179335054BE6A',
  TJ_SPELLV1_WAVAX_DAIe_ADDRESS: '0xdBc2Aa11Aa01bAa22892dE745C661Db9f204b2cd',
  PangolinSpellV2: '0x966bbec3ac35452133B5c236b4139C07b1e2c9b1',
  TJ_LP_WAVAX_USDC_ADDRESS: '0xf4003F4efBE8691B60249E6afbD307aBE7758adb',
  TJ_LP_WAVAX_DAIe_ADDRESS: '0x87dee1cc9ffd464b79e058ba20387c1984aed86a',
  PNG_LP_WAVAX_USDCe_ADDRESS: '0xbd918ed441767fe7924e99f6a0e0b568ac1970d9',
  PNG_LP_WAVAX_USDTe_ADDRESS: '0xe28984e1ee8d431346d32bec9ec800efb643eef4',
  WAVAX_TOKEN_ADDRESS: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  USDC_TOKEN_ADDRESS: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
  USDCe_TOKEN_ADDRESS: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
  USDTe_TOKEN_ADDRESS: '0xc7198437980c041c805a1edcba50c1ce5db95118',
  DAIe_TOKEN_ADDRESS: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
  JOE_TOKEN_ADDRESS: '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
  PNG_TOKEN_ADDRESS: '0x60781C2586D68229fde47564546784ab3fACA982',
  JOE_ROUTER_ADDRESS: '0x60ae616a2155ee3d9a68541ba4544862310933d4',
  PNG_ROUTER_ADDRESS: '0xe54ca86531e17ef3616d22ca28b0d458b6c89106',
  TJ_WAVAX_USDC_POOL_ID: '0',
  TJ_WAVAX_DAIe_POOL_ID: '37',
  PNG_WAVAX_USDCe_POOL_ID: '9',
  PNG_WAVAX_USDTe_POOL_ID: '7',
  AVAX_WHALE: '0x4aefa39caeadd662ae31ab0ce7c8c2c9c0a013e8',
  WAVAX_WHALE: '0x0e082F06FF657D94310cB8cE8B0D9a04541d8052',
  USDC_WHALE: '0x42d6ce661bb2e5f5cc639e7befe74ff9fd649541',
  USDTe_WHALE: '0xef3c714c9425a8F3697A9C969Dc1af30ba82e5d4',
  USDCe_WHALE: '0x7aad7840f119f3876ee3569e488c7c4135f695fa',
  DAIe_WHALE: '0x38dae04e4c874afc3d237e73677aee43066ac1f2',
  JOE_WHALE: '0x279f8940ca2a44c35ca3edf7d28945254d0f0ae6',
  PNG_WHALE: '0x0d0707963952f2fba59dd06f2b425ace40b492fe',
};
