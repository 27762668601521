import { getSignedVAA } from '@certusone/wormhole-sdk';
import { wormholeRpcHost } from '../../constants/crosschain';
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport';
import { logMsg } from '../dev';

const getSignedVAAWithRetry = async (
  emitterChain,
  emitterAddress,
  sequence
) => {
  while (true) {
    logMsg('trying to get Signed VAAs');
    try {
      const { vaaBytes } = await getSignedVAA(
        wormholeRpcHost,
        emitterChain,
        emitterAddress,
        sequence,
        {
          transport: NodeHttpTransport(),
        }
      );
      if (vaaBytes !== undefined) {
        return vaaBytes;
      }
    } catch (e) {}
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
};

export default getSignedVAAWithRetry;
