import { TransactionResponse } from '@ethersproject/providers';
import {
  CrosschainTransactionStatus,
  SamechainTransactionStatus,
} from '../../constants/base/transaction';
import { InvestType } from '../../constants/crosschain';
import { InvestParams } from '../provider/IChainProvider';
// import { TransactionType } from '../provider/transaction/types';
import { SliceCreator } from '..';
import {
  BN,
  deepCopy,
  getVaultName,
  roundBN,
  transactionMessageParser,
} from '../../helpers/utilities';
import { ModalType, PopupType } from '../base/AppSlice';

export interface ITransaction {
  providerChainType?: string;
  status: CrosschainTransactionStatus | SamechainTransactionStatus;
  transactionPayload: InvestParams;
  response?: any;
  error?: any;
  timestamp: number;
  hash?: string;
}
export interface ITransactionManagerSlice {
  initSubscription: () => void;

  pendingTransactions: {
    [account: string]: {
      [chainId: string]: {
        [key: string]: ITransaction;
      };
    };
  };
  completedTransactions: {
    [account: string]: {
      [chainId: string]: {
        [key: string]: ITransaction;
      };
    };
  };
  failedTransactions: {
    [account: string]: {
      [chainId: string]: {
        [key: string]: ITransaction;
      };
    };
  };
  addPendingTransaction: (key: string, txn: ITransaction) => void;
  removeAllTransactions: (account: string, chainId: string) => void;

  //TODO: Reimplment these functions.
  // setStatus: (key: string, status: any) => void;
  // setPayload: (key: string, payload: any) => void;
  // setError: (key: string, error: string) => void;
  // removePendingTransaction: (key: string) => void;
}

/**
 * Adds a pending transaction to the transaction manager.
 * @param {string} key - the key of the transaction.
 * @param {ITransaction} txn - the transaction to add.
 * @returns None
 */
export const useTransactionManagerSlice: SliceCreator<
  ITransactionManagerSlice
> = (set, get, api, setSelf, getSelf) => {
  return {
    pendingTransactions: {},
    completedTransactions: {},
    failedTransactions: {},

    addPendingTransaction(key: string, txn: ITransaction) {
      const { chainId, chainProvider } = get().chainSlice;
      if (!chainProvider) return;
      const { walletAddress } = chainProvider;
      if (walletAddress === null || chainId === null) return;

      set((state: any) => {
        let { pendingTransactions } = state.transactionManagerSlice;
        pendingTransactions[walletAddress] === undefined &&
          (pendingTransactions[walletAddress] = {});
        pendingTransactions[walletAddress][chainId] === undefined &&
          (pendingTransactions[walletAddress][chainId] = {});
        pendingTransactions[walletAddress][chainId][key] = txn;
      });
    },
    // TODO: Cleanup logic
    removeAllTransactions(walletAddress: string, chainId: string) {
      set((state: any) => {
        let { pendingTransactions, completedTransactions, failedTransactions } =
          state.transactionManagerSlice;
        pendingTransactions[walletAddress] === undefined &&
          (pendingTransactions[walletAddress] = {});
        pendingTransactions[walletAddress][chainId] === undefined &&
          (pendingTransactions[walletAddress][chainId] = {});
        pendingTransactions[walletAddress][chainId] = {};
        completedTransactions[walletAddress] === undefined &&
          (completedTransactions[walletAddress] = {});
        completedTransactions[walletAddress][chainId] === undefined &&
          (completedTransactions[walletAddress][chainId] = {});
        completedTransactions[walletAddress][chainId] = {};
        failedTransactions[walletAddress] === undefined &&
          (failedTransactions[walletAddress] = {});
        failedTransactions[walletAddress][chainId] === undefined &&
          (failedTransactions[walletAddress][chainId] = {});
        failedTransactions[walletAddress][chainId] = {};
      });
    },
    initSubscription() {
      api.subscribe(
        (state: any) => state.transactionManagerSlice.pendingTransactions,
        (
          pending: {
            [walletAddress: string]: {
              [chainId: string]: {
                [key: string]: ITransaction;
              };
            };
          },
          prevPending: {
            [walletAddress: string]: {
              [chainId: string]: {
                [key: string]: ITransaction;
              };
            };
          }
        ) => {
          const { addPopup } = get().appSlice;
          const { chainId, chainProvider, transactionProvider } =
            get().chainSlice;
          const walletAddress = chainProvider?.walletAddress;
          if (
            chainProvider === null ||
            transactionProvider === null ||
            walletAddress === null ||
            walletAddress === undefined ||
            chainId === null
          )
            return;
          const { addTransaction } = transactionProvider;
          if (!pending[walletAddress] || !pending[walletAddress][chainId])
            return;

          const changedTxns = Object.entries(
            pending[walletAddress][chainId]
          ).filter(
            ([k, v]) =>
              prevPending?.[walletAddress]?.[chainId]?.[k] === undefined ||
              prevPending?.[walletAddress]?.[chainId]?.[k]?.status !== v.status
          );
          changedTxns.forEach(async ([k, v]) => {
            // When providerChain is different than strategyChain, we know it's a crosschain transaction.
            // if (
            //   v.transactionPayload.providerChain !==
            //   v.transactionPayload.strategyChain
            // ) {
            // const status = v.status;
            // switch (status) {
            //   // TODO: Implement crosschain transaction status handling.
            //   case 'OPENED':
            //     // deltaNeutralGetVAA(k, v);
            //     break;
            //   case 'GOT_VAA':
            //     // _checkVAAs(k, v);
            //     break;
            //   case 'CHECK_VAA':
            //   // _checkVAAs(k, v);

            //   case 'CLOSED':
            //   default:
            // }
            // } else {
            // When providerChain is the same as strategyChain, it's a samechain transaction.
            const status = v.status;

            const { setOpenModal } = get().appSlice;
            switch (status) {
              case 'INIT':
                try {
                  const modalProps = {
                    isInvest: v.transactionPayload.type !== InvestType.DECREASE,
                    amount:
                      v.transactionPayload.type !== InvestType.DECREASE
                        ? v.transactionPayload.amount
                        : roundBN(
                            BN(
                              v.transactionPayload.positionInfo?.userEquity ?? 0
                            )
                              .times(v.transactionPayload.amount)
                              .times(
                                1 -
                                  v.transactionPayload.strategyInfo.withdrawFee.toNumber() /
                                    1e4
                              )
                              .toString(),
                            v.transactionPayload.token.decimals
                          ),
                    currency: v.transactionPayload.token.ticker,
                  };
                  setOpenModal(ModalType.CONFIRMATION, modalProps);
                  const res: TransactionResponse = await chainProvider.invest(
                    v.transactionPayload
                  );
                  const { from, hash } = res;
                  addTransaction({ chainId, from, hash, info: res });
                  set((state: any) => {
                    const { pendingTransactions } =
                      state.transactionManagerSlice;
                    // Deep copy the transaction so that the original transaction is not modified.
                    const txn = deepCopy(
                      pendingTransactions[walletAddress][chainId][k]
                    );
                    delete pendingTransactions[walletAddress][chainId][k];
                    pendingTransactions[walletAddress][chainId][hash] = txn;
                    pendingTransactions[walletAddress][chainId][hash].status =
                      'OPENED';
                    pendingTransactions[walletAddress][chainId][hash].response =
                      res;
                    pendingTransactions[walletAddress][chainId][hash].hash =
                      hash;
                    pendingTransactions[walletAddress][chainId][
                      hash
                    ].timestamp = new Date().getTime();
                  });
                  // addPopup({ txn: { hash, status: 'OPENED' } }, hash, 5000);
                  addPopup(
                    {
                      [PopupType.TXN]: {
                        title: getVaultName(
                          v.transactionPayload.strategyInfo.tokens
                        ),
                        hash,
                        status: 'OPENED',
                      },
                    },
                    hash,
                    5000
                  );
                  setOpenModal(ModalType.TXN_SUBMITTED);
                } catch (err: any) {
                  const txn = {
                    ...get().transactionManagerSlice.pendingTransactions[
                      walletAddress
                    ][chainId][k],
                  };
                  txn.status = 'ERROR';
                  txn.error = err;
                  console.log(err);
                  setOpenModal(ModalType.TXN_REJECTED);
                  addPopup(
                    {
                      [PopupType.ERROR]: {
                        title: getVaultName(
                          txn.transactionPayload.strategyInfo.tokens
                        ),
                        msg: transactionMessageParser(txn, err),
                      },
                    },
                    k,
                    5000
                  );
                }
                break;
              case 'CLOSED':
                set((state: any) => {
                  const { pendingTransactions, completedTransactions } =
                    state.transactionManagerSlice;
                  const txn = pendingTransactions[walletAddress][chainId][k];
                  delete pendingTransactions[walletAddress][chainId][k];
                  completedTransactions[walletAddress] === undefined &&
                    (completedTransactions[walletAddress] = {});
                  completedTransactions[walletAddress][chainId] === undefined &&
                    (completedTransactions[walletAddress][chainId] = {});
                  completedTransactions[walletAddress][chainId][k] = txn;
                  completedTransactions[walletAddress][chainId][k].timestamp =
                    new Date().getTime();
                });
                break;
              case 'ERROR':
                const errorMessage = await chainProvider.getRevertReason?.(k);
                set((state: any) => {
                  const { pendingTransactions, failedTransactions } =
                    state.transactionManagerSlice;
                  const txn = pendingTransactions[walletAddress][chainId][k];
                  delete pendingTransactions[walletAddress][chainId][k];
                  failedTransactions[walletAddress] === undefined &&
                    (failedTransactions[walletAddress] = {});
                  failedTransactions[walletAddress][chainId] === undefined &&
                    (failedTransactions[walletAddress][chainId] = {});
                  failedTransactions[walletAddress][chainId][k] = txn;
                  failedTransactions[walletAddress][chainId][k].error =
                    errorMessage;
                  failedTransactions[walletAddress][chainId][k].timestamp =
                    new Date().getTime();
                });
                break;
            }
          });
        },
        { fireImmediately: true }
      );
    },

    // processPendingTransaction(key: string, fn: Function, cb: Function) {
    //   get().
    // }
  };
};
