import { CheckedIcon } from '@aperture/assetkit';
import { useMatchBreakpoints } from '@aperture/uikit';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { useStore } from '../../store';
import textContext from '../../textContext';
import { Button } from '../v1';
import './styles/Disclaimer.scss';

const StyledCheckedIcon = styled(CheckedIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.textAlt};
  }
  linearGradient {
    stop:first-child {
      stop-color: ${({ theme }) => theme.colors.checkboxColor1};
    }
    stop:nth-child(2) {
      stop-color: ${({ theme }) => theme.colors.checkboxColor2};
    }
    stop:nth-child(3) {
      stop-color: ${({ theme }) => theme.colors.checkboxColor3};
    }
  }
`;

const Disclaimer = () => {
  const language = useStore((state) => state.appSlice.language);
  const disclaimerAccepted = useStore(
    (state) => state.appSlice.disclaimerAccepted
  );
  const setDisclaimerAccepted = useStore(
    (state) => state.appSlice.setDisclaimerAccepted
  );
  const text = textContext[language].disclaimer;
  const [checked, setChecked] = React.useState(false);
  const [state, setState] = React.useState(false);
  const { isSm } = useMatchBreakpoints();

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleAccept = () => {
    setDisclaimerAccepted();
    document.body.style.overflow = 'auto';
    setState(true);
  };

  React.useEffect(() => {
    if (!disclaimerAccepted) {
      document.body.style.overflow = 'hidden';
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="disclaimer-modal-title"
        aria-describedby="disclaimer-modal-description"
        open={!disclaimerAccepted && !state}
        disableEnforceFocus
        sx={{ zIndex: '2000' }}
      >
        <div className="disclaimer">
          <div className="wrapper">
            <div className={`title ${isSm ? 'headline-3' : 'headline-2'}`}>
              {text.header}
            </div>
            <div className={`text ${isSm ? 'body-2' : 'body-1'}`}>
              <p>
                {text.text1}
                <a className="linkClass" href="/terms">
                  {text.text2}
                </a>
                {text.text3}{' '}
                <a
                  href={text.link.link}
                  className="linkClass"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text.link.text}
                </a>{' '}
              </p>
            </div>
            <div className="disclaimer-confirm">
              <div className="flex" onClick={handleChange}>
                <Checkbox
                  disableRipple
                  checked={checked}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checkedIcon={<StyledCheckedIcon />}
                />
                <small className="acknowledge">{text.acknowledge}</small>
              </div>
              <Button
                variant="contained"
                light={true}
                size={isSm ? 'default' : 'large'}
                text={text.confirm}
                onClick={handleAccept}
                disabled={!checked}
              />
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Disclaimer;
