import React from 'react';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../contexts';

const StyledHeadline1 = styled.span<{ sm: boolean }>`
  font-size: ${({ sm }) => (sm ? '24px' : '32px')};
  font-weight: ${({ sm }) => (sm ? '500' : '600')};
  line-height: ${({ sm }) => (sm ? '24px' : '32px')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Headline1: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledHeadline1 sm={isSm} {...props}>
      {children}
    </StyledHeadline1>
  );
};

const StyledHeadline2 = styled.span<{ sm: boolean }>`
  font-size: ${({ sm }) => (sm ? '20px' : '24px')};
  font-weight: ${({ sm }) => (sm ? '500' : '600')};
  line-height: ${({ sm }) => (sm ? '20px' : '24px')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Headline2: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledHeadline2 sm={isSm} {...props}>
      {children}
    </StyledHeadline2>
  );
};

const StyledHeadline3 = styled.span<{ sm: boolean }>`
  font-size: ${({ sm }) => (sm ? '18px' : '24px')};
  font-weight: ${({ sm }) => (sm ? '600' : '500')};
  line-height: ${({ sm }) => (sm ? '18px' : '24px')};
  paragraph-spacing: ${({ sm }) => (sm ? '6' : '0')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Headline3: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledHeadline3 sm={isSm} {...props}>
      {children}
    </StyledHeadline3>
  );
};

const StyledHeadline4 = styled.span<{ sm: boolean }>`
  font-size: ${({ sm }) => (sm ? '16px' : '18px')};
  font-weight: ${({ sm }) => (sm ? '600' : '400')};
  line-height: ${({ sm }) => (sm ? '16px' : '18px')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Headline4: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledHeadline4 sm={isSm} {...props}>
      {children}
    </StyledHeadline4>
  );
};

const StyledHeadline5 = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Headline5: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledHeadline5 {...props}>{children}</StyledHeadline5>;
};

const StyledSubtitle1 = styled.span<{ sm: boolean }>`
  font-size: 14px;
  font-weight: ${({ sm }) => (sm ? '500' : '600')};
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Subtitle1: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledSubtitle1 sm={isSm} {...props}>
      {children}
    </StyledSubtitle1>
  );
};

const StyledSubtitle2 = styled.span<{ sm: boolean }>`
  font-size: 14px;
  font-weight: ${({ sm }) => (sm ? '400' : '500')};
  line-height: 14px;
  paragraph-spacing: ${({ sm }) => (sm ? '0' : '4')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Subtitle2: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledSubtitle2 sm={isSm} {...props}>
      {children}
    </StyledSubtitle2>
  );
};

const StyledSubtitle3 = styled.span<{ sm: boolean }>`
  font-size: 12px;
  font-weight: ${({ sm }) => (sm ? '400' : '500')};
  line-height: 12px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Subtitle3: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledSubtitle3 sm={isSm} {...props}>
      {children}
    </StyledSubtitle3>
  );
};

const StyledBody1 = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Body1: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledBody1 {...props}>{children}</StyledBody1>;
};

const StyledBody2 = styled.span<{ sm: boolean }>`
  font-size: ${({ sm }) => (sm ? '11px' : '12px')};
  paragraph-spacing: ${({ sm }) => (sm ? '2px' : '0')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Body2: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledBody2 sm={isSm} {...props}>
      {children}
    </StyledBody2>
  );
};

const StyledTitle3 = styled.span<{ sm: boolean }>`
  font-size: ${({ sm }) => (sm ? '14px' : '16px')};
  font-weight: 500;
  line-height: ${({ sm }) => (sm ? '21px' : '24px')};
  color: ${({ theme }) => theme.colors.text};
`;

export const Title3: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledTitle3 sm={isSm} {...props}>
      {children}
    </StyledTitle3>
  );
};
