import styled from 'styled-components';
import { useMatchBreakpoints } from '../../contexts';

const StyleBox = styled.div<{ sm: boolean }>`
  padding: ${({ sm }) => (sm ? '8px 13.5px' : '16px 24px')};
  background: ${({ theme }) => theme.colors.backgroundActivities};
  border-radius: 8px;
  position: relative;
`;

export const Box: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyleBox sm={isSm} {...props}>
      {children}
    </StyleBox>
  );
};

const StyleGraphBox = styled.div<{ sm: boolean; mini: boolean }>`
  margin: ${({ mini }) => (mini ? '8px 0px 0px' : '8px 0px')};
  padding: ${({ sm, mini }) =>
    sm ? '12px 8px' : mini ? '20px 16px' : '24px 32px'};
  background: ${({ theme, sm, mini }) =>
    sm || mini
      ? theme.colors.backgroundGraph
      : theme.colors.backgroundModalGraph};
  border-radius: 8px;
  position: relative;
`;

export const GraphBox: React.FC<
  React.PropsWithChildren<{ mini?: boolean }>
> = ({ mini = false, children, ...props }) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyleGraphBox sm={isSm} mini={mini} {...props}>
      {children}
    </StyleGraphBox>
  );
};
