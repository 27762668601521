//this is a hard coded one, need some improvement with finalized button
import {
  forwardRef,
  ButtonHTMLAttributes,
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import '../button.scss';
import styled from 'styled-components';
import { CopyIcon, CopiedIcon } from '@aperture/assetkit';

interface ButtonProps {
  mobile: boolean;
  copyContent?: any;
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps { }

export const CopyButton = forwardRef<HTMLButtonElement, Props>(
  ({ mobile, copyContent }, ref) => {
    const domRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    const [copied, setCopied] = useState(false);
    useEffect(() => {
      const timeout = setTimeout(() => {
        if (copied) setCopied(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }, [copied]);

    return (
      <button
        onClick={() => {
          navigator.clipboard.writeText(copyContent);
          setCopied(true);
        }}
        className="btn-outline-white btn"
      >
        <Clippy copied={copied} />
        <div className={mobile ? 'button-5' : 'button-6'}>
          {copied ? 'Copied!' : 'Copy Address'}
        </div>
      </button>
    );
  }
);

const StyledCopyIcon = styled(CopyIcon)`
  path { fill: ${({ theme }) => (theme.colors.text)}; }
  react { fill: ${({ theme }) => (theme.colors.text)}; }
`;

const StyledCopiedIcon = styled(CopiedIcon)`
  path { fill: ${({ theme }) => (theme.colors.buttonInfo)}; }
`;

const Clippy = (props: any) => {
  return (
    <span
      style={{
        position: 'relative',
        height: 16,
        width: 16,
        marginRight: '4px',
      }}
    >
      {!props.copied && (
        <StyledCopyIcon />
      )}
      {props.copied && (
        <StyledCopiedIcon />
      )}
    </span>
  );
};
