import { useQuery } from 'react-query';
import { getReturnData } from '../../helpers/graph';
import { dynamoClient } from '../../store/provider/AWSProvider';

export function useFetchWhiteListWalletAddress() {
  const param_whiteList = {
    TableName: 'aperture_whitelist_address',
    Select: 'ALL_ATTRIBUTES',
  };
  return useQuery(
    'fetchWhiteListWalletAddress',
    () =>
      dynamoClient.then((client) =>
        client
          .scan(param_whiteList)
          .promise()
          .then((res) => res.Items)
      ),
    { staleTime: Infinity }
  );
}

export function useFetchReturn(strategy_id: string) {
  const { data: rebalance, isFetching: isFetchingRebalance } =
    useFetchRebalance();
  const param_performance = {
    TableName: 'strategy_info',
    KeyConditionExpression: 'strategy_id = :a',
    ExpressionAttributeValues: {
      ':a': `${strategy_id}`,
    },
  };
  return useQuery(
    `fetchHomoraPerformance_strategy_${strategy_id}`,
    () =>
      dynamoClient.then((client) =>
        client
          .query(param_performance)
          .promise()
          .then(async (res) => {
            let items = res.Items ?? [];
            let LastEvaluatedKey = res.LastEvaluatedKey;
            while (LastEvaluatedKey) {
              const param_performance_continue = {
                ...param_performance,
                ExclusiveStartKey: LastEvaluatedKey,
              };
              const results = await client
                .query(param_performance_continue)
                .promise()
                .then(async (res) => {
                  return {
                    items: res.Items ?? [],
                    LastEvaluatedKey: res.LastEvaluatedKey,
                  };
                });
              items = [...items, ...results.items];
              LastEvaluatedKey = results.LastEvaluatedKey;
            }
            return getReturnData(
              items ?? [],
              (rebalance ?? []).filter(
                (item) => item.strategy_id === strategy_id
              )
            );
          })
      ),
    { enabled: !isFetchingRebalance, staleTime: 1800000 }
  );
}

export function useFetchBacktestAnalysis() {
  const param_performance = {
    TableName: 'homora_performance_backtest_v2',
    Select: 'ALL_ATTRIBUTES',
  };
  return useQuery(
    'fetchBacktestAnalysis',
    () =>
      dynamoClient.then((client) =>
        client
          .scan(param_performance)
          .promise()
          .then((res) => {
            const result = res?.Items?.reduce((r: any, a: any) => {
              r[a.vault_id] = r[a.vault_id] || [];
              r[a.vault_id].push(a);
              return r;
            }, Object.create(null));
            Object.keys(result).forEach((key) => {
              const initial_price = result[key][0].value;
              result[key] = result[key].map((itm: any) => ({
                timestamp_sec: itm.timestamp_sec,
                percentage: (itm.value - initial_price) / initial_price,
              }));
            });
            return result;
          })
      ),
    { staleTime: Infinity }
  );
}
export function useFetchUserGraphInfo(positionId: String) {
  const chainId = '6';
  const partition_key = `${chainId}-${positionId}`;
  const param_position_pnl = {
    TableName: 'position_pnl',
    KeyConditionExpression: 'chain_position = :a',
    ExpressionAttributeValues: {
      ':a': `${partition_key}`,
    },
  };
  return useQuery(
    `useFetchPositionPNL${partition_key}`,
    () =>
      dynamoClient.then((client) =>
        client
          .query(param_position_pnl)
          .promise()
          .then((res) => res.Items)
      ),
    { enabled: positionId !== '', staleTime: 1800000 }
  );
}

export function useFetchRebalance() {
  const param_rebalance = {
    TableName: 'pdn_rebalance',
    Select: 'ALL_ATTRIBUTES',
  };
  return useQuery(
    'fetchRebalance',
    () =>
      dynamoClient.then((client) =>
        client
          .scan(param_rebalance)
          .promise()
          .then((res) => res.Items)
      ),
    { staleTime: Infinity }
  );
}
