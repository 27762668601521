import { SvgProps } from '..';

export const UsdcEIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_usdc_e_clip0)`}>
        <g clipPath={`url(#${props.id}_usdc_e_clip1)`}>
          <path d="M20 40C31.0834 40 40 31.0834 40 20C40 8.9166 31.0834 0 20 0C8.9166 0 0 8.9166 0 20C0 31.0834 8.9166 40 20 40Z" fill="#5BA6FC" />
          <path d="M25.4996 23.167C25.4996 20.2504 23.7496 19.2504 20.2496 18.8338C17.7496 18.5004 17.2496 17.8338 17.2496 16.667C17.2496 15.5002 18.083 14.7504 19.7496 14.7504C21.2496 14.7504 22.083 15.2504 22.4996 16.5004C22.583 16.7504 22.833 16.917 23.083 16.917H24.4162C24.7496 16.917 24.9996 16.667 24.9996 16.3338V16.2504C24.6662 14.417 23.1662 13.0004 21.2496 12.8338V10.8338C21.2496 10.5004 20.9996 10.2504 20.583 10.167H19.333C18.9996 10.167 18.7496 10.417 18.6662 10.8338V12.7504C16.1662 13.0838 14.583 14.7504 14.583 16.8338C14.583 19.5838 16.2496 20.667 19.7496 21.0838C22.083 21.5004 22.833 22.0004 22.833 23.3338C22.833 24.6672 21.6662 25.5838 20.083 25.5838C17.9162 25.5838 17.1662 24.667 16.9162 23.417C16.833 23.0838 16.583 22.917 16.333 22.917H14.9162C14.583 22.917 14.333 23.167 14.333 23.5004V23.5838C14.6662 25.667 15.9996 27.167 18.7496 27.5838V29.5838C18.7496 29.917 18.9996 30.167 19.4162 30.2504H20.6662C20.9996 30.2504 21.2496 30.0004 21.333 29.5838V27.5838C23.833 27.167 25.4996 25.417 25.4996 23.167Z" fill="white" />
          <path d="M15.7497 31.9162C9.24967 29.583 5.91627 22.333 8.33307 15.9162C9.58307 12.4162 12.3331 9.74961 15.7497 8.49961C16.0831 8.33301 16.2497 8.08301 16.2497 7.66621V6.49961C16.2497 6.16621 16.0831 5.91621 15.7497 5.83301C15.6663 5.83301 15.4997 5.83301 15.4163 5.91621C7.49967 8.41621 3.16627 16.833 5.66627 24.7496C7.16627 29.4162 10.7497 32.9996 15.4163 34.4996C15.7497 34.6662 16.0831 34.4996 16.1663 34.1662C16.2497 34.083 16.2497 33.9996 16.2497 33.833V32.6662C16.2497 32.4162 15.9997 32.083 15.7497 31.9162ZM24.5831 5.91621C24.2497 5.74961 23.9163 5.91621 23.8331 6.24961C23.7497 6.33301 23.7497 6.41621 23.7497 6.58301V7.74961C23.7497 8.08301 23.9997 8.41621 24.2497 8.58301C30.7497 10.9162 34.0831 18.1662 31.6663 24.583C30.4163 28.083 27.6663 30.7496 24.2497 31.9996C23.9163 32.1662 23.7497 32.4162 23.7497 32.833V33.9996C23.7497 34.333 23.9163 34.583 24.2497 34.6662C24.3331 34.6662 24.4997 34.6662 24.5831 34.583C32.4997 32.083 36.8331 23.6662 34.3331 15.7496C32.8331 10.9996 29.1663 7.41621 24.5831 5.91621Z" fill="white" />
        </g>
        <g clipPath={`url(#${props.id}_usdc_e_clip2)`}>
          <path d="M37.8923 1.88672H31.1006V8.05959H37.8923V1.88672Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M39.9966 5.49952C39.9966 8.53308 37.5358 10.9922 34.5002 10.9922C31.4647 10.9922 29.0039 8.53308 29.0039 5.49952C29.0039 2.46599 31.4647 0.00683594 34.5002 0.00683594C37.5358 0.00683594 39.9966 2.46599 39.9966 5.49952ZM32.9427 7.68533H31.8761C31.6519 7.68533 31.5412 7.68533 31.4737 7.64218C31.4008 7.59493 31.3562 7.51668 31.3508 7.4303C31.3468 7.35073 31.4021 7.25354 31.5128 7.05923L34.1466 2.41992C34.2587 2.22292 34.3154 2.12442 34.387 2.08799C34.4639 2.04886 34.5557 2.04886 34.6327 2.08799C34.7043 2.12442 34.761 2.22292 34.873 2.41992L35.4145 3.36446L35.4173 3.36928C35.5383 3.58063 35.5997 3.68781 35.6265 3.80029C35.6562 3.92308 35.6562 4.05262 35.6265 4.17541C35.5995 4.28875 35.5387 4.39671 35.4158 4.61125L34.0324 7.05519L34.0288 7.06145C33.907 7.27454 33.8452 7.38254 33.7596 7.46402C33.6665 7.5531 33.5544 7.61783 33.4315 7.65432C33.3195 7.68533 33.1939 7.68533 32.9427 7.68533ZM35.6365 7.68533H37.1649C37.3904 7.68533 37.5038 7.68533 37.5714 7.64087C37.6443 7.59362 37.6902 7.51397 37.6943 7.42767C37.6982 7.35065 37.644 7.25726 37.5379 7.07424C37.5342 7.06801 37.5306 7.06167 37.5268 7.05522L36.7612 5.74636L36.7525 5.73163C36.6449 5.54982 36.5906 5.45801 36.5209 5.42252C36.4439 5.38339 36.3534 5.38339 36.2765 5.42252C36.2063 5.45895 36.1496 5.55476 36.0375 5.74771L35.2746 7.05657L35.272 7.06108C35.1603 7.25372 35.1045 7.34999 35.1085 7.42898C35.1139 7.51536 35.1585 7.59493 35.2314 7.64218C35.2976 7.68533 35.411 7.68533 35.6365 7.68533Z" fill="#E84142" />
        </g>
      </g>
      <defs>
        <clipPath id={`${props.id}_usdc_e_clip0`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id={`${props.id}_usdc_e_clip1`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id={`${props.id}_usdc_e_clip2`}>
          <rect width="11" height="11" fill="white" transform="translate(29)" />
        </clipPath>
      </defs>
    </svg>
  );
};
