import { SvgProps } from '..';

export const CheckIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 0.25C11.0305 0.25 8.37209 1.05642 6.11092 2.56729C3.84974 4.07816 2.08737 6.22562 1.04666 8.73811C0.00595771 11.2506 -0.266338 14.0153 0.264209 16.6825C0.794755 19.3497 2.10432 21.7998 4.02729 23.7227C5.95026 25.6457 8.40028 26.9553 11.0675 27.4858C13.7348 28.0164 16.4994 27.7441 19.0119 26.7034C21.5244 25.6627 23.6718 23.9003 25.1827 21.6391C26.6936 19.3779 27.5 16.7195 27.5 14C27.4957 10.3546 26.0457 6.85973 23.468 4.28203C20.8903 1.70434 17.3954 0.254301 13.75 0.25ZM19.6338 11.7588L13.3838 18.0088C13.1493 18.2431 12.8315 18.3747 12.5 18.3747C12.1686 18.3747 11.8507 18.2431 11.6163 18.0088L7.86626 14.2588C7.74687 14.1434 7.65164 14.0055 7.58613 13.853C7.52062 13.7005 7.48614 13.5365 7.48469 13.3705C7.48325 13.2045 7.51488 13.0399 7.57773 12.8863C7.64058 12.7327 7.7334 12.5931 7.85076 12.4758C7.96813 12.3584 8.10769 12.2656 8.26131 12.2027C8.41493 12.1399 8.57953 12.1083 8.74551 12.1097C8.91148 12.1111 9.07551 12.1456 9.22801 12.2111C9.38052 12.2766 9.51845 12.3719 9.63376 12.4913L12.5 15.3575L17.8663 9.99126C18.102 9.76356 18.4178 9.63757 18.7455 9.64041C19.0733 9.64326 19.3868 9.77472 19.6185 10.0065C19.8503 10.2382 19.9818 10.5518 19.9846 10.8795C19.9874 11.2073 19.8615 11.523 19.6338 11.7588Z"
        fill={`url(#${props.id}_check_paint0)`}
      />
      <defs>
        <linearGradient
          id={`${props.id}_check_paint0`}
          x1="2.25009"
          y1="25.5029"
          x2="32.4868"
          y2="15.6049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07D5D8" />
          <stop offset="0.6369" stopColor="#6E6EFF" />
          <stop offset="1" stopColor="#6E35FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
