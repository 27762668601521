import React from 'react';
import { MobileDrawer } from '../../../components/MobileDrawer';
import { TableAccordion } from '../../../components/TableAccordion';
import { useMatchBreakpoints } from '../../../contexts';
import { StrategyCardContainerProps } from './types';

const StrategyCardContainer: React.FC<StrategyCardContainerProps> = ({
  order,
  title,
  details,
  isLoading,
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <>
      {isSm ? (
        <MobileDrawer title={title} details={details} />
      ) : (
        <TableAccordion
          order={order}
          title={title}
          details={details}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default StrategyCardContainer;
