/**
 * A component that displays the asset strategy for a given asset.
 * @param props - The props for the component.
 * @returns A React component that displays the asset strategy.
 */
import './styles/AssetStrategy.scss';
import { Grid } from '@mui/material';

interface AssetStrategyProps {
  vaultItem: JSX.Element;
  title: string;
  leverage: number;
}
export default function AssetStrategy(props: AssetStrategyProps) {
  const { vaultItem, title, leverage } = props;

  return (
    <Grid sx={{ textAlign: 'left' }} container direction="column">
      <Grid item>
        <div
          className="medium headline-4"
          style={{ marginBlockStart: 0, marginBottom: '16px' }}
        >
          {`${title}(${leverage}X)`}
        </div>
      </Grid>
      <Grid item>{vaultItem}</Grid>
    </Grid>
  );
}
