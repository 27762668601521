import React from 'react';
import { StyledInfoDetail } from '../CardInfo/SharedComponents';
import {
  CurrentValueAndProfits,
  StrategyType,
  TVLAndCapacity,
} from '../StrategyCardContainer';
import { PortfolioPropsMobile } from './types';

export const PortfolioCardMobile: React.FC<PortfolioPropsMobile> = ({
  vaultItem,
  strategyType,
  tvlCapacity,
  position,
  MobileButtonGroup,
}) => {
  return (
    <>
      <span>{vaultItem}</span>
      <StrategyType strategyType={strategyType} />
      <TVLAndCapacity tvlCapacity={tvlCapacity} />
      <CurrentValueAndProfits position={position} />
      <StyledInfoDetail>{MobileButtonGroup}</StyledInfoDetail>
    </>
  );
};
