import { QuestionMark2Icon } from './Icon/question-mark-2';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { TooltipProps } from './types';
import { useMatchBreakpoints } from '../../../contexts';

const IconContainer = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }
  svg {
    display: flex !important;
  }
`;
const QuestionMark = styled(QuestionMark2Icon)`
  path {
    fill: ${({ theme }) => theme.colors.overallInfoText};
  }
`;
const TooltipTitle = styled.span`
  white-space: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

export const QuestionMarkTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  title = 'Question mark icon tooltip text',
  placementPC = 'bottom-start',
  placementMobile = 'bottom',
  toolTipClassName = '',
  children,
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <Tooltip
      classes={{ tooltip: toolTipClassName }}
      title={<TooltipTitle>{title}</TooltipTitle>}
      placement={isSm ? placementMobile : placementPC}
      enterTouchDelay={0}
    >
      <IconContainer>
        {children ? children : <QuestionMark />}
      </IconContainer>
    </Tooltip>
  );
};
