import React, { useState } from 'react';
import styled from 'styled-components';
import { TabProp, TabsProp } from '.';
import { useMatchBreakpoints } from '../../contexts';

const TabNav = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ sm, theme }) =>
    sm
      ? `
  background: ${theme.colors.backgroundTabNav};
  height: 45px;
  border-radius: 66px;
  width: 100%;
  `
      : `
  width: 100%;
  background: ${theme.colors.tabBackground};
  height: 38px;
  border-bottom: 2px solid ${theme.colors.tabBorder};
  `}
`;

const Tab = styled.div<{ sm: boolean; selected: boolean; width: number }>`
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ sm }) => (sm ? 'calc(100% - 8px)' : 'calc(100% - 2px)')};
  width: ${({ sm, width }) => (sm ? `${width}%` : 'fit-content')};
  margin: ${({ sm }) => (sm ? '4px' : '0')};
  padding: ${({ sm }) => (sm ? '8px 16px' : '0 35px;')}
    ${({ selected, sm, theme }) =>
      selected
        ? sm
          ? `
        height: calc(100% - 24px);
        background: ${theme.colors.mobileTabBackgroundSelected};
        border-radius: 66px;
        color: ${theme.colors.text};
        `
          : `
        height: calc(100% - 2px);
        color: ${theme.colors.tabBorderSelected};
        background: ${theme.colors.tabBackgroundSelected};
        border-bottom: 3px solid ${theme.colors.tabBorderSelected};
        padding-top: 3px;
        `
        : sm
        ? `
      padding: 8px 16px;
      color: ${theme.colors.subText};
      `
        : ``};
  path {
    fill: ${({ theme, selected }) =>
      selected ? theme.colors.tabBorderSelected : theme.colors.text};
  }
  ${({ sm, theme }) =>
    !sm &&
    `
  cursor: pointer;
  &:hover {
    transition: 0.2s;
    color: ${theme.colors.tabBorderSelected};
    path {
      fill: ${theme.colors.tabBorderSelected};
    }
    height: calc(100% - 2px);
    border-bottom: 3px solid ${theme.colors.tabBorderSelected};
    padding-top: 3px;
  }`}
`;

const Link = (props: any) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M1.5 3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H7.095C7.29391 1.5 7.48468 1.42098 7.62533 1.28033C7.76598 1.13968 7.845 0.948912 7.845 0.75C7.845 0.551088 7.76598 0.360322 7.62533 0.21967C7.48468 0.0790178 7.29391 0 7.095 0H3.75C2.75544 0 1.80161 0.395088 1.09835 1.09835C0.395088 1.80161 0 2.75544 0 3.75V14.25C0 15.2446 0.395088 16.1984 1.09835 16.9016C1.80161 17.6049 2.75544 18 3.75 18H14.25C15.2446 18 16.1984 17.6049 16.9016 16.9016C17.6049 16.1984 18 15.2446 18 14.25V10.905C18 10.7061 17.921 10.5153 17.7803 10.3747C17.6397 10.234 17.4489 10.155 17.25 10.155C17.0511 10.155 16.8603 10.234 16.7197 10.3747C16.579 10.5153 16.5 10.7061 16.5 10.905V14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5H9V11.655C9 10.9509 8.72028 10.2755 8.22237 9.77763C7.72446 9.27972 7.04915 9 6.345 9H1.5V3.75ZM1.5 10.5H6.345C6.65133 10.5 6.9451 10.6217 7.16171 10.8383C7.37831 11.0549 7.5 11.3487 7.5 11.655V16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V10.5ZM10.155 0.75C10.155 0.551088 10.234 0.360322 10.3747 0.21967C10.5153 0.0790178 10.7061 0 10.905 0H17.25C17.4489 0 17.6397 0.0790178 17.7803 0.21967C17.921 0.360322 18 0.551088 18 0.75V7.095C18 7.29391 17.921 7.48468 17.7803 7.62533C17.6397 7.76598 17.4489 7.845 17.25 7.845C17.0511 7.845 16.8603 7.76598 16.7197 7.62533C16.579 7.48468 16.5 7.29391 16.5 7.095V2.562L11.4345 7.626C11.3653 7.69763 11.2826 7.75477 11.1911 7.79408C11.0996 7.83338 11.0011 7.85407 10.9016 7.85494C10.802 7.8558 10.7032 7.83683 10.611 7.79912C10.5189 7.76141 10.4351 7.70572 10.3647 7.6353C10.2943 7.56488 10.2386 7.48114 10.2009 7.38897C10.1632 7.29679 10.1442 7.19803 10.1451 7.09845C10.1459 6.99886 10.1666 6.90045 10.2059 6.80895C10.2452 6.71744 10.3024 6.63469 10.374 6.5655L15.4395 1.5H10.905C10.7061 1.5 10.5153 1.42098 10.3747 1.28033C10.234 1.13968 10.155 0.948912 10.155 0.75Z"
      fill="black"
    />
  </svg>
);

const StyledLink = styled(Link)`
  position: absolute;
  right: 16px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.tabBorderSelected};
    }
  }
`;

export const Tabs: React.FC<TabsProp> = ({ tabs, openDetails, ...props }) => {
  const { isSm } = useMatchBreakpoints();
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div {...props}>
      <TabNav sm={isSm}>
        {tabs.map((tab: TabProp, index) => (
          <Tab
            sm={isSm}
            key={index}
            onClick={() => setSelectedIndex(index)}
            selected={selectedIndex === index}
            width={100 / tabs.length}
          >
            {tab.title}
          </Tab>
        ))}
        {!isSm && openDetails && <StyledLink onClick={openDetails} />}
      </TabNav>
      <div>{tabs[selectedIndex].content}</div>
    </div>
  );
};
