import { forwardRef, PropsWithChildren, useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import './index.scss';
import { Link, useLocation } from 'react-router-dom';
import textContext from '../../../../textContext';

interface MobileNavMenuProps { }

export const MobileNavMenu = forwardRef<
  MobileNavMenuProps,
  PropsWithChildren<any>
>(({ }, ref) => {
  const { pathname } = useLocation();
  const [value, setValue] = useState(pathname.includes('portfolio') ? 1 : 0);
  const text = textContext['english'].navbar;

  return (
    <div className="fix-bottom">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {text.navLinks.links.map((nav, i) => {
          const NavIcon = nav.icon;
          return (
            <BottomNavigationAction
              label={`${nav.text}`}
              icon={<NavIcon width="16px" />}
              disabled={nav.disabled}
              component={Link}
              to={`/${nav.link}`}
              key={i}
            />
          )
        })}
      </BottomNavigation>
    </div>
  );
});
