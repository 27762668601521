import { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';
import { PayloadProps } from '../components/Chart/LineGraph';
import { TimeStamp } from './timeFormat';
import { getDayCounts, TimeframeDays, TimeframeType } from './timeframe';

export const getMax = (array: any[], dataKey: string): any => {
  if (array.length === 0) return 0;
  const itm = array.reduce((prev, curr) =>
    prev[dataKey] > curr[dataKey] ? prev : curr
  );
  return itm[dataKey];
};

export const getMin = (array: any[], dataKey: string): any => {
  if (array.length === 0) return 0;
  const itm = array.reduce((prev, curr) =>
    prev[dataKey] < curr[dataKey] ? prev : curr
  );
  return itm[dataKey];
};

export const decimalCount = (num: number) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  return 0;
};
/* if we have array : 1,2,3,4,5,6,7,8
 we wants return 2,4,8
 formulat to get the index value is floor(N/T)*(itrCount+1)-1
*/
export const getXAxisTicker = (
  array: any[],
  ticksCount: number,
  dataKey: string
) => {
  if (!array || array.length === 0) return [];
  const gapCount = Math.floor(array.length / ticksCount);
  const result = Array.from(
    { length: ticksCount },
    (_, i) => array[Math.round(gapCount * (i + 0.5))][dataKey]
  );
  return result;
};

/* if we have array : 2,5,1,3,4,6,7,8,9,10
 we wants return 4,7,10
*/
export const getYAxisTicker = (
  array: any[],
  ticksCount: number,
  dataKey: string
) => {
  if (!array || array.length === 0) return [];
  const max = getMax(array, dataKey);
  const min = getMin(array, dataKey);
  const gap = (max - min) / ticksCount;
  const result = Array.from(
    { length: ticksCount },
    (_, i) => (i + 1) * gap + min
  );
  return result;
};

export const getYAxisTicker2 = (
  max: number,
  min: number,
  ticksCount: number
) => {
  const gap = (max - min) / ticksCount;
  const exp = getExponential(max - min, ticksCount * 5);
  const result = Array.from(
    { length: ticksCount },
    (_, i) =>
      Math.round(((i + 1) * gap + min) * Math.pow(10, exp)) / Math.pow(10, exp)
  );
  return result;
};

// hope someone can find a way logic to calculate this >:(
export const getYAxis = (
  array: any[],
  ticksCount: number,
  dataKey: string,
  dataKey2?: string
) => {
  if (ticksCount === 1) return [];

  var max = getMax(array, dataKey);
  var min = getMin(array, dataKey);

  if (dataKey2) {
    max = Math.max(max, getMax(array, dataKey2));
    min = Math.min(min, getMin(array, dataKey2));
  }

  if (max === min) {
    if (max > 0) {
      max = max * 1.05;
      min = min * 0.95;
    } else if (max < 0) {
      max = max * 0.95;
      min = min * 1.05;
    } else {
      max = 1;
      min = -1;
    }
  }
  const exp = getExponential(max - min, ticksCount * 5);
  // console.log(min, max, exp)
  max =
    (Math.ceil(max * Math.pow(10, exp)) +
      ticksCount * 5 -
      (Math.ceil(max * Math.pow(10, exp)) % (ticksCount * 5))) /
    Math.pow(10, exp);
  min =
    (min * Math.pow(10, exp) -
      Math.abs((min * Math.pow(10, exp)) % (ticksCount * 5))) /
    Math.pow(10, exp);
  // console.log(min, max, getYAxisTicker2(max, min, ticksCount));
  return [min, max, getYAxisTicker2(max, min, ticksCount)];
};

export const getExponential = (initial: number, target: number) => {
  if (initial < target) {
    let e = 0;
    while (initial < target) {
      e += 1;
      initial *= 10;
    }
    return e;
  } else if (initial > target) {
    let e = 0;
    while (initial > target) {
      e -= 1;
      initial /= 10;
    }
    return e + 1;
  } else {
    return 0;
  }
};

export const getLegendPayload = (payload: PayloadProps): LegendPayload[] => {
  if (payload.y && payload.y.length > 0) {
    let legendPayloads: LegendPayload[] = payload.y.map((item) => ({
      value: item.legendText,
      type: 'plainline',
      color: item.color,
      payload: {
        strokeDasharray: item.legendType === 'dash' ? '6 2' : '3 0',
      },
    }));
    if (payload?.y[2]?.legendType === 'dot') {
      legendPayloads[1] = legendPayloads.splice(2, 1, legendPayloads[1])[0];
    }
    return legendPayloads;
  } else {
    return [];
  }
};

export const getTimeframeType = (data: any[]) => {
  if (!data || data.length === 0) return TimeframeType.DAY;
  const last_timestamp = data[data.length - 1].timestamp_sec;
  const first_timestamp = data[0].timestamp_sec;
  const days = getDayCounts(last_timestamp - first_timestamp);
  if (days <= TimeframeDays.day + 1) {
    return TimeframeType.DAY;
  } else if (days <= TimeframeDays.week + 1) {
    return TimeframeType.WEEK;
  } else if (days <= TimeframeDays.month + 1) {
    return TimeframeType.MONTH;
  } else if (days <= TimeframeDays.season + 1) {
    return TimeframeType.SEASON;
  } else if (days <= TimeframeDays.inception + 1) {
    return 'Year'; // this will only appear here once
  } else {
    return TimeframeType.INCEPTION;
  }
};

/* 1.5 or 7.5 is becuse incase we have 1.1 or 7.05 */
export const getDateTicker = (
  data: any[],
  timeframeType: TimeframeType | 'Year'
) => {
  if (!data || data.length === 0) return [];
  const last_timestamp: number = data[data.length - 1].timestamp_sec;
  const first_timestamp: number = data[0].timestamp_sec;
  if (timeframeType === TimeframeType.DAY) {
    return Array.from(
      { length: 4 },
      (_, i) =>
        last_timestamp -
        (last_timestamp % TimeStamp.hour) -
        TimeStamp.hour * 6 * (3 - i)
    );
  } else if (timeframeType === TimeframeType.WEEK) {
    return Array.from(
      { length: 7 },
      (_, i) =>
        last_timestamp -
        (last_timestamp % TimeStamp.day) -
        TimeStamp.day * (6 - i)
    );
  } else if (timeframeType === TimeframeType.MONTH) {
    return Array.from(
      { length: 5 },
      (_, i) =>
        last_timestamp -
        (last_timestamp % TimeStamp.day) -
        TimeStamp.day * 7 * (4 - i)
    );
  } else if (timeframeType === TimeframeType.SEASON) {
    const date = new Date(first_timestamp * 1e3);
    const month = date.getMonth() + 2;
    const year = date.getUTCFullYear();
    return Array.from({ length: 3 }, (_, i) => {
      const unixCode = new Date(`${year}-${month + i}-01'`).getTime() / 1000;
      const item = data.find((itm) => itm.timestamp_sec === unixCode);
      if (item) {
        return item.timestamp_sec;
      } else {
        return (
          data.find((itm) => itm.timestamp_sec > unixCode)?.timestamp_sec ?? NaN
        );
      }
    });
  } else if (timeframeType === 'Year') {
    const date = new Date(first_timestamp * 1e3);
    const month = date.getMonth() + 2;
    const year = date.getUTCFullYear();
    return Array.from({ length: 5 }, (_, i) => {
      const unixCode =
        new Date(`${year}-${month + i * 2}-01'`).getTime() / 1000;
      const item = data.find((itm) => itm.timestamp_sec === unixCode);
      if (item) {
        return item.timestamp_sec;
      } else {
        return (
          data.find((itm) => itm.timestamp_sec > unixCode)?.timestamp_sec ?? NaN
        );
      }
    });
  } else {
    const year = new Date(first_timestamp * 1e3).getUTCFullYear();
    return Array.from(
      { length: 5 },
      (_, i) => new Date(`${year + i}-01-01'`).getTime() / 1000
    );
  }
};

export const getTickFormat = (
  value: any,
  timeframeType: TimeframeType | 'Year',
  small: boolean
) => {
  const date = new Date(value * 1e3);
  const year = date.toLocaleString('en-US', { year: 'numeric' });
  const month = date.toLocaleString('en-US', { month: 'short' });
  const day = date.toLocaleString('en-US', { day: 'numeric' });
  const hour = date
    .toLocaleTimeString('en-US', { hour12: false })
    .split(' ')[0]
    .slice(0, -3)
    .split(':');
  if (timeframeType === TimeframeType.DAY) {
    return (hour[0] === '24' ? '00' : hour[0]) + ':' + hour[1];
  } else if (timeframeType === TimeframeType.WEEK) {
    return `${small && Number(day) !== 1 ? '' : month} ${day}`;
  } else if (timeframeType === TimeframeType.MONTH) {
    return `${small && Number(day) !== 1 ? '' : month} ${day}`;
  } else if (timeframeType === TimeframeType.SEASON) {
    return month;
  } else if (timeframeType === 'Year') {
    return month;
  } else {
    return year;
  }
};
