import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';

const BorderLinearProgress = styled(LinearProgress)<{ height?: number }>`
  height: ${(props) =>
    props.height ? props.height + 'px' : '28px !important'};
  opacity: 0.1;
  border-radius: 8px;
`;

export default function Loader(props: { size?: number; height?: number }) {
  return (
    <Grid
      container
      // alignItems="center"
      // justifyContent="center"
      className="tableAssets"
    >
      <Grid item xs={props.size ?? 12}>
        <BorderLinearProgress height={props.height} color="inherit" />
      </Grid>
    </Grid>
  );
}
