import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

/*----------------------------
  xs: 0px
  sm: 600px
  md: 900px
  lg: 1200px
  xl: 1536px
----------------------------*/

/*----------------------------
  Global styles
----------------------------*/

// Palette Colors
  body {
    transition: .15s;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: 6px;  /* Firefox */
    * {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 6px;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        border-radius: 25px;
      }
    }
  }
  body::before {
    content: '';
    // display: inline-block;
    position: absolute;
    z-index:-99;
    height: 100vh!important;
    @media only screen and (max-width: 1200px) {
      width: 100vw !important;
      margin-left: 0px;
    }
    width: calc(100vw - 260px) !important;
    margin-left: 260px;
  }
  .textPrimary {
    transition: .15s;
  }
  .textSecondary {
    transition: .15s;
  }

// Layout
  #root {
    display: flex;
  }
  .wrapper {
    border-radius: 8px;
    border: 1px solid;
    padding: .5rem 1rem;
  }
  .textUpper {
    text-transform: uppercase!important;
  }
  .disabled {
    cursor: auto!important;
    pointer-events: none;
  }
  .flex {
    display: flex;
  }
  .linkClass {
    text-decoration: none;
  }
  .MuiInputBase-input {
    font-family: Poppins!important;
  }
  .MuiAccordion-region {
    @media (max-width: 899px) {
      padding-top: 1rem;
      position: relative;
    }
  }
  .MuiAccordion-region {
    @media (max-width: 899px) {
      padding-top: 1rem;
      position: relative;
    }
  }

// Text for static pages
  .textClass {
    padding: 0;
    .flex {
      align-items: baseline;
      a {
        text-decoration: underline;
        &:hover {
          color: #7befff;
        }
      }
    }
    & > div {
      padding: 3%;
      overflow: clip;
    }
    p {
      font-size: 14px;
    }
    a {
        text-decoration: underline;
        &:hover {
          color: #7befff;
        }
      }
    .sub {
      font-size: 16px;
      font-weight: 500;
    }
    h2 {
      font-size: 22px;
    }
    .list {
      font-size: 14px;
    }
    .bullets {
      font-size: 14px;
      span {
        font-weight: 500;
      }
    }
  }

/* Temp fix below for dark - light mode switch: */
  body {
    color: ${({ theme }) => theme.colors.text};
    scrollbar-color: ${({ theme }) => theme.colors.scrollbarTrack};;
    * {
      /* Track */
      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.scrollbarThumb};
        &:hover {
          background-color: ${({ theme }) => theme.colors.scrollbarThumbHover};
        }
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.scrollbarTrack};
        &:hover {
          background: ${({ theme }) => theme.colors.scrollbarTrackHover};
        }
        &:active {
          background-color: ${({ theme }) => theme.colors.scrollbarTrack};
        }
      }
    }
  }
  body::before {
    background: ${({ theme }) => theme.colors.backgroundMain};
  }
  nav {
    background: ${({ theme }) => theme.colors.backgroundNav};
  }
  .mobile-nav {
    .dot {
      background-color: ${({ theme }) =>
        theme.colors.backgroundMobileNavButton} !important;
    }
  }
  .private-beta {
    outline: 1px solid ${({ theme }) => theme.colors.tag};
    color: ${({ theme }) => theme.colors.tag};
  }
  .textSecondary {
    color: ${({ theme }) => theme.colors.linkText};
  }
  .disclaimer {
    .wrapper {
      background: ${({ theme }) => theme.colors.backgroundModal};
    }
    .title {
      color: ${({ theme }) => theme.colors.text};
    }
    .text {
      p {
        color: ${({ theme }) => theme.colors.text};
      }
      a {
        color: ${({ theme }) => theme.colors.text};
        &:hover {
          color: ${({ theme }) => theme.colors.linkHover};
        }
      }
    }
  }
  .notification-pop-over {
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
      background: ${({ theme }) => theme.colors.backgroundModal};
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .content-container {
    background: ${({ theme }) => theme.colors.backgroundModal};
    outline: ${({ theme }) => theme.colors.border};
    .content {
      .description {
        color: ${({ theme }) => theme.colors.modalText};
      }
    }
  }
  .light.btn-contained {
    background: ${({ theme }) => theme.colors.buttonContained};
  }
  .dark.btn-contained {
    background: ${({ theme }) => theme.colors.buttonContained};
  }
  .btn-contained:hover{
    background: ${({ theme }) => theme.colors.buttonContainedHover};
  }
  .btn-contained::after {
    color: ${({ theme }) => theme.colors.textAlt};
  }
  .btn-contained:hover::after {
    color: ${({ theme }) => theme.colors.textAlt};
  }
  .btn-outline.light {
    background: ${({ theme }) => theme.colors.buttonOutlineLight};
  }
  .btn-outline.dark {
    background: ${({ theme }) => theme.colors.buttonOutline};
  }
  .btn-outline::before {
    background-color: ${({ theme }) => theme.colors.buttonOutlineBackground};
  }
  .btn-info {
    background: ${({ theme }) => theme.colors.buttonInfo};
  }
  .btn-info::before {
    background-color: ${({ theme }) => theme.colors.buttonInfoText};
  }
  .btn-info::after {
    background: ${({ theme }) => theme.colors.buttonInfo};
    -webkit-background-clip: text;
  }
  .btn-info:hover::after {
    color: ${({ theme }) => theme.colors.buttonInfoHover};
  }
  .wallet-list {
    .btn-outline::before {
      background: ${({ theme }) => theme.colors.buttonOutlineWallet};
    }
  }
  .btn-outline::after {
    background: ${({ theme }) => theme.colors.buttonOutlineText};
    -webkit-background-clip: text;
  }
  .btn-outline:hover::after {
    color: ${({ theme }) => theme.colors.textAlt};
  }
  .btn-outline-white {
    background-color: ${({ theme }) => theme.colors.buttonOutlineWhite};
    color: ${({ theme }) => theme.colors.text};
  }
  .btn-outline-white:hover {
    background-color: ${({ theme }) => theme.colors.buttonOutlineWhiteHover};
  }
  .btn-error {
    background-color: ${({ theme }) => theme.colors.buttonDisabled};
  }
  .btn-error::before {
    background-color: ${({ theme }) => theme.colors.buttonDisabled};
  }
  .btn-error::after {
    background: ${({ theme }) => theme.colors.buttonError};
    -webkit-background-clip: text;
  }
  .btn {
    &.connectCard-selected::before {
      background: ${({ theme }) => theme.colors.buttonOutlineLight} !important;
    }
    &.disabled {
      color: ${({ theme }) => theme.colors.textButtonDisabled};
    }
  }
  .btn.light.disabled::before {
    background-color: ${({ theme }) => theme.colors.buttonDisabled};
  }
  .chain-list {
    .btn.disabled {
      background: ${({ theme }) => theme.colors.buttonDisabled};
    }
  }
  .comingSoon {
    background-color: ${({ theme }) => theme.colors.backgroundComingSoon};
    &>div {
      background: ${({ theme }) => theme.colors.buttonOutlineText};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: initial;
    }
    &:before {
      background: ${({ theme }) =>
        theme.colors.backgroundComingSoonBorder} !important;
    }
  }
  .connectCard {
    .chain-info {
      color: ${({ theme }) => theme.colors.text};
    }
    &:hover {
      span {
        color: ${({ theme }) => theme.colors.textAlt};
      }
    }
  }
  .connectCard-selected {
    .chain-info {
      color: ${({ theme }) => theme.colors.textAlt};
    }
  }
  .connect-wallet {
    color: ${({ theme }) => theme.colors.textAlt};
  }
  .authNetwork-trigger {
    background-color: ${({ theme }) => theme.colors.backgroundContent};
    color: ${({ theme }) => theme.colors.text};
  }
  .authNetwork-content {
    color: ${({ theme }) => theme.colors.text};
  }
  .authNetwork-content {
    .authNetwork-list {
      &:hover {
        background-color: ${({ theme }) => theme.colors.navHover};
      }
    }
  }
  .authUser-trigger {
    background-color: ${({ theme }) => theme.colors.backgroundContent};
  }
  .authUser-address-block {
    background-color: ${({ theme }) => theme.colors.backgroundWallet};
    @media (max-width: 1199px) {
      background-color: transparent;
    }
  }
  .authNetwork-content {
    .authNetwork-list {
      .circular {
        background-color: ${({ theme }) => theme.colors.online};
      }
    }
  }
  .authUser-content {
    background-color: ${({ theme }) => theme.colors.backgroundConnection};
    color: ${({ theme }) => theme.colors.text};
  }
  .authUser-content-bottom {
    background-color: ${({ theme }) => theme.colors.backgroundActivities};
    .authUser-content-bottom-title {
      >button {
        color: ${({ theme }) => theme.colors.linkClear};
      }
    }
  }
  .authUser-content-bottom-body {
    p {
      color: ${({ theme }) => theme.colors.linkClear};
    }
  }
  .MuiPaper-root.MuiPopover-paper {
    background-color: ${({ theme }) => theme.colors.backgroundDropdown};
  }
  .MuiAccordion-root {
    color: ${({ theme }) => theme.colors.text} !important;
    .MuiButtonBase-root {
      &:hover {
        background: ${({ theme }) =>
          theme.colors.backgroundDropdown} !important;
      }
      &.MuiAccordionSummary-root {
        &.Mui-expanded:hover {
          background: ${({ theme }) =>
            theme.colors.backgroundDropdown} !important;
        }
      }
    }
    &.Mui-expanded {
      background: ${({ theme }) => theme.colors.backgroundBanner} !important;
    }
  }
  .dropdown-bar {
    background: ${({ theme }) => theme.colors.tableDetailHeader} !important;
    .view-details {
      color: ${({ theme }) => theme.colors.boxTitleText};
    }
  }
  .tvl-box {
    background: ${({ theme }) => theme.colors.backgroundBox};
    box-shadow: ${({ theme }) => theme.colors.shadowBox};
  }
  .portClass{
    .title {
      color: ${({ theme }) => theme.colors.boxTitleText};
    }
    .number-counter {
      background: ${({ theme }) => theme.colors.boxTitleValue};
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
  .investContainer {
    background: ${({ theme }) => theme.colors.backgroundContent};
  }
  .box {
    color: ${({ theme }) => theme.colors.text};
  }
  .investTable {
    .tableHeaders {
      .tableItems {
        color: ${({ theme }) => theme.colors.tableHeaderText};
      }
    }
  }
  .borderBottom {
    border-bottom: 2px solid ${({ theme }) => theme.colors.border};
  }
  .modal-content {
    background: ${({ theme }) => theme.colors.backgroundModal};
  }
  .modal-pdn-strategy-details {
    outline: 2px solid ${({ theme }) => theme.colors.border};
  }
  .vaultItemContainer {
    .protocols {
      color: ${({ theme }) => theme.colors.subText};
    }
  }
  .line-graph-container {
    background: ${({ theme }) => theme.colors.backgroundGraph} !important;
  }
  .selectedButtonList {
    .selectedbutton {
      border: 1.5px solid ${({ theme }) => theme.colors.buttonInfo} !important;
      @media (hover: hover) {
        &:hover {
          border: 1.5px solid ${({ theme }) =>
            theme.colors.graphButton} !important;
          color: ${({ theme }) => theme.colors.textAlt};
          background: ${({ theme }) => theme.colors.graphButton};
        }
      }
    }
    .unselectedbutton {
      border: 1.5px solid ${({ theme }) =>
        theme.colors.buttonModalGraph} !important;
      color: ${({ theme }) => theme.colors.buttonModalGraph};
      @media (hover: hover) {
        &:hover {
          border: 1.5px solid ${({ theme }) =>
            theme.colors.graphButton} !important;
          color: ${({ theme }) => theme.colors.textAlt};
          background: ${({ theme }) => theme.colors.graphButton};
        }
      }
    }
  }
  .graph-tooltip {
    background: ${({ theme }) => theme.colors.backgroundGraphTooltip};
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  }
  .tooltip-float-text {
    color: white;
  }
  .tooltip-float-right {
    color: white;
  }
  .selectedbutton {
    color: ${({ theme }) => theme.colors.buttonInfoHover} !important;
    background: ${({ theme }) => theme.colors.buttonInfoFill};
  }
  .pdn-strategy-details {
    background: ${({ theme }) => theme.colors.backgroundModal};
  }
  .pdn-strategy-details-title {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    .details-row {
      .action-button-group {
        .action-withdraw {
          &.btn-info::before {
            background: ${({ theme }) => theme.colors.buttonModalWithdraw};
          }
        }
      }
    }
  }
  .overrall-info-container {
    .data-grid-left {
      background: ${({ theme }) => theme.colors.backgroundActivities};
    }
    .data-grid-right {
      background: ${({ theme }) => theme.colors.backgroundActivities};
    }
  }
  .overallInfo-title {
    color: ${({ theme }) => theme.colors.overallInfoText};
  }
  .back-test-anlysis {
    background: ${({ theme }) => theme.colors.backgroundModalGraph} !important;
    .headline-5 {
      color: ${({ theme }) => theme.colors.tableHeaderText};
    }
    .timeframe-text {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .assetModal {
    & > div {
      background: ${({ theme }) => theme.colors.backgroundModal};
      outline: 1.5px solid ${({ theme }) => theme.colors.border};
    }
    .assetContainer {
      & > div {
        background: ${({ theme }) =>
          theme.colors.backgroundInvestContainer} !important;
      }
    }
  }
  .token {
    .selectValue {
      background: ${({ theme }) => theme.colors.backgroundInvestToken};
      .MuiInputBase-root {
        input {
          color: ${({ theme }) => theme.colors.text};
        }
      }
      .textUpper {
        color: ${({ theme }) => theme.colors.textAlt};
      }
    }
    &.errorColor {
      input {
        color: ${({ theme }) => theme.colors.textInputError};
      }
      .selectValue {
        border: 1px solid ${({ theme }) =>
          theme.colors.textInputErrorBorder} !important;
      }
    }
  }
  .MuiTooltip-popper .MuiTooltip-tooltip {
    background: ${({ theme }) => theme.colors.backgroundTooltip};
    color: ${({ theme }) => theme.colors.textAlt};
    padding: 8px 16px;
    margin-top: 8px !important;
    @media (max-width: 1199px) {
        margin-left: 16px;
        margin-right: 20px;
        max-width: 326px;
    }
    &.apy-bottom-start-tooltip {
      margin-left: -30px;
    }
    &.apy-bottom-tooltip {
      max-width: 392px;
      margin-left: -10px;
      @media (max-width: 1199px) {
        margin-left: 16px;
      }
    }
    &.withdraw-tooltip {
      max-width: 392px;
    }
    &.tolerance-tooltip {
      margin-left: 40px;
    }
    &.dropdown-return-info-tooltip {
      margin-left: 108px;
    }
  }
  .approveButton {
    button {
      color: ${({ theme }) => theme.colors.textAlt};
    }
  }
  .settingModal {
    background: ${({ theme }) => theme.colors.backgroundModal} !important;
    outline: 1.5px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.text};
    .settingContainer {
      .info-grid {
        background: ${({ theme }) =>
          theme.colors.backgroundInvestContainer} !important;
      }
    }
    input {
      background: ${({ theme }) => theme.colors.backgroundInvestToken};
      color: ${({ theme }) => theme.colors.text};
      &:hover,
      &:focus {
        outline: 1px solid ${({ theme }) => theme.colors.border};
      }
    }
    ::placeholder {
      color: ${({ theme }) => theme.colors.overallInfoText} !important;
    }
  }
  .MuiSlider-valueLabel {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.trackValueLabel};
  }
  .MuiSlider-track {
    color: transparent;
    background: ${({ theme }) => theme.colors.trackValueBar};
  }
  .MuiSlider-rail {
    color: ${({ theme }) => theme.colors.trackValueRail};
  }
  .withdrawal-slider {
    .selectValue {
      background: ${({ theme }) => theme.colors.trackResultBackground};
      color: ${({ theme }) => theme.colors.trackResultText};
      border: 1px solid ${({ theme }) => theme.colors.trackResultBorder};
    }
  }
  .MuiSlider-markLabel {
    color: ${({ theme }) => theme.colors.text};
  }
  .MuiModal-root {
    .MuiBox-root {
      background: ${({ theme }) => theme.colors.backgroundModal};
      outline: 2px solid ${({ theme }) => theme.colors.border};
      .modal-text {
        color: ${({ theme }) => theme.colors.text};
      }
      .modal-subtext {
        color: ${({ theme }) => theme.colors.linkClear};
      }
    }
  }
  .transaction-pop-over {
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
      background: ${({ theme }) => theme.colors.backgroundModal};
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .overviewClass {
    background: ${({ theme }) => theme.colors.backgroundPortfolio};
  }
  .reminder-container {
    background: ${({ theme }) => theme.colors.backgroundContent};
    &.box {
      color: ${({ theme }) => theme.colors.linkClear};
    }
  }
  .position-container {
    background: ${({ theme }) => theme.colors.backgroundContent};
  }
  .search {
    .wrapper {
      border: 1px solid ${({ theme }) =>
        theme.colors.portfolioSearchBorder} !important;
      &:hover,
      &:focus-within {
        outline: 1px solid ${({ theme }) =>
          theme.colors.portfolioSearchBorder} !important;
      }
    }
    input {
      color: ${({ theme }) => theme.colors.portfolioSearchText} !important;
    }
  }
  .MuiBottomNavigation-root {
    background: ${({ theme }) => theme.colors.backgroundBottomNav};
  }
  .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected {
    background: ${({ theme }) => theme.colors.navSelected} !important;
  }
  .MuiPaper-root {
    &.MuiDrawer-paper {
      background: ${({ theme }) => theme.colors.backgroundNav};
    }
  }
  .mobile-menu-bar {
    .cross {
      background-color: ${({ theme }) =>
        theme.colors.backgroundMobileNavClose} !important;
    }
  }
  .cardContainer {
    background: ${({ theme }) => theme.colors.backgroundCardContainer};
  }
  .info-row {
    .fieldName {
      color: ${({ theme }) => theme.colors.textCardDetail};
      div {
        margin-left: 4px;
      }
    }
    .fieldValue {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .strategyContainer {
    background: ${({ theme }) => theme.colors.backgroundStrategyContainer};
    color: ${({ theme }) => theme.colors.text};
    .tabNav {
      background: ${({ theme }) => theme.colors.backgroundTabNav};
      li {
        &.active {
          background: ${({ theme }) => theme.colors.backgroundCardContainer};
          .tabName {
            color: ${({ theme }) => theme.colors.text};
          }
        }
        .tabName {
          color: ${({ theme }) => theme.colors.textButtonDisabled};
        }
      }
    }
  }
  .grid2-mini {
    background: ${({ theme }) => theme.colors.backgroundInvestContainer};
  }
  .grid3-mini {
    background: ${({ theme }) => theme.colors.backgroundInvestContainer};
  }
  .card-field-name {
    color: ${({ theme }) => theme.colors.textCardDetail};
  }
  .popup-title {
    color: ${({ theme }) => theme.colors.textPopupTitle};
  }
  .popup-text {
    color: white;
  }
  footer {
    .footerOfficial {
      a {
        &:hover {
          span {
            color: ${({ theme }) => theme.colors.footerHover};
          }
          svg {
            filter: ${({ theme }) => theme.colors.footerHoverFilter};
          }
        }
      }
    }
    .textSecondary {
      color: ${({ theme }) => theme.colors.footerText};
      &:hover {
        color: ${({ theme }) => theme.colors.footerHover};
      }
    }
    .footerSocial {
      a:hover {
        filter: ${({ theme }) => theme.colors.footerHoverFilter};
      }
    }
    .audits {
      text-transform: capitalize;
      width: max-content;
      background: ${({ theme }) => theme.colors.backgroundAudits};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      &:hover {
        background: ${({ theme }) => theme.colors.backgroundAuditsHover};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
  .avatar-img {
    background: ${({ theme }) => theme.colors.avatarBorder};
    box-shadow: ${({ theme }) => theme.colors.shadowBox};
    img {
      background-color: ${({ theme }) => theme.colors.avatarBackground};
    }
  }
  .strategy-button-group, .assets-button-group {
    span {
      color: ${({ theme }) => theme.colors.tableHeaderText};
    }
  }

  .MuiAccordionDetails-root{
    padding: 0 !important;
  }
  
`;

export default GlobalStyle;
