import { SvgProps } from '..';

export const UsdtEIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_usdt_e_clip0)`}>
        <path d="M20 0C31.0452 0 40 8.9548 40 20C40 31.0452 31.0448 40 20 40C8.9552 40 0 31.0476 0 20C0 8.9524 8.9536 0 20 0Z" fill="#53AE94" />
        <path d="M22.4687 17.3355V14.3603H29.2723V9.82715H10.7459V14.3603H17.5503V17.3331C12.0203 17.5871 7.8623 18.6823 7.8623 19.9943C7.8623 21.3063 12.0223 22.4015 17.5503 22.6571V32.1872H22.4703V22.6564C27.9903 22.4016 32.1399 21.3071 32.1399 19.9963C32.1399 18.6855 27.9903 17.5911 22.4703 17.3363M22.4703 21.8491V21.8467C22.3315 21.8555 21.6183 21.8984 20.0303 21.8984C18.7607 21.8984 17.8675 21.8624 17.5527 21.846V21.8499C12.6671 21.6335 9.0203 20.7827 9.0203 19.7647C9.0203 18.7467 12.6675 17.8971 17.5527 17.6803V21.0023C17.8727 21.0243 18.7879 21.0784 20.0511 21.0784C21.5683 21.0784 22.3311 21.0151 22.4711 21.0023V17.6803C27.3471 17.8975 30.9855 18.7491 30.9855 19.7635C30.9855 20.7779 27.3455 21.6299 22.4711 21.8471" fill="white" />
      </g>
      <g clipPath={`url(#${props.id}_usdt_e_clip1)`}>
        <path d="M37.8923 1.88672H31.1006V8.05959H37.8923V1.88672Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M39.9966 5.49952C39.9966 8.53308 37.5358 10.9922 34.5002 10.9922C31.4647 10.9922 29.0039 8.53308 29.0039 5.49952C29.0039 2.46599 31.4647 0.00683594 34.5002 0.00683594C37.5358 0.00683594 39.9966 2.46599 39.9966 5.49952ZM32.9427 7.68533H31.8761C31.6519 7.68533 31.5412 7.68533 31.4737 7.64218C31.4008 7.59493 31.3562 7.51668 31.3508 7.4303C31.3468 7.35073 31.4021 7.25354 31.5128 7.05923L34.1466 2.41992C34.2587 2.22292 34.3154 2.12442 34.387 2.08799C34.4639 2.04886 34.5557 2.04886 34.6327 2.08799C34.7043 2.12442 34.761 2.22292 34.873 2.41992L35.4145 3.36446L35.4173 3.36928C35.5383 3.58063 35.5997 3.68781 35.6265 3.80029C35.6562 3.92308 35.6562 4.05262 35.6265 4.17541C35.5995 4.28875 35.5387 4.39671 35.4158 4.61125L34.0324 7.05519L34.0288 7.06145C33.907 7.27454 33.8452 7.38254 33.7596 7.46402C33.6665 7.5531 33.5544 7.61783 33.4315 7.65432C33.3195 7.68533 33.1939 7.68533 32.9427 7.68533ZM35.6365 7.68533H37.1649C37.3904 7.68533 37.5038 7.68533 37.5714 7.64087C37.6443 7.59362 37.6902 7.51397 37.6943 7.42767C37.6982 7.35065 37.644 7.25726 37.5379 7.07424C37.5342 7.06801 37.5306 7.06167 37.5268 7.05522L36.7612 5.74636L36.7525 5.73163C36.6449 5.54982 36.5906 5.45801 36.5209 5.42252C36.4439 5.38339 36.3534 5.38339 36.2765 5.42252C36.2063 5.45895 36.1496 5.55476 36.0375 5.74771L35.2746 7.05657L35.272 7.06108C35.1603 7.25372 35.1045 7.34999 35.1085 7.42898C35.1139 7.51536 35.1585 7.59493 35.2314 7.64218C35.2976 7.68533 35.411 7.68533 35.6365 7.68533Z" fill="#E84142" />
      </g>
      <defs>
        <clipPath id={`${props.id}_usdt_e_clip0`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id={`${props.id}_usdt_e_clip1`}>
          <rect width="11" height="11" fill="white" transform="translate(29)" />
        </clipPath>
      </defs>
    </svg>
  );
};
