import React from 'react';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../../contexts';
import { WrongNetworkIcon } from './Icon/WrongNetworkIcon';
import { WrongNetworkButtonProps } from './types';

const StyledWrongNetwork = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ sm }) => (sm ? '8px 10px' : '8px 16px')};
  gap: ${({ sm }) => (sm ? '8px' : '10px')};
  width: fit-content;
  box-sizing: border-box;
  height: ${({ sm }) => (sm ? '36px' : '40px')};
  background: ${({ theme }) => theme.colors.warningError};
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ sm }) => (sm ? '14px' : '16px')};
  line-height: ${({ sm }) => (sm ? '21px' : '24px')};
  color: ${({ theme }) => theme.colors.black};
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.warningErrorHover};
  }
  > span {
    display: flex;
  }
`;
const StyledIcon = styled(WrongNetworkIcon)`
  width: 20px;
  height: 20px;
`;

export const WrongNetworkButton: React.FC<WrongNetworkButtonProps> = ({
  openModal,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledWrongNetwork {...props} sm={isSm}>
      <StyledIcon />
      <span>{isSm ? 'Network' : 'Wrong Network'}</span>
    </StyledWrongNetwork>
  );
};
