import React, { useLayoutEffect, useRef, useState } from 'react';
import { ResponsiveContainer as RC } from 'recharts';
import { RCProps } from '.';

export const ResponsiveContainer: React.FC<
  React.PropsWithChildren<RCProps>
> = ({ id, children, ...props }) => {
  const [height, setHeight] = useState(props.fixedheight);
  const parentRef = useRef(null);
  useLayoutEffect(() => {
    function updateSize() {
      const element = document.getElementById(id);
      if (element) setHeight(element.offsetWidth * 0.45);
    }
    if (!props.fixedheight) {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }
  }, [id]);

  return (
    <div id={id} ref={parentRef} {...props}>
      <RC width="100%" height={height}>
        <>{children}</>
      </RC>
    </div>
  );
};
