import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../Loader';
import { AccordionArrow } from './AccordionArrow';
import { TableAccordionProps } from './types';

const StyledAccordion = styled(Accordion)`
  color: ${({ theme }) => theme.colors.text} !important;
  background: transparent !important;
  box-shadow: none !important;
  transition: background 0.5s !important;
  border-radius: 8px !important;
  &.Mui-expanded {
    background: ${({ theme }) => theme.colors.backgroundBanner} !important;
    margin: 16px 0px;
  }
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  height: 90px;
  border-radius: 8px !important;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundDropdown} !important;
  }
  &.MuiAccordionSummary-root {
    padding: 0;
    &.Mui-expanded:hover {
      border-radius: 8px 8px 0px 0px !important;
      background: ${({ theme }) => theme.colors.backgroundDropdown} !important;
    }
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 !important;
`;
const AccordionSummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 16px;
`;

const TableAccordion: React.FC<TableAccordionProps> = ({
  order,
  title,
  details,
  isLoading,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(false);
  return (
    <>
      <StyledAccordion
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        sx={{ order: order }}
      >
        <StyledAccordionSummary>
          <AccordionSummaryContent>
            {title}
            <AccordionArrow {...{ expanded, hover }} />
          </AccordionSummaryContent>
        </StyledAccordionSummary>
        {isLoading ? (
          <Loader size="100%" />
        ) : (
          <StyledAccordionDetails>{details}</StyledAccordionDetails>
        )}
      </StyledAccordion>
    </>
  );
};

export default TableAccordion;
