import { SvgProps } from '..';

export const WbtcEIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M40.2388 25.3365C37.5666 36.0565 26.7054 42.5731 16.0007 39.9008C5.28044 37.2285 -1.2361 26.368 1.43623 15.6634C4.10841 4.94341 14.9537 -1.57314 25.6741 1.0992C36.3788 3.75576 42.9111 14.6164 40.2388 25.3365Z" fill={`url(#${props.id}_wbtc_e_paint0)`} />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.5516 15.9426C29.3311 13.267 27.0418 12.3171 24.116 12.0102L24.1822 8.29073L21.927 8.24698L21.8701 11.8681C21.2749 11.8604 20.6645 11.8561 20.0578 11.8671L20.1111 8.23069L17.8559 8.18693L17.8014 11.8877C17.3048 11.8894 16.8232 11.8877 16.357 11.8824L16.3535 11.8672L13.2302 11.8142L13.1973 14.2271C13.1973 14.2271 14.8655 14.2292 14.8386 14.2514C15.7559 14.2654 16.0425 14.809 16.1182 15.2727L16.0434 19.5074L16.0665 19.5077C16.1267 19.5084 16.1994 19.5092 16.2894 19.531C16.2098 19.5333 16.1301 19.5355 16.0469 19.5225L15.9476 25.4465C15.9012 25.7298 15.7191 26.1886 15.09 26.1725C15.1123 26.1996 13.4486 26.1482 13.4486 26.1482L12.9523 28.844L15.9011 28.9051C16.1337 28.9127 16.363 28.9183 16.5906 28.9239L16.5908 28.9239C16.9007 28.9314 17.2075 28.9389 17.5154 28.9515L17.4564 32.7014L19.7116 32.7452L19.7776 29.0257L19.8284 29.0273L19.829 29.0274C20.4258 29.0462 21.0216 29.065 21.5853 29.076L21.531 32.7767L23.7862 32.8204L23.8452 29.0706C27.6582 28.9171 30.335 28.0143 30.7094 24.4485C31.0228 21.5864 29.6913 20.2723 27.5397 19.7076C28.8814 19.0631 29.716 17.8933 29.5516 15.9426ZM26.2564 23.9148C26.2202 26.4964 22.1279 26.3536 20.2905 26.2895L20.2903 26.2895C20.1354 26.2841 19.9965 26.2792 19.8775 26.2766L19.9571 21.3034C20.115 21.3068 20.3079 21.3061 20.5269 21.3054C22.4314 21.2989 26.3072 21.2856 26.2564 23.9148ZM20.3643 19.0282C21.9018 19.0871 25.2802 19.2164 25.3231 16.8649C25.3596 14.484 22.1371 14.5012 20.5524 14.5097C20.3697 14.5107 20.2088 14.5116 20.0772 14.5086L20.0017 19.016C20.1056 19.0183 20.2277 19.023 20.3643 19.0282Z" fill="white" />
      <g clipPath={`url(#${props.id}_wbtc_e_clip1)`}>
        <path d="M38.8923 2.88672H32.1006V9.05959H38.8923V2.88672Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40.9966 6.49952C40.9966 9.53308 38.5358 11.9922 35.5002 11.9922C32.4647 11.9922 30.0039 9.53308 30.0039 6.49952C30.0039 3.46599 32.4647 1.00684 35.5002 1.00684C38.5358 1.00684 40.9966 3.46599 40.9966 6.49952ZM33.9427 8.68533H32.8761C32.6519 8.68533 32.5412 8.68533 32.4737 8.64218C32.4008 8.59493 32.3562 8.51668 32.3508 8.4303C32.3468 8.35073 32.4021 8.25354 32.5128 8.05923L35.1466 3.41992C35.2587 3.22292 35.3154 3.12442 35.387 3.08799C35.4639 3.04886 35.5557 3.04886 35.6327 3.08799C35.7043 3.12442 35.761 3.22292 35.873 3.41992L36.4145 4.36446L36.4173 4.36928C36.5383 4.58063 36.5997 4.68781 36.6265 4.80029C36.6562 4.92308 36.6562 5.05262 36.6265 5.17541C36.5995 5.28875 36.5387 5.39671 36.4158 5.61125L35.0324 8.05519L35.0288 8.06145C34.907 8.27454 34.8452 8.38254 34.7596 8.46402C34.6665 8.5531 34.5544 8.61783 34.4315 8.65432C34.3195 8.68533 34.1939 8.68533 33.9427 8.68533ZM36.6365 8.68533H38.1649C38.3904 8.68533 38.5038 8.68533 38.5714 8.64087C38.6443 8.59362 38.6902 8.51397 38.6943 8.42767C38.6982 8.35065 38.644 8.25726 38.5379 8.07424C38.5342 8.06801 38.5306 8.06167 38.5268 8.05522L37.7612 6.74636L37.7525 6.73163C37.6449 6.54982 37.5906 6.45801 37.5209 6.42252C37.4439 6.38339 37.3534 6.38339 37.2765 6.42252C37.2063 6.45895 37.1496 6.55476 37.0375 6.74771L36.2746 8.05657L36.272 8.06108C36.1603 8.25372 36.1045 8.34999 36.1085 8.42898C36.1139 8.51536 36.1585 8.59493 36.2314 8.64218C36.2976 8.68533 36.411 8.68533 36.6365 8.68533Z" fill="#E84142" />
      </g>
      <defs>
        <linearGradient id={`${props.id}_wbtc_e_paint0`} x1="0.82349" y1="0.4904" x2="0.82349" y2="40.496" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9AA4B" />
          <stop offset="1" stopColor="#F7931A" />
        </linearGradient>
        <clipPath id={`${props.id}_wbtc_e_clip1`}>
          <rect width="11" height="11" fill="white" transform="translate(30 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
