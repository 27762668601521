import './TransactionModal.scss';
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useMatchBreakpoints } from '@aperture/uikit';

export interface TransactionsProps {
  onClose: any;
  children: JSX.Element;
  modalClassName?: string;
}

export const TransactionModal = ({
  onClose,
  children,
  modalClassName = '',
}: TransactionsProps) => {
  const { isSm } = useMatchBreakpoints();

  return (
    <Modal
      className={modalClassName}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={() => {
        console.log('onclose');
        onClose();
      }}
    >
      <Fade in={true}>
        <div className={`${isSm ? 'strategyContainer' : ''}`}>
          <Box>{children}</Box>
        </div>
      </Fade>
    </Modal>
  );
};

export * from './ConfirmationModal';
export * from './RejectedModal';
export * from './SubmittedModal';
