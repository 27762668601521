import React from 'react';
import { Label } from '../../Label';
import { Tag } from '../../Tag';
import { variants } from '../../Tag/types';
import { useMatchBreakpoints } from '../../../contexts';
import styled from 'styled-components';

const StyledDescription = styled.div`
  padding: 20px 16px 16px !important;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: ${({ theme }) => theme.colors.backgroundBanner};
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;
const StyledDescriptionDetail = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: ${({ sm }) => (sm ? 'column' : 'row')};
`;
const StyledDescriptionTag = styled.div<{ sm: boolean }>`
  margin-right: ${({ sm }) => (sm ? '0' : '24px')};
  margin-bottom: ${({ sm }) => (sm ? '13px' : '0')};
  display: ${({ sm }) => (sm ? 'flex' : 'block')};
`;
const StyledTagWrap = styled.div`
  margin-right: 6px;
`;
const StyledLabelWrap = styled.div`
  margin-left: 2px;
  margin-top: -8px;
`;
const StyledReadMoreButton = styled.div<{ sm: boolean }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.linkClear};
`;
const StyledA = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`
const StrategyDescription: React.FC = () => {
  const { isSm } = useMatchBreakpoints();
  const [readMoreDescription, setReadMoreDescription] = React.useState<boolean>(
    !isSm
  );
  return isSm ? (
    <StyledDescription>
      <StyledDescriptionDetail sm={isSm}>
        <StyledDescriptionTag sm={isSm}>
          <StyledTagWrap>
            <Tag variant={variants.CRAB} />
          </StyledTagWrap>
          <b>Crab Market Leveraged Farming</b>
        </StyledDescriptionTag>
        <div>
          <span>
            Crab Market Leveraged Farming is an auto rebalancing vault that allows users to earn 3X the amount of swap fees less borrowing costs. Users enter with and accrue yield in stable coins.
          </span>
        </div>
      </StyledDescriptionDetail>
      <StyledDescriptionDetail sm={isSm}>
        <StyledDescriptionTag sm={isSm}>
          <StyledTagWrap>
            <Tag variant={variants.BULL} />
          </StyledTagWrap>
          <b>Bull Market Leveraged Farming</b>
          <StyledLabelWrap>
            <Label> New! </Label>
          </StyledLabelWrap>
        </StyledDescriptionTag>
        <div>
          <span>
            Bull Market Leveraged Farming is an auto rebalancing vault that allows users to earn 3X the amount of swap fees less borrowing costs.
          </span>
          {readMoreDescription && (
            <span>
              {' '}
              Users enter with and accrue yield in a volatile asset.
            </span>
          )}
        </div>
      </StyledDescriptionDetail>
      {readMoreDescription && (
        <span>
          <StyledA href='https://docs.aperture.finance/docs/aperture-originals/crab-market-leveraged-farming' target="_blank" >Click here</StyledA> 
          {" "}
          for more info on Crab Market and Bull Market Leveraged Farming.</span>)}
      <StyledReadMoreButton
        sm={isSm}
        onClick={() => setReadMoreDescription(!readMoreDescription)}
      >
        <span>{readMoreDescription ? 'Read Less' : 'Read More'}</span>
      </StyledReadMoreButton>
    </StyledDescription>
  ) : (
    <StyledDescription>
      <StyledDescriptionDetail sm={isSm}>
        <StyledDescriptionTag sm={isSm}>
          <Tag variant={variants.CRAB} />
        </StyledDescriptionTag>
        <div>
          <b>Crab Market Leveraged Farming</b>
          <span>
            {' '}
            is an auto rebalancing vault that allows users to earn 3X the amount of swap fees less borrowing costs. Users enter with and accrue yield in stable coins.
          </span>
        </div>
      </StyledDescriptionDetail>
      <StyledDescriptionDetail sm={isSm}>
        <StyledDescriptionTag sm={isSm}>
          <Tag variant={variants.BULL} />
        </StyledDescriptionTag>
        <div>
          <b>Bull Market Leveraged Farming</b>
          <Label> New! </Label>
          <span>
            is an auto rebalancing vault that allows users to earn 3X the amount of swap fees less borrowing costs. Users enter with and accrue yield in a volatile asset.
          </span>
        </div>

      </StyledDescriptionDetail>
      <span>
        <StyledA href='https://docs.aperture.finance/docs/aperture-originals/crab-market-leveraged-farming' target="_blank" >Click here</StyledA> 
        {" "}
        for more info on Crab Market and Bull Market Leveraged Farming.</span>
    </StyledDescription>
  );
};

export default StrategyDescription;
