import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonGroupsProps } from '.';
import { useMatchBreakpoints } from '../../contexts';
import { Button } from './Button';

const Container = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ sm }) => (sm ? '24px' : '16px')};
`;

export const ButtonGroups: React.FC<
  React.PropsWithChildren<ButtonGroupsProps>
> = ({
  input,
  defaultSelect = 0,
  onSelect,
  type = 'default',
  ...props
}: ButtonGroupsProps) => {
  const { isSm: mobile } = useMatchBreakpoints();
  const [selected, setSelected] = useState(input[defaultSelect].value);
  return (
    <Container sm={type === 'small' || mobile} {...props}>
      {input.map((item) => (
        <Button
          key={item.text}
          small={type === 'small' || mobile}
          selected={selected === item.value}
          onClick={(e: Event) => {
            e.stopPropagation();
            setSelected(item.value);
            onSelect(item.value);
          }}
        >
          {item.text}
        </Button>
      ))}
    </Container>
  );
};
