import { ethers } from 'ethers';
import { keys } from '../../keyNames';
import { getPosition } from './useFetchPosition';
import { Provider } from '@ethersproject/providers';
import { UnpopulatedPositionInfo } from '../../../types';

export const positionConfig = (
  strategyAbi: any,
  provider: Provider,
  positionInfo: UnpopulatedPositionInfo
) => ({
  queryKey: [keys.positions, positionInfo?.positionId],
  queryFn: () => getPosition(provider, strategyAbi, positionInfo),
});

export const extraPositionConfig = (
  strategyAbi: any,
  provider: Provider,
  positionInfo: UnpopulatedPositionInfo
) => ({
  queryKey: [keys.extraPositions, positionInfo?.positionId],
  queryFn: () => getPosition(provider, strategyAbi, positionInfo),
});
