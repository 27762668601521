import {
  Blockchain,
  IBlockchainInfo,
  BlockchainType,
  AvailableBlockchains,
  BlockchainTypeMap,
} from '../../constants/crosschain';
import IChainProvider from '../provider/IChainProvider';
import { EvmProvider, IEvmProvider } from '../provider/Evm/EvmProvider';
import { EvmTransactionProvider } from '../provider/transaction/Evm/EvmTransactionProvider';
import ITransactionProvider from '../provider/transaction/ITransactionProvider';
import { GetSelf, ISlice, IStore, SetSelf, SetStore, SliceCreator } from '..';
import { ChainSubscriptionKeys } from '../../constants/store';
import { WritableDraft } from 'immer/dist/internal';

export interface IChainSlice extends ISlice {
  chainProvider: IChainProvider | null;
  transactionProvider: ITransactionProvider | null;
  chain: Blockchain | null;
  walletChain: Blockchain | null;
  isWrongNetwork: boolean;

  chainId: string | null;
  chainInfo: IBlockchainInfo | null;
  chainType: BlockchainType | null;
  setChain: (chain: Blockchain | null) => void;
  setChainId: (chainId: string | null) => void;
  setChainType: (chainType: BlockchainType | null) => void;
  removeChain: () => void;
  isWalletConnected: boolean;
}

export const useChainSlice: SliceCreator<IChainSlice> = (
  set: SetStore<IStore>,
  get: () => IStore,
  api: any,
  setSelf: SetSelf<IChainSlice>,
  getSelf: GetSelf<IChainSlice>
) => {
  const getChainProvider = (blockchainType: BlockchainType): any => {
    switch (blockchainType) {
      case BlockchainType.EVM:
        const provider = EvmProvider(
          set,
          get,
          api,
          (fn: (state: WritableDraft<IEvmProvider>) => void) =>
            set((state) => {
              fn(state.chainSlice.chainProvider as WritableDraft<IEvmProvider>);
            }),
          () => getSelf().chainProvider as IEvmProvider
        );
        const transactionProvider = EvmTransactionProvider(set, get, api);
        return { provider, transactionProvider };
      default:
        throw new Error(
          `The blockchain type: ${blockchainType} is not supported yet.`
        );
    }
  };
  const _state = {};
  const state = {
    chain: null,
    chainProvider: null,
    chainInfo: null,
    chainId: null,
    chainType: null,
    isWalletConnected: false,
    transactionProvider: null,
    isWrongNetwork: false,
  };
  const actions = {
    setChain: (chain: Blockchain | null) => {
      if (chain !== null) {
        const type = BlockchainTypeMap[chain as keyof object];
        set((state: any) => {
          const draft = state.chainSlice;
          draft.chain = chain;
          draft.chainType = type;
          draft.chainInfo = AvailableBlockchains[chain];
        });
      }
    },
    setChainType: (chainType: BlockchainType | null) => {
      set((state: any) => {
        const draft = state.chainSlice;
        draft.chainType = chainType;
      });
    },
    setChainId: (chainId: string | null) => {
      set((state: any) => {
        const draft = state.chainSlice;
        draft.chainId = chainId;
      });
    },
    removeChain: () => {
      //Unsubscribe from chain provider.
      const removeSubs = (get() as IStore).removeSubscriptions;
      if (removeSubs) {
        removeSubs({ chainProvider: null });
      }
      set((state: any) => {
        const draft = state.chainSlice;
        // Reset state variables to null.
        Object.keys(draft).forEach((key: string) => {
          const type = typeof draft[key];
          if (type !== 'function') {
            if (type === 'boolean') {
              draft[key] = false;
            } else {
              draft[key] = null;
            }
          }
        });
      });
    },
  };

  const subscriptions = {
    initSubscription: () => ({
      [ChainSubscriptionKeys.CHAIN_PROVIDER]: api.subscribe(
        (state: any) => state.chainSlice.chainType,
        (chainType: BlockchainType) => {
          if (chainType !== null) {
            set((state: any) => {
              const draft = state.chainSlice;
              const { provider, transactionProvider } =
                getChainProvider(chainType);
              draft.chainProvider = provider;
              draft.transactionProvider = transactionProvider;
            });
            const unSubs = get().chainSlice.chainProvider!.initSubscribe();
            get().addSubscriptions({ chainProvider: unSubs });
          }
        },
        { fireImmediately: true }
      ),
      // [ChainSubscriptionKeys.WRONG_CHAIN]: api.subscribe(
      //   (state: any) => state.chainSlice,
      //   (chainSlice: IChainSlice) => {
      //     const {chain, walletChain} = chainSlice;
      //     setSelf((state) => {
      //       state.isWrongNetwork = Boolean(
      //         chain && walletChain && chain !== walletChain
      //       );
      //     });
      //   },
      //   { fireImmediately: true }
      // ),
    }),
  };

  const slice: IChainSlice = {
    ..._state,
    ...state,
    ...actions,
    ...subscriptions,
  };

  return slice;
};
