import React from 'react';
import { Box, NumberCounter } from '../../';
import './TvlBox.scss';
import { numberParse } from '../../../../helpers/utilities';
import { useMatchBreakpoints } from '@aperture/uikit';
export interface TvlBoxProps {
  isPrimary?: boolean;
  heading: string;
  amount: number | string;
  prefix?: string;
  decimals?: number;
  boxWrapper?: boolean;
  style?: any;
}

const Wrapper = ({
  children,
  style,
  boxWrapper,
  ...props
}: {
  children: JSX.Element;
  boxWrapper: boolean;
  style: any;
}) =>
  boxWrapper ? (
    <Box className="tvl-box" style={style}>
      {children}
    </Box>
  ) : (
    <div>{children}</div>
  );
export const TvlBox = ({
  isPrimary = false,
  prefix = '$',
  heading,
  amount,
  decimals = 0,
  boxWrapper = true,
  style,
  ...props
}: TvlBoxProps) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <Wrapper {...{ style, boxWrapper, ...props }}>
      <div className="portClass">
        <div
          className={`title ${isPrimary ? 'headline-5' : isSm ? 'subtitle-3' : 'headline-5'
            }`}
        >
          {heading}
        </div>
        <span
          className={
            isPrimary ? 'headline-1' : isSm ? 'headline-5' : 'headline-3'
          }
        >
          <NumberCounter
            value={amount}
            formatNumberFunction={(value: number | string) =>
              numberParse(value, 'currency')
            }
          />
        </span>
      </div>
    </Wrapper>
  );
};
