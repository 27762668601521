import { ColoredValue } from '@aperture/uikit';
import { FC } from 'react';
import './index.scss';

interface NumberCounterProps {
  value: number | string;
  colored?: boolean;
  formatNumberFunction?: (value: number | string) => number | string;
}

const toNumber = (val: number | string) => {
  let str = `${val}`;
  str = str.replace(',', '');
  str = str.replace(/[^\d,.-]/g, '');
  const float = parseFloat(str);
  return Number.isNaN(float) ? 0 : float;
};

export const NumberCounter: FC<NumberCounterProps> = ({
  value = 0,
  colored = false,
  formatNumberFunction = (value: any) => value,
}) => {
  return (
    <span className="number-counter">
      {value !== undefined && value !== null
        ? (
          colored ?
            (<ColoredValue value={Number(value)} >{formatNumberFunction(value)}</ColoredValue>) :
            formatNumberFunction(value)
        ) : '-'}
    </span >
  );
};
