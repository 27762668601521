import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ReturnProps } from '.';
import { Box, GraphBox } from '../../components/Box';
import { ButtonGroups } from '../../components/ButtonGroups';
import { LineChart } from '../../components/Chart/LineGraph';
import { YAxisTitle } from '../../components/Chart/YAxisTitle';
import { ColoredValue } from '../../components/ColoredValue';
import { Body1, Headline4, Headline5 } from '../../components/Typography';
import { useMatchBreakpoints } from '../../contexts';
import {
  calculate_timeframe_apy,
  getTimeIntervalText,
  numberParse,
  TimeframeType,
} from '../../helper';
import { ExpandedIcon } from '../Icon/ExpandedIcon';

const StyledButtonGroups = styled(ButtonGroups)<{ sm: boolean }>`
  ${({ sm }) =>
    sm
      ? `
      align-items: center;
      justify-content: center;`
      : `
      position: absolute;
      right: 32px;`}
`;
const H5Container = styled.div`
  margin-top: 8px;
`;
const StyledH5 = styled(Headline5)`
  margin-top: 8px;
  margin-right: 8px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.tableHeaderText};
`;
const StyledBody1 = styled(Body1)`
  margin-bottom: 8px;
  display: block;
  color: ${({ theme }) => theme.colors.tableHeaderText};
`;
const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;
const StyledBox = styled(Box)`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Return: React.FC<ReturnProps> = ({
  id,
  type = 'default',
  data = {
    [TimeframeType.DAY]: [],
    [TimeframeType.WEEK]: [],
    [TimeframeType.MONTH]: [],
    [TimeframeType.SEASON]: [],
    [TimeframeType.INCEPTION]: [],
  },
  payload,
  openStrategyDetails,
  ...props
}) => {
  const theme = useTheme();
  const { isSm: mobile } = useMatchBreakpoints();
  const [timeRage, setTimeRage] = useState<TimeframeType>(TimeframeType.WEEK);
  const [APY, setAPY] = useState<number | undefined>(
    calculate_timeframe_apy(data[timeRage])
  );

  const tooltipValue = [
    {
      color: theme.colors.graphActiveDot1,
      text: 'Return',
      value: 'percentage',
      suffix: '%',
      colorChanging: true,
    },
    {
      color: theme.colors.graphActiveDot2,
      text: `${payload.tokens[1]} Price`,
      value: 'price',
      suffix: ` ${payload.tokens[0]}`,
    },
    {
      color: theme.colors.graphDot,
      text: 'Rebalanced Point',
      value: 'price',
      type: 'rebalanced',
    },
  ];

  const tooltipValueMini = [
    {
      color: theme.colors.graphLine1,
      text: 'Return',
      value: 'percentage',
      suffix: '%',
      colorChanging: true,
    },
  ];

  const Content = (
    <GraphBox mini={type === 'mini'} {...props}>
      {!mobile && type === 'mini' && (
        <ExpandedIcon
          className="chart-expanded-icon"
          onClick={openStrategyDetails}
        />
      )}
      <StyledButtonGroups
        sm={mobile || type === 'mini'}
        defaultSelect={1}
        input={[
          { text: '1D', value: TimeframeType.DAY },
          { text: '1W', value: TimeframeType.WEEK },
          { text: '1M', value: TimeframeType.MONTH },
          { text: '3M', value: TimeframeType.SEASON },
          { text: 'ALL', value: TimeframeType.INCEPTION },
        ]}
        onSelect={(value: TimeframeType) => {
          setTimeRage(value);
          setAPY(calculate_timeframe_apy(data[value]));
        }}
        type={type === 'mini' ? 'small' : 'default'}
      />
      {type !== 'mini' && !mobile && (
        <TitleWrapper>
          <Headline4>Vault Return</Headline4>
          <H5Container>
            <StyledH5>Return:</StyledH5>
            <Headline5>
              <ColoredValue
                value={data[timeRage][data[timeRage].length - 1]?.percentage}
              >
                {numberParse(
                  data[timeRage][data[timeRage].length - 1]?.percentage,
                  'percentage'
                )}
              </ColoredValue>
            </Headline5>
          </H5Container>
          <StyledH5>
            APY Based on {getTimeIntervalText(timeRage, data[timeRage])} Return:{' '}
          </StyledH5>
          <ColoredValue value={APY}>
            {' '}
            {numberParse(APY ?? 0, 'percentage')}{' '}
          </ColoredValue>
        </TitleWrapper>
      )}

      {type !== 'mini' && !mobile && (
        <YAxisTitle
          left="Return(%)"
          right={`${payload.tokens[1]} Price (${payload.tokens[0]})`}
        />
      )}

      <LineChart
        id={id}
        data={data[timeRage]}
        referenceAmount={0}
        type={type === 'mini' || mobile ? 'mini' : 'full'}
        legend={type === 'mini' && !mobile ? false : true}
        payload={{ ...{ timeframe: timeRage }, ...payload }}
        tooltipInfo={{
          day: timeRage === TimeframeType.WEEK,
          hours: true,
          value: type === 'mini' && !mobile ? tooltipValueMini : tooltipValue,
        }}
        onClick={openStrategyDetails}
      />
    </GraphBox>
  );

  if (mobile) {
    return (
      <>
        <StyledBox>
          <div>
            <StyledBody1>Return:</StyledBody1>
            <Body1>
              <ColoredValue
                value={data[timeRage][data[timeRage].length - 1]?.percentage}
              >
                {numberParse(
                  data[timeRage][data[timeRage].length - 1]?.percentage,
                  'percentage'
                )}{' '}
              </ColoredValue>
              {getTimeIntervalText(timeRage, data[timeRage])}
            </Body1>
          </div>
          <div>
            <StyledBody1>APY:</StyledBody1>
            <Body1>
              <ColoredValue value={APY}>
                {' '}
                {numberParse(APY ?? 0, 'percentage')}{' '}
              </ColoredValue>
            </Body1>
          </div>
        </StyledBox>
        {Content}
      </>
    );
  } else {
    return <>{Content}</>;
  }
};
