import React from 'react';
import { useStore } from '../store';
import textContext from '../textContext';

export default function Privacy() {
  const language = useStore((state) => state.appSlice.language);
  const text = textContext[language].privacy;

  React.useEffect(() => {
    const scroller = document.getElementById('main-container');
    if (scroller) scroller.scrollTop = 0;
  }, []);

  return (
    <div className="textClass container">
      <div>
        <h1>{text.title}</h1>
        {text.body.map((txt, i) => (
          <div key={i}>
            {txt.type === 'p' ? (
              <p>{txt.text}</p>
            ) : txt.type === 'subtitle' ? (
              <p className="sub">{txt.text}</p>
            ) : txt.type === 'h2' ? (
              <h2>{txt.text}</h2>
            ) : txt.type === 'list' ? (
              <ul className="list">
                {txt.text.map((l, i) => (
                  <li key={i}>{l}</li>
                ))}
              </ul>
            ) : txt.type === 'bullets' ? (
              <ul className="bullets">
                {txt.text.map((l, i) => {
                  if (Object.keys(l)[0] === 'link') {
                    return (
                      <a
                        key={i}
                        target="_blank"
                        rel="noreferrer"
                        href={Object.values(l)}
                      >
                        {Object.values(l)}
                      </a>
                    );
                  } else {
                    return (
                      <li key={i}>
                        <span>{Object.keys(l)}</span> {Object.values(l)}
                      </li>
                    );
                  }
                })}
              </ul>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
