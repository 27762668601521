import { Strategy } from '.';
import { StrategyType } from '../crosschain';
import { ProtocolInfoMap } from '../protocolConfig';
import { ERC20TokenMap } from '../tokenConfig';

export const strategies: Strategy[] = [
  {
    strategyId: '0',
    strategyAddress: '0x39471BEe1bBe79F3BFA774b6832D6a530edDaC6B',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 11981,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['dai_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['tj']],
    poolKey: 'wchef-0xb41de9c1f50697cc3fd63f24ede2b40f6269cbcb-37',
    leverage: 3,
    hiddenFromNewUsers: true,
  },
  {
    strategyId: '1',
    strategyAddress: '0xFC7D93c51f1BCa3CEEbF71f30B4af3bf209115A0',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 11998,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdc_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['tj']],
    poolKey: 'wchef-0x8df47fc33df77ae0526cdac4a0ca89739ef9f1cc-3',
    leverage: 3,
    hiddenFromNewUsers: true,
  },
  {
    strategyId: '2',
    strategyAddress: '0x12D89E117141F061274692Ed43B774905433706A',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 11999,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdt_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-7',
    leverage: 3,
  },
  {
    strategyId: '3',
    strategyAddress: '0x0d93764B56e29045d4544F430b576AD2540c5c29',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12350,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdc_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-9',
    leverage: 3,
  },
  {
    strategyId: '4',
    strategyAddress: '0xA8b45dd1bbA7307f1D6e9Ac0eD3b84E2ABe5ce53',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12424,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['avax'], ERC20TokenMap['usdt_e']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-7',
    leverage: 3,
  },
  {
    strategyId: '5',
    strategyAddress: '0x6a3140FCf5e1485286E85EB30D9A9b8Bb7e7E3Ff',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12429,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['wbtc_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-5',
    leverage: 3,
    hiddenFromNewUsers: true,
  },
  {
    strategyId: '6',
    strategyAddress: '0x1f70aC92963bd1993fb94e221614232A8630120e',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12430,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['weth_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-8',
    leverage: 3,
  },
  {
    strategyId: '7',
    strategyAddress: '0xb2a32d8EDa45D77646E10bd2A27Af225D300DA3e',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12433,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['wbtc_e'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['tj']],
    poolKey: 'wchef-0xc32cb9d28b257ce286f4a1c01222171f55a6f7f9-5',
    leverage: 3,
    hiddenFromNewUsers: true,
  },
  {
    strategyId: '8',
    strategyAddress: '0xa9a35E68dCC4aCA341Ccc21377A0340B1Ea1d163',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12440,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdc'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-55',
    leverage: 3,
  },
  {
    strategyId: '9',
    strategyAddress: '0x10D82468E8a25CA75b708f042DeDdb609929082A',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12441,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdt'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-113',
    leverage: 3,
  },
  {
    strategyId: '10',
    strategyAddress: '0x5061FDc497f08D8DA2D070E82204Dec93f712114',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12436,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['avax'], ERC20TokenMap['usdc']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-55',
    leverage: 3,
  },
  {
    strategyId: '11',
    strategyAddress: '0x20e74DfCe4C9319505e5615352fA6f6480F6B83A',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12437,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['avax'], ERC20TokenMap['usdt']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-113',
    leverage: 3,
  },
  {
    strategyId: '12',
    strategyAddress: '0x02EA2d875F2759CBC2C9195eB76A450fe718E42A',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12662,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdc'], ERC20TokenMap['avax']],
    protocols: [ProtocolInfoMap['tj']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-115',
    leverage: 3,
    hiddenFromNewUsers: true,
  },
  {
    strategyId: '13',
    strategyAddress: '0xE84a478f867384E7573E9F48c98F3398a44cC07a',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12660,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['avax'], ERC20TokenMap['usdc']],
    protocols: [ProtocolInfoMap['tj']],
    poolKey: 'wchef-0xa67cf61b0b9bc39c6df04095a118e53bfb9303c7-115',
    leverage: 3,
    hiddenFromNewUsers: true,
  },
  {
    strategyId: '14',
    strategyAddress: '0xf2bBAf4Bb188a63d31622378059b4B39C48C3Ae0',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12663,
    strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    tokens: [ERC20TokenMap['usdc'], ERC20TokenMap['wbtc_e']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xab80758cec0a69a49ed1c9b3f114cf98118643f0-0',
    leverage: 3,
  },
  {
    strategyId: '15',
    strategyAddress: '0x961264E543eC4a79c62Fb690F6E4FCA2f136dC28',
    oracleAddress: '0x5196e0a4fb2A459856e1D41Ab4975316BbdF19F8',
    strategyChainId: 6,
    homoraPosId: 12661,
    strategyType: StrategyType.PSEUDO_1X_LONG,
    tokens: [ERC20TokenMap['wbtc_e'], ERC20TokenMap['usdc']],
    protocols: [ProtocolInfoMap['pangolin']],
    poolKey: 'wchef-0xab80758cec0a69a49ed1c9b3f114cf98118643f0-0',
    leverage: 3,
    hiddenFromNewUsers: false,
  },
];

export const strategiesMap: { [strategyId: string]: Strategy } =
  strategies.reduce((acc, curr) => ({ ...acc, [curr.strategyId]: curr }), {});
