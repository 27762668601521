import React from 'react';
import { ColoredValue } from '../../../components/ColoredValue';
import { Loader } from '../../../components/Loader';
import { Tag } from '../../../components/Tag';
import { Subtitle2 } from '../../../components/Typography';
import { numberParse } from '../../../helper';
import {
  StyledProgressBar,
  StyledTVLCapacity,
  TableRow,
} from '../CardInfo/SharedComponents';
import { PDNPropsWeb } from './types';
const StrategyCard: React.FC<PDNPropsWeb> = ({
  ratio,
  vaultItem,
  strategyType,
  tvlCapacity,
  apy,
  buttonGroup,
}) => {
  const apyData = [apy.apy, apy.apy_7days, apy.apy_season];
  return (
    <TableRow ratio={ratio}>
      <div>{vaultItem}</div>
      <div>
        {strategyType === 'crab' ? (
          <Tag variant={'crab'} />
        ) : (
          <Tag variant={'bull'} />
        )}
      </div>
      <StyledTVLCapacity>
        {tvlCapacity ? (
          <>
            <Subtitle2>{tvlCapacity.content}</Subtitle2>
            <StyledProgressBar percentage={tvlCapacity.percentage} />
          </>
        ) : (
          <Loader size="30%" />
        )}
      </StyledTVLCapacity>
      {apyData.map((item, index) => (
        <Subtitle2 key={index}>
          {item === undefined ? (
            <Loader />
          ) : (
            <ColoredValue value={item}>
              {numberParse(item, 'percentage')}
            </ColoredValue>
          )}
        </Subtitle2>
      ))}
      <div>{buttonGroup}</div>
    </TableRow>
  );
};

export default StrategyCard;
