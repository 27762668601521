import { SvgProps } from '..';

export const GrowIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_grow_clip0)`}>
        <g clipPath={`url(#${props.id}_grow_clip1)`}>
          <path
            d="M0.416718 11.5459H2.08328C2.31339 11.5459 2.5 11.7572 2.5 12.018V16.7403C2.5 17.0011 2.31339 17.2126 2.08328 17.2126H0.416718C0.186615 17.2126 0 17.0011 0 16.7403V12.018C0 11.7572 0.186615 11.5459 0.416718 11.5459Z"
            fill="#B8BBC6"
          />
          <path
            d="M5.13922 7.76758H6.80594C7.03604 7.76758 7.22266 7.97908 7.22266 8.23986V16.7399C7.22266 17.0006 7.03604 17.2121 6.80594 17.2121H5.13922C4.90912 17.2121 4.72266 17.0006 4.72266 16.7399V8.23986C4.72266 7.97908 4.90912 7.76758 5.13922 7.76758Z"
            fill="#B8BBC6"
          />
          <path
            d="M9.86105 9.65723H11.5278C11.7579 9.65723 11.9443 9.86872 11.9443 10.1295V16.7406C11.9443 17.0013 11.7579 17.2128 11.5278 17.2128H9.86105C9.63095 17.2128 9.44434 17.0013 9.44434 16.7406V10.1295C9.44434 9.86872 9.63095 9.65723 9.86105 9.65723Z"
            fill="#B8BBC6"
          />
          <path
            d="M14.5837 6.82324H16.2503C16.4804 6.82324 16.667 7.03474 16.667 7.29552V16.7399C16.667 17.0007 16.4804 17.2122 16.2503 17.2122H14.5837C14.3536 17.2122 14.167 17.0007 14.167 16.7399V7.29552C14.167 7.03474 14.3536 6.82324 14.5837 6.82324Z"
            fill="#B8BBC6"
          />
          <circle cx="1.25" cy="8.40723" r="1.25" fill="#B8BBC6" />
          <path
            d="M7.5 4.40723C7.5 5.09758 6.94036 5.65723 6.25 5.65723C5.55964 5.65723 5 5.09758 5 4.40723C5 3.71687 5.55964 3.15723 6.25 3.15723C6.94036 3.15723 7.5 3.71687 7.5 4.40723Z"
            fill="#B8BBC6"
          />
          <circle cx="10.25" cy="6.40723" r="1.25" fill="#B8BBC6" />
          <circle cx="15.25" cy="2.40723" r="1.25" fill="#B8BBC6" />
          <line
            x1="0.687652"
            y1="8.76679"
            x2="5.68765"
            y2="4.76679"
            stroke="#B8BBC6"
          />
          <line
            x1="10.6877"
            y1="5.76679"
            x2="15.6877"
            y2="1.76679"
            stroke="#B8BBC6"
          />
          <line
            x1="6.22361"
            y1="4.71001"
            x2="10.2236"
            y2="6.71001"
            stroke="#B8BBC6"
          />
        </g>
      </g>
      <defs>
        <clipPath id={`${props.id}_grow_clip0`}>
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 0.657227)"
          />
        </clipPath>
        <clipPath id={`${props.id}_grow_clip1`}>
          <rect
            width="17"
            height="16"
            fill="white"
            transform="translate(0 1.15723)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
