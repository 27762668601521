import React from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { MobileDrawerProps } from './types';

const CardContainer = styled.div`
  flex-direction: column;
  align-items: stretch;
  padding: 24px 16px;
  gap: 32px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.backgroundCardContainer};

  &>div:not(:first-child) {
    display: flex;
    padding: 16px 0 0 0;
  }
`;
const StyledDrawer = styled(Drawer)`
  .MuiPaper-root.MuiDrawer-paper {
    top: 98px;
    width: 100%;
    height: auto;
    border-radius: 24px 24px 0 0;
    background: ${({ theme }) => theme.colors.backgroundStrategyContainer};
  }
`;
const DrawerContent = styled.div`
  margin: 24px;
`;

const MobileDrawer: React.FC<MobileDrawerProps> = ({
  title,
  details,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <CardContainer onClick={handleOpen}>
        {title}
      </CardContainer>
      <StyledDrawer
        anchor={'bottom'}
        open={open}
        onClose={handleClose}
      >
        <DrawerContent>{details}</DrawerContent>
      </StyledDrawer>
    </>
  );
};

export default MobileDrawer;
