import { Grid } from '@mui/material';
import { TransactionModal } from '..';
import { ConfirmationSpinner } from '../../../Components/Spinner';
import { MobilePopOver } from '../../../Modal/MobilePopOver';
import { ModalProps } from '../../../../layout/ModalsContainer';
import { useMatchBreakpoints } from '@aperture/uikit';

export interface ConfirmationProps {
  amount: number;
  isInvest?: boolean;
  currency?: string;
}
export const ConfirmationModal = (props: ConfirmationProps & ModalProps) => {
  const { isSm } = useMatchBreakpoints();

  const Content = () => (
    <Grid
      container
      sx={{
        textAlign: 'center',
        padding: isSm ? '8px 0px' : '0px',
        gap: isSm ? '24px' : '56px',
      }}
    >
      <Grid item md={12} sm={12} xs={12}>
        <ConfirmationSpinner />
        <div
          style={{ marginTop: isSm ? '24px' : '36px' }}
          className={isSm ? 'headline-2 modal-text' : 'headline-3 modal-text'}
        >
          {' '}
          Waiting for Confirmation
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div
          className={
            isSm
              ? 'subtitle-2 regular modal-text'
              : 'headline-4 regular modal-text'
          }
        >
          {props.isInvest ? 'Depositing' : 'Withdrawing'} {props.amount}{' '}
          {props.currency ?? 'USDC'}
        </div>
        <div
          className="headline-5 medium modal-subtext"
          style={{ marginTop: isSm ? '8px' : '16px' }}
        >
          Confirm this transaction in your wallet{' '}
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      {!isSm ? (
        <TransactionModal
          onClose={props.onClose}
          modalClassName="modal-confirmation"
        >
          <Content />
        </TransactionModal>
      ) : (
        <MobilePopOver
          open={true}
          onClose={props.onClose}
          className="transaction-pop-over"
        >
          <Content />
        </MobilePopOver>
      )}
    </>
  );
};
