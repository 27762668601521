import styled from 'styled-components';
import { ProgressBar } from '../../../components/ProgressBar';
import { Subtitle3 } from '../../../components/Typography';

export const StyledInfoDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledInfoTitle = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.textCardDetail}!important;
`;

export const TableRow = styled.div<{ ratio: string }>`
  display: grid;
  grid-template-columns: ${({ ratio }) => `${ratio}`};
  grid-column-gap: auto;
  justify-items: start;
  > div {
    width: 100%;
  }
  > span {
    width: 100%;
  }
  width: 100%;
  align-items: center;
`;
export const StyledTVLCapacity = styled.div<{ sm?: boolean }>`
  display: flex;
  flex-direction: ${({ sm }) => (sm ? 'row' : 'column')};
  align-items: ${({ sm }) => (sm ? 'center' : 'flex-start')};
  gap: 8px;
`;
export const StyledProgressBar = styled(ProgressBar)`
  > div {
    padding-top: 0px !important;
  }
`;
export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-right: 16px;
`;
