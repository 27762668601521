import React from 'react';
import styled from 'styled-components';
import { Subtitle1 } from '../../../../components/Typography';
import { useMatchBreakpoints } from '../../../../contexts';
import { numberParse } from '../../../../helper';
import { VaultInfoProps } from './types';

const StyledContainer = styled.div<{ sm: boolean }>`
  display: grid;
  grid-template-columns: ${({ sm }) => (sm ? '1fr' : '9.5fr 2.5fr')};
  gap: 60px;
  align-items: flex-start;
  padding: ${({ sm }) => (sm ? '0px 0px 16px' : '16px 32px')};
`;
const StyledItemContainer = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: ${({ sm }) => (sm ? 'column' : 'row')};
  width: 100%;
  align-items: flex-start;
  padding: 0;
  gap: ${({ sm }) => (sm ? '12px' : '88px')};
  color: ${({ theme }) => theme.colors.text};
`;
const StyledSubContainer = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${({ sm }) => (sm ? '12px' : '8px')};
  width: 100%;
`;
const StyledInfoDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfoTitle = styled.span<{ sm: boolean }>`
  font-weight: 400;
  font-size: ${({ sm }) => (sm ? '12px' : '14px')};
  line-height: ${({ sm }) => (sm ? '12px' : '21px')};
`;

const StyledInfoData = styled(Subtitle1)`
  font-weight: 500;
  text-align: right;
`;
const StyledViewDetails = styled.button`
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 110px;
  // min-width: 130px;
  height: 40px;
  padding: 8px;
  margin: auto auto 0 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.buttonInfo};
  border: 1.5px solid ${({ theme }) => theme.colors.buttonInfo};
  filter: drop-shadow(0px 5px 18px rgba(4, 4, 21, 0.1));
  border-radius: 8px;
  background-color: transparent;
  :hover {
    background: ${({ theme }) => theme.colors.buttonInfo};
    border: 1.5px solid ${({ theme }) => theme.colors.buttonInfo};
    color: ${({ theme }) => theme.colors.buttonInfoHover};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
`;
const VaultInfo: React.FC<VaultInfoProps> = ({ strategy, viewDetails }) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledContainer sm={isSm}>
      <StyledItemContainer sm={isSm}>
        <StyledSubContainer sm={isSm}>
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              Target Leverage{isSm ? '' : ': '}
            </StyledInfoTitle>
            <StyledInfoData>{strategy.leverage}X</StyledInfoData>
          </StyledInfoDetail>
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              Vault Inception Date{isSm ? '' : ': '}
            </StyledInfoTitle>
            <StyledInfoData>{strategy.inceptionDate}</StyledInfoData>
          </StyledInfoDetail>
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              Return Since Vault Inception{isSm ? '' : ': '}
            </StyledInfoTitle>
            <StyledInfoData>
              {numberParse(strategy.returnChanges, 'percentage')}
            </StyledInfoData>
          </StyledInfoDetail>
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              Price Movement Rebalance Threshold{isSm ? '' : ': '}
            </StyledInfoTitle>
            <StyledInfoData>
              {numberParse(strategy.threshold, 'percentage')}
            </StyledInfoData>
          </StyledInfoDetail>
        </StyledSubContainer>
        <StyledSubContainer sm={isSm}>
          {/* <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              Sharpe Ratio{isSm ? '' : ': '}
            </StyledInfoTitle>
            <StyledInfoData>{strategy.sharpRatio}</StyledInfoData>
          </StyledInfoDetail> */}
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              {isSm ? 'Maximum Drawdown' : 'Max. Drawdown: '}
            </StyledInfoTitle>
            <StyledInfoData>
              {numberParse(strategy.maxDrawdown, 'percentage')}
            </StyledInfoData>
          </StyledInfoDetail>
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              {isSm ? 'Number of Rebalances' : '# of Rebalances: '}
            </StyledInfoTitle>
            <StyledInfoData>{strategy.rebalances}</StyledInfoData>
          </StyledInfoDetail>
          <StyledInfoDetail>
            <StyledInfoTitle sm={isSm}>
              Underlying Pool{isSm ? '' : ': '}
            </StyledInfoTitle>
            <StyledInfoData>{strategy.underlyingPool}</StyledInfoData>
          </StyledInfoDetail>
        </StyledSubContainer>
      </StyledItemContainer>
      {!isSm && (
        <StyledViewDetails onClick={viewDetails}>
          View Details
        </StyledViewDetails>
      )}
    </StyledContainer>
  );
};

export default VaultInfo;
