export const TimeStamp = {
  second: 1,
  minute: 60,
  hour: 3600,
  day: 86400,
  week: 604800,
};

export const getDayInToday = (timestamp_sec: number) => {
  const date = new Date(timestamp_sec * 1000).toLocaleDateString();
  const now = new Date().toLocaleDateString();
  return date === now ? 'Today' : 'Yesterday';
};

export const getDayInString = (timestamp_sec: number, full?: boolean) => {
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const date = new Date(timestamp_sec * 1000);
  return full ? weekday[date.getDay()] : weekday[date.getDay()].slice(0, 3);
};

// this will return in format mm/dd/yyyy
export const getDate = (timestamp_sec: number) => {
  const date = new Date(timestamp_sec * 1000);
  return date.toLocaleDateString('en-us');
};

export const getHours = (timestamp_sec: number) => {
  const date = new Date(timestamp_sec * 1000);
  return date.toLocaleTimeString('en-us');
};

export const timestampToUTCString = (timestamp: number, type?: string) => {
  const date = new Date(timestamp * 1e3).toUTCString().split(' ');
  switch (type) {
    case 'MonthDay':
      return `${date[2]} ${date[1]}`; //Jun 7
    case 'MonthYear':
      return `${date[2]} ${date[3]}`; //Jun 2022
    case 'YearMonth':
      return `${date[3]} ${date[2]}`;
    case 'DayMonthYear':
      return `${date[1]} ${date[2]} ${date[3]}`;
    case 'MonthDayYear':
      return `${date[2]} ${date[1]}, ${date[3]}`;
    default:
      return date.toLocaleString(); //Wed, 19 Oct 2022 21:30:00 GMT
  }
};
