import { DEFAULT_TXN_DISMISS_MS } from '../../../constants/misc';
import { useCallback, useMemo } from 'react';
import { useStore } from '../../../store';
import { ModalType, TxPopupContent } from '../AppSlice';
import { IStore } from '../../index';
import { Percent } from '@uniswap/sdk-core';
import JSBI from 'jsbi';

export function useModalIsOpen(modal: ModalType): boolean {
  const openModal = useStore((state: IStore) => state.appSlice.openModal);
  return openModal?.modal === modal;
}

export function useModalProps(modal: ModalType): object {
  const openModal = useStore((state: IStore) => state.appSlice.openModal);
  return openModal?.modal === modal ? openModal.modalProps ?? {} : {};
}

// export function useToggleModal(modal: ModalType, modalProps?: object): () => void {
//   const isOpen = useModalIsOpen(modal);
//   const { setOpenModal } = useStore((state: IStore) => state.appSlice);
//   return useCallback(
//     () => setOpenModal(isOpen ? null : modal),
//     [setOpenModal, isOpen, modal]
//   );
// }

export function useOpenModal(
  modal: ModalType,
  modalProps?: object
): () => void {
  const setOpenModal = useStore((state: IStore) => state.appSlice.setOpenModal);

  return useCallback(
    () => setOpenModal(modal, modalProps),
    [modal, modalProps, setOpenModal]
  );
}

export function useCurrentOpenModal() {
  const openModal = useStore((state: IStore) => state.appSlice.openModal);
  return useMemo(() => openModal, [openModal]);
}

export function useCloseModal(): () => void {
  const setOpenModal = useStore((state: IStore) => state.appSlice.setOpenModal);
  return useCallback(() => setOpenModal(null, undefined), [setOpenModal]);
}

// export function useToggleInvestModal(): () => void {
//   return useToggleModal(ModalType.INVEST);
// }

// returns a function that allows adding a popup
export function useAddPopup(): (
  content: TxPopupContent,
  key?: string,
  removeAfterMs?: number
) => void {
  const addPopup = useStore((state: IStore) => state.appSlice.addPopup);

  return useCallback(
    (content: TxPopupContent, key?: string, removeAfterMs?: number) => {
      addPopup(content, key ?? null, removeAfterMs ?? DEFAULT_TXN_DISMISS_MS);
    },
    [addPopup]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const removePopup = useStore((state: IStore) => state.appSlice.removePopup);

  return useCallback(
    (key: string) => {
      removePopup(key);
    },
    [removePopup]
  );
}

// get the list of active popups
export function useActivePopups() {
  const popupList = useStore((state: IStore) => state.appSlice.popupList);
  return useMemo(() => popupList.filter((item) => item.show), [popupList]);
}

export function useSetSlippageTolerance(): (
  slippageTolerance: Percent | 'auto'
) => void {
  const updateSlippageTolerance = useStore(
    (state: IStore) => state.appSlice.updateSlippageTolerance
  );
  return useCallback(
    (slippageTolerance: Percent | 'auto') => {
      let value: 'auto' | number;
      try {
        value =
          slippageTolerance === 'auto'
            ? 'auto'
            : JSBI.toNumber(slippageTolerance.multiply(10_000).quotient);
      } catch (error) {
        value = 'auto';
      }

      updateSlippageTolerance(value);
    },
    [updateSlippageTolerance]
  );
}

/**
 * Return the slippage tolerance, from the zustand store, and a function to update the slippage tolerance
 */
export function useSlippageTolerance(): Percent | 'auto' {
  const userSlippageTolerance = useStore((state) => {
    const walletAddress = state.chainSlice.chainProvider?.walletAddress;
    if (!walletAddress) return 'auto';
    if (!state.appSlice.slippageTolerance[walletAddress]) {
      const updateSlippageTolerance = state.appSlice.updateSlippageTolerance;
      updateSlippageTolerance('auto');
      return 'auto';
    }
    return state.appSlice.slippageTolerance[walletAddress];
  });

  return useMemo(
    () =>
      userSlippageTolerance === 'auto'
        ? 'auto'
        : new Percent(userSlippageTolerance, 10_000),
    [userSlippageTolerance]
  );
}

/**
 * Same as above but replaces the auto with a default value
 * @param defaultSlippageTolerance the default value to replace auto with
 */
export function useSlippageToleranceWithDefault(
  defaultSlippageTolerance: Percent
): Percent {
  const allowedSlippage = useSlippageTolerance();
  return useMemo(
    () =>
      allowedSlippage === 'auto' ? defaultSlippageTolerance : allowedSlippage,
    [allowedSlippage, defaultSlippageTolerance]
  );
}

export function useTransactionTTL(): [number, (deadline: number) => void] {
  const deadline = useStore((state) => state.appSlice.deadline);
  const updateDeadline = useStore((state) => state.appSlice.updateDeadline);

  const setUserDeadline = useCallback(
    (deadline: number) => {
      updateDeadline(deadline);
    },
    [updateDeadline]
  );

  return [deadline, setUserDeadline];
}

export function useUpgradeStore(): () => void {
  const upgradeStore = useStore((state) => state.appSlice.upgradeStore);
  return useCallback(() => upgradeStore(), [upgradeStore]);
}
