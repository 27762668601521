import { Navbar } from '../../Navbar';
import { TopBar } from '../../TopBar';
import { forwardRef, PropsWithChildren } from 'react';
import { MobileNavMenu } from '../../Navbar/MobileNavMenu';
import { Blockchain, BlockchainType } from '../../../../constants/crosschain';
import { useMatchBreakpoints } from '@aperture/uikit';
import RetirementBanner from '../../../../components/RetirementBanner';

interface NavContainerProps {
  walletConnected: boolean;
  balance: number;
  address: string;
  explorerURL: string;
  disconnectFunc: () => void;
  transactionsHistory: any;
  walletIcon: string;
  onChainSelect: (chainId: any) => void;
  onWalletSelect: (walletType: any) => void;
  chain: Blockchain;
  chainType: BlockchainType;
  clearAllTransactions: () => void;
}

export const NavContainer = forwardRef<
  NavContainerProps,
  PropsWithChildren<any>
>(
  (
    {
      walletConnected,
      balance,
      address,
      explorerURL,
      disconnectFunc,
      transactionsHistory,
      walletIcon,
      onChainSelect,
      onWalletSelect,
      chain,
      chainType,
      clearAllTransactions,
      children,
      ...props
    },
    ref
  ) => {
    const { isSm } = useMatchBreakpoints();
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        {!isSm && <Navbar mobile={isSm} active {...props} />}
        <RetirementBanner/>
        <TopBar
          {...props}
          mobile={isSm}
          walletConnected={walletConnected}
          balance={balance}
          address={address}
          explorerURL={explorerURL}
          disconnectFunc={disconnectFunc}
          transactionsHistory={transactionsHistory}
          walletIcon={walletIcon}
          onChainSelect={onChainSelect}
          onWalletSelect={onWalletSelect}
          onSelect={onChainSelect}
          chain={chain}
          chainType={chainType}
          clearAllTransactions={clearAllTransactions}
          {...props}
        >
          {children}
        </TopBar>
        {isSm && <MobileNavMenu />}
      </div>
    );
  }
);
