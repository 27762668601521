import { TokenInfo } from '../../../constants/tokenConfig';
import { Provider } from '@ethersproject/providers';
import { keys } from '../../keyNames';
import { getToken } from '../api';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';

export const tokensConfig = (
  token: TokenInfo,
  walletAddress: string | null,
  evmManagerAddress: string | null,
  provider: Provider | null,
  evmProvider: IEvmProvider | null
) => ({
  queryKey: [keys.tokens, token.tokenContractAddress],
  queryFn: () =>
    getToken(token, walletAddress, evmManagerAddress, provider, evmProvider),
});
