//this is a hard coded one, need some improvement with finalized button
import {
  forwardRef,
  ButtonHTMLAttributes,
  useRef,
  useImperativeHandle,
} from 'react';
import '../button.scss';
import styled from 'styled-components';
import { OpenLinkIcon } from '@aperture/assetkit';

interface ButtonProps {
  mobile: boolean;
  address?: any;
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps { }

const Open = styled(OpenLinkIcon)`
  display: flex;
  margin-right: 5px;
  path { fill: ${({ theme }) => (theme.colors.text)}; }
`;

export const ViewOnExplorer = forwardRef<HTMLButtonElement, Props>(
  ({ mobile, address, ...props }, ref) => {
    const domRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    return (
      <button
        ref={domRef}
        {...props}
        onClick={() => {
          address && window.open(address, '_blank');
        }}
        className="btn-outline-white btn"
      >
        <Open />
        <div className={mobile ? 'button-5' : 'button-6'}>View on explorer</div>
      </button>
    );
  }
);
