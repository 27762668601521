import styled from 'styled-components';

const Icon = (props: any) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity="0.01"
      x="11"
      y="11"
      width="11"
      height="11"
      transform="rotate(180 11 11)"
      fill="white"
    />
    <path
      d="M5.50061 6.5865L1.85985 2.95179C1.59034 2.68274 1.15913 2.68274 0.889629 2.95179C0.620124 3.22085 0.620124 3.65134 0.889629 3.92039L4.9175 7.94156C4.942 7.9807 4.9714 8.01494 5.0057 8.04919C5.14291 8.18616 5.31931 8.25465 5.50061 8.24975C5.67702 8.24975 5.85832 8.18616 5.99552 8.04919C6.02982 8.01494 6.05922 7.9807 6.08372 7.94156L10.1116 3.92039C10.2439 3.78831 10.3125 3.6122 10.3125 3.43609C10.3125 3.25998 10.2439 3.08387 10.1116 2.95179C9.84209 2.68274 9.41088 2.68274 9.14138 2.95179L5.50061 6.5865Z"
      fill="white"
    />
  </svg>
);

const StyledKeyboardArrowDown = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

export const KeyboardArrowDownIcon = (props: any) => (
  <StyledKeyboardArrowDown {...props} />
);
