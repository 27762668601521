
import { timestampToUTCString } from '.';

export enum TimeframeType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  SEASON = 'season',
  INCEPTION = 'inception',
}

export const TimeframeDays = {
  [TimeframeType.DAY]: 1,
  [TimeframeType.WEEK]: 7,
  [TimeframeType.MONTH]: 30,
  [TimeframeType.SEASON]: 90,
  [TimeframeType.INCEPTION]: 365,
};

export const TimeframeText = {
  [TimeframeType.DAY]: 'Past 1 Day',
  [TimeframeType.WEEK]: 'Past 1 Week',
  [TimeframeType.MONTH]: 'Past 1 Month',
  [TimeframeType.SEASON]: 'Past 3 Months',
  [TimeframeType.INCEPTION]: 'All Time',
};

export const getTimeIntervalText = (timeRage: TimeframeType, data: any[]) => {
  return timeRage === TimeframeType.INCEPTION
    ? data && data.length > 0
      ? `since ${timestampToUTCString(data[0].timestamp_sec, 'MonthDayYear')}`
      : TimeframeText[timeRage]
    : TimeframeText[timeRage];
};

export const calculate_timeframe_apy = (data: any[]) => {
  if (data && data.length > 0) {
    const returns = data[data.length - 1].percentage;
    const days = getDayCounts(data[data.length - 1].timestamp_sec - data[0].timestamp_sec);
    return Math.exp(Math.log(returns + 1) / (days / 365)) - 1;
  }
  return undefined;
};

export const getDayCounts = (timestamp_difference: number) => {
  return timestamp_difference / 86400
}
