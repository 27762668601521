import { useQuery } from 'react-query';
import { UnpopulatedPositionInfoMap } from '../../../constants/position';
import { getPositions } from '../../../helpers/crosschain';
import { IStore, useStore } from '../../../store';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';
import { UnpopulatedPositionInfo } from '../../../types';
import { keys } from '../../keyNames';

// TODO: We only need to fetch position info for the first time and after each deposit/withdrawal subsequently.
export function useFetchEvmPositionInfo(options?: any) {
  const evmProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  );
  const multicallProvider = evmProvider?._multicallProvider;
  const evmManager = evmProvider?._evmManager;
  const address = evmProvider?.walletAddress;

  const shouldFetch = !!(multicallProvider && evmManager && address);
  return useQuery(
    [keys.positionInfo],
    async () => {
      if (!multicallProvider || !evmManager || !address) return null;
      const positions: UnpopulatedPositionInfo[] = await getPositions(
        evmManager.connect(multicallProvider),
        address
      );
      // Temp fix to keep track of incorrectly opened extra positions.
      const extras: UnpopulatedPositionInfo[] = [];
      const unpopulatedPositionInfoMap = positions.reduce(
        (acc: UnpopulatedPositionInfoMap, position) => {
          const exists = acc[position.strategyId] !== undefined;
          if (exists) {
            extras.push(position);
          }
          return exists ? acc : { ...acc, [position.strategyId]: position };
        },
        {}
      ) as UnpopulatedPositionInfoMap;
      return { unpopulatedPositionInfoMap, extras };
    },
    {
      ...options,
      enabled: shouldFetch,
      // staleTime: 60000,
    }
  );
}
