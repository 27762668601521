import { SettingIcon, WarningIcon } from '@aperture/assetkit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { Percent } from '@uniswap/sdk-core';
import ms from 'ms.macro';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components/macro';
import { DEFAULT_SLIPPAGE } from '../../../../constants/crosschain';
import { StrategyInfo } from '../../../../constants/strategy';
import { slippageToNumber } from '../../../../helpers/utilities';
import {
  useSetSlippageTolerance,
  useSlippageTolerance,
} from '../../../../store/base/application/hooks';
import { Button } from '../../Components/Button';
import { Dropdown } from '../../Components/Dropdown';
import './Setting.scss';
import { useMatchBreakpoints, QuestionMarkTooltip } from '@aperture/uikit';

enum SlippageError {
  InvalidInput = 'InvalidInput',
}

const SettingsButton = styled('div')`
  background: transparent;
  border: 0;
  @media (max-width: 1199px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledSettingIcon = styled(SettingIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.text};
  }
`;

interface TransactionSettingsProps {
  mobile: boolean;
  strategyInfo: StrategyInfo;
  placeholderSlippage: Percent; // varies according to the context in which the settings dialog is placed
}

const THREE_DAYS_IN_SECONDS = ms`3 days` / 1000;

export default function TransactionSettings({
  mobile,
  strategyInfo,
  placeholderSlippage,
}: TransactionSettingsProps) {
  const { isSm } = useMatchBreakpoints();
  const stableCoin = strategyInfo.tokens[0].ticker;
  const token = strategyInfo.tokens[1].ticker;
  const userSlippageTolerance = useSlippageTolerance();
  const setUserSlippageTolerance = useSetSlippageTolerance();

  const [slippageInput, setSlippageInput] = useState('');
  const [slippageError, setSlippageError] = useState<SlippageError | false>(
    false
  );

  const [tooLow, setTooLow] = useState(false);
  const [tooHigh, setTooHigh] = useState(false);

  function parseSlippageInput(value: string) {
    // populate what the user typed and clear the error
    setSlippageError(false);
    setTooLow(false);
    setTooHigh(false);

    if (value.length === 0) {
      setUserSlippageTolerance('auto');
    } else {
      const parsed = Math.floor(Number.parseFloat(value) * 100);
      if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
        setUserSlippageTolerance('auto');
        if (value !== '.') {
          setSlippageError(SlippageError.InvalidInput);
        }
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000));
      }

      setTooLow(value !== 'auto' && Number.parseFloat(value) <= 0.1);
      setTooHigh(value !== 'auto' && Number.parseFloat(value) >= 3);
    }
  }

  const SettingButton = (props: any) => (
    <SettingsButton onClick={props.onClick}>
      {<StyledSettingIcon />}
    </SettingsButton>
  );

  return (
    <>
      <Dropdown
        popClassName="pop-transaction-setting"
        direction={{
          anchorOrigin_vertical: isSm ? 'bottom' : 'top',
          anchorOrigin_horizontal: 'left',
          transformOrigin_vertical: 'top',
          transformOrigin_horizontal: 'right',
        }}
        trigger={<SettingButton />}
      >
        <div className="settingModal">
          <h5 className="title">transaction settings</h5>
          <div className="settingContainer">
            <Grid container className="info-grid">
              <Grid item>
                <InfoOutlinedIcon fontSize="small" />
              </Grid>
              <Grid item xs>
                <p>
                  This strategy may involve one or more swaps to open the
                  position. Set your desired slippage tolerance below.
                </p>
              </Grid>
            </Grid>
            <Grid container className="value-setting" item>
              <Grid item gap="sm" display={'flex'} alignItems={'center'}>
                <p className="subtitle-2">Slippage tolerance</p>
                <QuestionMarkTooltip
                  title={`The amount of ${stableCoin} received is subject to the liquidity of ${stableCoin} and ${token}. The default slippage tolerance is set to ${slippageToNumber(DEFAULT_SLIPPAGE) * 100
                    }%.`}
                  placementPC={'bottom'}
                  placementMobile={'bottom'}
                  toolTipClassName={'tolerance-tooltip'}
                />
              </Grid>
              <Grid container item gap="sm" className="input-grid">
                <Grid
                  item
                  className={`number-input${slippageError || tooLow || tooHigh ? ' errorColor' : ''
                    }`}
                >
                  <NumberFormat
                    value={slippageInput}
                    onChange={(e) => setSlippageInput(e.target.value)}
                    customInput={InputBase}
                    type="text"
                    decimalScale={2}
                    placeholder={`${slippageToNumber(
                      userSlippageTolerance === 'auto'
                        ? placeholderSlippage
                        : userSlippageTolerance
                    ) * 100
                      }%`}
                    suffix="%"
                    allowNegative={false}
                    onBlur={() => parseSlippageInput(slippageInput)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    text={'AUTO'}
                    onClick={() => {
                      setSlippageInput('');
                      parseSlippageInput('');
                    }}
                    style={{ width: isSm ? '65px' : '88px' }}
                  ></Button>
                </Grid>
                {slippageError || tooLow || tooHigh ? (
                  <Grid item>
                    <div className="error-view">
                      <WarningIcon style={{ paddingRight: '6px' }} />
                      {slippageError ? (
                        <span className="body-1">
                          Enter a valid slippage percentage
                        </span>
                      ) : tooLow ? (
                        <span className="body-1">
                          Your transaction may fail
                        </span>
                      ) : (
                        <span className="body-1">
                          Your transaction may be frontrun
                        </span>
                      )}
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </div>
      </Dropdown>
    </>
  );
}
