import { forwardRef, HTMLAttributes, useRef, useImperativeHandle } from 'react';
import './box.scss';

interface BoxProps {
  className: any;
}

interface Props extends HTMLAttributes<HTMLDivElement>, BoxProps {}

export const Box = forwardRef<HTMLDivElement, Props>(
  ({ className, children, ...props }, ref) => {
    const domRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => domRef.current!);
    return (
      <div ref={domRef} {...props} className={`box ${className}`}>
        {children}
      </div>
    );
  }
);
