import { SvgProps } from '..';

export const WethEIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_wbtc_e_clip0)`}>
        <circle cx="20" cy="20.5555" r="20" fill="#628DFE" />
        <path d="M20.4431 5.59814V5.61234C20.4572 5.68334 20.4572 5.76854 20.4572 5.85374V16.0917C20.4431 16.1485 20.4006 16.1627 20.3579 16.1911C20.0597 16.3331 19.7757 16.4609 19.4776 16.5887C19.0657 16.7734 18.6397 16.972 18.2279 17.1567L16.7369 17.8384C16.3252 18.0229 15.9134 18.2075 15.5158 18.392C15.033 18.6192 14.536 18.8322 14.0532 19.0595C13.6414 19.244 13.2296 19.4429 12.8036 19.6275C12.4628 19.7837 12.122 19.9257 11.7955 20.0819C11.7671 20.096 11.7387 20.1102 11.7103 20.1102C11.6961 20.1102 11.6961 20.1102 11.6819 20.096L12.0652 19.457C12.8036 18.2359 13.5278 17.0289 14.2662 15.8077C15.0472 14.5014 15.8424 13.195 16.6234 11.8886C17.3476 10.6816 18.0859 9.47466 18.8101 8.26769C19.3356 7.38731 19.8751 6.50693 20.4006 5.62654C20.4147 5.59814 20.4289 5.58394 20.4289 5.55554H20.4431C20.4289 5.56974 20.4431 5.58394 20.4431 5.59814Z" fill="white" />
        <path opacity="0.840588" d="M29.1615 20.0964L29.1757 20.1105L27.0883 21.3459L20.528 25.2226C20.4997 25.2367 20.4713 25.2509 20.4572 25.265C20.4145 25.265 20.4145 25.2225 20.4145 25.2082V25.0806V16.305C20.4145 16.2624 20.4145 16.2056 20.4287 16.163C20.4428 16.1062 20.4855 16.1204 20.528 16.1346C20.7127 16.2198 20.9115 16.305 21.096 16.3902C21.6498 16.6459 22.2037 16.9014 22.7575 17.1429C23.2402 17.3559 23.7088 17.583 24.1917 17.796C24.6743 18.009 25.1572 18.2362 25.64 18.4492C26.0518 18.6339 26.4778 18.8325 26.8895 19.0172C27.3013 19.2017 27.7273 19.4005 28.1392 19.5852C28.4657 19.7272 28.7923 19.8834 29.1188 20.0254C29.1188 20.068 29.1332 20.0822 29.1615 20.0964Z" fill="white" />
        <path d="M20.442 34.0839C20.442 34.098 20.4279 34.1124 20.4279 34.1265H20.4135C20.4135 34.098 20.3852 34.0839 20.371 34.0555C19.4907 32.8202 18.6102 31.5705 17.7299 30.3352C16.8352 29.0714 15.9265 27.7934 15.0319 26.5297C14.1657 25.3085 13.2854 24.073 12.4192 22.8519C12.192 22.5254 11.9648 22.2129 11.7376 21.8864C11.7234 21.8579 11.7092 21.8437 11.6808 21.8012C11.7234 21.8012 11.7518 21.8295 11.766 21.8437C13.0014 22.5679 14.2225 23.2922 15.4579 24.0164C16.8779 24.854 18.2837 25.6919 19.7037 26.5297L20.4279 26.9557C20.4562 26.984 20.4562 27.0125 20.4562 27.0409V33.8852C20.4562 33.956 20.4562 34.027 20.442 34.0839Z" fill="white" />
        <path opacity="0.840588" d="M11.6656 20.1242V20.1098C12.12 19.9112 12.5602 19.6982 13.0146 19.4993C13.5968 19.2295 14.1789 18.974 14.7611 18.7042C15.2013 18.5053 15.6557 18.2923 16.0959 18.0935C16.7491 17.7953 17.3881 17.5113 18.0413 17.2132C18.4814 17.0143 18.9216 16.8155 19.3761 16.6025C19.6884 16.4605 20.0151 16.3186 20.3274 16.1766C20.3558 16.1624 20.3984 16.1482 20.4126 16.1198C20.4268 16.1198 20.4268 16.134 20.4126 16.1482V25.165C20.4126 25.2077 20.3984 25.2502 20.4268 25.2787C20.3984 25.3212 20.3701 25.2787 20.3558 25.2643C20.2281 25.1933 20.1003 25.1223 19.9724 25.0372C17.2319 23.4185 14.4771 21.7855 11.7366 20.1667C11.7224 20.1525 11.694 20.1383 11.6656 20.1242Z" fill="white" />
        <path d="M29.1331 21.8012H29.1473C29.1473 21.8295 29.1189 21.8579 29.1048 21.8864C26.4919 25.564 23.8793 29.256 21.2664 32.9337C20.9966 33.3172 20.7126 33.7005 20.4429 34.0839C20.4286 34.0697 20.4286 34.0555 20.4286 34.0414V33.956V27.0692V26.9415C21.0251 26.5865 21.6073 26.2457 22.2036 25.8907C24.5039 24.5275 26.8043 23.1785 29.0904 21.8154C29.1048 21.8295 29.1189 21.8154 29.1331 21.8012Z" fill="white" />
        <path d="M20.4278 16.1482V16.1198V16.0346V5.72557C20.4278 5.68297 20.4136 5.65457 20.442 5.61197C23.3245 10.3973 26.2071 15.1684 29.0755 19.9537C29.1038 19.9963 29.1465 20.0532 29.1606 20.1098C28.9618 20.0388 28.7771 19.9395 28.5926 19.8543C28.3655 19.7548 28.124 19.6413 27.8968 19.5418C27.7548 19.4708 27.5986 19.4142 27.4566 19.3432C27.2153 19.2295 26.9738 19.1302 26.7325 19.0165C26.5905 18.9597 26.4485 18.8887 26.3065 18.8177L25.3693 18.3917C25.2131 18.3207 25.057 18.2497 24.8865 18.1787L24.205 17.8805C24.063 17.8237 23.921 17.7527 23.779 17.6817L22.8418 17.2557C22.6855 17.1847 22.5293 17.1137 22.359 17.0427L21.6773 16.7445C21.5211 16.6735 21.3791 16.6025 21.223 16.5315C20.9531 16.4038 20.6833 16.276 20.3993 16.1624C20.442 16.1482 20.4278 16.1482 20.4278 16.1482Z" fill="white" />
        <circle cx="20" cy="20.5555" r="20" fill="#628DFE" />
        <path d="M20.4431 5.59814V5.61234C20.4572 5.68334 20.4572 5.76854 20.4572 5.85374V16.0917C20.4431 16.1485 20.4006 16.1627 20.3579 16.1911C20.0597 16.3331 19.7757 16.4609 19.4776 16.5887C19.0657 16.7734 18.6397 16.972 18.2279 17.1567L16.7369 17.8384C16.3252 18.0229 15.9134 18.2075 15.5158 18.392C15.033 18.6192 14.536 18.8322 14.0532 19.0595C13.6414 19.244 13.2296 19.4429 12.8036 19.6275C12.4628 19.7837 12.122 19.9257 11.7955 20.0819C11.7671 20.096 11.7387 20.1102 11.7103 20.1102C11.6961 20.1102 11.6961 20.1102 11.6819 20.096L12.0652 19.457C12.8036 18.2359 13.5278 17.0289 14.2662 15.8077C15.0472 14.5014 15.8424 13.195 16.6234 11.8886C17.3476 10.6816 18.0859 9.47466 18.8101 8.26769C19.3356 7.38731 19.8751 6.50693 20.4006 5.62654C20.4147 5.59814 20.4289 5.58394 20.4289 5.55554H20.4431C20.4289 5.56974 20.4431 5.58394 20.4431 5.59814Z" fill="white" />
        <path opacity="0.840588" d="M29.1615 20.0964L29.1757 20.1105L27.0883 21.3459L20.528 25.2226C20.4997 25.2367 20.4713 25.2509 20.4572 25.265C20.4145 25.265 20.4145 25.2225 20.4145 25.2082V25.0806V16.305C20.4145 16.2624 20.4145 16.2056 20.4287 16.163C20.4428 16.1062 20.4855 16.1204 20.528 16.1346C20.7127 16.2198 20.9115 16.305 21.096 16.3902C21.6498 16.6459 22.2037 16.9014 22.7575 17.1429C23.2402 17.3559 23.7088 17.583 24.1917 17.796C24.6743 18.009 25.1572 18.2362 25.64 18.4492C26.0518 18.6339 26.4778 18.8325 26.8895 19.0172C27.3013 19.2017 27.7273 19.4005 28.1392 19.5852C28.4657 19.7272 28.7923 19.8834 29.1188 20.0254C29.1188 20.068 29.1332 20.0822 29.1615 20.0964Z" fill="white" />
        <path d="M20.442 34.0839C20.442 34.098 20.4279 34.1124 20.4279 34.1265H20.4135C20.4135 34.098 20.3852 34.0839 20.371 34.0555C19.4907 32.8202 18.6102 31.5705 17.7299 30.3352C16.8352 29.0714 15.9265 27.7934 15.0319 26.5297C14.1657 25.3085 13.2854 24.073 12.4192 22.8519C12.192 22.5254 11.9648 22.2129 11.7376 21.8864C11.7234 21.8579 11.7092 21.8437 11.6808 21.8012C11.7234 21.8012 11.7518 21.8295 11.766 21.8437C13.0014 22.5679 14.2225 23.2922 15.4579 24.0164C16.8779 24.854 18.2837 25.6919 19.7037 26.5297L20.4279 26.9557C20.4562 26.984 20.4562 27.0125 20.4562 27.0409V33.8852C20.4562 33.956 20.4562 34.027 20.442 34.0839Z" fill="white" />
        <path opacity="0.840588" d="M11.6656 20.1242V20.1098C12.12 19.9112 12.5602 19.6982 13.0146 19.4993C13.5968 19.2295 14.1789 18.974 14.7611 18.7042C15.2013 18.5053 15.6557 18.2923 16.0959 18.0935C16.7491 17.7953 17.3881 17.5113 18.0413 17.2132C18.4814 17.0143 18.9216 16.8155 19.3761 16.6025C19.6884 16.4605 20.0151 16.3186 20.3274 16.1766C20.3558 16.1624 20.3984 16.1482 20.4126 16.1198C20.4268 16.1198 20.4268 16.134 20.4126 16.1482V25.165C20.4126 25.2077 20.3984 25.2502 20.4268 25.2787C20.3984 25.3212 20.3701 25.2787 20.3558 25.2643C20.2281 25.1933 20.1003 25.1223 19.9724 25.0372C17.2319 23.4185 14.4771 21.7855 11.7366 20.1667C11.7224 20.1525 11.694 20.1383 11.6656 20.1242Z" fill="white" />
        <path d="M29.1331 21.8012H29.1473C29.1473 21.8295 29.1189 21.8579 29.1048 21.8864C26.4919 25.564 23.8793 29.256 21.2664 32.9337C20.9966 33.3172 20.7126 33.7005 20.4429 34.0839C20.4286 34.0697 20.4286 34.0555 20.4286 34.0414V33.956V27.0692V26.9415C21.0251 26.5865 21.6073 26.2457 22.2036 25.8907C24.5039 24.5275 26.8043 23.1785 29.0904 21.8154C29.1048 21.8295 29.1189 21.8154 29.1331 21.8012Z" fill="white" />
        <path d="M20.4278 16.1482V16.1198V16.0346V5.72557C20.4278 5.68297 20.4136 5.65457 20.442 5.61197C23.3245 10.3973 26.2071 15.1684 29.0755 19.9537C29.1038 19.9963 29.1465 20.0532 29.1606 20.1098C28.9618 20.0388 28.7771 19.9395 28.5926 19.8543C28.3655 19.7548 28.124 19.6413 27.8968 19.5418C27.7548 19.4708 27.5986 19.4142 27.4566 19.3432C27.2153 19.2295 26.9738 19.1302 26.7325 19.0165C26.5905 18.9597 26.4485 18.8887 26.3065 18.8177L25.3693 18.3917C25.2131 18.3207 25.057 18.2497 24.8865 18.1787L24.205 17.8805C24.063 17.8237 23.921 17.7527 23.779 17.6817L22.8418 17.2557C22.6855 17.1847 22.5293 17.1137 22.359 17.0427L21.6773 16.7445C21.5211 16.6735 21.3791 16.6025 21.223 16.5315C20.9531 16.4038 20.6833 16.276 20.3993 16.1624C20.442 16.1482 20.4278 16.1482 20.4278 16.1482Z" fill="white" />
      </g>
      <g clipPath={`url(#${props.id}_wbtc_e_clip1)`}>
        <path d="M37.8923 2.88672H31.1006V9.05959H37.8923V2.88672Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M39.9966 6.49952C39.9966 9.53308 37.5358 11.9922 34.5002 11.9922C31.4647 11.9922 29.0039 9.53308 29.0039 6.49952C29.0039 3.46599 31.4647 1.00684 34.5002 1.00684C37.5358 1.00684 39.9966 3.46599 39.9966 6.49952ZM32.9427 8.68533H31.8761C31.6519 8.68533 31.5412 8.68533 31.4737 8.64218C31.4008 8.59493 31.3562 8.51668 31.3508 8.4303C31.3468 8.35073 31.4021 8.25354 31.5128 8.05923L34.1466 3.41992C34.2587 3.22292 34.3154 3.12442 34.387 3.08799C34.4639 3.04886 34.5557 3.04886 34.6327 3.08799C34.7043 3.12442 34.761 3.22292 34.873 3.41992L35.4145 4.36446L35.4173 4.36928C35.5383 4.58063 35.5997 4.68781 35.6265 4.80029C35.6562 4.92308 35.6562 5.05262 35.6265 5.17541C35.5995 5.28875 35.5387 5.39671 35.4158 5.61125L34.0324 8.05519L34.0288 8.06145C33.907 8.27454 33.8452 8.38254 33.7596 8.46402C33.6665 8.5531 33.5544 8.61783 33.4315 8.65432C33.3195 8.68533 33.1939 8.68533 32.9427 8.68533ZM35.6365 8.68533H37.1649C37.3904 8.68533 37.5038 8.68533 37.5714 8.64087C37.6443 8.59362 37.6902 8.51397 37.6943 8.42767C37.6982 8.35065 37.644 8.25726 37.5379 8.07424C37.5342 8.06801 37.5306 8.06167 37.5268 8.05522L36.7612 6.74636L36.7525 6.73163C36.6449 6.54982 36.5906 6.45801 36.5209 6.42252C36.4439 6.38339 36.3534 6.38339 36.2765 6.42252C36.2063 6.45895 36.1496 6.55476 36.0375 6.74771L35.2746 8.05657L35.272 8.06108C35.1603 8.25372 35.1045 8.34999 35.1085 8.42898C35.1139 8.51536 35.1585 8.59493 35.2314 8.64218C35.2976 8.68533 35.411 8.68533 35.6365 8.68533Z" fill="#E84142" />
      </g>
      <defs>
        <clipPath id={`${props.id}_wbtc_e_clip0`}>
          <rect width="40" height="40" fill="white" transform="translate(0 0.555542)" />
        </clipPath>
        <clipPath id={`${props.id}_wbtc_e_clip1`}>
          <rect width="11" height="11" fill="white" transform="translate(29 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
