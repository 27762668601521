import { useMatchBreakpoints } from '@aperture/uikit';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IStore, useStore } from '../../../../store';
import { useAddPopup } from '../../../../store/base/application/hooks';
import { PopupType } from '../../../../store/base/AppSlice';
import { IEvmProvider } from '../../../../store/provider/Evm/EvmProvider';
import { ModalProps } from '../../../layout/ModalsContainer';
import { Button } from '../../Components/Button';
import { Spinner } from '../../Components/Spinner';
import { MobilePopOver } from '../../Modal/MobilePopOver';
import { TransactionModal } from '../TransactionModals';
import { WrongNetworkIcon } from '@aperture/assetkit';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const BodyText = styled.div`
  color: ${({ theme }) => theme.colors.textAndLine} !important;
  margin-inline: auto;
`;

const Close = styled('span')`
  margin: 0rem !important;
  position: absolute !important;
  right: 22px !important;
  top: 30px !important;
  cursor: pointer;
  @media (max-width: 1199px) {
    > svg {
      width: 21px !important;
      height: 21px !important;
    }
  }
`;
export const WrongNetworkModal = (props: ModalProps) => {
  const { onClose } = props;
  const { isSm } = useMatchBreakpoints();
  const [isLoading, setIsLoading] = useState(false);
  const switchEvmNetwork = useStore(
    (state: IStore) =>
      state.chainSlice.chainProvider &&
      (state.chainSlice?.chainProvider as IEvmProvider)?._onConnect
  );
  const isWrongNetwork = useStore(
    (state: IStore) => state.chainSlice.isWrongNetwork
  );
  const addPopup = useAddPopup();

  useEffect(() => {
    if (!isWrongNetwork || !switchEvmNetwork) {
      onClose();
    }
  }, [isWrongNetwork, switchEvmNetwork, onClose]);
  const Content = () => (
    <Grid
      container
      sx={{
        textAlign: 'center',
        padding: isSm ? '8px 0px' : '0px',
        gap: isSm ? '24px' : '32px',
      }}
    >
      <Close onClick={() => onClose()}>
        <CloseIcon />
      </Close>
      <Grid item md={12} sm={12} xs={12}>
        <WrongNetworkIcon />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div
          className={isSm ? 'headline-2 modal-text' : 'headline-1 modal-text'}
        >
          {' '}
          Wrong Network{' '}
        </div>
        <BodyText
          className="headline-6 medium modal-subtext"
          style={{
            marginTop: isSm ? '8px' : '16px',
            width: isSm ? '297px' : '317px',
          }}
        >
          Open your wallet to switch to Avalanche to continue.
        </BodyText>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Button
          fullWidth
          variant="outline"
          size="large"
          text={'Switch Network'}
          onClick={async () => {
            try {
              setIsLoading(true);
              switchEvmNetwork && (await switchEvmNetwork(undefined, true));
            } catch (err: any) {
              console.log(err);
              addPopup({
                [PopupType.ERROR]: {
                  title: `Error Switching Network`,
                  msg: err.message,
                },
              });
            } finally {
              setIsLoading(false);
            }
          }}
          disabled={isLoading}
          style={{ padding: '0px 16px' }}
        >
          {isLoading && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      {!isSm ? (
        <TransactionModal
          onClose={props.onClose}
          modalClassName="modal-confirmation"
        >
          <Content />
        </TransactionModal>
      ) : (
        <MobilePopOver
          open={true}
          onClose={props.onClose}
          className="transaction-pop-over"
        >
          <Content />
        </MobilePopOver>
      )}
    </>
  );
};
