export const keys = {
  strategies: 'strategies',
  strategyInfo: 'strategyInfo',
  extraPositions: 'extraPositions',
  positions: 'positions',
  positionInfo: 'positionInfo',
  tokens: 'tokens',
  balance: 'balance',
  alpha: 'alpha',
  protocolData: 'protocolData',
};
