import InputBase from '@mui/material/InputBase';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { MIN_DEPOSIT_AMOUNT } from '../../constants/crosschain';
import { SerializedTokenInfo } from '../../constants/tokenConfig';
import {
  BN,
  countDecimals,
  getDigits,
  numberParse,
  numberParseLarge,
} from '../../helpers/utilities';
import { useStore } from '../../store';
import textContext from '../../textContext';
import TableLoader from '../Investment/TableLoader';
import { Button } from '../v1';
import './styles/AssetSettings.scss';

interface AssetSettingsProps {
  invest?: boolean;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  withdrawValue?: string;
  token: SerializedTokenInfo | null;
  maxDeposit: string;
  isLoading: boolean;
  tokenInAvax: string;
}

const LoaderContainer = styled('div')`
  margin-left: 10px;
  display: flex;
  width: 20px;
  top: -10px;
  position: relative;
`;
const MaxContainer = styled('div')`
  display: flex; //grid;
  // grid-template-columns: auto 50px;
  @media (max-width: 1199px) {
    position: absolute;
    /* top: 70px; */
    width: 100%;
    left: 0;
    flex-direction: column;
    align-items: flex-end;
    top: 0;
    flex-direction: column;
    just-content: end;
  }
`;
const MaxBalance = styled('div').attrs({
  className: 'subtitle-2 light-grey',
})`
  display: flex;
  white-space: nowrap;
  margin: 9px 8px 0px 12px;
  @media (max-width: 1199px) {
    /* margin: 0; */
    margin: auto 16px;
  }
`;
const MaxBalanceContainer = styled('div').attrs({})`
  @media (max-width: 1199px) {
    height: 53px;
    margin: auto 0;
    display: flex;
  }
`;
const MaxSupply = styled('div').attrs({
  className: 'subtitle-2 medium',
})`
  width: 100%;
  text-align: right;
  line-height: 1.17;
  @media (max-width: 1199px) {
    display: flex;
    justify-content: center;
    text-align: left;
    height: 44px;
    flex-direction: column;
    gap: 8px;
    max-width: calc(100% - 58px);
  }
`;
export default function AssetSettings(props: AssetSettingsProps) {
  const {
    invest,
    amount,
    setAmount,
    withdrawValue,
    token,
    maxDeposit,
    isLoading,
    tokenInAvax,
  } = props;
  const language = useStore((state) => state.appSlice.language);
  const text = textContext[language].assets;
  const { tokenText } = text;

  const handleNumber = (val: string, decimals = 6) => {
    let parsed = val === '' ? '0' : val.replace(/,/g, '');
    if (BN(parsed).gte(0) && countDecimals(parsed)! <= decimals) {
      setAmount(parsed);
    }
  };
  const TokenIcon = token?.icon;
  return (
    <>
      <div
        className={
          !token ||
            (BN(amount).gte(MIN_DEPOSIT_AMOUNT / Number(tokenInAvax)) &&
              BN(amount).lte(invest ? token?.displayBalance : withdrawValue!) &&
              BN(amount).lte(invest ? maxDeposit : Infinity)) ||
            BN(amount).eq(0)
            ? 'token'
            : 'token errorColor'
        }
      >
        <div className="selectToken">
          <div className="textUpper semiBold subtitle-1">{tokenText}</div>
          <div
            className="flex"
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '13px',
            }}
          >
            {TokenIcon && <TokenIcon width="36" height="36" />}
            <h4 style={{ padding: '1px .5rem' }}>{token && token.ticker}</h4>
          </div>
        </div>
        <div className={`selectValue ${!invest ? 'withdraw' : ''}`}>
          <NumberFormat
            value={amount}
            onChange={(event: any) =>
              handleNumber(event.target.value, token?.decimals ?? 6)
            }
            customInput={InputBase}
            type="text"
            decimalScale={token?.decimals ?? 6}
            placeholder={'0.00'}
            thousandSeparator={true}
            // disabled={currentValue ? true : false}
            prefix=""
            allowNegative={false}
          />
          {invest && (
            <MaxContainer>
              <MaxBalanceContainer>
                <MaxBalance>
                  {/* TODO: Refactor calculation using BigNumber.js. */}
                  {!isLoading ? (
                    `Balance : ${getDigits(token?.displayBalance ?? 0) > 3
                      ? numberParseLarge(token?.displayBalance ?? 0, 2)
                      : numberParse(token?.displayBalance ?? 0)
                    }`
                  ) : (
                    <>
                      Balance :{' '}
                      <LoaderContainer>
                        <TableLoader height={20} />{' '}
                      </LoaderContainer>
                    </>
                  )}
                </MaxBalance>
              </MaxBalanceContainer>
              <Button
                disabled={!invest && !withdrawValue && !token}
                onClick={() => {
                  setAmount(
                    withdrawValue
                      ? withdrawValue
                      : (BN(token!.displayBalance).gt(maxDeposit)
                        ? BN(maxDeposit)
                        : BN(token!.displayBalance)
                      ).toString()
                  );
                }}
                className="textUpper"
                style={{ height: '32px' }}
              >
                <span className="button-3 medium">{`Max`}</span>
              </Button>
            </MaxContainer>
          )}
        </div>
        <MaxSupply>
          <span>
            {`Remaining Vault Capacity: ${maxDeposit ? numberParse(maxDeposit) : '--'
              } ${token?.ticker ?? ''}`}
          </span>
        </MaxSupply>
      </div>
    </>
  );
}
