import styled from 'styled-components';

const Icon = (props: any) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.16667 17.625V15.7083H3.87583L8.00083 11.3958L6.70833 10.0446L2.58333 14.3571V10.9167H0.75V17.625H7.16667ZM11.2917 7.95542L15.4167 3.64292V7.08333H17.25V0.375H10.8333V2.29167H14.1242L9.99917 6.60417L11.2917 7.95542Z"
      fill="#A3C4D3"
    />
  </svg>
);

const StyledExpandedIcon = styled(Icon)`
  position: absolute;
  right: 34px;
  top: 23px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.border};
  }
  display: none;
`;

export const ExpandedIcon = (props: any) => <StyledExpandedIcon {...props} />;
