import {
  calculate_timeframe_apy,
  PDNCard,
  UnderlyingPool,
  VaultItem,
} from '@aperture/uikit';
import React, { Fragment, useEffect, useMemo } from 'react';
import {
  DELISTING_POLICY,
  StrategyTitleTextMap,
  StrategyType,
  StrategyTypeTextMap,
} from '../../constants/crosschain';
import { PositionInfoMap } from '../../constants/position';
import { Strategy, StrategyInfoMap } from '../../constants/strategy';
import {
  useFetchBacktestAnalysis,
  useFetchRebalance,
  useFetchReturn,
} from '../../features/graph/useFetchGraphData';
import { useFetchEvmPosition } from '../../features/positions/hooks';
import { useFetchStrategy } from '../../features/strategies/hooks';
import { getMaximumDrawdown, getStrategyRebalance } from '../../helpers/graph';
import {
  BN,
  calculateThreshold,
  getProtocol,
  isTVLGreaterOrEqualToCapacity,
  numberParseLarge,
  rebalanceDataParser,
  timestampToUTCString,
} from '../../helpers/utilities';
import { useStore } from '../../store';
import { useOpenModal } from '../../store/base/application/hooks';
import { ModalType } from '../../store/base/AppSlice';
import { UnpopulatedPositionInfo } from '../../types';
import { Button } from '../v1';

interface ITableAssetsProps {
  info?: Object;
  strategy: Strategy;
  setStrategyInfoMap: React.Dispatch<React.SetStateAction<StrategyInfoMap>>;
  strategyOrder: number | null;
  positionInfo: UnpopulatedPositionInfo | null;
  key: number;
  title: string;
  setPositionMap: React.Dispatch<React.SetStateAction<PositionInfoMap>>;
  search: string;
  strategyType: string;
  assets: string[];
}

export default function TableAssets(props: ITableAssetsProps) {
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const {
    strategy,
    positionInfo,
    title,
    setPositionMap,
    setStrategyInfoMap,
    strategyOrder,
    search,
    strategyType,
    assets,
  } = props;
  const { data } = useFetchStrategy(strategy);
  const { isLoading: positionIsLoading, data: position } =
    useFetchEvmPosition(positionInfo);
  const { data: returns } = useFetchReturn(strategy.strategyId);
  const { data: backtestAnalysisData } = useFetchBacktestAnalysis();
  const { data: rebalance } = useFetchRebalance();

  const vaultItem = (
    <VaultItem
      tokens={strategy.tokens}
      protocols={strategy.protocols}
      showNewTag={strategy.strategyType === StrategyType.PSEUDO_1X_LONG}
      leverage={strategy.leverage}
    />
  );
  const modalProps = {
    strategyInfo: data,
    positionInfo: position,
    vaultItem,
    title: StrategyTitleTextMap[strategy.strategyType],
  };
  const openInvestModal = useOpenModal(ModalType.INVEST, {
    invest: true,
    ...modalProps,
  });
  const openWithdrawModal = useOpenModal(ModalType.INVEST, {
    invest: false,
    ...modalProps,
  });
  const openPDNDetailsModal = useOpenModal(ModalType.PDN_DETAILS, {
    strategyInfo: data ?? null,
    positionInfo: position ?? null,
    vaultTitle: title,
  });
  const openWalletConnectModal = useOpenModal(ModalType.NETWORK_SELECTOR);

  const handleInvest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isWalletConnected) {
      openInvestModal();
    } else {
      openWalletConnectModal();
    }
  };
  const handleWithdraw = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    openWithdrawModal();
  };

  useEffect(() => {
    if (positionIsLoading || !position?.equity || !data?.strategyId) {
      return;
    }
    setPositionMap((prev: PositionInfoMap) => ({
      ...prev,
      [data.strategyId]: position,
    }));
  }, [data, position, positionIsLoading, setPositionMap]);

  const inception_Date = useMemo(
    () =>
      returns && returns.inception.length > 0
        ? timestampToUTCString(
            returns.inception[0]!.timestamp_sec,
            'DayMonthYear'
          )
        : '--',
    [returns]
  );
  const return_Changes = useMemo(
    () =>
      returns && returns.inception.length > 0
        ? returns.inception[returns.inception.length - 1].percentage
        : Infinity,
    [returns]
  );
  const threshold = useMemo(
    () =>
      calculateThreshold(data?.priceMovementThreshold ?? 0, strategy.leverage),
    [data, strategy]
  );
  const maximum_Drawdown = useMemo(
    () => getMaximumDrawdown(returns?.inception ?? []),
    [returns]
  );
  const number_Rebalance = useMemo(
    () =>
      rebalanceDataParser(
        getStrategyRebalance(rebalance ?? [], strategy.strategyId),
        returns && returns.inception.length > 0
          ? returns.inception[0]!.timestamp_sec
          : Infinity
      ),
    [returns, rebalance, strategy]
  );
  const apy_7days = useMemo(
    () => (returns?.week ? calculate_timeframe_apy(returns?.week) : undefined),
    [returns?.week]
  );
  const apy_season = useMemo(
    () =>
      returns?.season ? calculate_timeframe_apy(returns?.season) : undefined,
    [returns?.season]
  );
  const tvlGteCapacity = useMemo(
    () =>
      data
        ? isTVLGreaterOrEqualToCapacity(data.TVL, data.capacity, data.leverage)
        : false,
    [data]
  );

  useEffect(() => {
    if (data?.strategyId) {
      setStrategyInfoMap((prev: StrategyInfoMap) => {
        return {
          ...prev,
          [data.strategyId]: {
            ...data,
            apy_7days: apy_7days ?? undefined,
            apy_season: apy_season ?? undefined,
          },
        };
      });
    }
  }, [apy_7days, apy_season, data, setStrategyInfoMap]);

  const shouldShow = useMemo(
    () =>
      (!(DELISTING_POLICY && strategy.hiddenFromNewUsers) ||
        (DELISTING_POLICY &&
          position !== null &&
          position !== undefined &&
          BN(position?.equity).gt(0))) &&
      (title.toLowerCase().includes(search.toLowerCase()) ||
        getProtocol(strategy.protocols)
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        StrategyTypeTextMap[strategy.strategyType]
          .toLowerCase()
          .includes(search.toLowerCase())) &&
      ((strategyType !== '' && strategy.strategyType === strategyType) ||
        strategyType === '') &&
      ((assets.length === 1 && assets[0] === '') ||
        assets.filter(
          (item) =>
            item === strategy.tokens[0].ticker.toLowerCase() ||
            item === strategy.tokens[1].ticker.toLowerCase()
        ).length > 0),
    [title, search, strategy, strategyType, assets, position]
  );

  return shouldShow ? (
    <Fragment>
      <PDNCard
        order={strategyOrder}
        ratio="260px 12fr 24fr 12fr 10fr 11fr 18fr"
        vaultItem={vaultItem}
        buttonGroup={
          <div className="investButton">
            <Button
              className="action-deposit"
              variant="outline"
              size="small"
              text={'Deposit'}
              onClick={(event) => handleInvest(event)}
              disabled={tvlGteCapacity}
              style={{ padding: '0px 16px' }}
            />
          </div>
        }
        charts={[
          {
            chartsType: 'return',
            chartsData: returns,
            chartsId: strategy.strategyId,
          },
          {
            chartsType: 'backtest',
            chartsData:
              backtestAnalysisData && backtestAnalysisData[strategy.strategyId],
            chartsId: strategy.strategyId,
          },
        ]}
        tokens={[strategy.tokens[0].ticker, strategy.tokens[1].ticker]}
        openStrategyDetails={() => openPDNDetailsModal()}
        strategy={{
          leverage: strategy.leverage,
          inceptionDate: inception_Date,
          returnChanges: return_Changes,
          threshold: threshold,
          sharpRatio: 5.28, //missing
          maxDrawdown: maximum_Drawdown,
          rebalances: number_Rebalance,
          underlyingPool: (
            <UnderlyingPool
              icon={strategy.protocols[0].icon}
              text={`${strategy.protocols[0].name} ${strategy.tokens[0].ticker}-${strategy.tokens[1].ticker}`}
            />
          ),
          strategyType:
            StrategyTypeTextMap[strategy.strategyType] === 'bull'
              ? 'bull'
              : 'crab',
          tvlCapacity:
            !data?.TVL || !data?.capacity
              ? undefined
              : {
                  content: `$${numberParseLarge(
                    data?.TVL ?? 0,
                    2
                  )} / ${numberParseLarge(data?.capacity ?? 0, 2)}`,
                  percentage: Number(data?.TVL) / Number(data?.capacity),
                },
          apy: {
            apy: data?.apy === undefined ? undefined : Number(data?.apy),
            apy_7days: apy_7days,
            apy_season: apy_season,
          },
        }}
        MobileButtonGroup={
          <>
            <Button
              variant="outline"
              size="small"
              text={'Deposit'}
              onClick={(event) => handleInvest(event)}
              disabled={tvlGteCapacity}
              style={{ width: '40%', padding: '3px' }}
            />
            <Button
              variant="info"
              size="small"
              text={'Withdraw'}
              onClick={(event) => handleWithdraw(event)}
              disabled={
                !(
                  isWalletConnected &&
                  position &&
                  position.equity &&
                  Number(position.equity) > 0
                )
              }
              style={{ width: '40%', padding: '3px' }}
            />
          </>
        }
        isLoading={!data || !returns || !backtestAnalysisData}
      />
    </Fragment>
  ) : null;
}
