import { SvgProps } from '..';

export const OpenLinkIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <path
        d="M3.21875 1.8125C2.84579 1.8125 2.4881 1.96066 2.22438 2.22438C1.96066 2.4881 1.8125 2.84579 1.8125 3.21875V6.5H4.84063C5.75844 6.5 6.5 7.2425 6.5 8.15938V11.1875H9.78125C10.1542 11.1875 10.5119 11.0393 10.7756 10.7756C11.0393 10.5119 11.1875 10.1542 11.1875 9.78125V7.69063C11.1875 7.56631 11.2369 7.44708 11.3248 7.35917C11.4127 7.27126 11.5319 7.22188 11.6562 7.22188C11.7806 7.22188 11.8998 7.27126 11.9877 7.35917C12.0756 7.44708 12.125 7.56631 12.125 7.69063V9.78125C12.125 10.4029 11.8781 10.999 11.4385 11.4385C10.999 11.8781 10.4029 12.125 9.78125 12.125H3.21875C2.59715 12.125 2.00101 11.8781 1.56147 11.4385C1.12193 10.999 0.875 10.4029 0.875 9.78125V3.21875C0.875 2.59715 1.12193 2.00101 1.56147 1.56147C2.00101 1.12193 2.59715 0.875 3.21875 0.875H5.30937C5.4337 0.875 5.55292 0.924386 5.64083 1.01229C5.72874 1.1002 5.77812 1.21943 5.77812 1.34375C5.77812 1.46807 5.72874 1.5873 5.64083 1.67521C5.55292 1.76311 5.4337 1.8125 5.30937 1.8125H3.21875ZM7.22188 1.34375C7.22188 1.21943 7.27126 1.1002 7.35917 1.01229C7.44708 0.924386 7.56631 0.875 7.69063 0.875H11.6562C11.7806 0.875 11.8998 0.924386 11.9877 1.01229C12.0756 1.1002 12.125 1.21943 12.125 1.34375V5.30937C12.125 5.4337 12.0756 5.55292 11.9877 5.64083C11.8998 5.72874 11.7806 5.77812 11.6562 5.77812C11.5319 5.77812 11.4127 5.72874 11.3248 5.64083C11.2369 5.55292 11.1875 5.4337 11.1875 5.30937V2.47625L8.02156 5.64125C7.97832 5.68602 7.9266 5.72173 7.86941 5.7463C7.81222 5.77086 7.75071 5.7838 7.68847 5.78434C7.62623 5.78488 7.5645 5.77302 7.5069 5.74945C7.44929 5.72588 7.39695 5.69107 7.35294 5.64706C7.30893 5.60305 7.27412 5.55071 7.25055 5.4931C7.22698 5.4355 7.21512 5.37377 7.21566 5.31153C7.2162 5.24929 7.22914 5.18778 7.2537 5.13059C7.27827 5.0734 7.31398 5.02168 7.35875 4.97844L10.5247 1.8125H7.69063C7.56631 1.8125 7.44708 1.76311 7.35917 1.67521C7.27126 1.5873 7.22188 1.46807 7.22188 1.34375Z"
        fill="black"
      />
    </svg>
  );
};
