import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyle';
import './styles/typography.css';

// Comps
import { ModalsContainer } from './components/layout/ModalsContainer';

// Pages
import Investment from './pages/Investment';
import Portfolio from './pages/Portfolio';

// Zustand Store
import { IStore, useStore } from './store';

import { dark, light, UIKitProvider } from '@aperture/uikit';
import { Strategy } from 'constants/strategy';
import { ReactQueryDevtools } from 'react-query/devtools';
import Disclaimer from './components/layout/Disclaimer';
import NavbarContainer from './components/layout/NavbarContainer';
import Popups from './components/v1/Popups/index';
import { BlockchainType } from './constants/crosschain';
import { strategies } from './constants/strategy/strategies';
import {
  useFetchBacktestAnalysis,
  useFetchReturn,
  useFetchUserGraphInfo,
} from './features/graph/useFetchGraphData';
import {
  useFetchEvmPosition,
  useFetchEvmPositionInfo,
} from './features/positions/hooks';
import { BlockNumberProvider as EvmBlockNumberProvider } from './lib/hooks/useBlockNumber';
import Credits from './pages/Credits';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import { useUpgradeStore } from './store/base/application/hooks';
import { useChainType } from './store/base/chain/hooks';
import InitStore from './store/InitStore';
import { IEvmProvider } from './store/provider/Evm/EvmProvider';
import { TransactionUpdater as EvmTransactionUpdater } from './store/provider/transaction/TransactionUpdater';
import { UnpopulatedPositionInfo } from './types';

const PositionEntriesPrefetcher = (props: {
  positionInfo: UnpopulatedPositionInfo;
}) => {
  useFetchEvmPosition(props.positionInfo);
  return null;
};

export const PositionsPrefetcher = (props: {
  setPrefetched: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data } = useFetchEvmPositionInfo();
  const { unpopulatedPositionInfoMap: positionInfo } = data ?? {};
  useEffect(() => {
    if (positionInfo) props.setPrefetched(true);
  }, [positionInfo]);

  return (
    <>
      {positionInfo &&
        Object.values(positionInfo).map((pos) => (
          <div key={pos.positionId}>
            <PositionEntriesPrefetcher positionInfo={pos} />
            <UserGraphPrefetcher positionId={pos.positionId} />
          </div>
        ))}
    </>
  );
};

const ReturnGraphPrefetcher = (props: { strategyId: string }) => {
  useFetchReturn(props.strategyId);
  return null;
};

export const GlobalGraphPrefetcher = (props: {
  setPrefetched: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isSuccess: isSuccessAnalysis, isLoading: isLoadingAnalysis } =
    useFetchBacktestAnalysis();

  useEffect(() => {
    if (isSuccessAnalysis && !isLoadingAnalysis) {
      props.setPrefetched(true);
    }
  }, []);
  return (
    <>
      {strategies.map((strategy: Strategy) => (
        <ReturnGraphPrefetcher
          key={strategy.strategyId}
          strategyId={strategy.strategyId}
        />
      ))}
    </>
  );
};

export const UserGraphPrefetcher = (props: { positionId: String }) => {
  useFetchUserGraphInfo(props.positionId);
  return null;
};

function App() {
  const doneInit = useStore((state) => state.appSlice.doneInit);
  const [positionsPrefetched, setPositionsPrefetched] = useState(false);
  const [globalGraphPrefetched, setGlobalGraphPrefetched] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const upgradeStore = useUpgradeStore();

  const web3Provider = useStore(
    (state: IStore) =>
      (state.chainSlice.chainProvider as IEvmProvider)?._web3Provider
  );
  const walletAddress = useStore(
    (state: IStore) =>
      (state.chainSlice.chainProvider as IEvmProvider)?.walletAddress
  );
  const evmManager = useStore(
    (state: IStore) =>
      (state.chainSlice.chainProvider as IEvmProvider)?._evmManager
  );
  const chainType = useChainType();

  useEffect(() => {
    if (doneInit) {
      upgradeStore();
    }
  }, [doneInit, upgradeStore]);

  useEffect(() => {
    const exceptionCatcher = () => {
      window.onerror = (message, file, line, column, errorObject) => {
        if (
          typeof message === 'string' &&
          message?.match('PollingBlockTracker')
        ) {
          window.event.preventDefault();
          throw new Error('PollingBlockTracker');
        }
      };
    };
    exceptionCatcher();
  }, []);

  return (
    <>
      {!doneInit && <InitStore />}
      {doneInit && (
        <UIKitProvider theme={darkMode ? dark : light}>
          <GlobalStyle theme={darkMode ? dark : light} />
          <HashRouter>
            <NavbarContainer
              darkMode={darkMode}
              onChange={(dark: boolean) => {
                setDarkMode(dark);
              }}
            >
              <Disclaimer />
              <Routes>
                <Route path="/" element={<Investment />} />
                <Route path="/dn/*" element={<Investment />} />
                <Route path="/portfolio" element={<Portfolio />} />
                {/* <Route path="/news" element={<News />} />
              <Route path="/settings" element={<Settings />} /> */}
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/credits" element={<Credits />} />
              </Routes>
              {!globalGraphPrefetched && (
                <GlobalGraphPrefetcher
                  {...{ setPrefetched: setGlobalGraphPrefetched }}
                />
              )}
              {chainType === BlockchainType.EVM && (
                <>
                  {web3Provider && walletAddress && evmManager && (
                    <>
                      {!positionsPrefetched && (
                        <PositionsPrefetcher
                          {...{ setPrefetched: setPositionsPrefetched }}
                        />
                      )}
                    </>
                  )}
                  <EvmBlockNumberProvider>
                    <EvmTransactionUpdater />
                  </EvmBlockNumberProvider>
                </>
              )}
              <ModalsContainer />
              <Popups />
              <ReactQueryDevtools initialIsOpen={false} />
            </NavbarContainer>
          </HashRouter>
        </UIKitProvider>
      )}
    </>
  );
}

export default App;
