import { Grid } from '@mui/material';
import { TransactionModal } from '..';
import { useModalIsOpen } from '../../../../../store/base/application/hooks';
import { ModalType } from '../../../../../store/base/AppSlice';
import { Button } from '../../../Components/Button';
import { MobilePopOver } from '../../../Modal/MobilePopOver';
import styled from 'styled-components';
import { SubmittedIcon } from '@aperture/assetkit';
import { useMatchBreakpoints } from '@aperture/uikit';

export const SubmittedModal = ({ onClose }: { onClose: any }) => {
  const isOpen = useModalIsOpen(ModalType.TXN_SUBMITTED);
  const { isSm } = useMatchBreakpoints();

  const StyledSubmittedIcon = styled(SubmittedIcon)`
    linearGradient {
      stop:first-child {
        stop-color: ${({ theme }) => theme.colors.startupColor1};
      }
      stop:nth-child(2) {
        stop-color: ${({ theme }) => theme.colors.startupColor2};
      }
      stop:nth-child(3) {
        stop-color: ${({ theme }) => theme.colors.startupColor3};
      }
    }
  `;
  const Content = () => (
    <Grid
      container
      direction={'column'}
      style={{ alignItems: 'center', gap: isSm ? '24px' : '48px' }}
    >
      <Grid item>
        <StyledSubmittedIcon />
      </Grid>
      <Grid item>
        <div className="headline-1">Transaction Submitted </div>
        {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Swapping 2000.00 USDC for 1999.70 ETH{' '}
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Confirm this transaction in your wallet{' '}
                </Typography> */}
      </Grid>
      {!isSm && (
        <Grid item>
          <Button
            variant="contained"
            light={true}
            size="large"
            text="OK"
            onClick={() => onClose()}
            style={{ width: '483px', height: '60px' }}
          />
        </Grid>
      )}
    </Grid>
  );
  return (
    <>
      {isOpen && !isSm ? (
        <TransactionModal onClose={onClose}>
          <Content />
        </TransactionModal>
      ) : (
        <MobilePopOver
          cross={true}
          open={isOpen}
          onClose={onClose}
          className="transaction-pop-over"
        >
          <Content />
        </MobilePopOver>
      )}
    </>
  );
};
