// TODO: Refactor and separate constants into categorized files.
import { AvalancheIcon, EthereumIcon, SvgProps } from '@aperture/assetkit';
import { Percent } from '@uniswap/sdk-core';
import { BigNumber } from 'ethers';
import { FunctionComponent } from 'react';
import ApertureManager from '../../hardhat/contracts/ApertureManager.sol/ApertureManager.json';
import HomoraPDNVault from '../../hardhat/contracts/HomoraPDNVault.sol/HomoraPDNVault.json';
import VaultLib from '../../hardhat/contracts/libraries/VaultLib.sol/VaultLib.json';

//Wormhole chainIds TODO: Use wormhole from library after fixing issue with Storybook compatibility.
export const CHAIN_ID_SOLANA = 1;
export const CHAIN_ID_ETH = 2;
export const CHAIN_ID_TERRA = 3;
export const CHAIN_ID_BSC = 4;
export const CHAIN_ID_POLYGON = 5;
export const CHAIN_ID_AVAX = 6;
export const CHAIN_ID_OASIS = 7;
export const CHAIN_ID_ALGORAND = 8;
export const CHAIN_ID_AURORA = 9;
export const CHAIN_ID_FANTOM = 10;
export const CHAIN_ID_KARURA = 11;
export const CHAIN_ID_ACALA = 12;
export const CHAIN_ID_KLAYTN = 13;
export const CHAIN_ID_CELO = 14;
export const CHAIN_ID_NEAR = 15;
export const CHAIN_ID_ETHEREUM_ROPSTEN = 10001;
export enum Blockchain {
  ETHEREUM,
  ETHEREUM_ROPSTEN,
  AVALANCHE,
  AVALANCHE_FUJI,
  AVAX_LOCAL,
  POLYGON,
  SOLANA,
}

export enum EvmWalletType {
  INJECTED = '',
}

export enum BlockchainType {
  EVM,
  SOLANA,
}
export enum InvestType {
  OPEN,
  INCREASE,
  DECREASE,
  CLOSE, //(not yet supported)
}
export const InvestTypeTextMap = {
  [InvestType.OPEN]: 'open',
  [InvestType.INCREASE]: 'increase',
  [InvestType.DECREASE]: 'decrease',
  [InvestType.CLOSE]: 'close',
};

export enum StrategyType {
  PSEUDO_DELTA_NEUTRAL = '0',
  PSEUDO_1X_LONG = '1',
  // LENDING_OPTIMIZER = '1',
}

export const StrategyTypeTextMap = {
  [StrategyType.PSEUDO_DELTA_NEUTRAL]: 'crab',
  [StrategyType.PSEUDO_1X_LONG]: 'bull',
};

export const StrategyTitleTextMap = {
  [StrategyType.PSEUDO_DELTA_NEUTRAL]: 'Crab Market Leveraged Farming',
  [StrategyType.PSEUDO_1X_LONG]: 'Bull Market Leveraged Farming',
};

export enum BlockchainExplorerType {
  BLOCK = 'block',
  ADDRESS = 'address',
  TX = 'tx',
}

export interface IBlockchainInfo {
  name: string;
  icon: FunctionComponent<React.PropsWithChildren<SvgProps>>;
  nativeDenom: string;
}

export interface IErrorInfo {
  title: string;
  reason: string;
}

export interface IAvailableBlockchains {
  [blockchain: number]: IBlockchainInfo;
}
export const AvailableBlockchains: IAvailableBlockchains = {
  [Blockchain.AVALANCHE]: {
    name: 'Avalanche',
    icon: AvalancheIcon,
    nativeDenom: 'AVAX',
  },
  [Blockchain.AVALANCHE_FUJI]: {
    name: 'Avalanche Fuji Testnet',
    icon: AvalancheIcon,
    nativeDenom: 'AVAX',
  },
  [Blockchain.AVAX_LOCAL]: {
    name: 'Avalanche Local',
    icon: AvalancheIcon,
    nativeDenom: 'GO',
  },
  [Blockchain.ETHEREUM]: {
    name: 'Ethererum',
    icon: EthereumIcon,
    nativeDenom: 'ETH',
  },
  [Blockchain.ETHEREUM_ROPSTEN]: {
    name: 'Ethererum Ropsten Testnet',
    icon: EthereumIcon,
    nativeDenom: 'ETH',
  },
};

export interface IAddressMap<T> {
  [networkId: number]: T;
}
export interface IContractMap {
  [contractName: string]: string;
}

export const DefaultEvmChain = Blockchain.AVALANCHE;
export const BlockchainTypeMap = {
  [Blockchain.ETHEREUM_ROPSTEN]: BlockchainType.EVM,
  [Blockchain.ETHEREUM]: BlockchainType.EVM,
  [Blockchain.AVALANCHE_FUJI]: BlockchainType.EVM,
  [Blockchain.AVALANCHE]: BlockchainType.EVM,
  [Blockchain.POLYGON]: BlockchainType.EVM,
  [Blockchain.AVAX_LOCAL]: BlockchainType.EVM,
  [Blockchain.SOLANA]: BlockchainType.SOLANA,
};
export const BlockchainToEvmIdMap = {
  [Blockchain.ETHEREUM]: 1,
  [Blockchain.ETHEREUM_ROPSTEN]: 3,
  [Blockchain.AVALANCHE]: 43114,
  [Blockchain.AVALANCHE_FUJI]: 43113,
  [Blockchain.AVAX_LOCAL]: 31337,
  [Blockchain.POLYGON]: 137,
  [Blockchain.SOLANA]: -1,
};
export const WormholeChainIdMap = {
  [Blockchain.ETHEREUM_ROPSTEN]: CHAIN_ID_ETHEREUM_ROPSTEN,
  [Blockchain.ETHEREUM]: CHAIN_ID_ETH,
  [Blockchain.AVALANCHE]: CHAIN_ID_AVAX,
  // [Blockchain.AVALANCHE_FUJI]: Wormhole.CHAIN_ID_AVAX,
  // [Blockchain.AVAX_LOCAL]: CHAIN_ID_AVAX,
  [Blockchain.POLYGON]: CHAIN_ID_POLYGON,
  [Blockchain.SOLANA]: CHAIN_ID_SOLANA,
};
export const WormholeChainIdToBlockchainMap = (() => {
  let o: any = {};
  Object.keys(WormholeChainIdMap).forEach((x) => {
    o[WormholeChainIdMap[x as keyof object]] = x;
  });
  return o;
})();
export const EvmIdToBlockchainMap = (() => {
  let o: any = {};
  Object.keys(BlockchainToEvmIdMap).forEach((x) => {
    o[BlockchainToEvmIdMap[x as keyof object]] = x;
  });
  return o;
})();

export const erc20ABI = [
  // Read-Only Functions
  'function balanceOf(address owner) view returns (uint256)',
  // Authenticated Functions
  'function approve(address spender, uint256 value) returns (bool)',
];

export const ERC20TokenABI = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [
      {
        name: '',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_spender',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_from',
        type: 'address',
      },
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'transferFrom',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        name: '',
        type: 'uint8',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        name: 'balance',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        name: '',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
      {
        name: '_spender',
        type: 'address',
      },
    ],
    name: 'allowance',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    payable: true,
    stateMutability: 'payable',
    type: 'fallback',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
];

export const EvmManagerAddress: IAddressMap<string> = {
  3: '0x568c611BCC9FDFcF44A67E82C3E1b159699B6bFe',
  43114: '0xeD380115259FcC9088c187Be1279678e23a6E565',
  31337: '0x21dF544947ba3E8b3c32561399E88B52Dc8b2823',
};

export const EvmWormholeAddress: IAddressMap<string> = {
  3: '0x210c5F5e2AF958B4defFe715Dc621b7a3BA888c5',
  5: '0x706abc4E45D419950511e474C7B9Ed348A4a716c',
};
export const EvmTokenBridgeAddress: IAddressMap<string> = {
  3: '0xF174F9A837536C449321df1Ca093Bb96948D5386',
  5: '0xF890982f9310df57d00f659cf4fd87e65adEd8d7',
  56: '0x9dcF9D205C9De35334D646BeE44b2D2859712A09,',
};
export const EvmTokenContractAddress: IAddressMap<IContractMap> = {
  1: {
    UST: '0xa693B19d2931d498c5B318dF961919BB4aee87a5',
  },
  3: {
    UST: '0x67d574b0218DcA2eB790b5922C5dA6A7b77E25a5',
    AUST: '0x78D8036EB3Dcb4d8F099E9497d02CDaE202EA358',
  },
  43113: {
    WETH: '0xbB5A2dC896Ec4E2fa77F40FA630582ed9c6D0172',
  },
  31337: {
    USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
  },
};
export const EvmVaultLibContractAddress: IAddressMap<string> = {
  43114: '0x16Ab749236B326905be4195Fe01CBB260d944a1d',
  31337: '0xDC11f7E700A4c898AE5CAddB1082cFfa76512aDD',
};
export const EvmManagerContractAbi: any = {
  31337: ApertureManager,
  43114: ApertureManager,
};

export const EvmStrategyContractAbi: any = {
  31337: HomoraPDNVault,
  43114: HomoraPDNVault,
};

export const EvmVaultLibContractAbi: any = {
  31337: VaultLib,
  43114: VaultLib,
};

export const wormholeRpcHost = 'https://wormhole-v2-testnet-api.certus.one';

export const chainList = [
  // {
  //   chain: Blockchain.AVAX_LOCAL,
  //   name: 'Avalanche',
  //   icon: network.avalanche,
  //   nativeDenom: 'GO',
  // },
  {
    chain: Blockchain.AVALANCHE,
    name: 'Avalanche',
    icon: AvalancheIcon,
    nativeDenom: 'AVAX',
  },
  // {
  //   chain: Blockchain.SOLANA,
  //   name: 'Solana',
  //   icon: network.solana,
  //   nativeDenom: 'SOL',
  //   disabled: true,
  // },
];

export const isDevEnv = () => {
  const testnet = ['fleek', 'localhost', 'main'];
  return testnet.find((x) => window.location.href.includes(x));
};

export const isProdEnv = () => {
  const production = 'https://app.aperture.finance/';
  return window.location.href.includes(production);
};

export const MIN_WITHDRAW_AMOUNT = 0.00000000000001;
export const MIN_DEPOSIT_AMOUNT = 0.0; // isDevEnv() ? 0.0 : 0.5; //0.5 worth of avax

export const DEFAULT_SLIPPAGE = new Percent(5, 1000); //.50%
export const FULL_SLIPPAGE = new Percent(1000, 1000) //1

export const HOMORA_BN_VAL = BigNumber.from(2).pow(112);

export const MIN_REINVEST_ETH = BigNumber.from(2e6).mul(25e9);
export const DELISTING_POLICY = !isDevEnv();
