import { SvgProps } from "..";

export const OkxIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.908 8.33371H9.67471C9.4099 8.33371 9.19519 8.55253 9.19519 8.82253V15.1781C9.19519 15.4481 9.4099 15.667 9.67471 15.667H15.908C16.1728 15.667 16.3875 15.4481 16.3875 15.1781V8.82253C16.3875 8.55253 16.1728 8.33371 15.908 8.33371Z" fill="black" />
      <path d="M8.71279 1.00003H2.47952C2.21472 1.00003 2 1.21886 2 1.48895V7.84444C2 8.11443 2.21472 8.33336 2.47952 8.33336H8.71279C8.97769 8.33336 9.1923 8.11443 9.1923 7.84444V1.48895C9.1923 1.21886 8.97769 1.00003 8.71279 1.00003Z" fill="black" />
      <path d="M23.0975 1.00003H16.8642C16.5994 1.00003 16.3846 1.21886 16.3846 1.48895V7.84444C16.3846 8.11443 16.5994 8.33336 16.8642 8.33336H23.0975C23.3623 8.33336 23.577 8.11443 23.577 7.84444V1.48895C23.577 1.21886 23.3623 1.00003 23.0975 1.00003Z" fill="black" />
      <path d="M8.71279 15.6667H2.47952C2.21472 15.6667 2 15.8856 2 16.1556V22.5112C2 22.7812 2.21472 23.0001 2.47952 23.0001H8.71279C8.97769 23.0001 9.1923 22.7812 9.1923 22.5112V16.1556C9.1923 15.8856 8.97769 15.6667 8.71279 15.6667Z" fill="black" />
      <path d="M23.0975 15.6667H16.8642C16.5994 15.6667 16.3846 15.8856 16.3846 16.1556V22.5112C16.3846 22.7812 16.5994 23.0001 16.8642 23.0001H23.0975C23.3623 23.0001 23.577 22.7812 23.577 22.5112V16.1556C23.577 15.8856 23.3623 15.6667 23.0975 15.6667Z" fill="black" />
    </svg>
  );
};
