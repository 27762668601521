import { TransactionDetails } from './types';
import ITransactionProvider from '../ITransactionProvider';
import { IStore } from '../../../index';
import { TransactionResponse } from '@ethersproject/providers';

const now = () => new Date().getTime();

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionDetails;
  };
}

export const transactions: TransactionState = {};

export const EvmTransactionProvider = (set: any, get: any, api: any) => {
  const state = {
    transactions,
  };
  const actions = {
    addTransaction({ chainId, from, hash, ...info }: TransactionResponse) {
      console.log('addTransaction', chainId, from, hash, info);
      const { transactions } = get().chainSlice.transactionProvider;
      if (transactions[chainId]?.[hash]) {
        throw Error('Attempted to add existing transaction.');
      }

      set((state: IStore) => {
        const { transactions } = state.chainSlice.transactionProvider!;
        const txs = transactions[chainId] ?? {};
        txs[hash] = { hash, info, from, addedTime: now() };
        transactions[chainId] = txs;
      });
    },
    clearAllTransactions(chainId: number) {
      console.log('clearAllTransactions', chainId);
      const { transactions } =
        get().chainSlice.chainProvider.transactionProvider;
      if (!transactions[chainId]) return;
      set((state: IStore) => {
        const { transactions } = state.chainSlice.transactionProvider!;
        transactions[chainId] = {};
      });
    },
    checkedTransaction({ chainId, hash, blockNumber }: TransactionResponse) {
      console.log('checkedTransaction', chainId, hash, blockNumber);
      set((state: IStore) => {
        const { transactions } = state.chainSlice.transactionProvider!;

        const tx = transactions[chainId]?.[hash];
        if (!tx) {
          return;
        }
        if (!tx.lastCheckedBlockNumber) {
          tx.lastCheckedBlockNumber = blockNumber;
        } else {
          tx.lastCheckedBlockNumber = Math.max(
            blockNumber!,
            tx.lastCheckedBlockNumber
          );
        }
      });
    },
    finalizeTransaction({ hash, chainId, receipt }: any) {
      const walletAddress  = get().chainSlice.chainProvider?.walletAddress;
      console.log('finalizeTransaction', hash, chainId, receipt);
      set((state: IStore) => {
        const { transactions } = state.chainSlice.transactionProvider!;
        const { pendingTransactions } = state.transactionManagerSlice!;
        
        const tx = transactions[chainId]?.[hash];
        if (!tx) {
          return;
        }
        tx.receipt = receipt;
        tx.confirmedTime = now();
        const ptx = pendingTransactions[walletAddress][chainId]?.[hash];
        if (!tx) {
          return;
        }
        ptx.response = {
          receipt,
          confirmedTime: now(),
          hash,
        };
        if (receipt.status === 1) {
          ptx.status = 'CLOSED';
        } else {
          ptx.status = 'ERROR';
          // TODO: Add error info to ptx.error
        }
      });
    },
  };

  const slice: ITransactionProvider = { ...state, ...actions };
  return slice;
};
