import Grid from '@mui/material/Grid';
import React, { Fragment, useMemo } from 'react';
import { useStore } from '../../store';
import { Box } from '../v1';
import './styles/Overview.scss';

import UpdateIcon from '@mui/icons-material/Update';
import { UseQueryResult } from 'react-query';
import { Link } from 'react-router-dom';
import {
  useFetchEvmPositionInfo,
  useFetchEvmPositions,
} from '../../features/positions/hooks';
import {
  BN,
  calculateProfits,
  numberParseLarge,
  profitsDataParser,
} from '../../helpers/utilities';
import textContext from '../../textContext';
import { IPNLDetails, PositionInfo } from '../../types';
import { ColoredValue, useMatchBreakpoints } from '@aperture/uikit';

type OverviewProps = {
  activeSubpage: 'PDN' | 'LO';
};

type OverviewData = {
  equity: string;
  tokenAPxUSDC: string;
  profits: IPNLDetails | undefined;
};

// component for the responsive top portion of page
export default function Overview(props: OverviewProps) {
  const language = useStore((state) => state.appSlice.language);
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const text = textContext[language as keyof object]['portfolio']['overview'];
  const [subpage, setSubpage] = React.useState<'PDN' | 'LO'>(
    props.activeSubpage
  );
  const { isSm } = useMatchBreakpoints();

  const handleActivePage = (name: 'PDN' | 'LO') => {
    setSubpage(name);
  };
  const { data: positionInfos } = useFetchEvmPositionInfo();
  const positionResults = useFetchEvmPositions(positionInfos);

  const total_PNL_data = useMemo(() => {
    let result = {
      equity: '0',
      tokenAPxUSD: '1',
      profits: {
        timestamp: new Date().getTime() / 1e3,
        net_deposit: '0',
        equity: '0',
        net_deposit_24h: '0',
        equity_24h_ago: '0',
        profits: '0',
        profits_percentage: 0,
        today_profits: '0',
        today_profits_percentage: 0,
      },
    };
    positionResults
      .map((res: UseQueryResult<any, any>) => {
        if (res.data) {
          const data = res.data as PositionInfo;
          return {
            equity: data.equity,
            tokenAPxUSDC: data.tokenAPxUSDC,
            profits: data.profits,
          } as OverviewData;
        }
        return {
          equity: '0',
          profits: undefined,
        } as OverviewData;
      })
      .forEach((item) => {
        if (BN(item.equity).gt(0)) {
          result.equity = BN(result.equity).plus(item.equity).toString();
          if (item.profits) {
            result.profits.equity = BN(result.profits.equity)
              .plus(BN(item.profits.equity).mul(item.tokenAPxUSDC))
              .toString();
            result.profits.equity_24h_ago = BN(result.profits.equity_24h_ago)
              .plus(BN(item.profits.equity_24h_ago).mul(item.tokenAPxUSDC))
              .toString();
            result.profits.profits = BN(result.profits.profits)
              .plus(BN(item.profits.profits).mul(item.tokenAPxUSDC))
              .toString();
            result.profits.net_deposit = BN(result.profits.net_deposit)
              .plus(BN(item.profits.net_deposit).mul(item.tokenAPxUSDC))
              .toString();
            result.profits.today_profits = BN(result.profits.today_profits)
              .plus(BN(item.profits.today_profits).mul(item.tokenAPxUSDC))
              .toString();
            result.profits.net_deposit_24h = BN(result.profits.net_deposit_24h)
              .plus(BN(item.profits.net_deposit_24h).mul(item.tokenAPxUSDC))
              .toString();
          }
        }
      });
    return result;
  }, [positionResults]);

  const total = useMemo(
    () =>
      total_PNL_data && total_PNL_data.equity
        ? `$${numberParseLarge(total_PNL_data.equity, 2)}`
        : `$${numberParseLarge(0, 2)}`,
    [total_PNL_data]
  );

  const profitsResult = useMemo(
    () =>
      total_PNL_data.profits
        ? calculateProfits(total_PNL_data.profits)
        : undefined,
    [total_PNL_data]
  );

  const totalPL = useMemo(() => {
    const profits_Text = profitsResult
      ? profitsDataParser(total_PNL_data.equity, profitsResult)
      : '$--';
    return profitsResult
      ? [profitsResult.profits, profits_Text]
      : ['', profits_Text];
  }, [total_PNL_data, profitsResult]);

  const todayPL = useMemo(() => {
    const profits_Text = profitsResult
      ? profitsDataParser(total_PNL_data.equity, profitsResult, undefined, true)
      : '$--';

    return profitsResult
      ? [
        profitsResult.today_profits,
        profits_Text,
      ]
      : ['', profits_Text];
  }, [total_PNL_data, profitsResult]);

  const ViewHistory = () => (
    <Link
      to="/portfolio/history"
      style={{ position: 'absolute', top: '33px', right: '2.50%' }}
    >
      <UpdateIcon fontSize="medium" sx={{ color: 'white' }} />
      <div className={`view-history${isSm ? ' subtitle-3' : ' button-5'}`}>
        {text.history}
      </div>
    </Link>
  );

  const SectionGroups = () => (
    <div className="section-groups subtitle-1">
      <div
        className={`section-name${subpage === 'PDN' ? ' active' : ''}`}
        onClick={() => handleActivePage('PDN')}
      >
        {isSm ? text.section.dn : text.section.pdn}
      </div>
      <div
        className={`section-name${subpage === 'LO' ? ' active' : ''}`}
        onClick={() => handleActivePage('LO')}
      >
        {text.section.lending}
      </div>
    </div>
  );

  const ValueOverview = () => {
    const values = [
      { title: 'Total P&L', text: totalPL[1], value: totalPL[0] },
      { title: 'Today’s P&L', text: todayPL[1], value: todayPL[0] },
    ];

    return values.map((item, i) => {
      return (
        <Grid item key={i} className="item-block">
          <p className={isSm ? 'subtitle-3' : 'subtitle-2'}>{item.title}</p>
          <p
            className={`${isSm ? 'headline-5' : 'headline-3'}`}
          >
            <ColoredValue value={Number(item.value)}>{item.text}</ColoredValue>
          </p>
        </Grid>
      );
    });
  };

  return (
    <Fragment>
      <Box
        style={{
          position: 'relative',
        }}
        className="overviewClass"
      >
        <div className={`title ${isSm ? 'headline-3' : 'headline-2'}`}>
          {text.title}
        </div>
        <Grid container className="totalContainer">
          <Grid item className="item-block">
            <p className={isSm ? 'headline-5' : 'subtitle-2'}>Total Value</p>
            <p className={isSm ? 'headline-2' : 'headline-1'}>
              {isWalletConnected ? total : '$--'}
            </p>
          </Grid>
          {isWalletConnected && <>{ValueOverview()}</>}
        </Grid>
      </Box>
    </Fragment>
  );
}
