import {
  forwardRef,
  useRef,
  useImperativeHandle,
  PropsWithChildren,
} from 'react';
import './dropdown.scss';
import { Popover } from '@mui/material';
import { useState } from 'react';

interface anchorEIPosition {
  anchorOrigin_vertical: 'top' | 'center' | 'bottom';
  anchorOrigin_horizontal: 'left' | 'right' | 'center';
  transformOrigin_vertical: 'top' | 'center' | 'bottom';
  transformOrigin_horizontal: 'left' | 'right' | 'center';
}
interface DropdownProps {
  trigger: any;
  direction: anchorEIPosition;
  popClassName?: string;
}

export const Dropdown = forwardRef<DropdownProps, PropsWithChildren<any>>(
  (
    {
      trigger,
      direction = {
        anchorOrigin_vertical: 'bottom',
        anchorOrigin_horizontal: 'right',
        transformOrigin_vertical: 'top',
        transformOrigin_horizontal: 'right',
      },
      popClassName,
      children,
      ...props
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const domRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
      closeDropDown: () => domRef.current! && handleClose(),
    }));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <div
          ref={domRef}
          className="popover-trigger"
          aria-describedby={id}
          onClick={handleClick}
          {...props}
        >
          {trigger}
        </div>
        <Popover
          className={popClassName as string}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: direction.anchorOrigin_vertical,
            horizontal: direction.anchorOrigin_horizontal,
          }}
          transformOrigin={{
            vertical: direction.transformOrigin_vertical,
            horizontal: direction.transformOrigin_horizontal,
          }}
        >
          {children}
        </Popover>
      </>
    );
  }
);
