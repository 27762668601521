import { SvgProps } from '..';

export const CopyIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${props.id}_copy_clip0)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.92188C0 5.137 0.637 4.5 1.42188 4.5H2.64062C2.80224 4.5 2.95724 4.5642 3.07152 4.67848C3.1858 4.79276 3.25 4.94776 3.25 5.10938C3.25 5.27099 3.1858 5.42599 3.07152 5.54027C2.95724 5.65455 2.80224 5.71875 2.64062 5.71875H1.42188C1.368 5.71875 1.31634 5.74015 1.27824 5.77824C1.24015 5.81634 1.21875 5.868 1.21875 5.92188V12.0156C1.21875 12.1277 1.30975 12.2188 1.42188 12.2188H7.51562C7.5695 12.2188 7.62116 12.1973 7.65926 12.1593C7.69735 12.1212 7.71875 12.0695 7.71875 12.0156V10.7969C7.71875 10.6353 7.78295 10.4803 7.89723 10.366C8.01151 10.2517 8.16651 10.1875 8.32812 10.1875C8.48974 10.1875 8.64474 10.2517 8.75902 10.366C8.8733 10.4803 8.9375 10.6353 8.9375 10.7969V12.0156C8.9375 12.3927 8.7877 12.7544 8.52104 13.021C8.25439 13.2877 7.89273 13.4375 7.51562 13.4375H1.42188C1.04477 13.4375 0.683111 13.2877 0.416458 13.021C0.149804 12.7544 0 12.3927 0 12.0156L0 5.92188Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.0625 1.85938C4.0625 1.0745 4.6995 0.4375 5.48438 0.4375H11.5781C12.363 0.4375 13 1.0745 13 1.85938V7.95312C13 8.33023 12.8502 8.69189 12.5835 8.95854C12.3169 9.2252 11.9552 9.375 11.5781 9.375H5.48438C5.10727 9.375 4.74561 9.2252 4.47896 8.95854C4.2123 8.69189 4.0625 8.33023 4.0625 7.95312V1.85938ZM5.48438 1.65625C5.4305 1.65625 5.37884 1.67765 5.34074 1.71574C5.30265 1.75384 5.28125 1.8055 5.28125 1.85938V7.95312C5.28125 8.06525 5.37225 8.15625 5.48438 8.15625H11.5781C11.632 8.15625 11.6837 8.13485 11.7218 8.09676C11.7598 8.05866 11.7812 8.007 11.7812 7.95312V1.85938C11.7812 1.8055 11.7598 1.75384 11.7218 1.71574C11.6837 1.67765 11.632 1.65625 11.5781 1.65625H5.48438Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id={`${props.id}_copy_clip0`}>
          <rect
            width="13"
            height="13"
            fill="black"
            transform="translate(0 0.4375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
