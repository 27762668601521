import React from 'react';
import './search.scss';

// Material
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { SearchIcon } from '@aperture/assetkit';
import { StrategyType } from '../../../../constants/crosschain';
import { ButtonGroups } from '../../Components/Button/ButtonGroups';

interface ISearchBarProps {
  handleSearch: (search: string) => void;
  setStrategy: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({ ...props }: ISearchBarProps) {
  const [search, setSearch] = React.useState('');

  const handleEnter = (t: string) => {
    setSearch(t);
    props.handleSearch(t);
  };

  const clearSearch = () => {
    setSearch('');
    props.handleSearch('');
  };

  return (
    <Grid container className="search">
      <Grid item className="strategy-button-group">
        <span className="subtitle-2">Strategy: </span>
        <ButtonGroups
          input={[
            { text: 'All', value: '' },
            { text: 'Bull Market', value: StrategyType.PSEUDO_1X_LONG },
            { text: 'Crab Market', value: StrategyType.PSEUDO_DELTA_NEUTRAL },
          ]}
          onSelect={(value: string) => props.setStrategy(value)}
        />
      </Grid>
      <Grid item className="wrapper">
        <InputBase
          value={search}
          onChange={(event) => handleEnter(event.target.value)}
          placeholder={search === '' ? 'Search Strategy, Assets or DEX' : ''}
          inputProps={{ 'aria-label': 'search' }}
          startAdornment={
            <SearchIcon
              style={{
                width: '20px',
                height: '20px',
                paddingRight: '8px',
              }}
            />
          }
        />
        <div className="searchWrapper">
          {search !== '' && (
            <HighlightOffIcon onClick={clearSearch} className="close-icon" />
          )}
        </div>
      </Grid>
    </Grid>
  );
}
