import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckMarkIcon, CompletedLogo, InvestLogo, QuestionMarkIcon } from '@aperture/assetkit';
import { MAX_UINT256 } from '../../constants/misc';
import { SerializedTokenInfo, TokenInfo } from '../../constants/tokenConfig';
import { useFetchToken } from '../../features/tokens/hooks';
import { BN } from '../../helpers/utilities';
import { useAddPopup } from '../../store/base/application/hooks';
import { PopupType } from '../../store/base/AppSlice';
import { Button } from '../v1';
import { Spinner } from '../v1/Components/Spinner';
import { QuestionMarkTooltip } from '@aperture/uikit';
import './styles/ApproveButton.scss';

export interface ApproveButtonProps {
  token: SerializedTokenInfo | null;
  rawToken: TokenInfo;
  tokenIsLoading: boolean;
  isApproved: boolean;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  isWalletConnected: boolean;
  walletAddress: string | null;
  approve: ((tokenAddr: string, amount: any) => Promise<any>) | null;
  amount: string;
}
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const QuestionMark = styled(QuestionMarkIcon)`
  path { fill: ${({ theme }) => (theme.colors.textAlt)}; }
`;

export default function ApproveButton({
  token,
  rawToken,
  tokenIsLoading,
  isApproved,
  setIsApproved,
  isWalletConnected,
  walletAddress,
  approve,
  amount,
}: ApproveButtonProps) {
  const { refetch: refetchToken } = useFetchToken(rawToken);
  const [isLoading, setIsLoading] = useState(false);
  const addPopup = useAddPopup();

  useEffect(() => {
    if (!setIsApproved || !token || isLoading) return;
    BN(token.allowance.toString()).lte(
      BN(amount).times(BN(10).pow(token.decimals))
    )
      ? setIsApproved(false)
      : setIsApproved(true);
  }, [
    isWalletConnected,
    setIsApproved,
    token,
    amount,
    walletAddress,
    isLoading,
  ]);
  const handleApprove = useCallback(async () => {
    if (isWalletConnected && token && token.tokenContractAddress) {
      if (approve) {
        try {
          // Approve max erc20 amount
          setIsLoading(true);
          await approve(token.tokenContractAddress, MAX_UINT256);
          await refetchToken();
          setIsLoading(false);
        } catch (err: any) {
          addPopup({
            [PopupType.ERROR]: {
              title: `${token?.name} Spending Approval`,
              msg: err.cause,
            },
          });
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [addPopup, approve, isWalletConnected, refetchToken, token]);

  const NotApprovedLogo = styled(InvestLogo)`
    linearGradient {
      stop:first-child {
        stop-color: ${({ theme }) => theme.colors.notApprovedIconColor1};
      }
      stop:nth-child(2) {
        stop-color: ${({ theme }) => theme.colors.notApprovedIconColor2};
      }
      stop:nth-child(3) {
        stop-color: ${({ theme }) => theme.colors.notApprovedIconColor3};
      }
    }
  `;

  const ApertureLogo = () =>
    !isWalletConnected || isApproved ? (
      <CompletedLogo />
    ) : (
      <NotApprovedLogo />
    );

  return (
    <div className="approveButton">
      <Button
        light={true}
        onClick={handleApprove}
        disabled={
          !isWalletConnected || isApproved || isLoading || tokenIsLoading
        }
        className={''}
      >
        <Grid
          alignItems="center"
          container
          direction="row"
          style={{ zIndex: '2' }}
        >
          <Grid item xs={1}>
            {ApertureLogo()}
          </Grid>
          <Grid item xs={10}>
            {isLoading || tokenIsLoading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <h4 className="medium" style={{ textTransform: 'none' }}>
                {!isApproved
                  ? `Allow Aperture to use your ${token?.ticker} `
                  : `Completed!`}
              </h4>
            )}
          </Grid>
          <Grid item xs={1}>
            {isApproved ? (
              <CheckMarkIcon />
            ) : (
              <QuestionMarkTooltip
                title={`You must give the Aperture smart contracts permission to use
                  your ${token?.ticker}. You only have to do this once per token.`}
                placementPC={'top-end'}
                placementMobile={'top-end'}
                children={<QuestionMark />}
              />
            )}
          </Grid>
        </Grid>
      </Button>
    </div>
  );
}
