import { SvgProps } from '..';

export const SettingIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.9275 25.03C7.86004 24.4127 5.98761 23.2707 4.4925 21.715C4.97355 21.1444 5.27342 20.4433 5.35374 19.7014C5.43407 18.9595 5.29119 18.2104 4.9434 17.5501C4.59561 16.8899 4.05872 16.3484 3.40143 15.9949C2.74414 15.6415 1.99635 15.4923 1.25375 15.5662C1.08457 14.7376 0.99956 13.8939 1 13.0481C1 11.7419 1.2 10.4819 1.5725 9.29812H1.625C2.26235 9.29833 2.88923 9.13609 3.44646 8.82672C4.00369 8.51735 4.47287 8.07106 4.80971 7.52999C5.14654 6.98893 5.33992 6.37094 5.37158 5.73438C5.40323 5.09781 5.27212 4.46369 4.99063 3.89187C6.45599 2.52739 8.22816 1.53552 10.1575 1C10.4715 1.6165 10.9499 2.13412 11.5398 2.49559C12.1297 2.85707 12.8081 3.0483 13.5 3.04812C14.1919 3.0483 14.8703 2.85707 15.4602 2.49559C16.0501 2.13412 16.5285 1.6165 16.8425 1C18.7718 1.53552 20.544 2.52739 22.0094 3.89187C21.7258 4.46769 21.5948 5.10665 21.6289 5.7476C21.663 6.38854 21.861 7.01001 22.204 7.55251C22.547 8.09502 23.0235 8.54041 23.5879 8.84605C24.1523 9.15169 24.7857 9.30735 25.4275 9.29812C25.8081 10.5117 26.0011 11.7763 26 13.0481C26 13.9106 25.9125 14.7531 25.7462 15.5662C25.0036 15.4923 24.2559 15.6415 23.5986 15.9949C22.9413 16.3484 22.4044 16.8899 22.0566 17.5501C21.7088 18.2104 21.5659 18.9595 21.6463 19.7014C21.7266 20.4433 22.0264 21.1444 22.5075 21.715C21.0124 23.2707 19.14 24.4127 17.0725 25.03C16.8297 24.2733 16.3529 23.6132 15.7109 23.145C15.0688 22.6767 14.2947 22.4243 13.5 22.4243C12.7053 22.4243 11.9312 22.6767 11.2891 23.145C10.6471 23.6132 10.1703 24.2733 9.9275 25.03Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 17.4228C14.0745 17.4228 14.6434 17.3097 15.1742 17.0898C15.705 16.87 16.1873 16.5477 16.5936 16.1414C16.9998 15.7352 17.3221 15.2529 17.542 14.7221C17.7618 14.1913 17.875 13.6224 17.875 13.0479C17.875 12.4733 17.7618 11.9044 17.542 11.3736C17.3221 10.8428 16.9998 10.3605 16.5936 9.95426C16.1873 9.548 15.705 9.22574 15.1742 9.00588C14.6434 8.78601 14.0745 8.67285 13.5 8.67285C12.3397 8.67285 11.2269 9.13379 10.4064 9.95426C9.58594 10.7747 9.125 11.8875 9.125 13.0479C9.125 14.2082 9.58594 15.321 10.4064 16.1414C11.2269 16.9619 12.3397 17.4228 13.5 17.4228V17.4228Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
