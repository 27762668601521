import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../contexts';
import { LoaderProps } from './types';

const BorderLinearProgress = styled(LinearProgress)<{
  size?: string;
  height?: string;
  sm: boolean;
}>`
  height: ${({ sm, height }) =>
    height ? height : sm ? '14px' : '28px'} !important;
  opacity: 0.1;
  border-radius: ${({ sm }) => (sm ? '4' : '8')}px;
  width: ${({ size }) => (size ? size : '100px')};
`;

export const Loader: React.FC<LoaderProps> = ({ size, height }) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <BorderLinearProgress
      color="inherit"
      size={size}
      height={height}
      sm={isSm}
    />
  );
};
