import React from 'react';
import styled from 'styled-components';
import { ColoredValue } from '../../../../components/ColoredValue';
import { Loader } from '../../../../components/Loader';
import { ProgressBar } from '../../../../components/ProgressBar';
import { QuestionMarkTooltip } from '../../../../components/Tooltip';
import {
  Body1,
  Headline4,
  Headline5,
  Subtitle1,
  Subtitle2,
  Subtitle3,
} from '../../../../components/Typography';
import { numberParse } from '../../../../helper';
import { APYInfo } from '../../CardInfo';
import { StrategyProps } from '../../StrategyDropdown/types';
import { IDataInfo } from './types';

const StyledOverview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3.93fr;
  width: 100%;
  gap: 12px;
  margin-top: 16px;
`;
const StyledSubContainer = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => `${gap}px`};
  padding: 16px 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.backgroundActivities};
`;
const StyledItemContainer = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? `${gap}px` : '24px')};
`;
const StyledInfoDetail = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? `${gap}px` : '12px')};
`;
const StyledCurrentValue = styled(Headline4)`
  font-weight: 500 !important;
`;
const StyledInfoTitle = styled(Subtitle2)`
  color: ${({ theme }) => theme.colors.overallInfoText} !important;
`;
const StyledTVLDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
const StyledAPYContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const StyledAPYTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledAPYDetailTitle = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.overallInfoText} !important;
`;

const OverallInfo: React.FC<{ strategy: StrategyProps }> = ({ strategy }) => {
  const APY: APYInfo[] = [
    {
      title: 'Real-Time',
      value: strategy.apy.apy,
    },
    {
      title: '7-Day',
      value: strategy.apy.apy_7days,
    },
    {
      title: '3-Month',
      value: strategy.apy.apy_season,
    },
  ];
  const strategyInfo: IDataInfo[] = [
    { title: 'Underlying Pool', value: strategy.underlyingPool },
    { title: 'Target Leverage', value: `${strategy.leverage}X` },
    { title: 'Vault Inception Date', value: strategy.inceptionDate },
    {
      title: 'Price Movement Rebalance Threshold',
      value: numberParse(strategy.threshold, 'percentage'),
    },
    {
      title: 'Maximum Drawdown',
      value: numberParse(strategy.maxDrawdown, 'percentage'),
    },
    // { title: 'Sharpe Ratio', value: strategy.sharpRatio },
    {
      title: 'Return Since Vault Inception',
      value: numberParse(strategy.returnChanges, 'percentage'),
    },
    { title: 'Number of Rebalances', value: strategy.rebalances },
  ];

  const displayData = (dataInfo: IDataInfo[]) =>
    dataInfo.map((item, index) => {
      return (
        <StyledInfoDetail key={`${item.title}-${index}`}>
          <StyledInfoTitle>{item.title}</StyledInfoTitle>
          <Headline5>{item.value}</Headline5>
        </StyledInfoDetail>
      );
    });

  return (
    <div>
      <StyledOverview>
        <Subtitle1>Strategy Overview</Subtitle1>
        {strategy.strategyType === 'crab' ? (
          <Body1>
            Crab Market Leveraged Farming is an auto rebalancing vault that
            allows users to earn 3X the amount of swap fees less borrowing
            costs. Users enter with and accrue yield in stable coins.
          </Body1>
        ) : strategy.strategyType === 'bull' ? (
          <Body1>
            Bull Market Leveraged Farming is an auto rebalancing vault that
            allows users to earn 3X the amount of swap fees less borrowing
            costs. Users enter with and accrue yield in a volatile asset.
          </Body1>
        ) : (
          ''
        )}
      </StyledOverview>
      <StyledContainer>
        <StyledSubContainer gap={32}>
          <StyledItemContainer gap={32}>
            <StyledInfoDetail gap={16}>
              <StyledInfoTitle>Current Value</StyledInfoTitle>
              <StyledCurrentValue>
                {strategy.position?.currentValue}
              </StyledCurrentValue>
            </StyledInfoDetail>
            {strategy.position?.active && (
              <StyledInfoDetail>
                <StyledInfoTitle>Profits</StyledInfoTitle>
                <Headline5>
                  <ColoredValue value={Number(strategy.position?.profits[0])}>
                    {strategy.position?.profits[1]}
                  </ColoredValue>
                </Headline5>
              </StyledInfoDetail>
            )}
          </StyledItemContainer>
        </StyledSubContainer>
        <StyledSubContainer gap={48}>
          <StyledItemContainer>
            <StyledInfoDetail>
              <StyledInfoTitle>TVL / Capacity</StyledInfoTitle>
              {strategy.tvlCapacity ? (
                <div>
                  <StyledTVLDetail>
                    <Headline5>{strategy.tvlCapacity.content}</Headline5>
                    <Headline5>
                      {numberParse(
                        strategy.tvlCapacity.percentage,
                        'percentage'
                      )}
                    </Headline5>
                  </StyledTVLDetail>
                  <ProgressBar
                    percentage={strategy.tvlCapacity.percentage}
                    fullWidth={true}
                  />
                </div>
              ) : (
                <Loader size="60%" height="16px" />
              )}
            </StyledInfoDetail>
            {displayData(strategyInfo.slice(0, 3))}
          </StyledItemContainer>
          <StyledItemContainer>
            <StyledAPYContainer>
              <StyledAPYTitle>
                <StyledInfoTitle>Projected Annualized Returns</StyledInfoTitle>
                <QuestionMarkTooltip
                  title={`The 7-day and 3-month APYs are calculated based on real performance 
                    over the designated period of time, with all the fees included. 
                    The real-time APY is calculated assuming the same market condition 
                    where fees are not applicable.`}
                  placementPC={'bottom'}
                  toolTipClassName={'apy-bottom-tooltip'}
                />
              </StyledAPYTitle>
              {APY.map((item, index) => {
                return (
                  <StyledInfoDetail gap={10} key={`${item.title}-${index}`}>
                    <StyledAPYDetailTitle>{item.title}</StyledAPYDetailTitle>
                    {item.value === undefined ? (
                      <Loader size="50px" height="16px" />
                    ) : (
                      <Headline5>
                        {numberParse(item.value, 'percentage')}
                      </Headline5>
                    )}
                  </StyledInfoDetail>
                );
              })}
            </StyledAPYContainer>
            {displayData(strategyInfo.slice(3, 4))}
          </StyledItemContainer>
          <StyledItemContainer>
            {displayData(strategyInfo.slice(4))}
          </StyledItemContainer>
        </StyledSubContainer>
      </StyledContainer>
    </div>
  );
};
export default OverallInfo;
