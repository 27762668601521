import React, { useState } from 'react';
import styled from 'styled-components';
import { Headline5 } from '../../../components/Typography';
import { useMatchBreakpoints } from '../../../contexts';
import { AuthUserButtonProps } from './types';

const StyledAuthUserButton = styled.div<{ sm: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ sm }) => (sm ? '0px' : '4px')};
  width: fit-content;
  background: ${({ theme }) => theme.colors.backgroundMobileNavButton};
  border-radius: 8px;
  > span {
    display: flex;
  }
`;
const StyledWallet = styled.div<{ sm: boolean; open: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ sm }) => (sm ? '7px 9px' : '7px')};
  gap: ${({ sm }) => (sm ? '4px' : '10px')};
  background: ${({ theme }) => theme.colors.backgroundWallet};
  ${({ open, theme }) =>
    open
      ? `border: 1px solid ${theme.colors.border}`
      : `border: 1px solid transparent`};
  border-radius: ${({ sm }) => (sm ? '8px' : '4px')};
  box-sizing: border-box;
  :hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.border};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;
const StyledBalance = styled(Headline5)`
  padding: 0px 11px;
`;

export const AuthUserButton: React.FC<AuthUserButtonProps> = ({
  walletInfo,
  openDropdown,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  const [open, setOpen] = useState(false);
  return (
    <StyledAuthUserButton
      {...props}
      sm={isSm}
      onClick={() => {
        setOpen(!open);
        openDropdown();
      }}
    >
      <StyledWallet sm={isSm} open={open}>
        <walletInfo.walletIcon />
        <Headline5>{walletInfo.walletAddress}</Headline5>
      </StyledWallet>
      {!isSm && (
        <StyledBalance>{`${walletInfo.balance} ${walletInfo.currencySymbol}`}</StyledBalance>
      )}
    </StyledAuthUserButton>
  );
};
