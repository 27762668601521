import React from 'react';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../../contexts';
import { UnderlyingPoolProps } from './types';

const ProtocolContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: inherit;
`;

export const UnderlyingPool: React.FC<UnderlyingPoolProps> = ({
  showIcon,
  icon,
  text,
}) => {
  const { isSm } = useMatchBreakpoints();
  const ProtocolIcon = styled(icon)`
    width: 16px;
    height: 16px;
    padding-right: 4px;
    filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.199301));
  `;
  return (
    <ProtocolContainer>
      {((showIcon === undefined && !isSm) || showIcon) && <ProtocolIcon />}
      {text}
    </ProtocolContainer>
  );
};
