/**
 * The main app component.
 * @returns None
 */
import React from 'react';
import { useStore } from '.';
import { NetworkType } from '../constants/base/network';
import { useInitSubscribeSlices } from './hooks';

function InitStore() {
  // Initialize Zustand store here.
  const subscribeSlices = useInitSubscribeSlices();

  const setNetworkType = useStore((state) => state.appSlice.setNetworkType);
  const setDoneInit = useStore((state) => state.appSlice.setDoneInit);

  React.useEffect(() => {
    if (setNetworkType) {
      setNetworkType(NetworkType.MAINNET);
    }
  }, [setNetworkType]);

  React.useEffect(() => {
    subscribeSlices(setDoneInit);
  }, []);

  return <></>;
}

export default InitStore;
