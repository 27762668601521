import React from 'react';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../contexts';
import { Tag } from '../Tag';
import { ProgressBarProp } from './types';

const BarContainer = styled.div<{
  sm: boolean;
  fullWidth: boolean;
  percentage: number;
}>`
  display: grid;
  width: ${({ fullWidth, sm }) => (fullWidth ? '100%' : sm ? '63px' : '150px')};
  height: ${({ sm }) => (sm ? '7px' : '10px')};
  padding-top: 8px;
  grid-template-columns: ${({ percentage }) =>
    percentage === 0 || percentage === 1
      ? '100%'
      : percentage * 100 + '% ' + (1 - percentage) * 100 + '%'};
  div:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  div:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
const BarLeft = styled.div<{ sm: boolean }>`
  height: ${({ sm }) => (sm ? '7px' : '10px')};
  background: ${({ theme }) => theme.colors.positive};
`;
const BarRight = styled.div<{ sm: boolean }>`
  height: ${({ sm }) => (sm ? '7px' : '10px')};
  background: ${({ theme }) => theme.colors.backgroundBar};
`;

export const ProgressBar: React.FC<ProgressBarProp> = ({
  fullWidth = false,
  percentage = 0.5,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  const minBarPercentage = isSm ? 0.1 : 0.04;
  if (percentage > 0 && percentage < minBarPercentage) {
    percentage = minBarPercentage;
  } else if (percentage < 1 && percentage > 1 - minBarPercentage) {
    percentage = 1 - minBarPercentage;
  }
  return (
    <div {...props}>
      {percentage < 1 || fullWidth ? (
        <BarContainer sm={isSm} fullWidth={fullWidth} percentage={percentage}>
          {percentage > 0 && <BarLeft sm={isSm} />}
          {percentage < 1 && <BarRight sm={isSm} />}
        </BarContainer>
      ) : (
        <Tag variant={'full'} />
      )}
    </div>
  );
};
