import { SvgProps } from '../../../../components/Svg/types';

export const WrongNetworkIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6427 16.4709L2.43365 6.63621C2.97685 6.17133 3.55486 5.76126 4.15163 5.38677L3.16695 4.40213C2.40504 4.90186 1.67709 5.46414 1.00027 6.10013C0.727262 6.35659 0.713347 6.78533 0.969762 7.05834L11.1484 17.928C11.2763 18.0652 11.4559 18.1427 11.6434 18.1427H11.6441C11.8316 18.1427 12.0112 18.0646 12.1398 17.9274L14.3375 15.5726L13.3771 14.6122L11.6427 16.4709Z"
        fill="black"
      />
      <path
        d="M22.5 6.53488C22.4941 6.35462 22.4165 6.18501 22.2853 6.06176C19.3795 3.35012 15.6003 1.85714 11.6436 1.85714C9.5752 1.85714 7.56658 2.30189 5.69316 3.09003L3.10308 0.5L2.14355 1.45953L20.184 19.5L21.1436 18.5405L16.1905 13.5874L22.3185 7.02133C22.4411 6.88948 22.5067 6.71452 22.5001 6.53493L22.5 6.53488ZM15.2301 12.6269L6.7336 4.13047C8.29426 3.54502 9.94679 3.21423 11.6436 3.21423C15.0291 3.21423 18.2736 4.41231 20.852 6.60242L15.2301 12.6269Z"
        fill="black"
      />
    </svg>
  );
};
