import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { PerformanceProps } from '.';
import { Box, GraphBox } from '../../components/Box';
import { ButtonGroups } from '../../components/ButtonGroups';
import { LineChart } from '../../components/Chart/LineGraph';
import { YAxisTitle } from '../../components/Chart/YAxisTitle';
import { ColoredValue } from '../../components/ColoredValue';
import { Body1, Headline4, Headline5 } from '../../components/Typography';
import { useMatchBreakpoints } from '../../contexts';
import { getTimeIntervalText, numberParse, TimeframeType } from '../../helper';
import { ExpandedIcon } from '../Icon/ExpandedIcon';

const StyledButtonGroups = styled(ButtonGroups)<{ sm: boolean }>`
  ${({ sm }) =>
    sm
      ? `
      align-items: center;
      justify-content: center;`
      : `
      position: absolute;
      right: 32px;`}
`;
const H5Container = styled.div`
  margin-top: 8px;
`;
const StyledH5 = styled(Headline5)`
  margin-top: 8px;
  margin-right: 8px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.tableHeaderText};
`;
const StyledBody1 = styled(Body1)`
  margin-bottom: 8px;
  display: block;
  color: ${({ theme }) => theme.colors.tableHeaderText};
`;
const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;
const StyledBox = styled(Box)`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Performance: React.FC<PerformanceProps> = ({
  id,
  type = 'default',
  data = {
    [TimeframeType.DAY]: [],
    [TimeframeType.WEEK]: [],
    [TimeframeType.MONTH]: [],
    [TimeframeType.SEASON]: [],
    [TimeframeType.INCEPTION]: [],
  },
  payload,
  openStrategyDetails,
  ...props
}) => {
  const theme = useTheme();
  const { isSm: mobile } = useMatchBreakpoints();
  const [timeRange, setTimeRange] = useState<TimeframeType>(TimeframeType.WEEK);
  const PnlDiff =
    data[timeRange].length === 0
      ? 0
      : data[timeRange][data[timeRange].length - 1].p_and_l -
        data[timeRange][0].p_and_l;

  const tooltipValue = [
    {
      color: theme.colors.graphLine1,
      text: 'Total Position Value',
      value: 'position_value',
      suffix: ` ${payload.tokens[0]}`,
    },
    {
      color: theme.colors.graphLine2,
      text: `Total Net Deposits`,
      value: 'net_deposit',
      suffix: ` ${payload.tokens[0]}`,
    },
    {
      color: theme.colors.transparent,
      text: `Total Profits & Loss`,
      value: 'p_and_l',
      suffix: ` ${payload.tokens[0]}`,
      colorChanging: true,
      addSignForValue: true,
      changingRate: 'percentage',
    },
  ];

  const tooltipValueMobile = [
    {
      color: theme.colors.graphLine1,
      text: 'Position',
      value: 'position_value',
      suffix: ` ${payload.tokens[0]}`,
    },
    {
      color: theme.colors.graphLine2,
      text: `Deposits`,
      value: 'net_deposit',
      suffix: ` ${payload.tokens[0]}`,
    },
  ];

  const Content = (
    <GraphBox mini={type === 'mini'} {...props}>
      {!mobile && type === 'mini' && (
        <ExpandedIcon
          className="chart-expanded-icon"
          onClick={openStrategyDetails}
        />
      )}
      <StyledButtonGroups
        sm={mobile || type === 'mini'}
        defaultSelect={1}
        input={[
          { text: '1D', value: TimeframeType.DAY },
          { text: '1W', value: TimeframeType.WEEK },
          { text: '1M', value: TimeframeType.MONTH },
          { text: '3M', value: TimeframeType.SEASON },
          { text: 'ALL', value: TimeframeType.INCEPTION },
        ]}
        onSelect={(value: TimeframeType) => setTimeRange(value)}
        type={type === 'mini' ? 'small' : 'default'}
      />
      {type !== 'mini' && !mobile && (
        <TitleWrapper>
          <Headline4>My Position</Headline4>
          <H5Container>
            <StyledH5>
              Profit and Loss:
              <ColoredValue value={PnlDiff}>
                {' '}
                {numberParse(
                  PnlDiff,
                  undefined,
                  undefined,
                  4,
                  undefined,
                  payload.tokens[0]
                )}
                (
                {numberParse(
                  PnlDiff /
                    data[timeRange][data[timeRange].length - 1].net_deposit,
                  'percentage',
                  undefined,
                  3
                )}
                ){' '}
              </ColoredValue>
              {getTimeIntervalText(timeRange, data[timeRange])}
            </StyledH5>
          </H5Container>
        </TitleWrapper>
      )}

      {type !== 'mini' && !mobile && (
        <YAxisTitle left={`Value(${payload.tokens[0]})`} right={``} />
      )}
      <LineChart
        id={id}
        data={data[timeRange]}
        referenceAmount={0}
        type={type === 'mini' || mobile ? 'mini' : 'full'}
        legend={type === 'mini' && !mobile ? false : true}
        payload={{ ...{ timeframe: timeRange }, ...payload }}
        tooltipInfo={{
          day: timeRange === TimeframeType.WEEK,
          hours: true,
          value: type === 'mini' || mobile ? tooltipValueMobile : tooltipValue,
        }}
        onClick={openStrategyDetails}
      />
    </GraphBox>
  );

  if (mobile) {
    return (
      <>
        <StyledBox>
          <StyledBody1>Profit & Loss:</StyledBody1>
          <Body1>
            <ColoredValue value={PnlDiff}>
              {' '}
              {numberParse(
                PnlDiff - data[timeRange][0].p_and_l,
                undefined,
                undefined,
                4,
                undefined,
                payload.tokens[0]
              )}
              (
              {numberParse(
                (PnlDiff - data[timeRange][0].p_and_l) /
                  data[timeRange][data[timeRange].length - 1].net_deposit,
                'percentage',
                undefined,
                3
              )}
              ){' '}
            </ColoredValue>
            {getTimeIntervalText(timeRange, data[timeRange])}
          </Body1>
        </StyledBox>
        {Content}
      </>
    );
  } else {
    return <>{Content}</>;
  }
};
