import { useState, useRef, forwardRef, PropsWithChildren } from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Dropdown } from '../../Components/Dropdown';
import './index.scss';
import { useStore } from '../../../../store';
import { Blockchain, DefaultEvmChain, chainList } from '../../../../constants/crosschain';
import { Button } from '../../Components/Button';

interface SwitchNetworkProps {
  mobile: boolean;
  onSelect?: (chainId: any) => void;
}

interface ConfigObject {
  [key: string]: number;
}

export const NetworkMap: ConfigObject = {
  [Blockchain.AVALANCHE]: 0,
  [Blockchain.SOLANA]: 1,
};

export const SwitchNetwork = forwardRef<
  SwitchNetworkProps,
  PropsWithChildren<any>
>(({ mobile = false, onSelect, ...props }, ref) => {
  const chain = useStore((state) => state.chainSlice.chain);
  const [selected, setSelected] = useState(chain ? chain : DefaultEvmChain);

  const childRef = useRef<HTMLElement>(null);

  const onChainSelect = (chainId: any) => {
    setSelected(chainId);
    onSelect && onSelect(chainId);
    childRef.current!.closeDropDown();
  };

  const SelectedIcon = chainList[NetworkMap[selected]].icon;

  return (
    <>
      <Dropdown
        style={{ marginRight: mobile ? '8px' : '12px' }}
        ref={childRef}
        direction={{
          anchorOrigin_vertical: 'bottom',
          anchorOrigin_horizontal: 'left',
          transformOrigin_vertical: 'top',
          transformOrigin_horizontal: 'left',
        }}
        trigger={
          <div
            {...props}
            style={{ padding: mobile ? '6px 8px' : '8px 16px' }}
            className="authNetwork-trigger"
          >
            <SelectedIcon width={mobile ? 16 : 20} className="authNetwork-icon" />
            {!mobile && (
              <span className="authNetwork-ticker">
                <div className="button-3">
                  {chainList[NetworkMap[selected]].name}
                </div>
              </span>
            )}
            <KeyboardArrowDown fontSize="small" />
          </div>
        }
      >
        <div className="authNetwork-content">
          <div className={`title ${mobile ? 'subtitle-1' : 'subtitle-2'}`}>
            Switch Network
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {chainList.map((chainInfo, i) => {
              const ChainInfoIcon = chainInfo.icon;
              return (
                <div
                  onClick={() =>
                    !chainInfo.disabled && onChainSelect(chainInfo.chain)
                  }
                  className={`authNetwork-list${chainInfo.disabled ? ' network-disabled' : ''
                    } ${chainInfo.chain === selected}`}
                  key={i}
                >
                  <ChainInfoIcon width="16" className="authNetwork-list-icon" />
                  <div className="button-3">{chainInfo.name}</div>
                  {chainInfo.disabled && (
                    <Button
                      className="label-soon"
                      variant="outline"
                      size="small"
                      text="Coming soon"
                    />
                  )}
                  {chainInfo.chain === selected && (
                    <div className="circular"></div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Dropdown>
    </>
  );
});
