import styled from 'styled-components';

const Container = styled.div`
  padding: 16px;
  padding-left: 276px;
  top: 0;
  background-color: ${(props) => props.theme.colors.text};
  color: ${(props) => props.theme.colors.textAlt};
  @media only screen and (max-width: 1200px) {
    padding-left: 16px;
  }
  a {
    color: #F8A13A;
  }
`;
export default function RetirementBanner() {
  return (
    <Container>
      We have officially sunsetted our Pseudo Delta Neutral vaults and they are
      in withdrawing-only mode. You can sign up for our new Uniswap V3 liquidity
      management tool{' '}
      <a
        href="https://d40pie7y7yv.typeform.com/to/PKoJRAZs?typeform-source=bafybeib4ja5vtdnfhjhhbawsre236esd3ocdgjwyyew2tz76vbtnfurgza.on.fleek.co"
        target="_blank"
      >
        here
      </a>{' '}
      for early access and potential airdrops.{' '}
    </Container>
  );
}
