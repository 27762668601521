import {
  BookIcon,
  DiscordIcon,
  MediumIcon,
  TwitterIcon,
  WebsiteIcon,
} from '@aperture/assetkit';
import { ThemeSwitch } from '@aperture/uikit';
import { Link } from 'react-router-dom';
import textContext from '../../../../textContext';
import './footer.scss';

export const Footer = ({ mobile = false, ...props }) => {
  const text = textContext['english'].navbar;
  const socialLinks = [
    {
      icon: <DiscordIcon width="25" />,
      link: 'https://discord.com/invite/MGHguks25G',
    },
    {
      icon: <TwitterIcon width="25" />,
      link: 'https://twitter.com/ApertureFinance',
    },
    {
      icon: <MediumIcon width="25" />,
      link: 'https://medium.com/@aperturefinance',
    },
  ];

  const officialLinks = [
    {
      icon: <BookIcon width="20" height="23" />,
      link: 'https://docs.aperture.finance/docs/',
      text: 'Aperture Docs',
    },
    {
      icon: <WebsiteIcon width="20" />,
      link: 'https://aperture.finance',
      text: 'Official Website',
    },
  ];

  return (
    <footer>
      <div className="footerSocial">
        {socialLinks.map((s, i) => (
          <div key={i}>
            <a href={s.link} target="_blank" rel="noreferrer">
              {s.icon}
            </a>
          </div>
        ))}
      </div>
      <div className="footer-items">
        <div className="footerOfficial">
          {officialLinks.map((off, i) => (
            <div key={i}>
              <a href={off.link} target="_blank" rel="noreferrer">
                {off.icon}
                <span className={mobile ? 'button-3' : 'subtitle-2'}>
                  {off.text}
                </span>
              </a>
            </div>
          ))}
        </div>
        <div className="footerLink">
          {text.footer.links.map((l, i) => (
            <div key={i}>
              <Link className="linkClass" to={`/${l.link}`}>
                <div
                  className={`textSecondary ${
                    mobile ? 'subtitle-1' : 'subtitle-2'
                  }`}
                >
                  {l.text}
                </div>
              </Link>
            </div>
          ))}
          <div>
            <a
              className="audits subtitle-1"
              href="https://docs.aperture.finance/docs/transparency/security-audit"
              target="_blank"
              rel="noreferrer"
            >
              Audits
            </a>
          </div>
        </div>
        <span className="linkClass">
          <ThemeSwitch
            darkMode={props.darkMode}
            onChange={(dark: boolean) => props.onChange(dark)}
          />
        </span>
      </div>
    </footer>
  );
};
