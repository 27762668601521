import { Link, useLocation } from 'react-router-dom';
import textContext from '../../../../textContext';
import './navLink.scss';

interface NavLinkProps {
  active?: boolean;
  mobile: boolean;
  onClick?: () => void;
}

const ComingSoon = (props) => (
  <div className="navDisabled">
    <div>
      <p>{props.text}</p>
    </div>
  </div>
);

export const NavLink = ({ active = false, mobile, ...props }: NavLinkProps) => {
  const text = textContext['english'].navbar;
  const { pathname } = useLocation();

  return (
    <div className="navLinks">
      {text.navLinks.links.map((nav, i) => {
        const NavIcon = nav.icon;
        return (
          <Link
            className={`linkClass${pathname === '/' && i === 0 ? ' active' : ''}${pathname.includes(nav.link) ? ' active' : ''
              }${nav.disabled ? ' linkDisabled' : ''}`}
            to={`/${nav.link}`}
            key={i}
          >
            <NavIcon width="17" style={{
              marginRight: '8px',
              height: 'auto'
            }} />
            <p className={mobile ? 'button-1' : 'button-2'}>{nav.text}</p>
            {nav.disabled && <ComingSoon text={text.soon} />}
          </Link>
        )
      })}
    </div>
  );
};
