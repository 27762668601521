import { getAxios } from '../../../api/axios/generic-api-calls';

import {
  homoraApi,
  HomoraApyResponse,
  homoraEndPoints,
  ironBankApi,
  ironBankApyResponse,
  ironBankEndPoints,
  ProtocolDataResponses,
} from '../../../api/axios/api-config';
import { keys } from '../../keyNames';
import { useQuery } from 'react-query';

async function getProtocolData(): Promise<ProtocolDataResponses> {
  const apyPromise = getAxios<HomoraApyResponse>(
    homoraApi,
    homoraEndPoints.apys
  );
  const interestPromise = getAxios<ironBankApyResponse>(
    ironBankApi,
    ironBankEndPoints.rates
  );

  const [apyResp, interestResp] = await Promise.all([
    apyPromise,
    interestPromise,
  ]);
  const apys = apyResp.data;
  const rates = interestResp.data;
  return { apys, rates };
}

export default function useFetchProtocolData(options?: any) {
  return useQuery(
    [keys.protocolData],
    async () => {
      return getProtocolData();
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
}
