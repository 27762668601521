import { TransactionResponse } from '@ethersproject/providers';
// import { Token } from '@uniswap/sdk-core'
import { useCallback, useMemo } from 'react';

import { TransactionDetails, TransactionInfo, TransactionType } from './types';
import { useStore } from '../../../';

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (
  response: TransactionResponse,
  info: TransactionInfo
) => void {
  const _evmChainInfo = useStore(
    (state: any) => state.chainSlice.chainProvider?._evmChainInfo
  );
  const account = useStore(
    (state: any) => state.chainSlice.chainProvider?.account
  );
  const addTransaction = useStore(
    (state: any) => state.chainSlice.transactionProvider.addTransaction
  );

  const { chainId } = _evmChainInfo;

  return useCallback(
    (response: TransactionResponse, info: TransactionInfo) => {
      if (!account) return;
      if (!chainId) return;

      const { hash } = response;
      if (!hash) {
        throw Error('No transaction hash found.');
      }
      addTransaction({ hash, from: account, info, chainId });
    },
    [account, chainId, addTransaction]
  );
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const _evmChainInfo = useStore(
    (state: any) => state.chainSlice.chainProvider?._evmChainInfo
  );
  const transactions = useStore(
    (state: any) => state.chainSlice.transactionProvider?.transactions
  );
  const { chainId } = _evmChainInfo;

  return chainId ? transactions[chainId] ?? {} : {};
}

export function useTransaction(
  transactionHash?: string
): TransactionDetails | undefined {
  const allTransactions = useAllTransactions();

  if (!transactionHash) {
    return undefined;
  }

  return allTransactions[transactionHash];
}

export function useIsTransactionPending(transactionHash?: string): boolean {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return !transactions[transactionHash].receipt;
}

export function useIsTransactionConfirmed(transactionHash?: string): boolean {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return Boolean(transactions[transactionHash].receipt);
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx: TransactionDetails): boolean {
  return new Date().getTime() - tx.addedTime < 86_400_000;
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(
  token?: Token,
  spender?: string
): boolean {
  const allTransactions = useAllTransactions();
  return useMemo(
    () =>
      typeof token?.address === 'string' &&
      typeof spender === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash];
        if (!tx) return false;
        if (tx.receipt) {
          return false;
        } else {
          if (tx.info.type !== TransactionType.APPROVAL) return false;
          return (
            tx.info.spender === spender &&
            tx.info.tokenAddress === token.address &&
            isTransactionRecent(tx)
          );
        }
      }),
    [allTransactions, spender, token?.address]
  );
}
