import { useStore } from '../../store';
import textContext from '../../textContext';

// Comps
// import Search from './Search';

// Aperture uikit
import { useMatchBreakpoints } from '@aperture/uikit';
import Invest from './Invest';

// TODO: add storybook
export default function InvestContainer() {
  const { isSm } = useMatchBreakpoints();
  const language = useStore((state) => state.appSlice.language);
  const text =
    textContext[language as keyof object]['investment' as keyof object][
      'invest' as keyof object
    ];

  return (
    <>
      <Invest
        filters={text['filterPlus' as keyof object]}
        title={
          text['PDN' as keyof object][
            (isSm ? 'mobileTitle' : 'title') as keyof object
          ]
        }
      />
    </>
  );
}
