import {
  forwardRef,
  PropsWithChildren,
  useRef,
  useImperativeHandle,
} from 'react';
import './modal.scss';
import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';
import { CrossIcon } from '@aperture/assetkit';

interface ModalProps {
  cross?: boolean;
  open: boolean;
  onClose?: () => void;
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root.MuiDrawer-paper {
    background: ${({ theme }) => theme.colors.backgroundStrategyContainer};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;
export const MobilePopOver = forwardRef<ModalProps, PropsWithChildren<any>>(
  ({ open = true, cross = false, onClose, children, ...props }, ref) => {
    const domRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    const Cross = () => (
      <div className="cross" onClick={onClose}>
        <StyledCrossIcon />
      </div>
    );
    return (
      <>
        <StyledDrawer
          anchor={'bottom'}
          open={open}
          onClose={onClose}
          {...props}
        >
          {cross && <Cross />}
          {children}
        </StyledDrawer>
      </>
    );
  }
);
