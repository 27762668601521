import { useMatchBreakpoints } from '@aperture/uikit';
import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StrategyTypeTextMap } from '../../constants/crosschain';
import { PositionInfoMap } from '../../constants/position';
import { ProtocolInfo } from '../../constants/protocolConfig';
import { StrategyInfo, StrategyInfoMap } from '../../constants/strategy';
import { strategies } from '../../constants/strategy/strategies';
import { BN, getVaultName } from '../../helpers/utilities';
import { PositionInfo } from '../../types';
import TableLoader from '../Investment/TableLoader';
import { Box } from '../v1/Components/Box';
import { Button } from '../v1/Components/Button';
import SearchBar from '../v1/Widgets/Search/SearchBar';
import './styles/Position.scss';
import TablePositions from './TablePositions';

interface IPositionProps {
  extraPositions: PositionInfo[] | null;
  positions: PositionInfoMap;
  strategyType: 'PDN' | 'LO';
}
type SortType =
  | ''
  | 'position'
  | 'strategy'
  | 'tvl'
  | 'capacity'
  | 'deposit'
  | 'value'
  | 'yields'
  | 'profits';

export const AssetTableRow = styled.div`
  display: grid;
  grid-template-columns: 276px 12fr 24fr 16.5fr 16.5fr 18fr;
  width: calc(100% - 32px);
  grid-column-gap: auto;
  align-items: center;
  justify-items: start;
  > div {
    width: 100%;
  }
`;
export const AssetTableRowHeader = styled(AssetTableRow)`
  align-items: baseline;
`;

const Table = (props: any) => {
  const [sort, setSort] = React.useState<SortType>('');
  const ArrowDown = (props: any) => (
    <span
      style={props.style}
      className={`${props.active ? 'active' : ''} sorting-icon`}
    >
      {' '}
      &#x25bc;{' '}
    </span>
  );

  const UpdateSort = (sortType: SortType) => {
    sort === sortType ? setSort('') : setSort(sortType);
    props.onSelect!(sortType);
  };

  const GenerateGrid = (start: number, end: number) => {
    const setting = [
      { name: 'Position', sort: 'position' },
      { name: 'Strategy', sort: 'strategy' },
      { name: 'Current Value', sort: 'value' },
      { name: 'Profits', sort: 'profits' },
    ];

    return setting.slice(start, end).map((item, i) => {
      return (
        <div
          className="subtitle-2"
          key={`${i}-${item.name}`}
          onClick={() => UpdateSort(item.sort as SortType)}
        >
          {item.name} <ArrowDown active={sort === (item.sort as SortType)} />
        </div>
      );
    });
  };

  return (
    <AssetTableRowHeader className="tableItems">
      {GenerateGrid(0, 2)}
      <div>
        <span className="subtitle-2" onClick={() => UpdateSort('tvl')}>
          {' '}
          TVL <ArrowDown active={sort === 'tvl'} />
        </span>
        {' / '}
        <span className="subtitle-2" onClick={() => UpdateSort('capacity')}>
          Capacity <ArrowDown active={sort === 'capacity'} />
        </span>
      </div>
      {GenerateGrid(2, 4)}
      <Grid item md={1.5}>
        <div className="subtitle-2">Action</div>
      </Grid>
    </AssetTableRowHeader>
  );
};

const data = strategies;

export default function PositionList({ ...props }: IPositionProps) {
  const { positions, extraPositions } = props;
  const { isSm } = useMatchBreakpoints();

  // const [positions, setPositions] = useState<IPositionDetails[] | null>(
  //   positionDetails
  // );
  const [strategyInfoMap, setStrategyInfoMap] = React.useState<StrategyInfoMap>(
    {}
  );
  const [strategyOrder, setStrategyOrder] = React.useState<{
    [strategyId: string]: number;
  }>({});

  const [sort, setSort] = useState<string | null>('tvl');
  const [reverse, setReverse] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [def, setDefault] = useState<IPositionDetails | null>(null);
  const totalLength = useMemo(() => {
    return (
      Object.values(positions).length +
      (extraPositions ? extraPositions.length : 0)
    );
  }, [extraPositions, positions]);
  const [slice, handleSlice] = useState<number>(
    totalLength > 10 ? 10 : totalLength
  );
  const [search, setSearch] = React.useState('');
  const [strategyType, setStrategyType] = React.useState('');

  const handleSearch = (fil: string) => {
    setSearch(fil);
  };

  const handleSort = useCallback(
    (a: StrategyInfo, b: StrategyInfo) => {
      let getA = !reverse ? a : b;
      let getB = !reverse ? b : a;

      const poolSort = () =>
        getB.tokens[0].name < getA.tokens[0].name ? -1 : 1;
      const strategySort = () =>
        StrategyTypeTextMap[getB.strategyType] <
        StrategyTypeTextMap[getA.strategyType]
          ? -1
          : 1;
      const tvlSort = () => BN(getB.TVL).minus(getA.TVL).toNumber();
      const capacitySort = () =>
        BN(getB.capacity).minus(getA.capacity).toNumber();
      const valueSort = () =>
        positions
          ? BN(positions[getB.strategyId]?.userEquity ?? 0)
              .minus(positions[getA.strategyId]?.userEquity ?? 0)
              .toNumber()
          : 0;
      const profitsSort = () =>
        positions
          ? BN(positions[getB.strategyId]?.profits?.profits ?? 0)
              .minus(positions[getA.strategyId]?.profits?.profits ?? 0)
              .toNumber()
          : 0;

      switch (sort) {
        case 'position':
          return poolSort();
        case 'strategy':
          return strategySort();
        case 'tvl':
          return tvlSort();
        case 'capacity':
          return capacitySort();
        case 'value':
          return valueSort();
        case 'profits':
          return profitsSort();
        default:
          return tvlSort();
      }
    },
    [positions, reverse, sort]
  );

  useEffect(() => {
    const order: { [strategyId: string]: number } = {};
    Object.values(strategyInfoMap)
      .sort(handleSort)
      .forEach((s, i) => {
        order[s.strategyId] = i;
      });
    setStrategyOrder(order);
  }, [handleSort, strategyInfoMap]);

  const loaders = new Array(6).fill('');

  const displayExtraPositions = useCallback(() => {
    return extraPositions!.map((item, i) => {
      const strategy = data.filter((x) => x.strategyId === item.strategyId)[0];
      const title = getVaultName(strategy.tokens);
      return (
        <TablePositions
          isExtra={true}
          isOpen={setIsOpen}
          title={title}
          strategy={strategy}
          positionInfo={item}
          key={i}
          setStrategyInfoMap={setStrategyInfoMap}
          strategyOrder={strategyOrder?.[item.strategyId]}
        />
      );
    });
  }, [extraPositions, strategyOrder]);

  const displayPositions = useCallback(() => {
    if (Object.values(positions).length === 0) return [];
    return data
      .filter(
        (fil) =>
          (getVaultName(fil.tokens)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
            getProtocol(fil.protocols)
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            StrategyTypeTextMap[fil.strategyType]
              .toLowerCase()
              .includes(search.toLowerCase())) &&
          ((strategyType !== '' && fil.strategyType === strategyType) ||
            strategyType === '') &&
          positions[fil.strategyId] &&
          BN(positions[fil.strategyId].equity).gt(0)
      )
      .map((item, i) => {
        const title = getVaultName(item.tokens);
        return (
          <TablePositions
            isOpen={setIsOpen}
            title={title}
            strategy={item}
            positionInfo={positions[item.strategyId]}
            key={i}
            setStrategyInfoMap={setStrategyInfoMap}
            strategyOrder={strategyOrder?.[item.strategyId]}
          />
        );
      });
  }, [positions, search, strategyType, strategyOrder]);

  const viewMore = () => {
    if (slice + 10 <= totalLength) handleSlice(slice + 10);
    else handleSlice(totalLength);
  };

  const getProtocol = (protocols: ProtocolInfo[]) => {
    const dexes = protocols.map((protocol) => protocol.name).join('-');
    return dexes;
  };

  return (
    <React.Fragment>
      <Box
        style={{
          padding: '32px 24px',
          borderRadius: '0px 0px 8px 8px',
          position: 'relative',
          overflowX: 'auto',
        }}
        className="position-container"
      >
        <SearchBar handleSearch={handleSearch} setStrategy={setStrategyType} />
        <div
          className="investTable"
          style={{ minWidth: isSm ? '100%' : '1300px' }}
        >
          <div className="tableHeaders">
            {!isSm && (
              <Table
                onSelect={(data: any) => {
                  setReverse(data === sort ? !reverse : true);
                  setSort(data);
                }}
              />
            )}

            {totalLength > 0
              ? displayPositions()
                  // Temporary fix for users to be able to see and withdraw their incorrectly opened extra positions on portfolio page.
                  .concat(!extraPositions ? [] : displayExtraPositions())
                  .slice(0, slice)
              : loaders.map((d, i) => <TableLoader key={i} />)}
          </div>
        </div>

        {totalLength && slice < totalLength && (
          <div className="view-more">
            <Button
              variant="outline"
              size="small"
              text={'view more'}
              onClick={viewMore}
              style={{ padding: '0px 23px' }}
            />
          </div>
        )}
      </Box>
    </React.Fragment>
  );
}
