import React from 'react';
import styled from 'styled-components';
import { ColoredValue } from '../../../components/ColoredValue';
import { Loader } from '../../../components/Loader';
import { QuestionMarkTooltip } from '../../../components/Tooltip';
import { Subtitle1, Subtitle3 } from '../../../components/Typography';
import { numberParse } from '../../../helper';
import { StyledInfoDetail, StyledInfoTitle } from './SharedComponents';
import { APYGroupProps, APYInfo } from './types';

const StyledAPYTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const StyledAPYDetail = styled(StyledInfoDetail)`
  width: calc(100% - 32px) !important;
  margin: 0 16px;
`;
const StyledAPYDetailTitle = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.overallInfoText} !important;
`;

export const APYGroup: React.FC<APYGroupProps> = ({ apy }) => {
  const APY: APYInfo[] = [
    { title: 'Real-time', value: apy.apy },
    { title: '7-day', value: apy.apy_7days },
    { title: '3-month', value: apy.apy_season },
  ];
  return (
    <>
      <StyledAPYTitle>
        <StyledInfoTitle>Projected Annualized Returns</StyledInfoTitle>
        <QuestionMarkTooltip
          title={`The 7-day and 3-month APYs are calculated based on real performance 
                    over the designated period of time, with all the fees included. 
                    The real-time APY is calculated assuming the same market condition 
                    where fees are not applicable.`}
          placementPC={'bottom'}
          placementMobile={'bottom'}
          toolTipClassName={'apy-bottom-tooltip'}
        />
      </StyledAPYTitle>
      {APY.map((item, index) => {
        return (
          <StyledAPYDetail key={`${item.title}-${index}`}>
            <StyledAPYDetailTitle>{item.title}</StyledAPYDetailTitle>
            <Subtitle1>
              {item.value === undefined ? (
                <Loader size="50px" />
              ) : (
                <ColoredValue value={item.value}>
                  {numberParse(item.value, 'percentage')}
                </ColoredValue>
              )}
            </Subtitle1>
          </StyledAPYDetail>
        );
      })}
    </>
  );
};
