import { utils } from 'ethers';
import { useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';
import {
  ironBankRate,
  ProtocolDataResponses,
} from '../../../api/axios/api-config';
import {
  Strategy,
  StrategyInfo,
  StrategyInfoMap,
} from '../../../constants/strategy';
import { strategies } from '../../../constants/strategy/strategies';
import { BN } from '../../../helpers/utilities';
import { IStore, useStore } from '../../../store';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';
import { strategiesConfig, strategiesInfoConfig } from './config';
import useFetchProtocolData from './useFetchProtocolData';

export function useFetchStrategies(
  strategyInfos: StrategyInfoMap | null | undefined,
  options?: any
) {
  const { data: protocolData } = useFetchProtocolData();
  const shouldFetch: boolean = Boolean(
    strategyInfos &&
      Object.keys(strategyInfos).length > 0 &&
      protocolData &&
      Object.keys(protocolData).length > 0
  );

  return useQueries<StrategyInfo[]>(
    (!shouldFetch ? [] : strategies).map((strategy: Strategy) => {
      const { queryKey, queryFn } = strategiesConfig(
        strategy,
        strategyInfos![strategy.strategyId],
        protocolData!
      );
      return {
        queryKey,
        queryFn,
        ...options,
      };
    })
  );
}

export function useFetchStrategyInfos(options?: any) {
  const multicallProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  )?._multicallProvider;
  const shouldFetch: boolean = Boolean(multicallProvider);
  return useQueries(
    (!shouldFetch ? [] : strategies).map((strategy: Strategy) => {
      const { queryKey, queryFn } = strategiesInfoConfig(
        strategy,
        multicallProvider
      );
      return {
        queryKey,
        queryFn,
        ...options,
      };
    })
  );
}
export function useFetchStrategyInfo(strategy: Strategy, options?: any) {
  const multicallProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  )?._multicallProvider;
  const { queryKey, queryFn } = useMemo(
    () =>
      // @ts-ignore
      strategiesInfoConfig(strategy, multicallProvider),
    [multicallProvider, strategy]
  );
  const shouldFetch: boolean = Boolean(multicallProvider);
  return useQuery(queryKey, queryFn, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
    ...options,
  });
}

export function useFetchStrategy(strategy: Strategy, options?: any) {
  const { data: strategyInfo } = useFetchStrategyInfo(strategy, options);
  const { data: protocolData } = useFetchProtocolData();
  const { queryKey, queryFn } = useMemo(
    () =>
      // @ts-ignore
      strategiesConfig(strategy, strategyInfo, protocolData),
    [strategy, strategyInfo, protocolData]
  );
  const shouldFetch: boolean = Boolean(
    strategyInfo &&
      Object.keys(strategyInfo).length > 0 &&
      protocolData &&
      Object.keys(protocolData).length > 0
  );
  return useQuery(queryKey, queryFn, {
    // refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 1000 * 60,
    enabled: shouldFetch,
    ...options,
  });
}

export async function getStrategy(
  strategy: Strategy,
  strategyInfo: StrategyInfo,
  protocolData: ProtocolDataResponses
): Promise<StrategyInfo | undefined> {
  if (!strategyInfo || !protocolData) return;
  const { apys, rates } = protocolData;

  // const APR_TJ = BN(apys[strategy.poolKey].totalAPY).div(100).plus(1).ln();
  const APR_TJ = BN(0);
  const TVL = strategyInfo.TVLInUnits; // in avax
  const interest_A = BN(
    rates.borrowRates.find(
      (rate: ironBankRate) =>
        rate.tokenSymbol.toLowerCase() ===
        (strategyInfo.tokens[0].native
          ? strategyInfo.tokens[0].replacedTicker!.toLowerCase()
          : strategyInfo.tokens[0].ticker.toLowerCase())
    )!.apy
  ).times(1.2);
  const interest_B = BN(
    rates.borrowRates.find(
      (rate: ironBankRate) =>
        rate.tokenSymbol.toLowerCase() ===
        (strategyInfo.tokens[1].native
          ? strategyInfo.tokens[1].replacedTicker!.toLowerCase()
          : strategyInfo.tokens[1].ticker.toLowerCase())
    )!.apy
  ).times(1.2);
  /* we assumed tokenb is avax, and the way we calculate is all depends on avax, which needs update */
  /* However we are not using this data to calculate apy anymore :p */
  const debt_A = BN(
    utils.formatUnits(strategyInfo.debts[0], strategyInfo.tokens[0].decimals)
  ).times(strategyInfo.tokenInAvax); // in avax
  const debt_B = BN(
    utils.formatUnits(strategyInfo.debts[1], strategyInfo.tokens[1].decimals)
  )
    .div(strategyInfo.tokenATotokenB)
    .times(strategyInfo.tokenInAvax);
  const totalEquity = strategyInfo.totalEquityETHValue; // in avax
  /***********************************************************************************************
   *
   *  Using the following formula to calculate the APY:
   *  (APR_TJ * TVL - interest_A * debt_A - interest_B * debt_B) / totalEquity;
   *
   *  Using BigNumber.js to calculate avoid overflow in case of large TVL.
   *
   ***********************************************************************************************/
  const apr =
    !TVL || TVL === '0'
      ? BN(APR_TJ)
          .minus(BN(interest_A).times(debt_A))
          .minus(BN(interest_B).times(debt_B))
      : BN(APR_TJ)
          .times(TVL)
          .minus(BN(interest_A).times(debt_A))
          .minus(BN(interest_B).times(debt_B))
          .div(totalEquity); // calculating apy
  strategyInfo.apy = BN(apr).exp().minus(1).toString();
  // .toFixed(2);
  return strategyInfo;
}
