// Temporary fix for users to be able to see and withdraw their incorrectly opened extra positions on portfolio page.
import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { EvmStrategyContractAbi } from '../../../constants/crosschain';
import { UnpopulatedPositionInfoMap } from '../../../constants/position';
import { IStore, useStore } from '../../../store';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';
import { PositionInfo, UnpopulatedPositionInfo } from '../../../types';
import { extraPositionConfig } from './config';

export function useFetchEvmExtraPositions(
  positionInfos:
    | {
        unpopulatedPositionInfoMap: UnpopulatedPositionInfoMap;
        extras: UnpopulatedPositionInfo[];
      }
    | null
    | undefined,
  options?: any
) {
  const evmProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  );
  const { chainId } = evmProvider?._evmChainInfo ?? { chainId: null };
  const multicallProvider = evmProvider?._multicallProvider;
  const strategyAbi = useMemo(
    () => (chainId ? EvmStrategyContractAbi[chainId] : null),
    [chainId]
  );
  const { enabled: extraEnable, ...ops } = options ?? { enabled: true };

  const shouldFetch: boolean =
    // positionInfos !== (undefined || null) &&
    positionInfos?.extras !== null && !!multicallProvider && !!strategyAbi;

  return useQueries<PositionInfo[]>(
    (positionInfos?.extras ?? []).map((pos: UnpopulatedPositionInfo) => {
      const { queryKey, queryFn } = extraPositionConfig(
        strategyAbi,
        multicallProvider!,
        pos
      );
      return {
        queryKey,
        queryFn,
        // refetchOnMount: false,
        enabled: shouldFetch && extraEnable,
        ...ops,
      };
    })
  );
}
