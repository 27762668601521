import { SvgProps } from '..';

export const TwitterIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.3449 5.46885C22.5232 5.82332 21.629 6.07843 20.7079 6.17778C21.6585 5.61117 22.3809 4.71695 22.7219 3.65354C21.8438 4.17719 20.8502 4.56119 19.8164 4.75991C18.9785 3.86569 17.7889 3.3125 16.4704 3.3125C13.9327 3.3125 11.8918 5.36949 11.8918 7.89373C11.8918 8.2482 11.9348 8.60267 12.0046 8.94371C8.20484 8.74499 4.8159 6.92969 2.56288 4.15033C2.16545 4.83242 1.93988 5.61117 1.93988 6.46243C1.93988 8.05217 2.74817 9.45393 3.98076 10.2783C3.22885 10.2488 2.51992 10.0367 1.91034 9.69561V9.75201C1.91034 11.9782 3.48396 13.823 5.58123 14.2473C5.19723 14.3467 4.78637 14.4031 4.3755 14.4031C4.07743 14.4031 3.79547 14.3735 3.51082 14.3332C4.09086 16.1485 5.77995 17.4671 7.79129 17.51C6.21767 18.7426 4.24661 19.4677 2.10637 19.4677C1.72236 19.4677 1.3679 19.4542 1 19.4113C3.03014 20.7137 5.43891 21.4656 8.03297 21.4656C16.4543 21.4656 21.0624 14.489 21.0624 8.43349C21.0624 8.23477 21.0624 8.03606 21.0489 7.83734C21.9405 7.1848 22.7219 6.3765 23.3449 5.46885Z"
        fill="white"
      />
    </svg>
  );
};
