import Grid from '@mui/material/Grid';
import Decimal from 'decimal.js';
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import InvestContainer from '../components/Investment/InvestContainer';
import { TvlBox } from '../components/v1';
import { StrategyInfo } from '../constants/strategy';
import { useFetchStrategyInfos } from '../features/strategies/hooks';
import { BN } from '../helpers/utilities';

const BoxContainer = styled.div`
  padding: 0px 24px 16px;
  @media (max-width: 1199px) {
    padding: 0px 16px 16px;
  }
`;
const Container = styled.div`
  padding: 0px 24px 26px;
  @media (max-width: 1199px) {
    padding: 0px;
  }
`;
const TVLGrid = styled(Grid)`
  gap: 16px;
  @media (max-width: 1199px) {
    gap: 8px;
    .tvl-item {
      width: 100%;
    }
    // .tvl-item: first-child {
    //   width: 100%;
    // }
    // .tvl-item:not(:first-child) {
    //   width: calc(50% - 4px);
    // }
  }
`;

// TODO: Avoid invoking `Investment` unnecessarily
const Investment = () => {
  const strategyInfoResults = useFetchStrategyInfos();
  const strategyInfoIsLoading = strategyInfoResults.some((r) => !r.data);
  const [totalTvl, setTotalTvl] = React.useState('0');

  useEffect(() => {
    if (strategyInfoIsLoading || !strategyInfoResults) return;
    const newVal = Object.values(strategyInfoResults)
      .map((r) => r.data as StrategyInfo)
      .reduce(
        (acc: Decimal, strategy: StrategyInfo) => acc.plus(strategy?.TVL ?? 0),
        BN(0)
      )
      .toString();
    setTotalTvl(newVal);
  }, [strategyInfoIsLoading, strategyInfoResults]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <BoxContainer>
        <TVLGrid container>
          <Grid item className="tvl-item">
            <TvlBox
              isPrimary={true}
              heading="Total Value Locked"
              amount={totalTvl ? totalTvl : 0}
              decimals={2}
            />
          </Grid>
          <Grid item className="tvl-item">
            <TvlBox
              heading="TVL in Leveraged Farming"
              amount={totalTvl ? totalTvl : 0}
              decimals={2}
            />
          </Grid>
          {/* Hide TVL in lending optimizer for Beta Launch
          <Grid item className="tvl-item">
            <TvlBox
              heading={text.tvlBox.lendingOptimizer}
              amount={totalTvl ? totalTvl : 0}
              decimals={2}
            />
          </Grid> */}
        </TVLGrid>
      </BoxContainer>
      <Container>
        <InvestContainer />
      </Container>
    </Fragment>
  );
};

export default Investment;
