import {
  CHAIN_ID_ETHEREUM_ROPSTEN,
  getEmitterAddressEth,
  parseSequencesFromLogEth,
} from '@certusone/wormhole-sdk';
import {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/providers';
import { BigNumber, ethers, utils } from 'ethers';
import {
  ERC20TokenABI,
  EvmTokenBridgeAddress,
  EvmWormholeAddress,
  HOMORA_BN_VAL,
  MIN_REINVEST_ETH,
} from '../../constants/crosschain';
import getSignedVAAWithRetry from './wormhole.js';
import { sqrt } from '../utilities';

const utf8Encode = new TextEncoder();

export const approveERC20: (
  tokenAddr: string,
  amount: number,
  signer: ethers.Signer,
  evmManager: ethers.Contract
) => Promise<TransactionResponse> = async (
  tokenAddr: string,
  amount: number,
  signer: ethers.Signer,
  evmManager: ethers.Contract
) => {
  var ethUST = new ethers.Contract(tokenAddr, ERC20TokenABI, signer);
  return await ethUST.approve(evmManager.address, amount);
};

export const checkApprovalERC20 = async (
  tokenAddr: string,
  signer: ethers.Signer,
  evmManager: ethers.Contract
) => {
  var ethUST = new ethers.Contract(tokenAddr, ERC20TokenABI, signer);
  return await ethUST.allowance(signer.getAddress(), evmManager.address);
};

// Connect `ethManager` to `MulticallProvider` before passing it in
export const getPositions = async (
  ethManager: ethers.Contract,
  address: string
) => {
  const positions: [[BigNumber, number, BigNumber]] =
    await ethManager.getPositionsExt(address);
  return positions.map(([positionId, strategyChainId, strategyId]) => {
    return {
      positionId: positionId.toString(),
      strategyChainId: strategyChainId,
      strategyId: strategyId.toString(),
    };
  });
};

function minEquityETH(
  stableTokenDepositAmount: BigNumber,
  stableTokenETHvalue: BigNumber,
  slippage: number
) {
  return BigNumber.from(stableTokenDepositAmount)
    .mul(stableTokenETHvalue)
    .mul(BigNumber.from(1e4 * (1 - slippage)))
    .div(BigNumber.from(1e4))
    .div(HOMORA_BN_VAL);
}

function minReinvestETH(equity: BigNumber) {
  return sqrt(equity.mul(MIN_REINVEST_ETH)).add(MIN_REINVEST_ETH);
}

export const openPosition = async (
  ethManager: ethers.Contract,
  vaultManager: ethers.Contract,
  stableTokenDepositAmount: BigNumber,
  strategyChainId: number,
  strategyId: string,
  spendingTokenAddr: string,
  stableTokenETHvalue: BigNumber,
  slippage: number,
  native: boolean,
  estimateGas?: boolean
) => {
  const _minEquityETH = minEquityETH(
    stableTokenDepositAmount,
    stableTokenETHvalue,
    slippage
  );
  const equity: BigNumber = await vaultManager.getEquityETHValue();
  const openPositionBytesArray = ethers.utils.arrayify(
    ethers.utils.defaultAbiCoder.encode(
      ['uint256', 'uint256'],
      [
        _minEquityETH, // uint256 minEquityETH
        minReinvestETH(equity), // uint256 minReinvestETH
      ]
    )
  );
  let txOptions, params;
  if (native) {
    params = [
      strategyChainId,
      strategyId,
      [],
      openPositionBytesArray,
      { gasLimit: 3300000, value: stableTokenDepositAmount },
    ];
  } else {
    // TODO: Only needed for test chain, remove txOptions when using mainnet
    txOptions = { gasLimit: 3300000 }; // if wavax value:xx
    params = [
      strategyChainId,
      strategyId,
      [[spendingTokenAddr, stableTokenDepositAmount]],
      openPositionBytesArray,
      txOptions,
    ];
  }

  if (estimateGas) {
    const createPosEstimateRes = await ethManager.estimateGas.createPosition(
      ...params
    );
    return createPosEstimateRes;
  } else {
    const createPosRes = await ethManager.createPosition(...params);
    return createPosRes;
  }
};

export const getETHPxRaw = async (
  vaultLib: ethers.Contract,
  oracleAddr: string,
  tokenAddr: string
): Promise<BigNumber> => {
  return await vaultLib.getETHPx(oracleAddr, tokenAddr);
};

export const getGasPrice = async (
  provider: ethers.providers.Web3Provider,
  txnFn: Function,
  params: unknown[]
): Promise<string> => {
  const gasPrice: BigNumber = await provider.getGasPrice();
  const functionGasFees = await txnFn(...params, true);
  const finalGasPrice = gasPrice.mul(functionGasFees);
  return utils.formatEther(finalGasPrice + '');
};
export const simulateThenExecute = async (
  txnFn: Function,
  params: unknown[]
): Promise<TransactionReceipt> => {
  let simulateRes = null;
  try {
    simulateRes = await txnFn(...params, true);
  } catch (e) {
    console.log('Error in simulation', e);
    throw e;
  }
  if (!simulateRes) {
    console.log('Simulation result is empty');
    throw new Error('Simulation result is empty');
  }
  return await txnFn(...params);
};

export const decreasePosition = async (
  ethManager: ethers.Contract,
  strategyChainId: number,
  walletAddress: string,
  shareAmount: BigNumber,
  totalShares: BigNumber,
  totalEquity: BigNumber,
  withdrawRatio: number,
  slippage: number,
  posId: string,
  decimals: number,
  withdrawFee: BigNumber,
  estimateGas?: boolean
) => {
  // Withdraw half amount from vault for wallet 0.
  var withdrawAmount = shareAmount
    .mul(BigNumber.from(Number(withdrawRatio.toFixed(6)) * 1e6))
    .div(1e6);
  console.log('withdraw amount 0: ', withdrawAmount.toString());
  // const withdrawFee = 0;
  // TODO: Only needed for test chain, remove txOptions when using mainnet
  const txOptions = { gasLimit: 3300000 };

  const amtAMin = totalEquity
    .mul(withdrawAmount)
    .div(totalShares)
    .mul(1e4 - withdrawFee.toNumber())
    .mul(1e4 * (1 - slippage))
    .div(1e8);

  // First byte is Action enum.
  // 0 -> Open, 1 -> Increase, 2 -> Decrease, 3 -> Close (not yet supported).
  const encodedWithdrawData = ethers.utils.concat([
    new Uint8Array([2]),
    new Uint8Array([0, strategyChainId]), // recipient chainId.
    ethers.utils.zeroPad(ethers.utils.arrayify(walletAddress), 32), // recipient address (padded to 32 bytes).
    ethers.utils.arrayify(
      ethers.utils.defaultAbiCoder.encode(
        ['uint256', 'uint256', 'uint256'],
        [
          withdrawAmount,
          amtAMin, // AmountA min unit in tokenA
          '0', //amtBmin
        ]
      )
    ),
  ]);
  const params = [
    /*positionId=*/ posId,
    /*assetInfos=*/ [],
    encodedWithdrawData,
    txOptions,
  ];

  if (estimateGas) {
    const decreasePosEstimateRes = await ethManager.estimateGas.executeStrategy(
      ...params
    );
    return decreasePosEstimateRes;
  } else {
    const decreasePosRes = await ethManager.executeStrategy(...params);
    return decreasePosRes;
  }
};

export const increasePosition = async (
  ethManager: ethers.Contract,
  vaultManager: ethers.Contract,
  stableTokenDepositAmount: BigNumber,
  positionId: string,
  spendingTokenAddr: string,
  stableTokenETHvalue: BigNumber,
  slippage: number,
  native: boolean,
  estimateGas?: boolean
) => {
  const _minEquityETH = minEquityETH(
    stableTokenDepositAmount,
    stableTokenETHvalue,
    slippage
  );
  const equity: BigNumber = await vaultManager.getEquityETHValue();
  const encodedIncreaseData = ethers.utils.concat([
    new Uint8Array([1]),
    ethers.utils.arrayify(
      ethers.utils.defaultAbiCoder.encode(
        ['uint256', 'uint256'],
        [_minEquityETH, minReinvestETH(equity)]
      )
    ),
  ]);

  let txOptions, params;
  if (native) {
    params = [
      positionId,
      [],
      encodedIncreaseData,
      { gasLimit: 3300000, value: stableTokenDepositAmount },
    ];
  } else {
    txOptions = { gasLimit: 3300000 };
    params = [
      positionId,
      [[spendingTokenAddr, stableTokenDepositAmount]],
      encodedIncreaseData,
      txOptions,
    ];
  }
  // TODO: Only needed for test chain, remove txOptions when using mainnet
  if (estimateGas) {
    const increasePosEstimateRes = await ethManager.estimateGas.executeStrategy(
      ...params
    );
    return increasePosEstimateRes;
  } else {
    const increasePosRes = await ethManager.executeStrategy(...params);
    return increasePosRes;
  }
};

/*******************************************
 *
 *  Legacy EVM Crosschain Helper Functions
 *
 *******************************************/

export const getStableYieldOpenRequest = () => {
  const actionData = {
    open_position: {},
  };
  const encodedActionData = utf8Encode.encode(
    Buffer.from(JSON.stringify(actionData)).toString('base64')
  );
  return encodedActionData;
};

export const getDeltaNeutralOpenRequest = (collateral, address) => {
  const deltaNeutralParams = {
    target_min_collateral_ratio: (collateral / 100 - 0.2).toFixed(1).toString(),
    target_max_collateral_ratio: (collateral / 100 + 0.2).toFixed(1).toString(),
    mirror_asset_cw20_addr: address,
  };
  const encodedActionData = utf8Encode.encode(
    Buffer.from(JSON.stringify(deltaNeutralParams)).toString('base64')
  );
  return encodedActionData;
};

export const processVAAs = async (
  ethereumManager,
  terraGenericMessagingVAABytes,
  terraTokenTransferVAABytes
) => {
  try {
    return await ethereumManager.processApertureInstruction(
      terraGenericMessagingVAABytes,
      [terraTokenTransferVAABytes],
      { gasLimit: 900000 }
    );
  } catch (e) {
    return e;
  }
};

export const getStableYieldIncreaseRequest = () => {
  const encodedIncreasePostionActionData = utf8Encode.encode(
    Buffer.from(
      JSON.stringify({
        increase_position: {},
      })
    ).toString('base64')
  );
  return encodedIncreasePostionActionData;
};

export const getCloseRequest = (redeemAddr) => {
  const closeActionData = {
    close_position: {
      recipient: {
        external_chain: {
          recipient_chain: CHAIN_ID_ETHEREUM_ROPSTEN,
          recipient: Buffer.from(
            getEmitterAddressEth(redeemAddr),
            'hex'
          ).toString('base64'),
        },
      },
    },
  };

  const encodedCloseActionData = utf8Encode.encode(
    Buffer.from(JSON.stringify(closeActionData)).toString('base64')
  );
  return encodedCloseActionData;
};

export const getVAAs = async (txReceipt, ethereumManagerAddr, chainId) => {
  let [tokenTransferSeq, genericMessagingSeq] = parseSequencesFromLogEth(
    txReceipt,
    EvmWormholeAddress[chainId]
  );
  let ethTokenBridgeEmitterAddress = getEmitterAddressEth(
    EvmTokenBridgeAddress[chainId]
  );
  let ethManagerEmitterAddress = getEmitterAddressEth(ethereumManagerAddr);
  let tokenTransferVAA = await getSignedVAAWithRetry(
    CHAIN_ID_ETHEREUM_ROPSTEN,
    ethTokenBridgeEmitterAddress,
    tokenTransferSeq
  );
  // Fetch the VAAs for generic message and token transfer.
  let genericMessagingVAA = await getSignedVAAWithRetry(
    CHAIN_ID_ETHEREUM_ROPSTEN,
    ethManagerEmitterAddress,
    genericMessagingSeq
  );
  return [genericMessagingVAA, tokenTransferVAA];
};

export const getVAA = async (txReceipt, ethereumManagerAddr, chainId) => {
  let [genericMessagingSeq] = parseSequencesFromLogEth(
    txReceipt,
    EvmWormholeAddress[chainId]
  );
  let ethManagerEmitterAddress = getEmitterAddressEth(ethereumManagerAddr);
  // Fetch the VAAs for generic message and token transfer.
  console.log('running getSignedVAAWithRetry');
  let genericMessagingVAA = await getSignedVAAWithRetry(
    CHAIN_ID_ETHEREUM_ROPSTEN,
    ethManagerEmitterAddress,
    genericMessagingSeq
  );
  return genericMessagingVAA;
};
