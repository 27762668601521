import React from 'react';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../contexts';

const StyledLabel = styled.span<{ sm: boolean }>`
  width: ${({ sm }) => (sm ? '32px' : '35px')};
  height: ${({ sm }) => (sm ? '18px' : '12px')};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ sm }) => (sm ? '12px' : '13px')};
  line-height: ${({ sm }) => (sm ? '18px' : '12px')};
  background: ${({ theme }) => theme.colors.new};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const Label: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isSm } = useMatchBreakpoints();
  return <StyledLabel sm={isSm}>{children}</StyledLabel>;
};
