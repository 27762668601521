import React from 'react';
import styled from 'styled-components';
import { YAxisTitleProps } from '.';
import { Subtitle2 } from '../../Typography';

const StyledRight = styled(Subtitle2)`
  position: absolute;
  right: 0px;
  bottom: 0px;
`;
const StyledLeft = styled(Subtitle2)`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;
const Wrapper = styled.div`
  position: relative;
  height: 14px;
  width: 100%;
  margin-bottom: -14px;
`;

export const YAxisTitle: React.FC<YAxisTitleProps> = ({ left, right }) => {
  return (
    <Wrapper>
      <StyledLeft>{left}</StyledLeft>
      <StyledRight>{right}</StyledRight>
    </Wrapper>
  );
};
