import React from 'react';
import styled, { useTheme } from 'styled-components';
import { numberParse, QuestionMarkTooltip } from '../../../..';
import { Headline5 } from '../../../../components/Typography';
import { useMatchBreakpoints } from '../../../../contexts';
import { Backtest, BacktestPayloadProps } from '../../../BacktestChart';
import {
  Performance,
  PerformanceDataProps,
  PerformancePayloadProps,
} from '../../../PerformanceChart';
import {
  Return,
  ReturnDataProps,
  ReturnPayloadProps,
} from '../../../ReturnChart';
import { ChartDataType, ChartsOverviewProps, ChartType } from './types';

const Container = styled.div<{ sm: boolean }>`
  ${({ sm }) =>
    !sm
      ? `
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 24px;
    padding: 16px 32px;
    width: calc(100% - 88px);
    `
      : `
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0px;
      width: 100%;
      `}
`;

const StyledHeadline5 = styled(Headline5)`
  color: ${({ theme }) => theme.colors.text};
`;

const StyledReturn = styled(Return)<{ isSm: boolean; id: string }>`
  transition: 0.5s;
  &:hover {
    filter: ${({ theme, isSm }) => (isSm ? '' : theme.colors.graphShadow)};
    .chart-expanded-icon {
      display: block;
    }
  }
  #${({ id }) => id} {
    margin-left: ${({ isSm }) => (isSm ? '0' : '-2px')};
    margin-right: ${({ isSm }) => (isSm ? '0' : '-18px')};
  }
`;

const StyledBacktest = styled(Backtest)<{ isSm: boolean }>`
  transition: 0.5s;
  &:hover {
    filter: ${({ theme, isSm }) => (isSm ? '' : theme.colors.graphShadow)};
    .chart-expanded-icon {
      display: block;
    }
  }
  #${({ id }) => id} {
    margin-left: ${({ isSm }) => (isSm ? '0' : '-12px')};
    margin-right: ${({ isSm }) => (isSm ? '0' : '-18px')};
  }
`;

const StyledPerformance = styled(Performance)<{ isSm: boolean; id: string }>`
  transition: 0.5s;
  &:hover {
    filter: ${({ theme, isSm }) => (isSm ? '' : theme.colors.graphShadow)};
    .chart-expanded-icon {
      display: block;
    }
  }
  #${({ id }) => id} {
    margin-left: ${({ isSm }) => (isSm ? '0' : '-18px')};
    margin-right: ${({ isSm }) => (isSm ? '0' : '-18px')};
  }
`;

const ReturnChart: React.FC<{
  data: ReturnDataProps | undefined;
  tokens: [string, string];
  id: string;
  openStrategyDetails?: () => void;
}> = ({ data, tokens, id, openStrategyDetails }) => {
  const { isSm } = useMatchBreakpoints();
  const theme = useTheme();
  const payload: ReturnPayloadProps = {
    tokens: tokens,
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'percentage',
        legendText: 'Return',
        legendType: 'line',
        color: theme.colors.graphLine1,
        tickFormatter: (value: any, index: number) =>
          numberParse(value, 'percentage', undefined, 3),
        fill: true,
      },
    ],
  };
  const mobilePayload: ReturnPayloadProps = {
    tokens: tokens,
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'percentage',
        legendText: 'Return',
        legendType: 'line',
        color: theme.colors.graphLine1,
        activeColor: theme.colors.graphActiveDot1,
        tickFormatter: (value: any, index: number) =>
          numberParse(value, 'percentage', undefined, 3),
        fill: true,
        hide: true,
      },
      {
        dataKey: 'price',
        legendText: `${tokens[1]} Price`,
        legendType: 'dash',
        color: theme.colors.graphLine2,
        activeColor: theme.colors.graphActiveDot2,
        tickFormatter: (value: any, index: number) => value,
        hide: true,
      },
      {
        dataKey: 'price',
        legendText: 'Rebalanced Point',
        legendType: 'dot',
        color: theme.colors.graphDot,
      },
    ],
  };
  return (
    <div>
      <StyledHeadline5>
        <span>Vault Return</span>
        <QuestionMarkTooltip
          title={`All the data displayed in APYs and charts are calculated after fees. 
            For Aperture Crab Market Leveraged Farming and Bull Market Leveraged Farming, 
            a harvest fee of 10% is charged on the reward token harvested during the compounding process. 
            A withdrawal fee of 0.2% applies upon exit.`}
          placementPC={'bottom'}
          placementMobile={'bottom'}
          toolTipClassName={'dropdown-return-info-tooltip'}
        />
      </StyledHeadline5>
      <StyledReturn
        id={`return-charts-mini-${id}`}
        type="mini"
        data={data}
        payload={isSm ? mobilePayload : payload}
        openStrategyDetails={openStrategyDetails}
        isSm={isSm}
      />
    </div>
  );
};

const BacktestChart: React.FC<{
  data: any[] | undefined;
  tokens: [string, string];
  id: string;
  openStrategyDetails?: () => void;
}> = ({ data, tokens, id, openStrategyDetails }) => {
  const { isSm } = useMatchBreakpoints();
  const theme = useTheme();
  const payload: BacktestPayloadProps = {
    tokens: tokens,
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'percentage',
        legendText: 'Return',
        legendType: 'line',
        color: theme.colors.graphLine2,
        tickFormatter: (value: any, index: number) =>
          numberParse(value, 'percentage'),
        fill: true,
        hide: isSm,
      },
    ],
  };
  return (
    <div>
      <StyledHeadline5>Backtest Analysis</StyledHeadline5>
      <StyledBacktest
        id={`backtest-charts-mini-${id}`}
        type="mini"
        data={data}
        payload={payload}
        openStrategyDetails={openStrategyDetails}
        isSm={isSm}
      />
    </div>
  );
};

const PerformanceChart: React.FC<{
  data: PerformanceDataProps | undefined;
  tokens: [string, string];
  id: string;
  openStrategyDetails?: () => void;
}> = ({ data, tokens, id, openStrategyDetails }) => {
  const { isSm } = useMatchBreakpoints();
  const theme = useTheme();
  const payload: PerformancePayloadProps = {
    tokens: tokens,
    x: { dataKey: 'timestamp_sec' },
    y: [
      {
        dataKey: 'position_value',
        legendText: 'Position Value',
        legendType: 'line',
        color: theme.colors.graphLine1,
        fill: true,
        hide: isSm,
      },
      {
        dataKey: 'net_deposit',
        legendText: 'Net Deposits',
        legendType: 'dash',
        color: theme.colors.graphLine2,
        hide: true,
        shareYaxis: true,
      },
    ],
  };
  return (
    <div>
      <StyledHeadline5>My Position</StyledHeadline5>
      <StyledPerformance
        id={`performance-charts-mini-${id}`}
        type="mini"
        data={data}
        payload={payload}
        openStrategyDetails={openStrategyDetails}
        isSm={isSm}
      />
    </div>
  );
};

export const ChartsOverview: React.FC<ChartsOverviewProps> = ({
  tokens,
  charts,
  openStrategyDetails,
}) => {
  const { isSm } = useMatchBreakpoints();
  const getChart = (
    chartType: ChartType,
    data: ChartDataType | undefined,
    id: string,
    openStrategyDetails?: () => void
  ) => {
    if (chartType === 'return') {
      return (
        <ReturnChart
          data={data as ReturnDataProps}
          tokens={tokens}
          id={id}
          openStrategyDetails={openStrategyDetails}
        />
      );
    } else if (chartType === 'backtest') {
      return (
        <BacktestChart
          data={data as any[]}
          tokens={tokens}
          id={id}
          openStrategyDetails={openStrategyDetails}
        />
      );
    } else {
      return (
        <PerformanceChart
          data={data as PerformanceDataProps}
          tokens={tokens}
          id={id}
          openStrategyDetails={openStrategyDetails}
        />
      );
    }
  };

  return (
    <Container sm={isSm}>
      {getChart(
        charts[0].chartsType,
        charts[0].chartsData,
        charts[0].chartsId,
        openStrategyDetails
      )}
      {getChart(
        charts[1].chartsType,
        charts[1].chartsData,
        charts[1].chartsId,
        openStrategyDetails
      )}
    </Container>
  );
};
