import { SelectWallet } from './SelectWallet';
import { SvgProps } from '@aperture/assetkit';
import { FunctionComponent } from 'react';

interface ConnectWalletProps {
  walletList: { name: string; icon: FunctionComponent<React.PropsWithChildren<SvgProps>>; walletType: string }[];
  onWalletSelect?: (walletType: string) => void;
  onChainSelect?: (chainId: number) => void;
}

export const WalletConnect = ({
  walletList = [],
  onWalletSelect = (walletType: string) => { },
  onChainSelect = (chainId: number) => { },
  ...props
}: ConnectWalletProps) => {
  return (
    <>
      <SelectWallet walletList={walletList} onSelect={onWalletSelect} />
    </>
  );
};
