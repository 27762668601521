import {
  EvmChainMap,
  ChainExplorerRegex,
} from '../../constants/crosschain/evmChainidMap';
export enum ExplorerDataType {
  TRANSACTION = 'tx',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(
  chainId: number,
  data: string,
  type: ExplorerDataType
): string {
  if (chainId === undefined || chainId == null || !EvmChainMap[chainId])
    return '';
  const { infoURL } = EvmChainMap[chainId];
  return infoURL.replace(ChainExplorerRegex, `$1${type}$3${data}`);
}
