import React, { Fragment } from 'react';

// Comps
import styled from 'styled-components';
import Overview from '../components/Portfolio/Overview';
import PositionContainer from '../components/Portfolio/PositionContainer';

interface IPortfolioProps {
  activeSubpage?: 'PDN' | 'LO';
}

export default function Portfolio({
  activeSubpage = 'PDN',
  ...props
}: IPortfolioProps) {
  // sets default window position to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BoxContainer = styled.div`
    padding: 0px 24px;
    @media (max-width: 1199px) {
      padding: 0px 16px;
      margin-bottom: 16px;
    }
  `;

  const Container = styled.div`
    padding: 0px 24px 26px 24px;
    @media (max-width: 1199px) {
      padding: 0px;
    }
    min-height: 25%;
  `;

  return (
    <Fragment>
      <BoxContainer>
        <Overview activeSubpage={activeSubpage} />
      </BoxContainer>
      <Container>
        <PositionContainer />
      </Container>
    </Fragment>
  );
}
