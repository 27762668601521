import React from 'react';
import styled from 'styled-components';
import { useMatchBreakpoints } from '../../contexts';
import { ButtonGProps } from './types';

const StyledButton = styled.div<{ sm: boolean; selected: boolean }>`
  width: ${({ sm }) => (sm ? '32px' : '55px')};
  height: ${({ sm }) => (sm ? '21px' : '37px')};
  font-size: ${({ sm }) => (sm ? '14px' : '16px')};
  font-weight: 500;
  line-height: ${({ sm }) => (sm ? '21px' : '24px')};
  border-radius: ${({ sm }) => (sm ? '6px' : '8px')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;

  background: ${({ theme, selected }) =>
    selected ? theme.colors.graphButtonActive : 'none'};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.textAlt : theme.colors.buttonModalGraph};
  border: 1.5px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.graphButtonActive
        : theme.colors.buttonModalGraph};
  :hover {
    background: ${({ theme }) => theme.colors.graphButton};
    color: ${({ theme }) => theme.colors.textAlt};
    border: 1.5px solid ${({ theme }) => theme.colors.graphButton};
    box-shadow: ${({ theme }) => theme.colors.graphButtonShadow};
  }
`;

export const Button: React.FC<React.PropsWithChildren<ButtonGProps>> = ({
  selected = false,
  small = false,
  children,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StyledButton sm={isSm || small} selected={selected} {...props}>
      {children}
    </StyledButton>
  );
};
