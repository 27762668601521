import React from 'react';
import { Tag } from '../../../components/Tag';
import { StyledInfoDetail, StyledInfoTitle } from './SharedComponents';
import { StrategyTypeProps } from './types';

export const StrategyType: React.FC<StrategyTypeProps> = ({ strategyType }) => {
  return (
    <StyledInfoDetail>
      <StyledInfoTitle>Strategy</StyledInfoTitle>
      {strategyType === 'crab' ? (
        <Tag variant={'crab'} />
      ) : (
        <Tag variant={'bull'} />
      )}
    </StyledInfoDetail>
  );
};
