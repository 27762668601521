// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 25000;

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const MIN_ALLOWANCE = '0xffffffffffffffffffffffffffffffff';

export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
