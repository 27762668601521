import { ERC20TokenABI } from '../../../constants/crosschain';
import { Contract, utils } from 'ethers';
import { Provider } from '@ethersproject/providers';
import { SerializedTokenInfo, TokenInfo } from '../../../constants/tokenConfig';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';
import { MAX_UINT256 } from '../../../constants/misc';

export const getToken = async (
  token: TokenInfo,
  walletAddress: string | null,
  evmManagerAddress: string | null,
  provider: Provider | null,
  evmProvider: IEvmProvider | null
): Promise<SerializedTokenInfo | null> => {
  if (!provider || !walletAddress || !evmManagerAddress || !evmProvider)
    return null;
  const contract = new Contract(
    token.tokenContractAddress,
    ERC20TokenABI,
    provider
  );
  let allowance, balance;
  if (token.native) {
    allowance = MAX_UINT256; //bigges number allow
    balance = utils.parseUnits(evmProvider!.balance ?? '0', token.decimals);
  } else {
    [allowance, balance] = await Promise.all([
      contract.allowance(walletAddress, evmManagerAddress),
      contract.balanceOf(walletAddress),
    ]);
  }
  return {
    ...token,
    balance: balance,
    displayBalance: token.native
      ? evmProvider!.balance ?? '0'
      : utils.formatUnits(balance, token.decimals || 18),
    allowance,
  };
};
