// import { BlockchainType } from '../../../constants/crosschain';
// import { useStore } from '../../../store';
import EvmTransactionUpdater from './Evm/updater';

export const TransactionUpdater = () => {
  // const { chainType } = useStore((state: any) => state.chainSlice);
  return <EvmTransactionUpdater />;

  //  <>
  // {chainType === BlockchainType.EVM &&
  //  <EvmTransactionUpdater />
  //  }
  //  </>;
};
