import { useQuery } from 'react-query';
import { TokenInfo } from '../../../constants/tokenConfig';
import { IStore, useStore } from '../../../store';
import { IEvmProvider } from '../../../store/provider/Evm/EvmProvider';
import { useMemo } from 'react';
import { tokensConfig } from './config';

export function useFetchToken(token: TokenInfo, options?: any) {
  const walletAddress = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  )?.walletAddress;
  const multicallProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  )?._multicallProvider;
  const evmManager = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  )?._evmManager;
  const evmProvider = useStore(
    (state: IStore) => state.chainSlice.chainProvider as IEvmProvider
  );

  const shouldFetch = useMemo(
    () => !!(multicallProvider && walletAddress && evmManager),
    [evmManager, multicallProvider, walletAddress]
  );
  const { queryKey, queryFn } = useMemo(
    () =>
      tokensConfig(
        token,
        walletAddress,
        evmManager?.address ?? null,
        multicallProvider,
        evmProvider
      ),
    [evmManager?.address, multicallProvider, token, walletAddress, evmProvider]
  );

  return useQuery(queryKey, queryFn, {
    // refetchOnMount: false,
    ...options,
    enabled: shouldFetch,
  });
}
