import { PositionInfo } from '../../types/responses/evm';
import { IPositionDetails } from '../../types/position';
import { SliceCreator } from '..';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import { PositionInfoMap } from '../../constants/position';

export interface IPositionSlice {
  positions: PositionInfoMap | null;
  setPositions: (positions: {
    [strategyAddress: string]: PositionInfo;
  }) => void;
  initSubscription: () => void;
  positionsRefetch:
    | (<TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
      ) => Promise<QueryObserverResult<PositionInfoMap, unknown>>)
    | null;
  setPositionsRefetch: (
    positionsRefetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<PositionInfoMap, unknown>>
  ) => void;
  positionDetails: IPositionDetails[] | null;
  setPositionDetails: () => void;
  setPositionsIsLoading: (isLoading: boolean) => void;
  positionsIsLoading: boolean;
}

export const usePositionSlice: SliceCreator<IPositionSlice> = (
  set,
  get,
  api,
  setSelf,
  getSelf
) => {
  // const { getPositionInfo } = useMockPositionInfo();

  const _state = {};

  const state = {
    positionDetails: null,
    positionInfos: null,
    positionsRefetch: null,
    positions: null,
    positionsIsLoading: true,
  };

  const _actions = {};

  const actions = {
    setPositions: (positionInfos: PositionInfoMap) => {
      setSelf((state) => {
        state.positions = positionInfos;
      });
    },

    setPositionsRefetch: (
      positionsRefetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
      ) => Promise<QueryObserverResult<PositionInfoMap, unknown>>
    ) => {
      !getSelf().positionsRefetch &&
        setSelf((state) => (state.positionsRefetch = positionsRefetch));
    },
    // grabs positionInfos from evmManager and also separates out arrays of postionIDs and chainIDs
    // setPositionDetails: async () => {
    //   const positionInfos = Object.values(getSelf().positions!);
    //   const strategiesMap: StrategyInfoMap = get().strategySlice.strategies!;
    //   const positionDetails: IPositionDetails[] = [];
    //   // Populate position details from strategy slice, get share amount using strategy contract
    //   for (let i = 0; i < positionInfos.length; i++) {
    //     // If the strategy location differs from the current chain,
    //     // then it means we need to refer to the Aperture manager contract on that chain to get detailed information.
    //     // TODO: Implement crosschiain position lookup.
    //     const { strategyId, equity } = positionInfos[i];
    //     const strategyInfo = strategiesMap[strategyId];

    //     const deposit = 1000;
    //     positionDetails.push({
    //       myDeposit: deposit,
    //       currValue: Number(equity),
    //       strategyInfo,
    //       title: '',
    //       strategyType: StrategyType.PSEUDO_DELTA_NEUTRAL,
    //       yields: {
    //         yieldVal: Number(equity) - deposit,
    //         yieldPercent: (Number(equity) - deposit) / Number(equity),
    //       },
    //     });
    //   }
    //   set((state: any) => {
    //     state.positionSlice.positionDetails = positionDetails;
    //   });
    // },
    setPositionsIsLoading: (isLoading: boolean) => {
      setSelf((state) => {
        state.positionsIsLoading = isLoading;
      });
    },
  };
  const subscriptions = {
    //   initSubscription: () => {
    //     api.subscribe(
    //       (state: any) => state.positionSlice.positions,
    //       (positions: PositionInfoMap) => {
    //         getSelf().setPositionDetails();
    //       }
    //       // { fireImmediately: true }
    //     );
    //   },
  };

  const slice: IPositionSlice = {
    ..._state,
    ...state,
    ..._actions,
    ...actions,
    ...subscriptions,
  };

  return slice;
};
