//this is a hard coded one, need some improvement with finalized button
import { SvgProps } from '@aperture/assetkit';
import { HtmlComponent } from 'bizcharts/lib/plots/core/dependents';
import { forwardRef, FunctionComponent, useEffect, useState } from 'react';
import { ERC20TokenMap } from '../../../../../constants/tokenConfig';
import './index.scss';

interface ButtonGroupsContentProps {
  text: string;
  value: string;
}

interface ButtonGroupsProps {
  input: ButtonGroupsContentProps[];
  onSelect: (value: string) => void;
  type?: 'default' | 'small';
}

interface ToggleButtonGroupContentProps {
  text: string;
  value: string;
  icon?: FunctionComponent<React.PropsWithChildren<SvgProps>>;
}

interface ToggleButtonGroupProps {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ButtonGroups = forwardRef<HtmlComponent, ButtonGroupsProps>(
  ({ input, onSelect, type = 'default' }, ref) => {
    const [selected, setSelected] = useState(input[0].value);
    return (
      <div
        className={`selectedButtonList ${type === 'default' ? 'button-3' : 'small button-5'
          }`}
        style={{
          alignItems: 'center',
        }}
      >
        {input.map((item) => (
          <div
            key={item.value}
            className={`selectebutton${type === 'small' ? ' small' : ''} ${selected === item.value ? 'selectedbutton' : 'unselectedbutton'
              }`}
            onClick={(e) => {
              e.stopPropagation();
              setSelected(item.value);
              onSelect(item.value);
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    );
  }
);

export const ERC20TokenButtonGroup = forwardRef<
  HtmlComponent,
  ToggleButtonGroupProps
>(({ setSelected }, ref) => {
  const [all, setAll] = useState(true);
  const [tokenList, setTokenList] = useState(['']);
  const content: Array<ToggleButtonGroupContentProps> = [
    { text: 'All', value: 'all' },
  ];

  Object.values(ERC20TokenMap).forEach((item, key) => {
    content.push({
      text: item.ticker,
      value: item.ticker.toLowerCase(), //Object.keys(ERC20TokenMap)[key],
      icon: item.icon,
    });
  });

  const toggleOption = (id: string) => {
    if (id === 'all') {
      setAll(true);
      setTokenList(['']);
    } else {
      setAll(false);
      setTokenList((prevSelected) => {
        const newArray = [...prevSelected];
        if (newArray.includes(id)) {
          return newArray.filter((item) => item !== id);
        } else {
          newArray.push(id);
          return newArray;
        }
      });
    }
  };

  useEffect(() => {
    if (tokenList.length === 1 && tokenList[0] === '') {
      setAll(true);
    }
    if (tokenList.length === content.length) {
      setAll(true);
      setTokenList(['']);
    }
    setSelected(tokenList);
  }, [tokenList, content.length, setSelected]);

  return (
    <div className="selectedButtonList button-3">
      {content.map((item) => {
        const ItemIcon = item.icon;
        return (
          <div
            key={item.value}
            style={{
              padding: '4px 8px',
              gap: '4px',
              display: 'flex',
              alignItems: 'center',
            }}
            className={`selectebutton ${tokenList.includes(item.value) ||
              (item.value === 'all' && all === true)
              ? 'selectedbutton'
              : 'unselectedbutton'
              }`}
            onClick={(e) => {
              e.stopPropagation();
              toggleOption(item.value);
            }}
          >
            {ItemIcon && (
              <ItemIcon style={{ height: '16px', width: '16px' }} />
            )}{' '}
            {item.text}
          </div>
        )
      })}
    </div>
  );
});
