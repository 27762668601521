/**
 * A function that returns a slice of the state and actions for the strategy slice.
 * @param {Function} set - The set function to update the state.
 * @param {Function} get - The get function to get the state.
 * @param {Object} api - The api object to subscribe to state changes.
 * @returns {IStrategySlice} - The slice of the state and actions for the strategy slice.
 */

import { StrategyInfoMap } from '../../constants/strategy';
import { SliceCreator } from '..';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
export interface IStrategySlice {
  strategies: StrategyInfoMap | null;
  oracles: { [strategyId: string]: string };
  totalTvl: number | null;
  setStrategies: (strategies: StrategyInfoMap) => void;
  setTotalTvl: (totalTvl: number) => void;
  setOracles: (oracles: { [strategyId: string]: string }) => void;
  strategiesRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<StrategyInfoMap, unknown>>;
  setStrategiesRefetch: (
    strategiesRefetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<StrategyInfoMap, unknown>>
  ) => void;
}

// TODO: use this data structure for crosschain support.
// export interface IStrategiesMap {
//   [strategyChainId: number]: {
//     [strategyId: string]: StrategyInfo;
//   };
// }
export const useStrategySlice: SliceCreator<IStrategySlice> = (
  set,
  get,
  api,
  setSelf,
  getSelf
) => {
  // const { getStrategyInfo } = useMockStrategies();

  const _state = {};

  const state = {
    strategies: null,
    strategiesMap: null,
    totalTvl: null,
    strategiesRefetch: null,
  };

  const _actions = {};

  const actions = {
    setStrategiesRefetch: (
      strategiesRefetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
      ) => Promise<QueryObserverResult<StrategyInfoMap, unknown>>
    ) => {
      !getSelf().strategiesRefetch &&
        setSelf((state) => (state.strategiesRefetch = strategiesRefetch));
    },
    // initStrategies: async () => {
    //   const strategiesMap: IStrategiesMap = {};
    //   const strategyInfos: StrategyInfo[] = [];
    //   // Using mock data hook for now, TODO: Implement hydration for actual strategy data from node.
    //   // TODO: Use poller to hydrate strategy data with pre-defined interval.
    //   let totalTvl = 0;
    //   try {
    //     for (const strategy of strategies) {
    //       const strategyInfo = await getStrategyInfo(strategy);
    //       if (!strategyInfo) continue;
    //       if (!strategiesMap[strategy.strategyChainId])
    //         strategiesMap[strategy.strategyChainId] = {};
    //       strategiesMap[strategy.strategyChainId][strategy.strategyId] =
    //         strategyInfo;
    //       strategyInfos.push(strategyInfo);
    //       totalTvl += strategyInfo.TVL;
    //     }
    //   } catch (e: any) {
    //     console.error('Error getting strategy info' + e);
    //   }

    //   set((state: any) => {
    //     state.strategySlice.strategies = strategyInfos;
    //     state.strategySlice.strategiesMap = strategiesMap;
    //     state.strategySlice.totalTvl = totalTvl;
    //   });
    // },

    initSubscription: () => {
      // api.subscribe(
      //   (state: any) => state.appSlice.networkType,
      //   (networkType: NetworkType) => {
      //     const { initStrategies } = get().strategySlice;
      //     initStrategies();
      //   },
      //   { fireImmediately: true }
      // );
    },
    setStrategies: (strategies: StrategyInfoMap) => {
      setSelf((state) => (state.strategies = strategies));
    },
    setTotalTvl: (totalTvl: number) => {
      setSelf((state) => (state.totalTvl = totalTvl));
    },
    setOracles: (oracles: { [strategyId: string]: string }) => {
      setSelf((state) => (state.oracles = oracles));
    },
  };

  const slice: IStrategySlice = {
    ..._state,
    ...state,
    ..._actions,
    ...actions,
  };

  return slice;
};
