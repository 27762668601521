import { getStrategy } from '.';
import { ProtocolDataResponses } from '../../../api/axios/api-config';
import { Strategy, StrategyInfo } from '../../../constants/strategy';
import { keys } from '../../keyNames';
import { providers } from '@0xsequence/multicall';
import { getStrategyInfo } from '../api/getStrategy';

export const strategiesConfig = (
  strategy: Strategy,
  strategyInfo: StrategyInfo,
  protocolData: ProtocolDataResponses
) => ({
  queryKey: [keys.strategies, strategy.strategyId],
  queryFn: () => getStrategy(strategy, strategyInfo, protocolData),
});

export const strategiesInfoConfig = (
  strategy: Strategy,
  multicallProvider: providers.MulticallProvider
) => ({
  queryKey: [keys.strategyInfo, strategy.strategyId],
  queryFn: () => getStrategyInfo(strategy, multicallProvider),
});
