import LibUpdater from '../../../../lib/hooks/transactions/updater';
import { useCallback, useMemo } from 'react';
import { useAddPopup } from '../../../base/application/hooks';
import { IStore, useStore } from '../../..';
import { PopupType } from '../../../base/AppSlice';
import { useQueryClient } from 'react-query';
import { keys } from '../../../../features/keyNames';

export default function EvmTransactionUpdater() {
  const queryClient = useQueryClient();

  const chainId = useStore((state: IStore) => state.chainSlice.chainId);
  const checkedTransaction = useStore(
    (state: IStore) => state.chainSlice.transactionProvider?.checkedTransaction
  );
  const finalizeTransaction = useStore(
    (state: IStore) => state.chainSlice.transactionProvider?.finalizeTransaction
  );

  const addPopup = useAddPopup();
  const transactions = useStore(
    (state: IStore) => state.chainSlice.transactionProvider?.transactions
  );
  const onCheck = useCallback(
    ({
      chainId,
      hash,
      blockNumber,
    }: {
      chainId: number;
      hash: string;
      blockNumber: number;
    }) => {
      if (!checkedTransaction) return;
      checkedTransaction({ chainId, hash, blockNumber });
    },
    [checkedTransaction]
  );
  const onReceipt = useCallback(
    ({
      chainId,
      hash,
      receipt,
    }: {
      chainId: number;
      hash: string;
      receipt: any;
    }) => {
      if (!finalizeTransaction) return;
      finalizeTransaction({
        chainId,
        hash,
        receipt: {
          blockHash: receipt.blockHash,
          blockNumber: receipt.blockNumber,
          contractAddress: receipt.contractAddress,
          from: receipt.from,
          status: receipt.status,
          to: receipt.to,
          transactionHash: receipt.transactionHash,
          transactionIndex: receipt.transactionIndex,
        },
      });
      addPopup(
        {
          [PopupType.TXN]: { hash },
        },
        hash
      );
      queryClient
        .refetchQueries([keys.positionInfo], { active: true })
        .then((res) => {
          queryClient.refetchQueries([keys.positions], { active: true });
        })
        .then((res) => {
          if (process.env.NODE_ENV === 'development') {
            console.log('Done refetching positions.');
          }
        });
    },
    [addPopup, finalizeTransaction, queryClient]
  );

  const pendingTransactions = useMemo(
    () => (chainId && transactions ? transactions[Number(chainId)] ?? {} : {}),
    [chainId, transactions]
  );

  return (
    <>
      {transactions && chainId && (
        <LibUpdater
          pendingTransactions={pendingTransactions}
          onCheck={onCheck}
          onReceipt={onReceipt}
        />
      )}
    </>
  );
}
