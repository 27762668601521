import { SvgProps } from '..';

export const MediumIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.343 12.5C14.343 15.9169 11.5655 18.6875 8.13759 18.6875C7.32396 18.6886 6.51807 18.5294 5.76595 18.219C5.01384 17.9087 4.33021 17.4532 3.75412 16.8786C3.17802 16.3041 2.72074 15.6217 2.40837 14.8704C2.09601 14.1191 1.93468 13.3136 1.93359 12.5C1.93359 9.08176 4.71109 6.31251 8.13759 6.31251C8.95135 6.31124 9.75738 6.47029 10.5097 6.78058C11.2619 7.09086 11.9457 7.5463 12.522 8.12088C13.0982 8.69546 13.5556 9.37793 13.8681 10.1293C14.1805 10.8807 14.3419 11.6863 14.343 12.5ZM21.1492 12.5C21.1492 15.7175 19.7605 18.3245 18.0472 18.3245C16.334 18.3245 14.9452 15.7161 14.9452 12.5C14.9452 9.28251 16.334 6.67551 18.0472 6.67551C19.7605 6.67551 21.1492 9.28388 21.1492 12.5ZM23.9336 12.5C23.9336 15.382 23.4455 17.7181 22.8418 17.7181C22.2396 17.7181 21.7515 15.3806 21.7515 12.5C21.7515 9.61801 22.2396 7.28188 22.8432 7.28188C23.4455 7.28188 23.9336 9.61801 23.9336 12.5Z"
        fill="white"
      />
    </svg>
  );
};
