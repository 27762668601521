import './AssetModal.scss';

import CloseIcon from '@mui/icons-material/Close';
import { Grid, Modal } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  DEFAULT_SLIPPAGE,
  InvestType,
  MIN_DEPOSIT_AMOUNT,
  MIN_WITHDRAW_AMOUNT,
} from '../../../../constants/crosschain';
import { StrategyInfo } from '../../../../constants/strategy';
import { useFetchToken } from '../../../../features/tokens/hooks';
import {
  BN,
  isSlippageRelatedError,
  slippageToNumber,
} from '../../../../helpers/utilities';
import useDebounce from '../../../../lib/hooks/useDebounce';
import { useStore } from '../../../../store';
import { useSlippageToleranceWithDefault } from '../../../../store/base/application/hooks';
import textContext from '../../../../textContext';
import { PositionInfo } from '../../../../types';
import ApproveButton from '../../../Asset/ApproveButton';
import AssetSettings from '../../../Asset/AssetSettings';
import AssetStrategy from '../../../Asset/AssetStrategy';
import InvestButton from '../../../Asset/InvestButton';
import WithdrawalSlider from '../../../Asset/WithdrawalSlider';
import { ModalProps } from '../../../layout/ModalsContainer';
import TransactionSettings from '../../Widgets/TransactionSettings';
import { LeftIcon } from '@aperture/assetkit';
import { useMatchBreakpoints, QuestionMarkTooltip } from '@aperture/uikit';

// Comps
const Close = styled('span')`
  margin: 0rem !important;
  position: absolute !important;
  right: 22px !important;
  top: 30px !important;
  cursor: pointer;
  @media (max-width: 1199px) {
    right: initial !important;
    left: 20px !important;
    top: 30px !important;
  }
`;

const FeeGrid = styled(Grid)`
  gap: 12px;
  div > p {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  > div {
    justify-content: space-between;
    @media (max-width: 1199px) {
      display: grid;
      grid-template-columns: 1fr 1.05fr;
      align-items: end;
      line-height: 1.17;
    }
  }
`;

export interface AssetProps {
  title: string;
  positionInfo: PositionInfo | null | undefined;
  invest?: boolean;
  initial?: string | number;
  strategyInfo: StrategyInfo;
  vaultItem: JSX.Element;
}

const StyledLeftIcon = styled(LeftIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.buttonModalLeft};
  }
`;

export default function Asset(props: AssetProps & ModalProps) {
  const { title, positionInfo, onClose, invest, vaultItem, strategyInfo } =
    props;

  const prevWalletAddressRef = useRef<string | null | undefined>();
  const language = useStore((state) => state.appSlice.language);
  const slippage = useSlippageToleranceWithDefault(DEFAULT_SLIPPAGE);
  const chainInfo = useStore((state) => state.chainSlice.chainInfo);
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const approve = useStore((state) => state.chainSlice.chainProvider?.approve);
  const walletAddress = useStore(
    (state) => state.chainSlice.chainProvider?.walletAddress
  );
  const balance = useStore((state) => state.chainSlice.chainProvider?.balance);
  const investFn = useStore((state) => state.chainSlice.chainProvider?.invest);

  const text = textContext[language].assets;
  const { investText, withdrawText } = text;

  const {
    data: tokenInfo,
    refetch: refetchTokenInfo,
    isRefetching: isTokenRefetching,
    isLoading: tokenInfoIsLoading,
  } = useFetchToken(strategyInfo.tokens[0]);
  const tokenIsLoading = Boolean(
    tokenInfoIsLoading || isTokenRefetching || !tokenInfo
  );
  const [amount, setAmount] = React.useState<string>(
    !invest ? positionInfo?.equity! : '0'
  );
  //TODO: Refactor debounce delay to a constant.
  const debouncedAmount = useDebounce(amount, 1000);

  const [isApproved, setIsApproved] = React.useState(false);
  const [gasEstimate, setGasEstimate] = React.useState('0');
  const [estimateError, setEstimateError] = React.useState('');
  const [sliderValue, setSliderValue] = React.useState(100);
  const debouncedWithdrawRatio = useDebounce(sliderValue / 100 + '', 1000);
  const { isSm } = useMatchBreakpoints();
  useEffect(() => {
    if (
      isWalletConnected &&
      prevWalletAddressRef.current &&
      walletAddress !== prevWalletAddressRef.current
    ) {
      onClose();
    }
    prevWalletAddressRef.current = walletAddress;
  }, [isWalletConnected, onClose, walletAddress]);
  useEffect(() => {
    if (!strategyInfo || !investFn || !tokenInfo) return;
    if (invest && !isApproved) return;
    if (invest && BN(tokenInfo?.displayBalance ?? 0).lte(0)) return;
    const getGasPrice = async () => {
      setGasEstimate('0');
      setEstimateError('');
      try {
        const gasPrice: string = await investFn({
          strategyInfo,
          positionInfo: positionInfo ?? undefined,
          amount: invest
            ? debouncedAmount !== '0'
              ? debouncedAmount
              : Number(tokenInfo?.displayBalance) > 0
                ? tokenInfo?.displayBalance
                : '1'
            : debouncedWithdrawRatio
              ? debouncedWithdrawRatio !== '0'
                ? debouncedWithdrawRatio
                : '1'
              : '1',
          token: tokenInfo!,
          slippage: slippageToNumber(slippage),

          type: invest
            ? positionInfo?.equity
              ? InvestType.INCREASE
              : InvestType.OPEN
            : InvestType.DECREASE,
          assetAddr: tokenInfo?.tokenContractAddress,
          estimateGas: true,
        });
        setGasEstimate(gasPrice);
      } catch (err: any) {
        if (BN(amount).gt(0)) {
          if (isSlippageRelatedError(err.toString())) {
            setEstimateError(
              `Your ${invest ? 'deposit' : 'withdraw'
              } failed due to slippage settings`
            );
          } else {
            // Failed with other reason
            if (!err.toString().includes('transfer amount exceeds balance')) {
              setEstimateError(
                `Your ${invest ? 'deposit' : 'withdraw'
                } failed due to ${err.toString()}`
              );
            }
          }
        }
      }
    };
    getGasPrice();
    console.log('getting gas estimate');
  }, [
    debouncedAmount,
    invest,
    tokenInfo?.tokenContractAddress,
    debouncedWithdrawRatio,
    strategyInfo,
    tokenInfo,
    slippage,
    positionInfo?.equity,
    investFn,
    positionInfo,
    isApproved,
    amount,
  ]);

  useEffect(() => {
    if (!isWalletConnected) return onClose();
    refetchTokenInfo();
  }, [refetchTokenInfo, walletAddress, isWalletConnected, onClose]);

  function calculateMaxDeposit() {
    const availableMax = BN(strategyInfo.capacity)
      .minus(strategyInfo.TVL)
      .div(strategyInfo.leverage);
    return BN(strategyInfo.maxDeposit).gt(availableMax)
      ? availableMax.toString()
      : strategyInfo.maxDeposit;
  }

  return (
    <>
      {/* {isOpen && ( */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={() => onClose()}
        sx={{
          '& .MuiBackdrop-root': { display: isSm ? 'none' : 'flex' },
        }}
      >
        <div className="assetModal">
          <div>
            <div className="assetHeightContainer">
              <Close onClick={() => onClose()}>
                {isSm ? <StyledLeftIcon /> : <CloseIcon />}
              </Close>
              <h4 className="title">{invest ? investText : withdrawText}</h4>
              {/* {errMsg && <ErrorMessage />} */}
              <div className="contentContainer">
                <div className="assetContainer">
                  <Grid container direction="row">
                    <Grid item xs={12} sm={11}>
                      <AssetStrategy
                        vaultItem={vaultItem}
                        title={title}
                        leverage={strategyInfo.leverage}
                      />
                    </Grid>
                    <Grid sx={{ textAlign: 'right' }} item xs={0} sm={1}>
                      <TransactionSettings
                        mobile={isSm}
                        strategyInfo={strategyInfo}
                        placeholderSlippage={DEFAULT_SLIPPAGE}
                      />
                    </Grid>
                  </Grid>

                  {invest && (
                    <AssetSettings
                      isLoading={tokenIsLoading}
                      token={tokenInfo ?? null}
                      invest={invest}
                      amount={amount}
                      setAmount={setAmount}
                      maxDeposit={calculateMaxDeposit()}
                      tokenInAvax={strategyInfo.tokenInAvax}
                    />
                  )}
                  {!invest && (
                    <WithdrawalSlider
                      {...{
                        amount: Number(positionInfo?.userEquity ?? 0)!,
                        maxAmount: Number(positionInfo?.userEquity ?? 0)!,
                        token: strategyInfo.tokens[0],
                        setSliderValue,
                        sliderValue,
                        // maxWithdraw: strategyInfo.maxWithdraw,
                        withdrawFee: strategyInfo.withdrawFee.toNumber() / 1e4,
                      }}
                    />
                  )}

                  <Grid
                    justifyContent="space-between"
                    container
                    style={{ alignItems: 'center' }}
                    className={isSm ? 'subtitle-1' : 'subtitle-2'}
                  >
                    <Grid item display={'flex'} alignItems={'center'}>
                      <span>Gas Fee</span>
                      <QuestionMarkTooltip
                        title={`Gas Fee will be deducted from your wallet balance
                            directly (not from the current value of your
                            Aperture investment).`}
                        placementPC={'bottom-start'}
                        placementMobile={'bottom'}
                      />
                    </Grid>
                    <Grid item>
                      <div>
                        {chainInfo &&
                          `${BN(gasEstimate).lte(0) ? '--' : gasEstimate} ${chainInfo.nativeDenom
                          }`}
                      </div>
                    </Grid>
                  </Grid>
                  <FeeGrid
                    className={isSm ? 'subtitle-3' : 'subtitle-2 medium'}
                    container
                  >
                    {!invest && (
                      <Grid container item>
                        <Grid item>
                          <p>Withdrawal Fee</p>
                        </Grid>
                        <Grid item>
                          <p
                            style={{ textAlign: 'right' }}
                            className={isSm ? 'subtitle-1' : 'subtitle-2'}
                          >
                            {`${strategyInfo.withdrawFee.toNumber() / 1e2}%`}
                          </p>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container item>
                      <Grid item>
                        <p>
                          {invest
                            ? 'Minimum Deposit Value After Slippage'
                            : 'Minimum Receivable After Fees And Slippage'}
                        </p>
                      </Grid>
                      <Grid item>
                        <p
                          style={{ textAlign: 'right' }}
                          className={isSm ? 'subtitle-1' : 'subtitle-2'}
                        >
                          {BN(
                            invest
                              ? BN(amount).times(100)
                              : BN(positionInfo?.userEquity!)
                                .times(sliderValue)
                                .times(
                                  1 -
                                  strategyInfo.withdrawFee.toNumber() / 1e4
                                )
                          )
                            .times(
                              1 - slippageToNumber(slippage)
                            )
                            .div(100)
                            .toFixed(4)}
                          {' ' + strategyInfo.tokens[0].ticker}
                        </p>
                      </Grid>
                    </Grid>
                  </FeeGrid>
                </div>
                {invest && (
                  <ApproveButton
                    tokenIsLoading={tokenIsLoading}
                    amount={amount}
                    token={tokenInfo ?? null}
                    rawToken={strategyInfo.tokens[0]}
                    isWalletConnected={isWalletConnected}
                    walletAddress={walletAddress ?? null}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    approve={approve ?? null}
                  />
                )}

                {/* {isOpen && ( */}
                <InvestButton
                  tokenIsLoading={tokenIsLoading}
                  slippage={slippageToNumber(slippage)}
                  gasEstimate={gasEstimate}
                  amount={invest ? amount : sliderValue / 100 + ''}
                  invest={invest ?? false}
                  strategyInfo={strategyInfo}
                  positionInfo={positionInfo}
                  token={tokenInfo ?? null}
                  isApproved={isApproved}
                  alertText={
                    invest
                      ? BN(amount).gt(0)
                        ? BN(amount).gt(calculateMaxDeposit())
                          ? `Not enough supply`
                          : BN(amount).gt(tokenInfo!.displayBalance)
                            ? `Insufficient ${tokenInfo?.ticker} Balance`
                            : BN(amount).lt(
                              MIN_DEPOSIT_AMOUNT /
                              Number(strategyInfo.tokenInAvax ?? '1')
                            )
                              ? `Minimum deposit amount is ${MIN_DEPOSIT_AMOUNT /
                              Number(strategyInfo.tokenInAvax ?? '1')
                              } ${tokenInfo?.ticker}`
                              : BN(gasEstimate).gt(balance ?? 0)
                                ? 'Insufficient Funds For Gas Fee'
                                : estimateError !== ''
                                  ? estimateError
                                  : ''
                        : ''
                      : BN(amount).lt(MIN_WITHDRAW_AMOUNT)
                        ? `Minimum withdraw amount is ${MIN_WITHDRAW_AMOUNT} ${tokenInfo?.ticker}`
                        : BN(gasEstimate).gt(balance ?? 0)
                          ? 'Insufficient Funds For Gas Fee'
                          : estimateError !== ''
                            ? estimateError
                            : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
