import React from 'react';
import { ColoredValue } from '../../../components/ColoredValue';
import { Loader } from '../../../components/Loader';
import { Tag } from '../../../components/Tag';
import { Subtitle2 } from '../../../components/Typography';
import {
  StyledButtonGroup,
  StyledProgressBar,
  StyledTVLCapacity,
  TableRow,
} from '../CardInfo/SharedComponents';
import { PortfolioProps } from './types';

export const PortfolioCardWeb: React.FC<PortfolioProps> = ({
  ratio,
  vaultItem,
  strategyType,
  tvlCapacity,
  position,
  buttonGroup,
}) => {
  return (
    <TableRow ratio={ratio}>
      <div>{vaultItem}</div>
      <div>
        {strategyType === 'crab' ? (
          <Tag variant={'crab'} />
        ) : (
          <Tag variant={'bull'} />
        )}
      </div>
      <StyledTVLCapacity>
        {tvlCapacity ? (
          <>
            <Subtitle2>{tvlCapacity.content}</Subtitle2>
            <StyledProgressBar percentage={tvlCapacity.percentage} />
          </>
        ) : (
          <Loader size="30%" />
        )}
      </StyledTVLCapacity>
      <Subtitle2>{position.currentValue}</Subtitle2>
      <Subtitle2>
        <ColoredValue value={Number(position.profits[0])}>
          {position.profits[1]}
        </ColoredValue>
      </Subtitle2>
      <StyledButtonGroup>{buttonGroup}</StyledButtonGroup>
    </TableRow>
  );
};
