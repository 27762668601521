import { animated, config, useSpring } from '@react-spring/web';
import styled from 'styled-components';
import { CaretIcon } from './caret';
import { AccordionArrowProps } from './types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  justify-content: space-evenly;
`;

const StyledCaretIcon = styled(CaretIcon)`
  fill: ${({ theme }) => theme.colors.text};
`;

const AccordionArrow: React.FC<AccordionArrowProps> = ({
  hover,
  expanded,
}: {
  hover: boolean;
  expanded: boolean;
}) => {
  const hoverTrans = useSpring({
    config: config.stiff,
    from: { size: '0%', opacity: 0 },
    to: {
      size: hover || expanded ? '100%' : '0%',
      opacity: hover || expanded ? 1 : 0,
    },
  });

  const upDownTrans = useSpring({
    config: config.stiff,
    from: { transform: 'rotate(0deg)' },
    to: {
      transform: `rotate(${expanded ? 0 : 180}deg`,
    },
  });

  return (
    <Wrapper>
      <animated.div style={{ ...hoverTrans, ...upDownTrans }}>
        <StyledCaretIcon />
      </animated.div>
    </Wrapper>
  );
};

export default AccordionArrow;
