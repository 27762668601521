import { ConnectDialogCard } from '../ConnectDialogCard';
import './selectNetwork.scss';
import { Blockchain } from '../../../../constants/crosschain/index';
import { FunctionComponent } from 'react';
import { SvgProps } from '@aperture/assetkit';

interface SelectNetworkProps {
  chainList: {
    name: string;
    icon: FunctionComponent<React.PropsWithChildren<SvgProps>>;
    chain: Blockchain;
    disabled?: boolean;
  }[];
  onSelect: (chainId: number) => void;
  selectedChain: Blockchain | null;
}

export const SelectNetwork = ({
  chainList = [],
  onSelect,
  selectedChain,
}: SelectNetworkProps) => {
  return (
    <div className="connectBody borderBottom">
      <div className="headline-4 medium" style={{ paddingBottom: '32px' }}>
        Select Network
      </div>
      {chainList && (
        <div className="chain-list">
          {chainList.map((chainInfo, i) => (
            <ConnectDialogCard
              disabled={chainInfo.disabled ? true : false}
              text={chainInfo.name}
              Icon={chainInfo.icon}
              onClickReturn={chainInfo.chain}
              onSelect={onSelect}
              selected={selectedChain === chainInfo.chain}
              key={i}
            />
          ))}
        </div>
      )}
    </div>
  );
};
