import { SvgProps } from '@aperture/assetkit';
import { FunctionComponent } from 'react';
import { ConnectDialogCard } from '../ConnectDialogCard';

interface SelectNetworkProps {
  walletList: {
    name: string;
    icon: FunctionComponent<React.PropsWithChildren<SvgProps>>;
    walletType: string;
    disabled?: boolean;
  }[];
  onSelect: (walletType: string) => void;
}

export const SelectWallet = ({
  walletList = [],
  onSelect,
}: SelectNetworkProps) => {
  return (
    <div style={{ padding: '40px 24px 32px' }}>
      <div className="headline-4 medium" style={{ paddingBottom: '32px' }}>
        Connect Wallet
      </div>
      <div className="wallet-list">
        {walletList &&
          walletList.map((walletInfo, i) => (
            <ConnectDialogCard
              text={walletInfo.name}
              Icon={walletInfo.icon}
              onClickReturn={walletInfo.walletType}
              onSelect={onSelect}
              disabled={walletInfo.disabled ? true : false}
              key={i}
            />
          ))}
      </div>
    </div>
  );
};
