import React from 'react';
import './spinner.scss';
import '../../theme/theme.css';
// Zustand Store
import { IStore, useStore } from '../../../../store';
import { dark } from '@mui/material/styles/createPalette';
import styled from 'styled-components';
import { ConfirmationIcon } from '@aperture/assetkit';

export const Spinner = () => {
  return (
    <div className="circularGContainer">
      <div className="circularG circularG_1"></div>
      <div className="circularG circularG_2"></div>
      <div className="circularG circularG_3"></div>
      <div className="circularG circularG_4"></div>
      <div className="circularG circularG_5"></div>
      <div className="circularG circularG_6"></div>
      <div className="circularG circularG_7"></div>
      <div className="circularG circularG_8"></div>
    </div>
  );
};

export const ConfirmationSpinner = styled(ConfirmationIcon)`
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  linearGradient {
    stop:first-child {
      stop-color: ${({ theme }) => theme.colors.startupColor1};
    }
    stop:nth-child(2) {
      stop-color: ${({ theme }) => theme.colors.startupColor2};
    }
    stop:nth-child(3) {
      stop-color: ${({ theme }) => theme.colors.startupColor3};
    }
  }
`;
