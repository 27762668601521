import { SvgProps } from '..';

export const WrongNetwork2Icon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9994 16.1811L1.66919 6.21709C2.21954 5.7461 2.80515 5.33063 3.40978 4.95121L2.41214 3.95361C1.64021 4.45992 0.902682 5.0296 0.216952 5.67395C-0.0596451 5.93379 -0.0737436 6.36818 0.186046 6.64477L10.4986 17.6575C10.6282 17.7965 10.8101 17.875 11.0001 17.875H11.0008C11.1908 17.875 11.3727 17.7958 11.503 17.6568L13.7297 15.2711L12.7566 14.2981L10.9994 16.1811Z"
        fill="white"
      />
      <path
        d="M21.9995 6.11429C21.9934 5.93165 21.9149 5.75981 21.7819 5.63494C18.8379 2.88762 15.009 1.37499 11.0001 1.37499C8.90455 1.37499 6.8695 1.82559 4.97144 2.62411L2.34728 0L1.37512 0.972153L19.653 19.25L20.6251 18.2778L15.6069 13.2596L21.8155 6.60714C21.9398 6.47355 22.0062 6.29629 21.9995 6.11434L21.9995 6.11429ZM14.6338 12.2865L6.02556 3.67824C7.60676 3.08509 9.28103 2.74994 11.0001 2.74994C14.4303 2.74994 17.7174 3.96379 20.3297 6.18272L14.6338 12.2865Z"
        fill="white"
      />
    </svg>
  );
};
