import { v4 as uuidv4 } from 'uuid';
import { InvestType, StrategyType } from '../../constants/crosschain';
import { StrategyInfo } from '../../constants/strategy';
import { SerializedTokenInfo } from '../../constants/tokenConfig';
import { BN } from '../../helpers/utilities';
import { useStore } from '../../store';
import textContext from '../../textContext';
import { PositionInfo } from '../../types';
import { Button } from '../v1';
import './styles/InvestButton.scss';
import { WarningIcon } from '@aperture/assetkit';
import styled from 'styled-components';

interface InvestButtonProps {
  invest: boolean;
  amount: string;
  token: SerializedTokenInfo | null;
  tokenIsLoading: boolean;
  isApproved: boolean;
  strategyInfo: StrategyInfo;
  slippage: number;
  gasEstimate: string;
  positionInfo: PositionInfo | null | undefined;
  alertText: string;
}
export default function InvestButton(props: InvestButtonProps) {
  const {
    invest,
    amount,
    token,
    isApproved,
    strategyInfo,
    slippage,
    gasEstimate,
    positionInfo,
    alertText,
    tokenIsLoading,
  } = props;

  const language = useStore((state) => state.appSlice.language)!;
  const text = textContext[language as keyof object]['assets' as keyof object];
  const { depositText, withdrawText } = text;
  const isWalletConnected = useStore(
    (state) => state.chainSlice.isWalletConnected
  );
  const addPendingTransaction = useStore(
    (state) => state.transactionManagerSlice.addPendingTransaction
  );

  const handleInvest = async () => {
    if (!isWalletConnected || positionInfo === undefined || !token) return;

    const strategyType = strategyInfo.strategyType;
    if (
      strategyType === StrategyType.PSEUDO_DELTA_NEUTRAL ||
      strategyType === StrategyType.PSEUDO_1X_LONG
    ) {
      if (invest) {
        if (
          positionInfo !== null &&
          positionInfo.strategyId === strategyInfo.strategyId
        ) {
          // Increase position instead if user has a position.
          addPendingTransaction(uuidv4(), {
            status: 'INIT',
            transactionPayload: {
              strategyInfo,
              positionInfo,
              amount: amount,
              token,
              slippage,
              type: InvestType.INCREASE,
              assetAddr: token!.tokenContractAddress,
            },
            timestamp: new Date().getTime(),
          });
        } else {
          addPendingTransaction(uuidv4(), {
            status: 'INIT',
            transactionPayload: {
              strategyInfo,
              amount: amount,
              token,
              slippage,
              type: InvestType.OPEN,
              assetAddr: token!.tokenContractAddress,
            },
            timestamp: new Date().getTime(),
          });
        }
      } else if (positionInfo !== null && positionInfo !== undefined) {
        // TODO: implement withdrawl
        addPendingTransaction(uuidv4(), {
          status: 'INIT',
          transactionPayload: {
            strategyInfo,
            positionInfo,
            amount: amount,
            token,
            slippage,
            type: InvestType.DECREASE,
            assetAddr: token!.tokenContractAddress,
          },
          timestamp: new Date().getTime(),
        });
      }
    } else {
      console.log(strategyType);
      // TODO: implement this
      if (invest) {
      } else {
        // const ancWithdrawAmount = withdrawValue && Number(amount) / Number(exchange.exchange_rate);
      }
    }
    // props.onClose();
  };

  const StyledWarningIcon = styled(WarningIcon)`
    linearGradient{
      stop:first-child {
        stop-color: ${({ theme }) => theme.colors.warningColor1};
      }
      stop:nth-child(2) {
        stop-color: ${({ theme }) => theme.colors.warningColor2};
      }
    }
  `;

  return (
    <div className="confirmAsset">
      {alertText !== '' ? (
        <Button variant="error" text={alertText}>
          <StyledWarningIcon />
        </Button>
      ) : (
        <Button
          variant="contained"
          light={true}
          size="large"
          onClick={handleInvest}
          disabled={
            (invest && !isApproved) ||
            !isWalletConnected ||
            !token ||
            tokenIsLoading ||
            positionInfo === undefined ||
            BN(gasEstimate).lte(0) ||
            BN(amount).lte(0)
          }
          text={invest ? depositText : withdrawText}
        />
      )}
    </div>
  );
}
