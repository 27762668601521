import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../components/Loader';
import { Subtitle1 } from '../../../components/Typography';
import {
  StyledProgressBar,
  StyledTVLCapacity,
} from '../CardInfo/SharedComponents';
import { StyledInfoDetail, StyledInfoTitle } from './SharedComponents';
import { TVLAndCapacityProps } from './types';

const StyledSubtitle1 = styled(Subtitle1)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TVLAndCapacity: React.FC<TVLAndCapacityProps> = ({
  tvlCapacity,
}) => {
  return (
    <StyledInfoDetail>
      <StyledInfoTitle>TVL/Capacity</StyledInfoTitle>
      <StyledTVLCapacity sm={true}>
        {tvlCapacity ? (
          <>
            <StyledSubtitle1>{tvlCapacity.content}</StyledSubtitle1>
            <StyledProgressBar percentage={tvlCapacity.percentage} />
          </>
        ) : (
          <Loader />
        )}
      </StyledTVLCapacity>
    </StyledInfoDetail>
  );
};
