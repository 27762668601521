import { GoToIcon } from '@aperture/assetkit';
import {
  QuestionMarkTooltip,
  StrategyDescription,
  useMatchBreakpoints,
} from '@aperture/uikit';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { StrategyTypeTextMap } from '../../constants/crosschain';
import { PositionInfoMap } from '../../constants/position';
import { StrategyInfo, StrategyInfoMap } from '../../constants/strategy';
import { strategies } from '../../constants/strategy/strategies';
import { useFetchEvmPositionInfo } from '../../features/positions/hooks';
import { BN, getVaultName } from '../../helpers/utilities';
import { Box, ERC20TokenButtonGroup } from '../v1';
import SearchBar from '../v1/Widgets/Search/SearchBar';
import './styles/Invest.scss';
import TableAssets from './TableAssets';

const AssetTableRow = styled.div`
  display: grid;
  grid-template-columns: 276px 12fr 24fr 33fr 18fr;
  width: calc(100% - 32px);
  grid-column-gap: auto;
  align-items: center;
  justify-items: start;
  > div {
    width: 100%;
  }
`;
const AssetTableRowHeader = styled(AssetTableRow)`
  align-items: baseline;
`;
const ApyRow = styled.div`
  display: grid;
  grid-template-columns: 12fr 10fr 11fr;
  width: 100%;
  grid-column-gap: auto;
  align-items: center;
  justify-items: start;
  > div {
    width: 100%;
  }
`;
const StyledGoToIcon = styled(GoToIcon)`
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

type SortType =
  | ''
  | 'vault'
  | 'strategy'
  | 'leverage'
  | 'tvl'
  | 'capacity'
  | 'apy'
  | 'apyWeek'
  | 'apySeason';

const Table = (props: any) => {
  const [sort, setSort] = React.useState<SortType>('');
  const ArrowDown = (props: any) => (
    <span
      style={props.style}
      className={`${props.active ? 'active' : ''} sorting-icon`}
    >
      {' '}
      &#x25bc;{' '}
    </span>
  );

  const UpdateSort = (sortType: SortType) => {
    sort === sortType ? setSort('') : setSort(sortType);
    props.onSelect!(sortType);
  };

  return (
    <AssetTableRowHeader className="tableItems">
      <div className="subtitle-2" onClick={() => UpdateSort('vault')}>
        Vault <ArrowDown active={sort === 'vault'} />
      </div>
      <div className="subtitle-2" onClick={() => UpdateSort('strategy')}>
        Strategy <ArrowDown active={sort === 'strategy'} />
      </div>
      <div>
        <span className="subtitle-2" onClick={() => UpdateSort('tvl')}>
          {' '}
          TVL <ArrowDown active={sort === 'tvl'} />
        </span>
        {' / '}
        <span className="subtitle-2" onClick={() => UpdateSort('capacity')}>
          Capacity <ArrowDown active={sort === 'capacity'} />
        </span>
      </div>
      <Grid direction="row" container>
        <Grid
          sx={{ paddingBottom: '13px' }}
          item
          display={'flex'}
          alignItems={'center'}
        >
          <span className="subtitle-2">Projected Annualized Returns</span>
          <QuestionMarkTooltip
            title={`The 7-day and 3-month APYs are calculated based on real performance 
                    over the designated period of time, with all the fees included. 
                    The real-time APY is calculated assuming the same market condition 
                    where fees are not applicable.`}
            placementPC={'bottom'}
            placementMobile={'bottom'}
            toolTipClassName={'apy-bottom-tooltip'}
          />
        </Grid>
        <ApyRow>
          <div onClick={() => UpdateSort('apy')} className="subtitle-3">
            Real-time <ArrowDown active={sort === 'apy'} />
          </div>

          <div onClick={() => UpdateSort('apyWeek')} className="subtitle-3">
            7-day <ArrowDown active={sort === 'apyWeek'} />{' '}
          </div>

          <div onClick={() => UpdateSort('apySeason')} className="subtitle-3">
            3-month <ArrowDown active={sort === 'apySeason'} />
          </div>
        </ApyRow>
      </Grid>
      <Grid item md={1.5}>
        <div className="subtitle-2">Action</div>
      </Grid>
    </AssetTableRowHeader>
  );
};

interface IInvestProps {
  filters: { text: string }[];
  title: string;
}

export default function Invest(props: IInvestProps) {
  const { isSm } = useMatchBreakpoints();
  const [positionMap, setPositionMap] = React.useState<PositionInfoMap>({});
  const [strategyInfoMap, setStrategyInfoMap] = React.useState<StrategyInfoMap>(
    {}
  );
  const [strategyOrder, setStrategyOrder] = React.useState<{
    [strategyId: string]: number;
  }>({});
  const [sort, setSort] = React.useState<SortType>('tvl');
  const [reverse, setReverse] = React.useState<boolean>(false);
  const { data: positionInfoData } = useFetchEvmPositionInfo();
  const { unpopulatedPositionInfoMap: positionInfo } = positionInfoData ?? {};
  const handleSort = useCallback(
    (a: StrategyInfo, b: StrategyInfo) => {
      const getA = !reverse ? a : b;
      const getB = !reverse ? b : a;

      const poolSort = () =>
        getB.tokens[0].name < getA.tokens[0].name ? -1 : 1;
      const strategySort = () =>
        StrategyTypeTextMap[getB.strategyType] <
        StrategyTypeTextMap[getA.strategyType]
          ? -1
          : 1;
      const leverageSort = () => getB.leverage - getA.leverage;
      const tvlSort = () => BN(getB.TVL).minus(getA.TVL).toNumber();
      const capacitySort = () =>
        BN(getB.capacity).minus(getA.capacity).toNumber();
      const apyWeekSort = () =>
        getB.apy_7days !== null &&
        getB.apy_7days !== undefined &&
        getA.apy_7days !== null &&
        getA.apy_7days !== undefined
          ? getB.apy_7days - getA.apy_7days
          : 0;
      const apySeasonSort = () =>
        getB.apy_season !== null &&
        getB.apy_season !== undefined &&
        getA.apy_season !== null &&
        getA.apy_season !== undefined
          ? getB.apy_season - getA.apy_season
          : 0;
      const apySort = () =>
        getB.apy !== null &&
        getB.apy !== undefined &&
        getA.apy !== null &&
        getA.apy !== undefined
          ? Number(getB.apy) - Number(getA.apy)
          : 0;

      switch (sort) {
        case 'vault':
          return poolSort();
        case 'strategy':
          return strategySort();
        case 'leverage':
          return leverageSort();
        case 'tvl':
          return tvlSort();
        case 'capacity':
          return capacitySort();
        case 'apy':
          return apySort();
        case 'apyWeek':
          return apyWeekSort();
        case 'apySeason':
          return apySeasonSort();
        default:
          return 1;
      }
    },
    [reverse, sort]
  );

  useEffect(() => {
    const order: { [strategyId: string]: number } = {};
    Object.values(strategyInfoMap)
      .sort(handleSort)
      .forEach((s, i) => {
        order[s.strategyId] = i;
      });
    setStrategyOrder(order);
  }, [handleSort, strategyInfoMap]);

  const [search, setSearch] = React.useState('');
  const [strategyType, setStrategyType] = React.useState('');
  const [assets, setAssets] = React.useState(['']);

  const handleSearch = (fil: string) => {
    setSearch(fil);
  };

  const TableInit = useMemo(() => {
    return strategies.map((item, i) => {
      const title = getVaultName(item.tokens);
      return (
        <TableAssets
          title={title}
          positionInfo={positionInfo?.[item.strategyId] ?? null}
          setStrategyInfoMap={setStrategyInfoMap}
          strategyOrder={strategyOrder?.[item.strategyId]}
          strategy={item}
          key={i}
          search={search}
          strategyType={strategyType}
          assets={assets}
          setPositionMap={setPositionMap}
        />
      );
    });
  }, [positionInfo, strategyOrder, search, strategyType, assets]);

  const GoTo = () => (
    <a
      href={
        'https://docs.aperture.finance/docs/aperture-originals/crab-market-leveraged-farming'
      }
      target="_blank"
      rel="noreferrer"
      className="goTo"
    >
      <StyledGoToIcon />
    </a>
  );

  return (
    <React.Fragment>
      <Box className="investContainer">
        <div>
          <GoTo />
          <div className={`title ${isSm ? 'headline-3' : 'headline-2'}`}>
            {' '}
            {props.title}{' '}
          </div>
          <StrategyDescription />
        </div>
        <SearchBar handleSearch={handleSearch} setStrategy={setStrategyType} />
        <div className="assets-button-group">
          <span className="subtitle-2">Yield Generating Assets:</span>
          <ERC20TokenButtonGroup setSelected={setAssets} />
        </div>
        <div className="investTable">
          <div className="tableHeaders">
            {!isSm && (
              <Table
                onSelect={(data: any) => {
                  setReverse(data === sort ? !reverse : true);
                  setSort(data);
                }}
              />
            )}
            {TableInit}
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
}
