import React from 'react';
import { APYGroup, StrategyType, TVLAndCapacity } from '../CardInfo';
import { StyledInfoDetail } from '../CardInfo/SharedComponents';
import { PDNPropsMobile } from './types';

const StrategyCard: React.FC<PDNPropsMobile> = ({
  vaultItem,
  strategyType,
  tvlCapacity,
  apy,
  MobileButtonGroup,
}) => {
  return (
    <>
      <span>{vaultItem}</span>
      <StrategyType strategyType={strategyType} />
      <TVLAndCapacity tvlCapacity={tvlCapacity} />
      <APYGroup apy={apy} />
      <StyledInfoDetail>{MobileButtonGroup}</StyledInfoDetail>
    </>
  );
};

export default StrategyCard;
