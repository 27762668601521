import React from 'react';
import { useMatchBreakpoints } from '../../../contexts';
import { StrategyCardContainer } from '../StrategyCardContainer';
import { StrategyDropdown } from '../StrategyDropdown';
import PDNCardMobile from './PDNCardMobile';
import PDNCardWeb from './PDNCardWeb';
import { PDNCardProps } from './types';

export const PDNCard: React.FC<PDNCardProps> = ({
  order,
  ratio,
  vaultItem,
  strategy,
  buttonGroup,
  MobileButtonGroup,
  isLoading,
  ...props
}) => {
  const { isSm } = useMatchBreakpoints();
  return (
    <StrategyCardContainer
      order={order}
      title={
        <>
          {isSm ? (
            <PDNCardMobile
              {...{
                vaultItem,
                strategyType: strategy.strategyType,
                tvlCapacity: strategy.tvlCapacity,
                apy: strategy.apy,
                MobileButtonGroup,
              }}
            />
          ) : (
            <PDNCardWeb
              {...{
                ratio,
                vaultItem,
                strategyType: strategy.strategyType,
                tvlCapacity: strategy.tvlCapacity,
                apy: strategy.apy,
                buttonGroup,
              }}
            />
          )}
        </>
      }
      details={
        <StrategyDropdown
          {...{ vaultItem, strategy, MobileButtonGroup, ...props }}
        />
      }
      isLoading={isLoading}
    />
  );
};
