import React from 'react';
import NumericInput from 'react-numeric-input';
import './styles/WithdrawalSlider.scss';

// Material
import { Grid, Slider } from '@mui/material';
import { TokenInfo } from '../../constants/tokenConfig';
import { useMatchBreakpoints, QuestionMarkTooltip } from '@aperture/uikit';

interface WithdrawalSliderProps {
  amount: number;
  token: TokenInfo;
  sliderValue: number;
  setSliderValue: React.Dispatch<React.SetStateAction<number>>;
  withdrawFee: number;
}
export default function WithdrawalSlider(props: WithdrawalSliderProps) {
  const { amount, token, setSliderValue, sliderValue, withdrawFee } = props;

  const { isSm } = useMatchBreakpoints();
  const handleSliderValue = (val: any) => {
    setSliderValue(val ?? 0);
  };

  const marks = ((steps: number, range: number) => {
    const step = range / steps;
    const marks = [];
    for (let i = 0; i <= steps; i++) {
      const value = i * step;

      marks.push({ value, label: value + '%' });
    }
    return marks;
  })(4, 100);

  function valuetext(value: number) {
    return `${value}%`;
  }

  return (
    <div className="withdrawal-slider">
      <Grid flexDirection={'row'} gap={'24px'} container>
        <Grid item>
          <div className="subtitle-1 semiBold">
            WITHDRAWAL PERCENTAGE
            <QuestionMarkTooltip
              title={`Choose withdrawal amount by dragging the slider to desired
                withdraw percentage (e.g. withdraw everything = 100%).`}
              placementPC={'bottom'}
              placementMobile={'bottom'}
              toolTipClassName={'withdraw-tooltip'}
            />
          </div>
        </Grid>
        <Grid container item>
          <Grid container item>
            <Grid item className="slider-set-percentage">
              {/* TODO: Make re-usable slider component */}
              <Slider
                aria-label="Custom marks"
                value={sliderValue}
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
                min={0}
                max={100}
                step={0.1}
                marks={marks}
                valueLabelDisplay="auto"
                sx={{
                  width: '100%',
                  'margin-top': '24px',
                  '& .MuiSlider-thumb': {
                    width: '15px',
                    height: '15px',
                    color: '#DFEEF5',
                  },
                  '& .MuiSlider-rail': {
                    height: '10px',
                  },
                  '& .MuiSlider-track': {
                    height: '10px',
                  },
                  '& .MuiSlider-markLabel': {
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0.0025em',
                    '&:first-of-type': {
                      left: '2%!important',
                    },
                    '&:last-of-type': {
                      left: '98%!important',
                    },
                  },
                  '& .MuiSlider-mark': {
                    background: '#DFEEF5',
                    height: '10px',
                    width: '10px',
                    borderRadius: '100%',
                  },

                  '& .MuiSlider-valueLabel': {
                    height: 'auto',
                    width: 'auto',
                    borderRadius: '10px',
                    padding: '8px',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    letterSpacing: '0.0025em',
                  },
                  '& .MuiSlider-valueLabel:before': {
                    content: 'none',
                  },
                }}
                onChange={(e, v) => handleSliderValue(v)}
              />
            </Grid>
            <Grid
              item
              sx={{ margin: isSm ? '0px' : 'auto', marginRight: '0px' }}
            >
              <NumericInput
                className="selectValue headline-4 medium"
                style={false}
                min={0}
                max={100}
                precision={1}
                value={sliderValue}
                step={0.1}
                format={(x) => (x === null ? 0 : x) + '%'}
                parse={(x) => {
                  const f = parseFloat(x.replace(/%/, ''));
                  return f > 100 ? 100 : f < 0 ? 0 : f;
                }}
                onChange={handleSliderValue}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className="estimated-amount">
          <Grid item>
            <p className={isSm ? 'subtitle-1' : 'subtitle-2'}>
              Estimated Amount To Withdraw
            </p>
          </Grid>
          <Grid item>
            <p className={isSm ? 'subtitle-1' : 'headline-4 medium'}>
              ~
              {Math.floor(amount * (1 - withdrawFee) * sliderValue * 100) /
                10000}{' '}
              {token.ticker}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
