import { Grid } from '@mui/material';
import { TransactionModal } from '..';
import { useModalIsOpen } from '../../../../../store/base/application/hooks';
import { ModalType } from '../../../../../store/base/AppSlice';
import { Button } from '../../../Components/Button';
import { MobilePopOver } from '../../../Modal/MobilePopOver';
import styled from 'styled-components';
import { RejectedIcon } from '@aperture/assetkit';
import { useMatchBreakpoints } from '@aperture/uikit';

export const RejectedModal = ({ onClose }: { onClose: any }) => {
  const isOpen = useModalIsOpen(ModalType.TXN_REJECTED);
  const { isSm } = useMatchBreakpoints();

  const StyledRejectIcon = styled(RejectedIcon)`
    linearGradient {
      stop:first-child {
        stop-color: ${({ theme }) => theme.colors.rejectModalIconColor1};
      }
      stop:nth-child(2) {
        stop-color: ${({ theme }) => theme.colors.rejectModalIconColor2};
      }
    }
  `;
  const Content = () => (
    <Grid
      container
      direction={'column'}
      style={{
        alignItems: 'center',
        gap: isSm ? '24px' : '48px',
        padding: isSm ? '24px' : '0px',
      }}
    >
      <Grid item>
        <StyledRejectIcon />
      </Grid>
      <Grid item>
        <div className="headline-1">Transaction Rejected </div>
      </Grid>
      {!isSm && (
        <Grid item>
          <Button
            variant="contained"
            light={true}
            size="large"
            text="Dismiss"
            onClick={() => onClose()}
            style={{ width: '483px', height: '60px' }}
          />
        </Grid>
      )}
    </Grid>
  );
  return (
    <>
      {isOpen && !isSm ? (
        <TransactionModal onClose={onClose}>
          <Content />
        </TransactionModal>
      ) : (
        <MobilePopOver
          open={isOpen}
          onClose={onClose}
          cross={true}
          className="transaction-pop-over"
        >
          <Content />
        </MobilePopOver>
      )}
    </>
  );
};
